import React, {useCallback, useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as StudentApi from "../../api/StudentApi";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";
import {ErrorMessage, Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import {useNavigate, useParams} from "react-router-dom";
import * as FacultyApi from "../../api/FacultyApi";
import * as DepartmentApi from "../../api/DepartmentApi";
import ImageUploader from "../ImageUploader/ImageUploaderWithPreview";
import * as LinkFactory from "../../util/LinkFactory";
import {FILES_URL} from "../../util/LinkFactory";
import {toast} from "react-toastify";
import * as ProgramApi from "../../api/ProgramApi";
import TextFieldComponent from "../common/TextFieldComponent";


function StudentEditForm() {
    const params = useParams();

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [gradeSetup, setGradeSetup] = useState([]);
    const [sectionSetup, setSectionSetup] = useState([]);
    const [studentInfo, setStudentInfo] = useState({});
    const [loader, setLoader] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [department, setDepartment] = useState([]);
    const [faculty, setFaculty] = useState(null);
    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });
    const [program, setProgram] = useState({});
    const [session, setSession] = useState("");
    const [batchNo, setBatchNo] = useState("");
    const [programs, setPrograms] = useState([]);
    let PhotoRootUrl;
    const [image, setImage] = useState([]);
    let fileBase64s = [];
    const [bloodGroup, setBloodGroup] = useState([
        {bloodGroup: "A+"},
        {bloodGroup: "A-"},
        {bloodGroup: "B+"},
        {bloodGroup: "B-"},
        {bloodGroup: "O+"},
        {bloodGroup: "O-"},
        {bloodGroup: "AB+"},
        {bloodGroup: "AB-"}
    ]);

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(prevState => [
                    ...prevState,
                    {id: params.id, src: e.target.result}
                ]);
            };
            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    useEffect(() => {
        if (params.id) getStudentInfo(params.id);
    }, [params]);

    useEffect(() => {
        // GradeSetupApi.getAll().then(json => {
        //     setGradeSetup(json.data);
        // });
        // SectionSetupApi.getAll().then(json => {
        //     setSectionSetup(json.data);
        // });

    }, []);
    const getStudentInfo = id => {
        setLoader(true);
        StudentApi.getStudentInfoById(id)
            .then(json => {
                PhotoRootUrl = SERVER_NAME_URL + json.data.photoName;
                let response = json.data;
                // if (response.grade.id) {
                //     response.gradeName = response.grade.gradeName;
                // }
                // if (response.section.id) {
                //     response.sectionName = response.section.sectionName;
                // }
                FacultyApi.getAllFaculties().then(json => {
                    setFaculties(json.data);
                    if (response.department) {
                        DepartmentApi.getAllByFacultyId(response.department.faculty.id).then(json => {
                            setDepartment(json.data);
                            if (response.program) {
                                ProgramApi.getAllByDepartmentId(response.department.id).then(json => {
                                    setPrograms(json.data);
                                    setFormValue(response);
                                });
                            }
                        });
                    } else {
                        setFormValue(response);
                    }
                });
            })
            .catch(errors => {
                console.error(errors);
            })
            .finally(() => setLoader(false));
    };

    function setFormValue(data) {
        const dataSet = {
            id: params.id,
            firstName: data.firstName || "",
            middleName: data.middleName || "",
            lastName: data.lastName || "",
            fatherName: data.fatherName || "",
            motherName: data.motherName || "",
            permanentAddress: data.permanentAddress || "",
            presentAddress: data.presentAddress || "",
            photoName: data.photoName || "",
            section: data.section || null,
            grade: data.grade || null,
            bloodGroup: {bloodGroup: data.bloodGroup} || "",
            healthConcern: data.healthConcern || "",
            emergencyContact: data.emergencyContact || "",
            emergencyContactPerson: data.emergencyContactPerson || "",
            contactNo: data.contactNo || "",
            department: data?.department || {},
            faculty: data?.department?.faculty || {},
            program: data.program || {},
            session: data.session,
            batchNo: data.batchNo,
            rollNo: data.rollNo
        };
        setStudentInfo(dataSet)
    }

    const setFacultyObj = (fieldName, value) => {
        getDepartment(value.id);
    }
    const setDepartmentObj = value => {
        setDepartment(value)
        getProgramsByDepartmentId(value.id);
    }
    const getDepartment = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartment(json.data);
        });
    }
    const getProgramsByDepartmentId = (departmentId) => {
        ProgramApi.getAllByDepartmentId(departmentId).then(json => {
            setPrograms(json.data);
        });
    }
    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    const handleSessionType = value => {
        // console.log(value);
        console.log(value.currentTarget.value);
        setSession(value.currentTarget.value);
    }
    const handleSubmit = values => {
        console.log(values);
        const dataSet = {
            id: params.id,
            firstName: values.firstName || "",
            middleName: values.middleName || "",
            lastName: values.lastName || "",
            fatherName: values.fatherName || "",
            motherName: values.motherName || "",
            permanentAddress: values.permanentAddress || "",
            presentAddress: values.presentAddress || "",
            photoName: values.photoName || "",
            section: values.section || null,
            grade: values.grade || null,
            bloodGroup: values.bloodGroup.bloodGroup || "",
            healthConcern: values.healthConcern || "",
            emergencyContact: values.emergencyContact || "",
            emergencyContactPerson: values.emergencyContactPerson || "",
            contactNo: values.contactNo || "",
            photoAttachment: image.length > 0 ? image[0].blob : null,
            photoAttachmentBlobType: image.length > 0 ? image[0].type : null,
            department: values.department,
            program: values.program,
            session: values.session,
            batchNo: values.batchNo,
            rollNo: values.rollNo
        };
        StudentApi.updateStudentInfo(dataSet)
            .then(response => {
                notifyUpdate();
                navigateTo(LinkFactory.studentsList);
            })
            .catch(err => console.error(err));
    };
    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    const TextArea = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
        <textarea
            {...field}
            className="form-control"
            aria-label="industry"
            aria-describedby="basic-addon1"
        />
            </div>
        );
    };
    const handleProfilePhotoSelection = file => {
        if (file.length > 0)
            fileToDataUri(file[0]).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file[0].type});
            });
        setImage(fileBase64s);
    }
    if (studentInfo && !loader) {
        return (
            <>
                <CommonLayout headerName={"Update Student Data"}>
                    <main>
                        <div className="card-default student-edit-form container-responsive">
                            <div className="card-body">
                                <label className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                    Profile Photo
                                </label>
                                <ImageUploader
                                    image={
                                        studentInfo?.photoName
                                            ? FILES_URL + studentInfo?.photoName
                                            : null
                                    }
                                    cbImage={file => handleProfilePhotoSelection(file)}
                                />
                                <br/>
                                <br/>
                                <Formik
                                    initialValues={studentInfo}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}>
                                    {({props, values, setFieldValue}) => {
                                        return (
                                            <Form>
                                                <div className="row">
                                                    <div className="col-lg-4 col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                First Name{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="firstName"
                                                                placeholder="First Name"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Middle Name{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="middleName"
                                                                placeholder="Middle Name"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Last Name{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="lastName"
                                                                placeholder="Last Name"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Father Name{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="fatherName"
                                                                placeholder="Father Name"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Mother Name{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="motherName"
                                                                placeholder="Mother Name"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-md-6 col-12">*/}
                                                    {/*    <label*/}
                                                    {/*        className="font-weight-medium text-gray-25 text-lg-left mb-2">*/}
                                                    {/*        {" "}*/}
                                                    {/*        Select Grade{" "}*/}
                                                    {/*    </label>*/}
                                                    {/*    <Field*/}
                                                    {/*        type="text"*/}
                                                    {/*        name="gradeName"*/}
                                                    {/*        placeholder={values?.gradeName || "Select Grade"}*/}
                                                    {/*        fieldName={"gradeName"}*/}
                                                    {/*        label="Grade"*/}
                                                    {/*        handleChangeSection={(fieldName, value) => {*/}
                                                    {/*            setFieldValue("grade", value);*/}
                                                    {/*        }}*/}
                                                    {/*        options={gradeSetup || []}*/}
                                                    {/*        component={SelectDropdown}*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}
                                                    {/*<div className="col-md-6 col-12">*/}
                                                    {/*    <label*/}
                                                    {/*        className="font-weight-medium text-gray-25 text-lg-left mb-2">*/}
                                                    {/*        {" "}*/}
                                                    {/*        Select Section{" "}*/}
                                                    {/*    </label>*/}
                                                    {/*    <Field*/}
                                                    {/*        type="text"*/}
                                                    {/*        name="sectionName"*/}
                                                    {/*        placeholder={*/}
                                                    {/*            values?.sectionName || "Select Section"*/}
                                                    {/*        }*/}
                                                    {/*        fieldName={"sectionName"}*/}
                                                    {/*        label="Section"*/}
                                                    {/*        handleChangeSection={(fieldName, value) =>*/}
                                                    {/*            setFieldValue("section", value)*/}
                                                    {/*        }*/}
                                                    {/*        options={sectionSetup || []}*/}
                                                    {/*        component={SelectDropdown}*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-6 col-12">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Select Faculty{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="faculty"
                                                            placeholder={values?.name || "Select Faculty"}
                                                            fieldName={"name"}
                                                            label="facultyName"
                                                            handleChangeSection={setFacultyObj}
                                                            options={faculties || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Select Department{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="department"
                                                            placeholder={values?.name || "Select Department"}
                                                            fieldName={"name"}
                                                            label="departmentName"
                                                            handleChangeSection={(fieldName, value) => {
                                                                setFieldValue("department", value);
                                                                setDepartmentObj(value)
                                                            }}
                                                            options={department || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Select Program{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="program"
                                                                placeholder={values?.name || "Select Program"}
                                                                fieldName={"name"}
                                                                label="departmentName"
                                                                handleChangeSection={(fieldName, value) => {
                                                                    setFieldValue("programName", value);
                                                                    setProgram(value)
                                                                }

                                                                }
                                                                options={programs || []}
                                                                component={SelectDropdown}
                                                            />
                                                            <ErrorMessage
                                                                name="programName"/>

                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Session{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="session"
                                                                placeholder="Session"
                                                                onChange={value => {
                                                                    setFieldValue("session", value.currentTarget.value);
                                                                    handleSessionType(value)
                                                                }}
                                                                component={TextFieldComponent}
                                                            />
                                                            <ErrorMessage
                                                                name="session"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Batch No{" "}
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="batchNo"
                                                                placeholder="Batch No"
                                                                onChange={value => {
                                                                    setFieldValue("batchNo", value.currentTarget.value);
                                                                }}
                                                                component={TextFieldComponent}
                                                            />
                                                            <ErrorMessage
                                                                name="session"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Permanent Address
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="permanentAddress"
                                                                placeholder="Permanent Address"
                                                                component={TextArea}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Present Address
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="presentAddress"
                                                                placeholder="Present Address"
                                                                component={TextArea}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Emergency Contact Person
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="emergencyContactPerson"
                                                                placeholder="Emergency Contact Person"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Emergency Contact Number
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="emergencyContact"
                                                                placeholder="Emergency Contact Number"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Blood Group
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="bloodGroup"
                                                                placeholder="Select Blood Group"
                                                                fieldName={"bloodGroup"}
                                                                options={bloodGroup || []}
                                                                component={SelectDropdown}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Health Concern
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="healthConcern"
                                                                placeholder="Health Concern"
                                                                component={TextArea}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Student ID
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="rollNo"
                                                                placeholder="Student ID"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                Contact No
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="contactNo"
                                                                placeholder="Contact No"
                                                                component={TextField}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-lg btn-primary">
                                                        <i className="fa fa-save"/> Save
                                                    </button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </main>
                </CommonLayout>
            </>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default StudentEditForm;
