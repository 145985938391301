import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as LiveMatchApi from "../../api/LiveMatchApi";
import * as LinkFactory from "../../util/LinkFactory";
import {FILES_URL, streamsPlayer} from "../../util/LinkFactory";
import {useNavigate, useParams} from "react-router-dom";

export default function LiveStreams(props) {
    const navigate = useNavigate();

    const params = useParams();
    const [streamList, seStreamList] = useState([]);
    const [streamInfo, setStreamInfo] = useState(null);

    useEffect(() => {
        console.log(params);
        if (params.id) getAllLiveStreamByLiveMatchId(params.id)
    }, [params.id]);

    function getAllLiveStreamByLiveMatchId(id) {
        LiveMatchApi.getAllStreamByMatchId(id)
            .then(resp => {
                console.log(resp);
                if (resp.response.status===200){
                    setStreamInfo(resp.data.data)
                    seStreamList(resp.data.data.streamerList)
                }
            })
    }

    const handleStreamPlay = (stream) => {
        console.log(stream);
        navigate(LinkFactory.streamsPlayer + "/" + stream.id, {state: stream});
    };


    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>

                        <div className="container">
                            <div className="row">

                                <div className="col-md-12">
                                    <h2>Streams</h2>
                                    {streamList && streamList.length ?
                                        streamList.map((item, index) => (
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="row card-body">
                                                        <div className="col-sm-6">
                                                            <h5 className="card-title">{item.streamTitle}</h5>
                                                            <p className="card-text">{item.location}</p>
                                                            <button type="button" className="btn btn-primary"
                                                            onClick={()=>handleStreamPlay(item)}
                                                            >Watch</button>
                                                        </div>
                                                        <div className="col-sm-6"
                                                             style={{height: 200, width: 200, overflow: 'hidden'}}>
                                                            <img src={FILES_URL + item.thumbnail}
                                                                 style={{
                                                                     height: '100%', // Fill the height of the parent
                                                                     width: '100%', // Fill the width of the parent
                                                                     objectFit: 'cover' // Maintain aspect ratio, cover the area
                                                                 }}
                                                                 alt={"thumbnail_" + index}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

