import React, {useEffect} from "react";
import Agent from "../../api/Agent";

import * as LinkFactory from "../../util/LinkFactory";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";


export default function EmailVerification(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    useEffect(() => {
        console.log(params.key);
        Agent.Register.verifyOtp(params.key).then(res => {
            console.log(res.data);
            if (res.data.status === 200) {
                navigateTo(LinkFactory.login);
                toast("Email Verified!", "Success");
            }
        });
    }, []);

    return (
        <div>
            <div className="bg-default">
                <div id="reg_layout">
                    <div id="reg_content">
                        <main>
                            <div id="registrations">
                                <div id="login">
                                    <div className="container">

                                        <div className="card-default radius-15 w-60">
                                            <div className="input-reg">
                                                <form>
                                                    <div className="block-group">
                                                        <div className="block m-auto text-center">

                                                            <h5>Your email has been verified</h5>
                                                            <p>Congratulation! you've successfully verified your email address.</p>
                                                            <div className="text-center">
                                                                <img src="img/verify-mail.png"/>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-outline-dark w-100"
                                                                >
                                                                    <p>Go To Dashboard</p>
                                                                </button>
                                                            </div>

                                                            <p className="text-center text-muted">If that doesn't work, copy and paste the following link in your browser:
                                                                <a href="#">https://edooket.com/dashboard</a></p>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            <div id="layoutAuthentication_footer">
                <footer className="py-4 mt-auto" style={{background: '#dadce3'}}>
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-center small">
                            <div className="text-muted">
                                Copyright &copy; Edooket {new Date().getFullYear()}
                            </div>
                            <div></div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );

}
