import React, {useEffect, useRef, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";

import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import FormTitle from "../Typhography/formTitle";
import {ErrorMessage, Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import * as FacultyApi from "../../api/FacultyApi";
import * as DepartmentApi from "../../api/DepartmentApi";
import * as ProgramApi from "../../api/ProgramApi";
import {fetchChannelsApi} from "../../storeManager/channel/middleware/ChannelMiddleware";
import {useDispatch} from "react-redux";
import Swal from "sweetalert2";
import Loader from "../common/Loader";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {SERVER_NAME_URL} from "../../constants/Constants";
import * as GradingApi from "../../api/GradingApi";
import {toast} from "react-toastify";
import * as Utils from "../../util/Utils";
import LinearProgress from "@mui/material/LinearProgress";

function ChannelList() {
    const dispatch = useDispatch();
    let history = useNavigate();
    const FILE_URL = SERVER_NAME_URL;

    function navigateTo(pathname) {
        history(pathname);
    }

    const initSearchField = {
        channelType: "",
        searchText: "",
        faculty: {},
        department: {},
        program: {},
        session: "",
        page: 0
    }

    const searchButton = useRef(null);
    const [channelList, setChannelList] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [id, setId] = useState(null);
    const [faculty, setFaculty] = useState();
    const [department, setDepartment] = useState();
    const [program, setProgram] = useState();
    const [loader, setLoader] = useState(true);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [searchSession, setSearchSession] = useState("");
    const [sessions, setSessions] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageOffset, setPageOffset] = useState(0);
    const [searchFields, setSearchFields] = useState(initSearchField);
    const [searchValues, setSearchValues] = useState(null);


    const changeChannelPage = ({selected}) => {
        setPageNumber(selected);
        setShowProgressBar(true);
    }

    function handleChannelSearch(searchObject) {
        ChannelApi.searchChannel(searchObject).then(json => {
            setLoader(false);
            setChannelList(json?.data?.content);
            setPageCount(json?.data?.totalPages);
            setShowProgressBar(false);
            setPageOffset(pageNumber);
        }).catch(errors => {
            setLoader(false);
            setShowProgressBar(false);
            setPageOffset(pageNumber);
        });
    }

    const handleSearch = values => {
        setSearchValues(values);
        setLoader(true);
        handleChannelSearch({
            channelType: values?.channelType,
            department: values?.department,
            faculty: values?.faculty,
            program: values?.program,
            searchText: values?.searchText,
            page: pageNumber,
            session: searchSession ? searchSession : ""
        });
    }

    useEffect(() => {
        dispatch(fetchChannelsApi());
        // loadAllChannelsWithInitSearch();
        loadFacultyList();
        loadSession();
    }, []);

    useEffect(() => {
        handleSearch(searchValues);
    }, [pageNumber]);

    const loadFacultyList = () => {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties(json.data);
        });
    }
    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    }
    const getProgramsByDepartmentId = (departmentId) => {
        ProgramApi.getAllByDepartmentId(departmentId).then(json => {
            setPrograms(json.data);
        });
    }
    const loadAllChannelsWithInitSearch = () => {
        setLoader(true);
        handleChannelSearch(initSearchField);
    }
    const setFacultyObj = value => {
        setFaculty(value);
        getDepartmentsByFaculty(value.id);
    }
    const setDepartmentObj = value => {
        setDepartment(value);
        getProgramsByDepartmentId(value.id);
    }

    const registrationByXlsx = () => {
        navigateTo(LinkFactory.newChannelXlsx);
    }

    const handleEdit = data => {
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_EXAM_CONTROLLER"]) || getGradeBookForThisRoom(data.id)) {
            navigateTo(LinkFactory.newChannel + "/" + data.id);
        } else {
            toast("This room is already finalized! You can't edit it!")
        }
    };
    const handleView = data => {
        navigateTo(LinkFactory.groupView + "/" + data.id);
    };
    const handleBoardRedirect = data => {
        navigateTo(LinkFactory.channelBoard + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.newChannel);
    };
    const handleDelete = data => {
        setId(data.id);
    };
    const handleChannelRankingSetupNew = data => {
        navigateTo(LinkFactory.channelRankingSetup + "/" + data.id);
    };
    const handleChannelPostRankingView = data => {
        navigateTo(LinkFactory.channelPostRankingView + "/" + data.id);
    };

    const getGradeBookForThisRoom = (channelId) => {
        GradingApi.getGradingByChannelId(channelId).then(res => {
            if (res.data !== null) {
                return !!res?.data?.isFinalized;
            } else {
                return false;
            }
        });
    }

    const channelTypeConversion = channelType => {
        if (channelType === 'class')
            return "Class"
        else if (channelType === 'co-currullar')
            return "Co - Curricular"
        else if (channelType === 'organizational')
            return "Institute Room"
        else if (channelType === 'departmental')
            return "Departmental"
    }
    const deleteData = () => {
        Swal.fire("Working on it!", "We are working on to this delete functionality, for smoother experience please wait", "info");
        // ChannelApi.deleteChannelById(id).then(() => {
        //     setId(null);
        //     dispatch(fetchChannelsApi());
        //     loadAllChannels();
        //     {
        //         Swal.fire("Deleted!", "Successfully Deleted", "success");
        //     }
        // });
    };
    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <>
                <div className={`input-group mb-3`}>
                    <input
                        {...field}
                        type="text"
                        className="form-control"
                        aria-label="industry"
                        aria-describedby="basic-addon1"
                    />
                </div>

            </>
        );
    };

    function resetSearchFields() {

        /*     searchButton.current.click();*/

        setSearchSession("");
        setSearchFields(initSearchField);
        loadAllChannelsWithInitSearch();
        // ChannelApi.searchChannel(initSearchField)
        //     .then(json => {
        //         setChannelList(json.data);
        //     })
        //     .catch(errors => {
        //         console.log(errors);
        //     });
    }

    function loadSession() {
        ChannelApi.getAllCourseSession().then(json => {
            setSessions([...json.data]);
        });
    }

    return (
        <>
            <CommonLayout headerName={process.env.REACT_APP_ENV_NAME}>
                <main>
                    <div className="card-default room-setup container-responsive">
                        <div className="title-header">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="mt-4 mb-4">
                                    Room Setup
                                </h3>
                                <div className="d-flex align-items-center">
                                    <button
                                        type="button"
                                        className="btn bg-c-blue-light"
                                        onClick={() => handleNew()}>
                                        <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.429 15.5V9.071H0V6.93h6.429V.5H8.57v6.429H15V9.07H8.571V15.5H6.43Z" fill="#4575EE"/>
                                        </svg>
                                        Create new Room
                                    </button>
                                    <button
                                        type="button"
                                        className="btn bg-c-blue-light"
                                        onClick={() => registrationByXlsx()}>
                                        <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.429 15.5V9.071H0V6.93h6.429V.5H8.57v6.429H15V9.07H8.571V15.5H6.43Z" fill="#4575EE"/>
                                        </svg>
                                        Bulk Room Creation
                                    </button>
                                    <div>
                                        <a title="Download"
                                           className="btn bg-c-blue-light"
                                           target="_blank"
                                           href={FILE_URL + 'Course_Room_Registration_template.xlsx'}>
                                            <i className="fa fa-file-download mr-2"/>
                                            {"Download Sample"}
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <Formik
                                initialValues={searchFields}
                                enableReinitialize={true}
                                onSubmit={handleSearch}
                                onReset={resetSearchFields}>
                                {({props, values, setFieldValue}) => {
                                    return (
                                        <Form>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="form-group input-default-white">
                                                        <FormTitle>Room Type</FormTitle>
                                                        <Field
                                                            as="select"
                                                            name="channelType"
                                                            className="form-control">
                                                            <option selected>Select Type</option>
                                                            <option value="class">Class</option>
                                                            <option value="co-currullar">Co Curricular</option>
                                                            <option value="organizational"> Organizational</option>
                                                            <option value="departmental">Department</option>
                                                        </Field>
                                                        <ErrorMessage name="channelType"/>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group input-default-white">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Select Faculty{" "}
                                                        </label>
                                                        <br/>
                                                        <Field
                                                            type="text"
                                                            name="faculty"
                                                            placeholder={"Select Faculty"}
                                                            fieldName={"name"}
                                                            label="facultyName"
                                                            handleChangeSection={(fieldName, value) => {
                                                                setFacultyObj(value)
                                                            }}

                                                            options={faculties || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group input-default-white">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Select Department{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="department"
                                                            placeholder={"Select Department"}
                                                            fieldName={"name"}
                                                            label="departmentName"
                                                            handleChangeSection={(fieldName, value) => {
                                                                setDepartmentObj(value)
                                                            }}
                                                            options={departments || []}
                                                            component={SelectDropdown}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group input-default-white">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Select Program{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="program"
                                                            placeholder={"Select Program"}
                                                            fieldName={"name"}
                                                            label="departmentName"
                                                            handleChangeSection={(fieldName, value) =>
                                                                setProgram(value)
                                                            }
                                                            options={programs || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="col-3">
                                                    <div className="form-group input-default-white">
                                                        <label className="font-weight-medium text-gray-25 text-lg-left mb-2">{" "}Select Session{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="session"
                                                            placeholder={"Select Session"}
                                                            fieldName={"name"}
                                                            label="session"
                                                            handleChangeSection={(fieldName, value) =>
                                                                setSearchSession(value?.name)
                                                            }
                                                            options={sessions || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-3 search-btn">
                                                    <label className=" ">&nbsp;</label>
                                                    <button
                                                        ref={searchButton}
                                                        type="submit"
                                                        className="btn btn-red-md radius-25  w-100">
                                                        <i className="fa fa-search mr-2"/>
                                                        Search
                                                    </button>
                                                </div>
                                                {/*<div className="col-3">*/}
                                                {/*    <div className="form-group input-default-white">*/}
                                                {/*        <label*/}
                                                {/*            className="font-weight-medium text-gray-25 text-lg-left mb-2">*/}
                                                {/*            {" "}*/}
                                                {/*            Search Here{" "}*/}
                                                {/*        </label>*/}
                                                {/*        <Field*/}
                                                {/*            type="text"*/}
                                                {/*            name="searchText"*/}
                                                {/*            placeholder="Type Here"*/}
                                                {/*            component={TextField}>*/}

                                                {/*        </Field>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="col-12">
                                                    <div className=" input-default-white d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <button
                                                                type="reset"
                                                                title="Reset Fields"
                                                                onClick={resetSearchFields}
                                                                className="btn btn-none-default radius-25">
                                                                <i className="fa fa-refresh mr-2"/>
                                                                Reset all fields
                                                            </button>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                        <div>
                        {showProgressBar ? (<LinearProgress color="primary" />) : ""}
                        <div className="table-responsive table-light table-striped table-hover"
                            // style={{height: "62vh"}}
                        >
                            <table
                                className="table"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead>
                                <tr>
                                    <th scope="col">Sl.</th>
                                    <th scope="col">Room Name</th>
                                    <th scope="col">Faculty</th>
                                    <th scope="col">Department</th>
                                    <th scope="col">Program</th>
                                    <th scope="col">Type</th>
                                    <th scope="col" width="20%">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {channelList.length > 0
                                    ? channelList.map((data, index) => {
                                        return (
                                            <tr>
                                                <td scope="row">{(index + 1) + pageOffset * 10}</td>
                                                <td>{null != data.courseCode ? data?.courseCode + " : " : ""}{data.channelName}</td>
                                                <td>{null != data.program ? data?.program?.department?.faculty?.name : data?.department?.faculty?.name}</td>
                                                <td>{null != data.program ? data?.program?.department?.name : data?.department?.name}</td>
                                                <td>{data?.program?.name}</td>
                                                <td>{channelTypeConversion(data?.channelType)}</td>
                                                <td className=" ">
                                                    <button
                                                        type="button"
                                                        title="Room Board"
                                                        className="btn btn-sm bg-c-blue-light"
                                                        onClick={() => handleBoardRedirect(data)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="17" height="12" fill="none">
                                                            <path fill="#4575EE"
                                                                  d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                        </svg>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        title="Edit"
                                                        className="btn btn-sm bg-c-light-orange mr-1"
                                                        onClick={() => handleEdit(data)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="18" height="15" fill="none">
                                                            <path fill="#000"
                                                                  d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                        </svg>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        title="Delete"
                                                        className="btn btn-sm bg-c-red-light"
                                                        data-toggle="modal"
                                                        data-target="#deleteModal"
                                                        // onClick={() => handleDelete(data)}
                                                        onClick={() => {
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="13" height="14" fill="none">
                                                            <path fill="#D81414"
                                                                  d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                        </svg>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        title="Configure Post Ranking"
                                                        className="btn btn-sm bg-c-light-green "
                                                        onClick={() => handleChannelRankingSetupNew(data)}>
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        title="View Post Rankings"
                                                        className="btn btn-sm bg-c-light-green"
                                                        onClick={() => handleChannelPostRankingView(data)}>
                                                        <i className="fa fa-star"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <td colSpan="12" className="text-center">
                                        {loader ? <Loader/> : <h5>No data found</h5>}
                                    </td>}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className="page-navigation">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changeChannelPage}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header  ">
                            <div className="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                    <path fill="#D81414"
                                          d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                                </svg>

                            </div>
                            <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className=" "
                                data-dismiss="modal"
                                onClick={deleteData}>
                                Delete
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChannelList;
