import _ from "lodash";
import {
    FORM_CONCAT_ARRAY,
    FORM_ERROR,
    FORM_FIELD_INVESTMENT,
    FORM_FIELD_REGISTER,
    FORM_FILE_REMOVE,
    FORM_FILE_UPLOAD,
    FORM_FILE_UPLOAD_COMPLETED,
    FORM_FILE_UPLOAD_ERROR,
    FORM_LOADING,
    FORM_LOADING_COMPLETE,
    FORM_SET_FIELD,
    FORM_UNLOADED,
    FORM_UPDATE_ARRAY,
    PROPERTY_CATEGORY
} from "../../constants/ActionTypes";

const initialState = {
    files: [],
    errors: []
};

export default function FormReducer(state = initialState, action) {
    state.files = state.files || [];
    switch (action.type) {
        case FORM_FIELD_REGISTER:
            let filter = _.set(state, action.key, action.value);
            return {...state, ...filter};
        case FORM_FIELD_INVESTMENT:
            let data = _.set(state, action.key, action.value);
            return {...state, ...data};

        case FORM_ERROR:
            return {...state, errors: action.payload.errors};
        case FORM_SET_FIELD:
            return {...state, ...action.payload};
        case FORM_UNLOADED:
            return {...initialState};
        case FORM_LOADING:
            return {...state, loading: true};
        case FORM_LOADING_COMPLETE:
            return {...state, loading: false};
        case PROPERTY_CATEGORY:
            return {...state, propertyCategory: action.payload};
        case FORM_FILE_UPLOAD:
        case FORM_FILE_UPLOAD_COMPLETED:
        case FORM_FILE_REMOVE:
        case FORM_FILE_UPLOAD_ERROR:
            return FileUploadReducer(state, action);
        case FORM_CONCAT_ARRAY:
            if (state[action.key] === undefined) {
                return {...state, [action.key]: [action.payload]};
            }
            return {...state, [action.key]: [...state[action.key], action.payload]};
        case FORM_UPDATE_ARRAY:
            return {
                ...state,
                [action.key]: state[action.key].map(item => {
                    return item.id === action.id ? {...action.payload} : item;
                })
            };
        default:
            return state;
    }
}

function FileUploadReducer(state = [], action) {
    switch (action.type) {
        case FORM_FILE_UPLOAD:
            if (state[action.key] === undefined) {
                return {...state, [action.key]: [action.payload]};
            }
            return {...state, [action.key]: [...state[action.key], action.payload]};
        case FORM_FILE_UPLOAD_COMPLETED:
        case FORM_FILE_UPLOAD_ERROR:
            return {
                ...state,
                [action.key]: state[action.key].map(item => {
                    return item.value.name === action.payload.value.name
                        ? {...action.payload}
                        : item;
                })
            };
        case FORM_FILE_REMOVE:
            return {
                ...state,
                [action.key]: state[action.key].filter(item => {
                    console.log(item.value.name, " and ", action.payload);
                    return item.value.name !== action.payload.value.name;
                })
            };
        default:
            return state;
    }
}
