import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveSemester = semester => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/semester/", semester);
};
export const updateSemester = semester => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/semester/", semester);
};
export const getSemesterById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/semester/" + id);
};
export const deleteSemesterById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/semester/" + id);
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/semester");
};

export const searchSemester = (param) => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/semester/search", param);
};
