import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveSubject = subject => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/subject/", subject);
};

export const updateSubject = subject => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/subject/", subject);
};
export const getSubjectById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/subject/" + id);
};
export const deleteSubjectById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/subject/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/subject");
};
