import React, {useEffect, useState} from "react";
import * as SubjectApi from "../../api/SubjectApi";

import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function SubjectList() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [subject, setSubject] = useState([]);
    const [id, setId] = useState(null);
    useEffect(() => {
        SubjectApi.getAll()
            .then(json => {
                console.log(json);
                setSubject(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleEdit = data => {
        navigateTo(LinkFactory.subject + "/" + data.id);
    };
    const handleView = data => {
        navigateTo(LinkFactory.subjectView + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.subjectNew);
    };
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        SubjectApi.deleteSubjectById(id);
        setId(null);
        window.location.reload();
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h2 className="mt-4">
                            Subject
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={() => handleNew()}
                            >
                                Create new Subject
                            </button>
                        </h2>

                        <div className="table-responsive">
                            <table
                                className="table table-bordered mt-4"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col">id</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {subject.length > 0
                                    ? subject.map((data, index) => {
                                        return (
                                            <tr>
                                                <th scope="row">{data.id}</th>
                                                <td>{data.name}</td>
                                                <td className="text-right">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => handleView(data)}
                                                    >
                                                        <i className="fa fa-eye"></i> View
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => handleEdit(data)}
                                                    >
                                                        <i className="fa fa-edit"></i> Edit
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger"
                                                        data-toggle="modal"
                                                        data-target="#deleteModal"
                                                        onClick={() => handleDelete(data)}
                                                    >
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubjectList;
