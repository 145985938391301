import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const sendMessage = directMessageThreadDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/message-thread/",
        directMessageThreadDTO
    );
};
export const sendGroupMessage = directGroupMessageThreadDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/group-message-thread/",
        directGroupMessageThreadDTO
    );
};
export const threadAll = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/messages-thread-list-with-recipients/"
    );
};
export const getMessagesByThreadId = threadId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/messages-by-thread-id/" + threadId
    );
};
export const messageThreadWithRecipientsByThreadId = threadId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/messages-thread-with-recipients-by-thread-id/" + threadId
    );
};
