import React, {useEffect, useState} from "react";
import * as AssignmentApi from "../../api/AssignmentApi";
import * as GradingEvaluationApi from "../../api/GradingEvaluationApi";
import {Field, FieldArray, Form, Formik} from "formik";
import {TextArea} from "semantic-ui-react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SelectDropdown from "../common/SelectDropwodn";
import TextFieldComponent from "../common/TextFieldComponent";
import * as LinkFactory from "../../util/LinkFactory";
import FileUploader from "../Uploader/fileUploader";
import FormTitle from "../Typhography/formTitle";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import LoaderOverlay from "../common/LoaderOverlay";
import * as Utils from "../../util/Utils";
import {AssignmentStatusEnum} from "../../enum/AssignmentStatusEnum";
import {useDispatch} from "react-redux";
import * as AssessmentApi from "../../api/AssessmentApi";
import {addUpcomingAssignmentsSuccess, fetchAssignmentsFailure} from "../../storeManager/assignment/action/AssignmentsAction";
import MarkdownEditor from "../MarkdownEditor";


var dayEnd = new Date();
dayEnd.setHours(23, 59, 59, 999);
const initialState = {
    name: "",
    deadline: dayEnd,
    subject: "",
    id: "",
    assignmentTasks: [],
    breakdown: "",
    assessmentTitle: "",
    assessmentExamDate: new Date(),
    examStartDate: new Date(),
    examEndDate: new Date(),
    assessmentDetails: "",
    assessmentScore: "",
    attachmentName: "",
    attachmentType: ""
};

function AssignmentForm(props) {
    const dispatch = useDispatch();
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [totalScore, setTotalScore] = useState(0);
    const [taskScores, setTaskScores] = useState([]);
    const [assignment, setAssignment] = useState(initialState);
    const [assignmentTask, setAssignmentTask] = useState(props.assignmentTask || null);
    const [gradingEvaluation, setGradingEvaluation] = useState(props.gradingEvaluation || null);
    const [selectedGradingBreakdown, setSelectedGradingBreakdown] = useState(props.selectedGradingBreakdown || null);
    const [selectedExamType, setSelectedExamType] = useState(props.selectedAssessmentType || null);
    const [selectedVisibility, setSelectedVisibility] = useState({});
    const [loader, setLoader] = useState(false);
    const [showTypes, setShowTypes] = useState([{showType: "Visible"}, {showType: "Hidden"}]);
    const [assignmentStatus, setAssignmentStatus] = useState(AssignmentStatusEnum.SAVED);

    useEffect(() => {
        console.log('assignment', assignment);
        console.log('gradingEvaluation', gradingEvaluation);
        if (Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(gradingEvaluation)) {
            setAssignment({
                name: assignment.title,
                deadline: dayEnd,
                subject: assignment.subject,
                id: assignment.id,
                assignmentTasks: assignmentTask,
                breakdown: assignment.breakdown,
                assessmentTitle: assignment.assessmentTitle,
                assessmentExamDate: assignment.assessmentExamDate,
                examStartDate: assignment.examStartDate,
                examEndDate: assignment.examEndDate,
                assessmentDetails: assignment.assessmentDetails,
                assessmentScore: assignment.assessmentScore,
                attachmentName: assignment.attachmentName,
                attachmentType: assignment.attachmentType
            });
        } else {
            setAssignment({
                ...assignment,
                assignmentTasks: [
                    ...assignment.assignmentTasks,
                    {
                        name: "",
                        assignmentAttachmentType: "",
                        assignmentAttachmentName: ""
                    }
                ]
            });
        }
    }, [gradingEvaluation]);

    const setStatusForAssignment = (values) => {
        saveRecord(values, AssignmentStatusEnum.DRAFT)
    }

    const onScoreInput = (data, index) => {
        var scores = taskScores;
        scores[index] = parseInt(data.target.value);
        var total = 0;
        scores.forEach(value => {
            total = total + value;
        })
        setTotalScore(total);
    }
    const onScoreTaskDelete = (index) => {
        var scores = taskScores;
        scores.splice(index, 1);
        var total = 0;
        scores.forEach(value => {
            total = total + value;
        })
        setTotalScore(total);
    }

    function showSuccessAndNavigate(assignmentSaveType) {
        Swal.fire("Success", "Record Saved !", "success");
            navigateTo(LinkFactory.channelBoard + "/" + props.channel.id);
    }

    const handleSubmit = values => {
        saveRecord(values, AssignmentStatusEnum.SAVED);
    };

    function saveRecord(values, assignmentSaveType) { //assignmentSaveType = DRAFT, SAVE, ARCHIVE

        if (isRecordInvalid(values)) {
            Swal.fire("Missing Required fields", "Please fill all the fields", "error");
            return;
        }

        let fullScore = 0;
        let examScore = false;
        setLoader(true);
        values.assignmentTasks.forEach(value => {
            fullScore = fullScore + value.score;
            if (value.score) examScore = true;
        });

        let evaluationDTO = {
            score: values.assignmentTasks && examScore === true ? fullScore : values.assessmentScore ? values.assessmentScore : null,
            title: selectedExamType === "assignment" ? values.name : values.assessmentTitle,
            details: values.assessmentDetails,
            attachmentName: "" !== values.attachmentName ? values.attachmentName : null,
            attachmentType: "" !== values.attachmentType ? values.attachmentType : null,
            gradingBreakdown: props.selectedGradingBreakdown,
            show: selectedVisibility.showType === "Visible",
            examType: selectedExamType,
            examDate: values.deadline,
            examStartDate: values.examStartDate,
            examEndDate: values.deadline,
            assignmentStatus: assignmentSaveType
        };

        GradingEvaluationApi.saveGradingEvaluation(evaluationDTO).then(response => {
            if (response && response.response && response.response.status === 201) {
                if (selectedExamType === "assignment") {
                    let assignmentTasks = [];
                    values.assignmentTasks.forEach(value => {
                        console.log(value);
                        let assignmentTaskDTO = {
                            name: "",
                            score: "",
                            assignmentSubmitType: "",
                            submitContentBlob: "",
                            submitContentBlobType: "",
                            assignment: "",
                            attachmentName: ""
                        };
                        assignmentTaskDTO.name = value.name;
                        assignmentTaskDTO.submitContentBlob = Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(value.assignmentAttachmentName) ? value.assignmentAttachmentName : null;
                        assignmentTaskDTO.submitContentBlobType = Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(value.assignmentAttachmentType) ? value.assignmentAttachmentType : null;
                        assignmentTaskDTO.assignment = response.data;
                        assignmentTaskDTO.score = value.score;
                        assignmentTaskDTO.attachmentName = value.assignmentAttachmentFileName ? value.assignmentAttachmentFileName : null;
                        assignmentTaskDTO.archivedAttachmentName = value.archivedAttachmentName ? value.archivedAttachmentName : null;
                        assignmentTasks.push(assignmentTaskDTO);
                    });
                    let assignmentDTO = {
                        name: values.name,
                        channel: props.channel,
                        deadline: values.deadline,
                        gradingEvaluation: response.data,
                        score: fullScore,
                        assignmentTaskSaveDtoList: assignmentTasks
                    };

                    AssignmentApi.saveAssignmentWithTasks(assignmentDTO).then(response => {

                        if (response && response.response && response.response.status === 201) {
                            AssessmentApi.getAssessmentDTO(response?.data?.id).then(value => {
                                if ("SAVED" === value?.data[0]?.draftOrSave){
                                    dispatch(addUpcomingAssignmentsSuccess(value.data[0]));
                                }
                                setTimeout(showSuccessAndNavigate(assignmentSaveType), 500);
                            }).catch(errors => {
                                dispatch(fetchAssignmentsFailure(errors));
                            });

                        } else {
                            setLoader(false);
                            Swal.fire("Failed", response.response.data.errorMsg, "error");
                        }

                    });

                } else {
                    //exam type assignment save
                    let assignmentDTO = {
                        name: values.assessmentTitle,
                        channel: props.channel,
                        deadline: values.deadline,
                        gradingEvaluation: response.data,
                        score: values.assessmentScore
                    };
                    AssignmentApi.saveAssignment(assignmentDTO).then(response => {
                        console.log(response.response.status, "save asign only");
                        if (response && response.response && response.response.status === 201) {
                            let assignmentTaskDTO = {
                                name: "",
                                assignmentSubmitType: "",
                                submitContentBlob: "",
                                submitContentBlobType: "",
                                assignment: "",
                                score: ""
                            };
                            assignmentTaskDTO.name = values.assessmentTitle;
                            assignmentTaskDTO.submitContentBlob = Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.attachmentName) ? values.attachmentName : null;
                            assignmentTaskDTO.submitContentBlobType = Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.attachmentType) ? values.attachmentType : null;
                            assignmentTaskDTO.assignment = response.data;
                            assignmentTaskDTO.score = values.assessmentScore;

                            AssignmentApi.saveAssignmentTask(assignmentTaskDTO).then(
                                response => {
                                    setLoader(false);
                                    if (response && response.response && response.response.status === 201 && response.data) {
                                        showSuccessAndNavigate(assignmentSaveType);
                                    } else {
                                        Swal.fire("Failed", response.response.data.errorMsg, "error");
                                    }
                                }
                            );
                        } else {
                            setLoader(false);
                            Swal.fire("Failed", response.response.data.errorMsg, "error");
                        }

                    });
                }
            } else {
                setLoader(false);
                Swal.fire("Failed", response.response.data.errorMsg, "error");
            }
        });
    }

    function isRecordInvalid(values) {
        console.log(values);
        if (Object.keys(props.selectedGradingBreakdown).length === 0 || Object.keys(props.selectedAssessmentType).length === 0) {
            return true;
        }
        if (selectedExamType && selectedExamType === 'exam') {
            if (!Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.assessmentScore) ||
                !Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.assessmentTitle) ||
                !Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.assessmentDetails)) {
                return true;
            }
        }
        if (selectedExamType && selectedExamType === 'assignment') {
            if (!Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.name) || !values.assignmentTasks || !values.assignmentTasks.length) {
                return true;
            } else if (values.assignmentTasks.length) {
                let status = false;
                values.assignmentTasks.forEach(value => {
                        if (selectedExamType !== 'exam' && !Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(value.score)) {
                           return status = true;
                        }
                    }
                )
                if (status){
                    return status
                }
            }
        }

        return !Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.examStartDate) || !Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(values.deadline);

    }

    const TextAreaComponent = ({field, form: {touched, errors, ...rest}}) => {
        return (
            <div className="input-group mb-3">
                <TextArea
                    {...field}
                    {...rest}
                    type="text"
                    cols="50"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                    placeholder="Details…"
                />
            </div>
        );
    };
    const DatePickerComponent = ({field, form: {touched, errors, setFieldValue}}) => {
        return (
            <div className="mr-2">
                <DatePicker
                    showTimeInput2
                    selected={Date.parse(field.value)}
                    placeholderText="Pick date and time"
                    onChange={value => setFieldValue(field.name, value)}
                    className="form-control btn-block w-100 overflow-auto "
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                />
            </div>
        );
    };
    const handleVisibilitySelection = (fieldName, value) => {
        setSelectedVisibility(value);
    };

    const fileToDataUri = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const handleBack = () => {
        navigateTo(
            LinkFactory.channelBoard + "/" + props.channel.id
        );
    };

    const handleSaveToQuestionArchive = (values) => {
        saveRecord(values, AssignmentStatusEnum.ARCHIVED)
    }

    useEffect(() => {
        if (props.selectedArchivedQuestion) {
            const selected = props.selectedArchivedQuestion;
            let assignmentTasks = [];

            setTotalScore(selected.score || null);
            if (selected && selected.questionList) {
                selected.questionList.forEach(item => {
                    assignmentTasks.push({
                        name: null,
                        archivedName: item.name || null,
                        score: item.score || 0,
                        archivedAttachmentName: item.attachmentName || null
                    })
                })
            }

            const formData = {
                name: selected.name || null,
                deadline: dayEnd,
                subject: "",
                id: "",
                assignmentTasks: assignmentTasks,
                breakdown: "",
                assessmentTitle: "",
                assessmentExamDate: new Date(),
                examStartDate: new Date(),
                examEndDate: new Date(),
                assessmentDetails: "",
                assessmentScore: "",
                attachmentName: "",
                attachmentType: ""
            };

            setAssignment(formData);
        }
    }, [props.selectedArchivedQuestion]);

    return (
        <div>
            {loader ? <LoaderOverlay/> : null}
            <Formik
                initialValues={assignment}
                enableReinitialize={true}
                onSubmit={handleSubmit}>
                {({props, values, setFieldValue}) => (
                    <Form>
                        {selectedExamType === "exam" ? (
                            <div className="takentype-question-left left-col">

                                <div className="form-row">

                                    <div className=" ">
                                        <div className="form-group mr-2 ">
                                            <FormTitle>Total Score</FormTitle>
                                            <input
                                                name="totalScore"
                                                placeholder="Total Score [auto calculated]"
                                                className="form-control btn-block w-100 overflow-auto "
                                                value={totalScore}
                                            />
                                        </div>
                                    </div>


                                    <div className="form-row">
                                        <div className=" ">
                                            <div className="form-group mx-1 ">
                                                <FormTitle>Exam Start</FormTitle>
                                                <Field
                                                    name="examStartDate"
                                                    placeholder="Exam Start Date"
                                                    component={DatePickerComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className=" ">
                                            <div className="form-group mx-1">
                                                <FormTitle>End Time</FormTitle>
                                                <Field
                                                    name="deadline"
                                                    placeholder="Exam Date"
                                                    component={DatePickerComponent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< exam type >>>>>>>>>>>>>>>>>>>>>>>>*/}
                        {selectedExamType !== "assignment" ? (
                            <div className="takentype-question-right right-col">
                                <div className="form-row">
                                    <div className="col-12">
                                        <div className="form-group d-flex">
                                            {/*<FormTitle>Title</FormTitle>*/}
                                            <Field
                                                type="text"
                                                name="assessmentTitle"
                                                placeholder="Title"
                                                component={TextFieldComponent}
                                            />
                                            <button
                                                title="Edit"
                                                type="button"
                                                className="btn btn-sm mr-1 ml-1"
                                            >
                                                <svg width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="m16.202 9.42-2.01-2.011L15.54 6.06l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 10.366V8.472h6.623v1.892H0ZM0 6.58V4.689h10.407V6.58H0Zm0-3.785V.904h10.407v1.892H0Z"
                                                        fill="#4575EE"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <FormTitle>Details</FormTitle>
                                            <Field
                                                type="text"
                                                name="assessmentDetails"
                                                placeholder="Details"
                                                component={TextAreaComponent}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="form-group">
                                            <FormTitle>Assignment Visibility For Students</FormTitle>
                                            <Field
                                                type="text"
                                                name="Show Type"
                                                placeholder="Select Visibility"
                                                fieldName={"showType"}
                                                handleChangeSection={handleVisibilitySelection}
                                                options={showTypes || []}
                                                component={SelectDropdown}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-row d-flex">
                                    <div className=" ">
                                        <div className="form-group">
                                            <FormTitle>Score</FormTitle>
                                            <Field
                                                type="number"
                                                name="assessmentScore"
                                                placeholder="Score"
                                                onBlur={e => {
                                                    onScoreInput(e, 0)
                                                }}
                                                component={TextFieldComponent}
                                            />
                                        </div>
                                        {/*<div className="form-row ">*/}
                                        {/*    <div className="form-group ml-2">*/}
                                        {/*        <div className="form-group mt-2 pl-2">*/}
                                        {/*            <button onClick={setStatusForAssignment} type="button" className="btn btn-cancel w-100 ml-2">*/}
                                        {/*                Save as Draft*/}
                                        {/*            </button>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="pl-4 " style={{zIndex: 0}}>
                                        <div className="form-group">
                                            <FormTitle>Attachment</FormTitle>
                                            <FileUploader
                                                onChange={files => {
                                                    values.attachmentType = files[0].type
                                                    fileToDataUri(files[0]).then(data => {
                                                        values.attachmentName = data.split(",")[1];
                                                    });
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="form-row d-flex">
                                    <div className="form-row">
                                        <div className="form-group mt-2">
                                            <button
                                                type="submit"
                                                className="btn btn-red-md  w-100">
                                                Save
                                            </button>
                                        </div>
                                        <div className="form-group mt-2">
                                            <button
                                                onClick={handleBack}
                                                type="button"
                                                className="btn btn-cancel w-100 ml-2">
                                                {" "}
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< assignment type >>>>>>>>>>>>>>>>>>>>>>>>>*/}

                        {selectedExamType === "assignment" ? (
                            <div className="regular-type-question">
                                <div className="form-row">

                                </div>
                                <div className="left-col regular-left-col">
                                    <div className="form-row">
                                        <div className=" ">
                                            <div className="form-group mr-2 ">
                                                <FormTitle>Total Score</FormTitle>
                                                {/*<Field*/}
                                                {/*    name="totalScore"*/}
                                                {/*    placeholder="Score"*/}
                                                {/*    onBlur={onScoreInput}*/}
                                                {/*    component={TextFieldComponent}*/}
                                                {/*/>*/}
                                                <input
                                                    name="totalScore"
                                                    placeholder="Score"
                                                    className="form-control btn-block w-100 overflow-auto "
                                                    value={totalScore}
                                                />
                                            </div>
                                        </div>
                                        <div className=" ">
                                            <div className="form-group ">
                                                <FormTitle>Exam Start</FormTitle>
                                                <Field
                                                    name="examStartDate"
                                                    placeholder="Exam Start Date"
                                                    component={DatePickerComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className=" ">
                                            <div className="form-group">
                                                <FormTitle>End Time</FormTitle>
                                                <Field
                                                    name="deadline"
                                                    placeholder="Dead line"
                                                    component={DatePickerComponent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <FieldArray
                                    name="assignmentTasks"
                                    render={arrayHelpers => (
                                        <div className="right-col">
                                            <div className="form-row">
                                                <div className="col-12">
                                                    <div className="form-group d-flex">
                                                        {/*<FormTitle>Name<span className="required text-danger">*</span></FormTitle>*/}
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            placeholder="Assigment Name"
                                                            component={TextFieldComponent}
                                                        />
                                                        {/*<button*/}
                                                        {/*    title="Edit"*/}
                                                        {/*    type="button"*/}
                                                        {/*    className="btn btn-sm mr-1 ml-1"*/}
                                                        {/*>*/}
                                                        {/*    <svg width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m16.202 9.42-2.01-2.011L15.54 6.06l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 10.366V8.472h6.623v1.892H0ZM0 6.58V4.689h10.407V6.58H0Zm0-3.785V.904h10.407v1.892H0Z" fill="#4575EE"/></svg>*/}
                                                        {/*</button>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-100 mt-2">
                                                {values.assignmentTasks &&
                                                values.assignmentTasks.length > 0
                                                    ? values.assignmentTasks.map(
                                                        (assignmentTask, index) => (
                                                            <div
                                                                key={index}
                                                                className="w-100 task-writing-box ">
                                                                <div className="form-row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <FormTitle>Task {index + 1} </FormTitle>
                                                                                <div className="form-group ">
                                                                                    <button
                                                                                        className={"btn bg-c-red-light mt-3 "}
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            arrayHelpers.remove(index);
                                                                                            onScoreTaskDelete(index)
                                                                                        }
                                                                                        }
                                                                                    >
                                                                                        <svg width="12" height="14"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M2.25 13.75c-.413 0-.766-.147-1.06-.441a1.442 1.442 0 0 1-.44-1.059V2.5H0V1h3.75V.25h4.5V1H12v1.5h-.75v9.75c0 .412-.147.766-.441 1.06-.294.294-.647.44-1.059.44h-7.5ZM9.75 2.5h-7.5v9.75h7.5V2.5Zm-6 8.25h1.5V4h-1.5v6.75Zm3 0h1.5V4h-1.5v6.75Z"
                                                                                                fill="#D81414"/>
                                                                                        </svg>
                                                                                        <p>Delete task</p>
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                            <MarkdownEditor
                                                                                name={`assignmentTasks[${index}].name`}
                                                                                cbValue={value => setFieldValue(`assignmentTasks[${index}].name`, value)}
                                                                                value={assignmentTask.archivedName}
                                                                            />

                                                                            {/*<Field
                                                                                name={`assignmentTasks[${index}].name`}
                                                                                component={TextAreaComponent}
                                                                            />*/}

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="col-4 ">
                                                                        <div className="form-row">
                                                                            <div className="col">
                                                                                <div className="form-group ">
                                                                                    <FormTitle>{"Score"}</FormTitle>
                                                                                    <Field
                                                                                        type="number"
                                                                                        name={`assignmentTasks[${index}].score`}
                                                                                        placeholder="Score"
                                                                                        onBlur={e => {
                                                                                            onScoreInput(e, index)
                                                                                        }}
                                                                                        component={TextFieldComponent}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className=" col-8 overflow-hidden d-flex"
                                                                        style={{zIndex: 0}}>
                                                                        <div className="form-group">
                                                                            <FormTitle>Add attachment</FormTitle>

                                                                            <FileUploader
                                                                                onChange={files => {
                                                                                    values.assignmentTasks[index].assignmentAttachmentType = files[0].type;
                                                                                    values.assignmentTasks[index].assignmentAttachmentFileName = files[0].name;
                                                                                    fileToDataUri(files[0]).then(data => {
                                                                                        values.assignmentTasks[index].assignmentAttachmentName = data.split(",")[1];
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {/*<div className="  col">*/}
                                                                        {/*    <div className="form-group ">*/}
                                                                        {/*        <FormTitle>{"Uploaded contents"}</FormTitle>*/}

                                                                        {/*    </div>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                    : ""}
                                                <div className="form-group mt-3 mb-0">
                                                    <button
                                                        type="button"
                                                        className={"btn bg-c-blue-light p-2"}
                                                        onClick={() =>
                                                            arrayHelpers.push({
                                                                name: "",
                                                                score: "",
                                                                assignmentAttachmentType: "",
                                                                assignmentAttachmentName: ""
                                                            })
                                                        }
                                                    >
                                                        <svg width="13" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5.85 7.65V9.6a.63.63 0 0 0 .187.463c.125.125.28.187.463.187a.63.63 0 0 0 .463-.187.626.626 0 0 0 .187-.463V7.65H9.1a.63.63 0 0 0 .463-.187A.626.626 0 0 0 9.75 7a.63.63 0 0 0-.187-.463.626.626 0 0 0-.463-.187H7.15V4.4a.63.63 0 0 0-.187-.463.626.626 0 0 0-.463-.187.63.63 0 0 0-.463.187.626.626 0 0 0-.187.463v1.95H3.9a.63.63 0 0 0-.463.187A.626.626 0 0 0 3.25 7a.63.63 0 0 0 .187.463c.125.125.28.187.463.187h1.95Zm.65 5.85c-.9 0-1.744-.17-2.535-.512a6.574 6.574 0 0 1-2.064-1.39A6.56 6.56 0 0 1 .512 9.536 6.34 6.34 0 0 1 0 7c0-.9.17-1.744.512-2.535a6.574 6.574 0 0 1 1.39-2.064 6.56 6.56 0 0 1 2.063-1.389A6.34 6.34 0 0 1 6.5.5c.9 0 1.744.17 2.535.512.79.342 1.479.805 2.064 1.39a6.568 6.568 0 0 1 1.39 2.063c.34.79.511 1.636.511 2.535 0 .9-.17 1.744-.512 2.535a6.574 6.574 0 0 1-1.39 2.064 6.567 6.567 0 0 1-2.063 1.39A6.318 6.318 0 0 1 6.5 13.5Zm0-1.3c1.452 0 2.681-.504 3.689-1.511C11.196 9.68 11.7 8.452 11.7 7s-.504-2.681-1.511-3.689C9.18 2.304 7.952 1.8 6.5 1.8s-2.681.504-3.689 1.511C1.804 4.32 1.3 5.548 1.3 7s.504 2.681 1.511 3.689C3.82 11.696 5.048 12.2 6.5 12.2Z"
                                                                fill="#4575EE"/>
                                                        </svg>
                                                        Add more task
                                                    </button>
                                                </div>

                                                <div className="form-row d-flex justify-content-between align-items-center py-4">
                                                    {// assignment && assignment.name && assignment.deadline && assignment.assessmentTitle
                                                        !loader ? (
                                                            <div className="form-row ">
                                                                <div className="form-group mt-2">
                                                                    <button type="submit" className="btn btn-red-md">
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                                <div className="form-group mt-2">
                                                                    <button onClick={handleBack} type="button" className="btn btn-cancel w-100 ml-2">
                                                                        {" "}
                                                                        Cancel
                                                                    </button>
                                                                </div>

                                                                <div className="form-row ">
                                                                    <div className="form-group ml-2">
                                                                        <div className="form-group mt-2 pl-2">
                                                                            <button onClick={()=>setStatusForAssignment(values)} type="button" className="btn btn-cancel w-100 ml-2">
                                                                                Save as Draft
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mt-2 pl-3">
                                                                    <button type="button" className="btn btn-cancel w-100 ml-2" onClick={()=>handleSaveToQuestionArchive(values)}>{" "}
                                                                        Save to Question Archive
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}


                                                    {/*<div className="form-group">*/}
                                                    {/*    <button*/}
                                                    {/*        type="button"*/}
                                                    {/*        className={"btn bg-c-blue-light p-2"}*/}
                                                    {/*        onClick={() =>*/}
                                                    {/*            arrayHelpers.push({*/}
                                                    {/*                name: "",*/}
                                                    {/*                score: "",*/}
                                                    {/*                assignmentAttachmentType: "",*/}
                                                    {/*                assignmentAttachmentName: ""*/}
                                                    {/*            })*/}
                                                    {/*        }*/}
                                                    {/*    >*/}
                                                    {/*        <svg width="13" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.85 7.65V9.6a.63.63 0 0 0 .187.463c.125.125.28.187.463.187a.63.63 0 0 0 .463-.187.626.626 0 0 0 .187-.463V7.65H9.1a.63.63 0 0 0 .463-.187A.626.626 0 0 0 9.75 7a.63.63 0 0 0-.187-.463.626.626 0 0 0-.463-.187H7.15V4.4a.63.63 0 0 0-.187-.463.626.626 0 0 0-.463-.187.63.63 0 0 0-.463.187.626.626 0 0 0-.187.463v1.95H3.9a.63.63 0 0 0-.463.187A.626.626 0 0 0 3.25 7a.63.63 0 0 0 .187.463c.125.125.28.187.463.187h1.95Zm.65 5.85c-.9 0-1.744-.17-2.535-.512a6.574 6.574 0 0 1-2.064-1.39A6.56 6.56 0 0 1 .512 9.536 6.34 6.34 0 0 1 0 7c0-.9.17-1.744.512-2.535a6.574 6.574 0 0 1 1.39-2.064 6.56 6.56 0 0 1 2.063-1.389A6.34 6.34 0 0 1 6.5.5c.9 0 1.744.17 2.535.512.79.342 1.479.805 2.064 1.39a6.568 6.568 0 0 1 1.39 2.063c.34.79.511 1.636.511 2.535 0 .9-.17 1.744-.512 2.535a6.574 6.574 0 0 1-1.39 2.064 6.567 6.567 0 0 1-2.063 1.39A6.318 6.318 0 0 1 6.5 13.5Zm0-1.3c1.452 0 2.681-.504 3.689-1.511C11.196 9.68 11.7 8.452 11.7 7s-.504-2.681-1.511-3.689C9.18 2.304 7.952 1.8 6.5 1.8s-2.681.504-3.689 1.511C1.804 4.32 1.3 5.548 1.3 7s.504 2.681 1.511 3.689C3.82 11.696 5.048 12.2 6.5 12.2Z" fill="#4575EE"/></svg>*/}
                                                    {/*        Add more task*/}
                                                    {/*    </button>*/}
                                                    {/*</div>*/}

                                                </div>
                                            </div>
                                        </div>

                                    )}
                                />
                            </div>
                        ) : null}


                    </Form>
                )}
            </Formik>

        </div>
    );
}

export default AssignmentForm;

export class assignmentTaskDTOClass {
    constructor(name, submitType, attachment, assignment) {
        this.name = name;
        this.submitType = submitType;
        this.attachment = attachment;
        this.assignment = assignment;
    }
}
