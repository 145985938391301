import React from "react";
import {NavLink} from "react-router-dom";

const NotFound = () => (
    <div>
        <div className={"notfound-1"}>
            <div className={"notfound"}>
                <div className={"notfound-404"}>
                    <h3>Oops! Page not found</h3>
                    <h1 className={"error-h"}>
                        <span>4</span>
                        <span>0</span>
                        <span>4</span>
                    </h1>
                </div>
                <h2>we are sorry, but the page you requested was not found</h2>
                <NavLink to={"/"} className="btn btn-primary btn-success btn-sm btn-tt">
                    <i className="fas fa-arrow-left"/> Take Me Home
                </NavLink>
            </div>
        </div>
    </div>
);

export default NotFound;
