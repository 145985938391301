import React, {useState} from "react";
import {Form, Formik} from "formik";

import "react-datepicker/dist/react-datepicker.css";
import CommonLayout from "../layout/commonLayout";
import * as LinkFactory from "../../util/LinkFactory";
import LoaderOverlay from "../common/LoaderOverlay";
import * as EmployeeApi from "../../api/EmployeeApi";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {SERVER_NAME_URL} from "../../constants/Constants";
import FileSaver from "file-saver";

function EmployeeRegistrationUpload(props) {
    let history = useNavigate();
    const FILE_URL = SERVER_NAME_URL;

    function navigateTo(pathname) {
        history(pathname);
    }
    const [files, setFiles] = useState();
    const [employeeRegistration, setEmployeeRegistration] = useState({});
    const [uploadLoader, setUploadLoader] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const changeHandler = (event) => {
        setSelectedFiles(event.target.files);
        setFiles(event.target.files[0]);
    };

    const notifyError = (msg) => toast.error(msg, {
        position: toast.POSITION.TOP_CENTER
    });

    async function handleSubmit(values) {
        if(files!==undefined) {
            setUploadLoader(true);
            EmployeeApi.employeeRegistrationXlsx(files).then(res => {
                console.log(res.data);
                console.log(res);
                if (res.data.anyError) {
                    notifyError(res.data.responseMessage);
                } else if (res.response.status === 500) {
                    notifyError(res.response.data.detail);
                } else {
                    navigateTo(LinkFactory.employeeList);
                    toast.success("Successfully Registered!");
                }
            });
            setUploadLoader(false);
        } else {
            notifyError('File cannot be empty!');
        }
    }

    const handleDownload = () => {
        EmployeeApi.getBulkEmployeeExcel().then(response => {
           if (response.response && response.response.data){
               const blob = new Blob([response.response.data], {type: 'application/vnd.ms.excel'});
               const file = new File([blob], 'bulk_employee_registration' + '.xlsx', {type: 'application/vnd.ms.excel'});
               FileSaver.saveAs(file);
           }
        })
    }

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout headerName="Employee Bulk Registration">
                <main>
                    <div className=" card-default employee-registration-upload">
                        <div className="title-header mt-3">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Employee Registration Upload </p>
                            </div>
                        </div>
                        {uploadLoader ? <LoaderOverlay/> : null}
                        <div>
                            <div>
                                <Formik
                                    initialValues={employeeRegistration}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}>
                                    {({props, values}) => (
                                        <Form>
                                            <div className="form-row ml-0 mt-1">
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <input
                                                            className="custom-file-input"
                                                            id="file"
                                                            name="file"
                                                            type="file"
                                                            onChange={changeHandler}
                                                        />
                                                        <label className="custom-file-label" htmlFor="file">
                                                            {Array.from(selectedFiles).length > 0 ? Array.from(selectedFiles).map((data, index) => {
                                                                return (<p>{(index + 1) + ". " + data.name}</p>);
                                                            }) : "Choose file"}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-dark radius-25 mr-2">
                                                        {uploadLoader ? <i className="fas fa-circle-notch fa-spin mr-2"></i>:<i className="fa fa-upload mr-2"/>}
                                                        Upload
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button" onClick={handleDownload}
                                                        className="btn btn-dark radius-25 mr-2">
                                                        <i className="fa fa-download mr-2"/>
                                                        Download Form
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EmployeeRegistrationUpload;
