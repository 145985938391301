import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";


export const getAllByDepartmentId = (departmentId) => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/program/by-department-id/" + departmentId);
};
export const saveProgram = program => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/program/", program);
};
export const updateProgram = program => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/program/", program);
};
export const deleteProgramById = programId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/program/" + programId);
};
export const getProgram = programId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/program/" + programId);
};
export const getAllProgram = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/programs");
};

export const searchProgram = (param) => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/programs/search", param);
};
