import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveRoutine = routineSaveDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/routine-save/",
        routineSaveDTO
    );
};
export const getRoutineListByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/routine-list-by-channel-id/" + channelId
    );
};
export const getRoutineById = routineId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/routine-setup/" + routineId
    );
};
export const getRoutineDaysByRoutineId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/routine-days-by-routine-id/" + channelId
    );
};
export const getRoutineDaysByCurrentActivatedGrading = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/routine-days-by-current-date/" + channelId
    );
};
export const routineStatusUpdate = routineStatusUpdateDTO => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/routine-status-update/",
        routineStatusUpdateDTO
    );
};
