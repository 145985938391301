import {ADD_ATTENDANCE_SUCCESS, FETCH_ATTENDANCE_BEGIN, FETCH_ATTENDANCE_FAILURE, FETCH_ATTENDANCE_SUCCESS} from "../action/AssignmentsAction";

const initialState = {
    previousAssignments: null,
    upcomingAssignments: null,
    loading: false,
    error: null
};

export default function AssignmentReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ATTENDANCE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                previousAssignments: null != action.payload.previousAssignments ? action.payload.previousAssignments : state.previousAssignments,
                upcomingAssignments: null != action.payload.upcomingAssignments ? action.payload.upcomingAssignments : state.upcomingAssignments
            };
        case ADD_ATTENDANCE_SUCCESS:
            console.log(action.payload.upcomingAssignments);
            console.log([...state.upcomingAssignments, action.payload.upcomingAssignments]);
            return {
                ...state,
                loading: false,
                previousAssignments: null != action.payload.previousAssignments ? [...state.previousAssignments, action.payload.previousAssignments] : state.previousAssignments,
                upcomingAssignments: null != action.payload.upcomingAssignments ? [...state.upcomingAssignments, action.payload.upcomingAssignments] : state.upcomingAssignments
            };

        case FETCH_ATTENDANCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                previousAssignments: null,
                upcomingAssignments: null
            };

        default:
            return state;
    }
}
