import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveSportsType = sportsType => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/sports-info/", sportsType);
};

export const updateSportsType = sportsType => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/sports-info/", sportsType);
};
export const getSportsTypeById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/sports-info/" + id);
};
export const deleteSportsTypeById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/sports-info/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/sports-infos");
};
