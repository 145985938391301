import {
    MODAL_FORM_ERROR,
    MODAL_FORM_LOADING,
    MODAL_FORM_LOADING_COMPLETE,
    MODAL_FORM_UNLOADED,
    Modal_HIDE,
    MODAL_SetField,
    Modal_SHOW,
    MODAL_Unloaded,
    MODAL_UPDATE_FIELD_REGISTER
} from "../../constants/ActionTypes";
import _ from "lodash";

const initialState = {
    form: {
        files: [],
        errors: []
    }
};
//store.modal
export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case Modal_SHOW:
            return {
                ...state,
                visible: true,
                heading: action.heading,
                body: action.body,
                onSave: action.onSave,
                initialFooterState: action.initialFooterState,
                className: action.className,
                footer: action.footer
            };
        case Modal_HIDE:
            return {...state, visible: false};
        case MODAL_SetField:
        case MODAL_UPDATE_FIELD_REGISTER:
        case MODAL_FORM_UNLOADED:
        case MODAL_FORM_ERROR:
        case MODAL_FORM_LOADING:
        case MODAL_FORM_LOADING_COMPLETE:
            return {...state, form: ModalFormReducer(state.form, action)};
        case MODAL_Unloaded:
            return {...state, form: initialState.form};
        default:
            return state;
    }
}

//store.modal.form
function ModalFormReducer(state = [], action) {
    let filter = {};
    switch (action.type) {
        case MODAL_UPDATE_FIELD_REGISTER:
            filter = _.set(state, action.key, action.value);
            return {...state, ...filter};
        case MODAL_SetField:
            return {...state, ...action.payload};
        case MODAL_FORM_UNLOADED:
            return {...initialState};
        case MODAL_FORM_LOADING:
            return {...state, loading: true};
        case MODAL_FORM_LOADING_COMPLETE:
            return {...state, loading: false};
        case MODAL_FORM_ERROR:
            return {...state, errors: action.payload.errors};
        default:
            return state;
    }
}
