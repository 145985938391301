import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import * as GradingBreakdownApi from "../../api/GradingBreakdownApi";
import * as ChannelApi from "../../api/ChannelApi";
import {Field, FieldArray, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function GradingEvaluationEntry(props) {
    const params = useParams();
    //grading-id will be passed here and it pull data from there
    const [grading, setGrading] = useState({});
    const [channelMembers, setChannelMembers] = useState({});
    const [gradingBreakdown, setGradingBreakdown] = useState([]);
    const [gradingEvaluation, setGradingEvaluation] = useState({
        no: "", evaluations: []
    });
    //  const [grading, setGrading] = useState({});

    useEffect(() => {
        GradingBreakdownApi.getGradingBreakdownById(params.id).then(breakdownResponse => {
            setGradingBreakdown(breakdownResponse.data);
            setGrading(breakdownResponse.data.grading);
            ChannelApi.getChannelMemberListByChannelId(breakdownResponse.data.grading.channel.id).then(json => {
                setChannelMembers(json.data);
                console.log(json.data);
                const tempEvaluation = gradingEvaluation;
                json.data.forEach(student => {
                    console.log(student);
                    tempEvaluation.evaluations.push({
                        studentUser: student, score: ""
                    });
                    console.log(tempEvaluation.evaluations);
                });
                setGradingEvaluation(tempEvaluation);
                console.log(gradingEvaluation);
            });
        });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        console.log("hello");
    };
    useEffect(() => {
        //TODO:

        setGradingEvaluation({
            ...gradingEvaluation, evaluations: [...gradingEvaluation.evaluations, {
                studentUser: "", score: ""
            }]
        });
    }, []);

    return (<>
        <CommonLayout>
            <main>
                <div>
                    <h1 className="mt-4">Grading Evaluation</h1>
                    <br/>
                    <div className="card-body">
                        <Formik
                            initialValues={gradingEvaluation}
                            enableReinitialize={true}
                            onSubmit={handleSubmit}
                        >
                            {({props, values}) => (<Form>
                                <div className="form-row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>{gradingBreakdown?.evaluationName} No</label>
                                            <Field
                                                type="text"
                                                name="no"
                                                placeholder="no"
                                                component={TextFieldComponent}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <FieldArray
                                    name="evaluations"
                                    render={arrayHelpers => (<div className="w-100">
                                        {values.evaluations && values.evaluations.length > 0 ? values.evaluations.map((evaluation, index) => (
                                            <span key={index} className="w-100">
                                  <div className="form-row">
                                    <div className="col-8">
                                      <div className="form-group">
                                        <label>
                                          {" "}
                                            {evaluation?.studentUser?.firstName}'s
                                          Score
                                        </label>
                                        <Field
                                            name={`evaluations[${index}].score`}
                                            component={TextFieldComponent}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </span>)) : ""}
                                    </div>)}
                                />

                                <div className="form-row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-lg btn-success float-right"
                                            >
                                                <i className="fa fa-save"/> Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>)}
                        </Formik>
                    </div>
                </div>
            </main>
        </CommonLayout>
    </>);
}

export default GradingEvaluationEntry;
