import React, {useEffect, useState} from "react";
import * as CountryApi from "../../api/CountryApi";
import {Field, Form, Formik} from "formik";
import TextField from "../common/TextField";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function CountryEdit(props) {
    const params = useParams();
    const [country, setCountry] = useState({country: "", id: ""});

    useEffect(() => {
        CountryApi.getCountryById(params.id)
            .then(json => {
                console.log(json);
                setCountry(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        CountryApi.updateCountry(data);
    };

    return (
        <>
            <CommonLayout id="layoutSidenav_content">
                <main>
                    <div>
                        <h1 className="mt-4">Country Edit</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-pencil-square-o mr-2"/>
                                Edit here
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={country}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {props => (
                                        <Form>
                                            <div className="table-responsive">
                                                <table
                                                    className="table table-bordered"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0"
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="12">#</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <Field
                                                                type="number"
                                                                name="id"
                                                                placeholder="id"
                                                                readonly
                                                                component={TextField}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="country"
                                                                placeholder="Country"
                                                                component={TextField}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="12">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-lg btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>
        </>
    );
}

export default CountryEdit;
