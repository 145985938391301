import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveCountry = country => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/country/", country);
};

export const updateCountry = country => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/country/", country);
};
export const getCountryById = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/country/" + userId);
};
export const deleteCountryById = userId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/country/" + userId);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/countries");
};
