import React, {useEffect, useState} from "react";
import * as Utils from "../../util/Utils";
import * as McqApi from "../../api/McqApi";
import CommonLayout from "../layout/commonLayout";
import LoaderOverlay from "../common/LoaderOverlay";
import {useParams} from "react-router-dom";

function McqSubmissions(props) {
    const params = useParams();
    const [submissions, setSubmissions] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setLoader(true);
        console.log(params);
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) === true)
            McqApi.getSubmissionList(params.mcqId, params.userId)
                .then(response => {
                    setLoader(false);
                    setSubmissions(response.data);
                })
                .catch(errors => {
                    setLoader(false);
                    console.log(errors);
                }).finally(() => {
                setLoader(false);
            });
    }, []);

    function createMarkup(html) {
        console.log(html);
        return {__html: html};
    }

    return (
        <>

            <CommonLayout headerName={"MCQ Submissions"}>
                <main>
                    <div>
                        {loader ? <LoaderOverlay/> : null}
                        <div className="card-default">
                            <di>
                                <div className="table-responsive rounded">
                                    {submissions?.length > 0 ? (
                                        submissions?.map((submission, index) => {
                                            return (
                                                <>
                                                    <div>
                                                        <p> Question : <strong
                                                            dangerouslySetInnerHTML={createMarkup(submission?.question)}></strong></p>
                                                    </div>

                                                    {submission?.mcqSubmittedAnswerList?.length > 0 ? (
                                                        submission?.mcqSubmittedAnswerList?.map((answer, index) => {
                                                            return (
                                                                <div>
                                                                    <table className="table table-bordered" width="100%" cellSpacing="0">
                                                                        <tr>
                                                                            <td width="70%"><span
                                                                                style={{color: answer?.correct ? '#008000' : '#FF0000'}}
                                                                                dangerouslySetInnerHTML={createMarkup(answer?.answer)}></span></td>
                                                                            <td width="30%" style={{color: answer?.correct ? '#008000' : '#FF0000'}}><span>{answer?.correct ? '✓ Right Answer' : '✕ Wrong Answer'}</span></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <h3>No Submission Answer found</h3>
                                                    )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <h3>No Submissions found</h3>
                                    )}
                                </div>
                            </di>

                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default McqSubmissions;