import {AUTH_LOGIN, AUTH_LOGOUT} from "../constants/ActionTypes";
import *  as Utils from "../util/Utils";

class AuthActions {
    static addUser(user) {
        Utils.addUserToLocalStorage(user);
        return {type: AUTH_LOGIN, payload: user};
    }

    static addUserToStoreOnly(user) {
        return {type: AUTH_LOGIN, payload: user};
    }

    static removeUser() {
        return {type: AUTH_LOGOUT};
    }
}

export default AuthActions;
