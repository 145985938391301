import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as StudentApi from "../../api/StudentApi";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";
import LinearProgress from "@mui/material/LinearProgress";
import * as Utils from "../../util/Utils";
import FileSaver from "file-saver";


toast.configure();

function CourseWiseStudentsList(props) {

    let history = useNavigate();
    const params = useParams();
    const headerName = Utils.getCurrentChannelName();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [loader, setLoader] = useState(true);
    const [studentList, setStudentList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [slNoCount, setSlNoCount] = useState(0);

    const PhotoRootUrl = SERVER_NAME_URL;

    useEffect(() => {
        getStudentsList();
    }, [pageNumber]);


    const changeStudentsPage = ({selected}) => {
        setPageNumber(selected);
    }

    const getStudentsList = () => {
        if (!params || !params.id){
            return toast.warning('No course information found !')
        }
        setLoader(pageNumber < 1);
        setShowProgressBar(!loader);
        StudentApi.getStudentPageableByChannelId(params?.id, {"page": pageNumber,"size": 10})
            .then(json => {
                setLoader(false);
                setShowProgressBar(false);
                setStudentList(json.data.content);
                setPageCount(json.data.totalPages);
                setSlNoCount(pageNumber)
            })
            .catch(errors => {
                setLoader(false);
                console.error(errors);
            });
    };

    function goBack() {
        navigateTo(-1)
    }

    const handleExportExcel = () => {
        StudentApi.exportStudentInfoExcel(params.id).then(response => {
            if (response.response && response.response.data){
                const blob = new Blob([response.response.data], {type: 'application/vnd.ms.excel'});
                const file = new File([blob], 'student_information_' + headerName + '.xlsx', {type: 'application/vnd.ms.excel'});
                FileSaver.saveAs(file);
            }
        }, error => {
            console.log(error);
        });
    }

    return (<>
        <CommonLayout headerName={headerName}>
            <main>
                <div className="card-default student-list container-responsive">
                    <div className="title-header ">
                        <div className="mb-0 d-flex align-items-center">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                <a onClick={goBack} className="pl-0 back-button">
                                    <i className="fa fa-chevron-left"> </i> </a>
                            </div>
                            <p>Student Information</p>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-4">
                            <div className="form-group input-default-white">
                                <label className="d-block">&nbsp;</label>
                                <button type="button" onClick={handleExportExcel} className="btn btn-dark radius-25 ml-2">
                                    <i className="fa fa-download mr-1"/>
                                    Export as Excel
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive table-light mb-5 mt-3">
                        {showProgressBar ? (<LinearProgress color="primary"/>) : ""}
                        <table
                            className="table table-light table-hover table-striped"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0">
                            <thead>
                            <tr>
                                <th scope="row">Sl.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Student ID</th>
                                <th scope="col">Email</th>
                                <th scope="col">Contacts</th>
                            </tr>
                            </thead>
                            <tbody>
                            {studentList.length > 0 ? (studentList.map((data, index) => {
                                    return (<tr key={index}>
                                        <td scope="row">{(index + 1) + (slNoCount * 10)}</td>
                                        <td>
                                            <div className="grid px-1">
                                                <div>
                                                    <img
                                                        src={data?.user?.imageUrl ? PhotoRootUrl + data?.user?.imageUrl : "img/Avatar.png"}
                                                        style={{
                                                            width: "45px", height: "45px", objectFit: "cover"
                                                        }}
                                                        className="rounded-circle p-1"/>
                                                    <span>{" "}{data?.firstName} {data?.middleName}{" "}{data?.lastName}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{data?.studentId || "-----"}</td>
                                        <td>{data?.email || "-----"}</td>
                                        <td>{data?.phoneNo || "-----"}</td>
                                    </tr>);
                                }))
                                :
                                <td colSpan="12" className="text-center">{loader ? <Loader className="my-5"/> :
                                    <h6 className="my-5">No data found</h6>}
                                </td>
                            }
                            </tbody>
                        </table>
                        <div className="page-navigation">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changeStudentsPage}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    </div>

                </div>
            </main>
        </CommonLayout>
    </>);
}

export default CourseWiseStudentsList;
