import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveLetterGradeMaster = letterGradeMaster => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/letter-grade-master/", letterGradeMaster);
};
export const updateLetterGradeMaster = letterGradeMaster => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/letter-grade-master/", letterGradeMaster);
};
export const getLetterGradeMasterById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/letter-grade-master/" + id);
}
export const getLetterGradeMasterListByMainId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/letter-grade-master/" + "by-main-id/" + id);
};
export const deleteLetterGradeMasterById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/letter-grade-master/" + id);
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/letterGradeMaster");
};
