import React from "react";
import * as LinkFactory from "../../util/LinkFactory";
import * as Utils from "../../util/Utils";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";


const Country = () => {
    const history = useNavigate();
    const handleCountryListPageNavigation = () =>
        history(LinkFactory.country);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleCountryListPageNavigation}
        >
            Country
        </button>
    );
};
const Post = () => {
    const history = useNavigate();
    const handleCountryListPageNavigation = () =>
        history(LinkFactory.post);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleCountryListPageNavigation}
        >
            Post
        </button>
    );
};
const ForgetPassword = () => {
    const history = useNavigate();
    const handleCountryListPageNavigation = () =>
        history(LinkFactory.forgotPassword);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleCountryListPageNavigation}
        >
            Forget Password
        </button>
    );
};

const States = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.states);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            States
        </button>
    );
};

const Promotions = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.promotion);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Promotion
        </button>
    );
};
const CompanyPartner = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.company);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Company Partner
        </button>
    );
};
const Industry = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.industry);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Industry
        </button>
    );
};
const UserType = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.userType);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            User Type
        </button>
    );
};


const ChannelSetup = () => {
    const history = useNavigate();
    const handleChannelListPageNavigation = () =>
        history(LinkFactory.channelList);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleChannelListPageNavigation}
        >
            Channel Setup
        </button>
    );
};
const Subject = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.subject);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Subject
        </button>
    );
};
const Employee = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.employeeList);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Employee
        </button>
    );
};
const AssignmentSetup = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.assignmentNew);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Assignment Setup
        </button>
    );
};
const Assignment = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.assignmentSubmitList);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Assignment
        </button>
    );
};
const Channel = props => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.channelBoard + "/" + props.channel.id);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            <a className="nav-link">
                <div className="sb-nav-link-icon">
                    <i className="fas fa-chalkboard-teacher"/>
                </div>
                {props.channel.channelName}
            </a>
        </button>
    );
};
const ClassSetup = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.classSetup);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Class Setup
        </button>
    );
};
const ClassListForStudent = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.classListForLoggedInUser);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Class List
        </button>
    );
};
const Home = () => {
    const history = useNavigate();
    const handleStatesListPageNavigation = () =>
        history(LinkFactory.home);
    return (
        <button
            type="button"
            className="btn-none"
            onClick={handleStatesListPageNavigation}
        >
            Dashboard
        </button>
    );
};




const Login = () => {
    const history = useNavigate();
    const handleLogin = () => history(LinkFactory.login);

    return (
        <a type="button" className="dropdown-item" onClick={handleLogin}>
            Login
        </a>
    );
};

const LogOut = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const handleLogOut = () => {
        Utils.LogOut(dispatch);
        history(LinkFactory.loginPage)
    };

    return (
        <a type="button" className="dropdown-item" onClick={handleLogOut}>
            <img src="img/log-out.png" alt="Logout Icon" className="icon"/>
            Log Out
        </a>
    );
};

;

export {
    Login,
    Post,
    LogOut,
    Country,
    States,
    Promotions,
    UserType,
    CompanyPartner,
    Industry,
    Subject,
    Employee,
    AssignmentSetup,
    Channel,
    ClassSetup,
    ForgetPassword,
    Assignment,
    ClassListForStudent,
    Home,
    ChannelSetup,

};
