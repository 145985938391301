import React, {useEffect, useState} from "react";

import * as MessageApi from "../../api/MessageApi";
import * as Utils from "../../util/Utils";
import {RotatingLines} from "react-loader-spinner";

import * as LinkFactory from "../../util/LinkFactory";
import CommonLayout from "../layout/commonLayout";
import {useNavigate, useParams} from "react-router-dom";

function DirectMessageThreads(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [sportsType, setSportsType] = useState({states: "", id: ""});
    const [threadList, setThreadList] = useState([]);
    const [receiver, setReceiver] = useState([]);
    useEffect(() => {
        MessageApi.threadAll()
            .then(json => {
                console.log(json);
                setThreadList(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        let directMessageThreadDTO = {
            sender: Utils.getUserFromLocalStorage(),
            receiver: receiver,
            message: data.message
        };
        console.log(directMessageThreadDTO);
    };
    const handleChangeSection = (fieldName, value) => {
        setReceiver(value);
    };

    const handleMessageThreadView = data => {
        navigateTo(LinkFactory.messageThread + "/" + data.threadId);
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Sports Type New</h1>
                        <br/>
                        <div className="card-body">
                            <table
                                className="table table-bordered"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col">Thread id</th>
                                    {/*<th scope="col">Subject</th>*/}
                                    {/*<th scope="col">Teacher Name</th>*/}
                                    {/*<th scope="col">Grade</th>*/}
                                    {/*<th scope="col">Action</th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                {threadList.length > 0 ? (
                                    threadList.map((thread, index) => {
                                        return (
                                            <tr>
                                                <td>{thread.threadId}</td>

                                                <td className="text-right">
                                                    <button
                                                        type="button"
                                                        onClick={() => handleMessageThreadView(thread)}
                                                        className="btn btn-sm btn-info"
                                                    >
                                                        <i className="fa fa-eye"/> View Assignment
                                                    </button>
                                                    &nbsp;
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <div className="text-center position-absolute">
                                        <RotatingLines
                                            width="300"
                                            height="300"
                                            strokeColor="blue"
                                        />
                                    </div>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default DirectMessageThreads;
