//form
export const FORM_FIELD_REGISTER = "FORM_FIELD_REGISTER";
export const FORM_FIELD_INVESTMENT = "FORM_FIELD_INVESTMENT";
export const FORM_ERROR = "FORM_ERROR";
export const FORM_SET_FIELD = "FORM_SET_FIELD";
export const FORM_UNLOADED = "FORM_UNLOADED";
export const FORM_LOADING = "FORM_LOADING";
export const FORM_LOADING_COMPLETE = "FORM_LOADING_COMPLETE";
export const FORM_CONCAT_ARRAY = "FORM_CONCAT_ARRAY";
export const FORM_UPDATE_ARRAY = "FORM_UPDATE_ARRAY";

//form.fileUpload
export const FORM_FILE_UPLOAD = "FORM_FILE_UPLOAD";
export const FORM_FILE_UPLOAD_COMPLETED = "FORM_FILE_UPLOAD_COMPLETED";
export const FORM_FILE_REMOVE = "FORM_FILE_REMOVE";
export const FORM_FILE_UPLOAD_ERROR = "FORM_FILE_UPLOAD_ERROR";

//Modal
export const Modal_SHOW = "modal/Modal_SHOW";
export const Modal_HIDE = "modal/Modal_HIDE";
export const MODAL_UPDATE_FIELD_REGISTER = "modal/MODAL_UPDATE_FIELD_REGISTER";
export const MODAL_FORM_ERROR = "modal/MODAL_FORM_ERROR";
export const MODAL_FORM_LOADING = "modal/MODAL_FORM_LOADING";
export const MODAL_FORM_LOADING_COMPLETE = "modal/MODAL_FORM_LOADING_COMPLETE";
export const MODAL_Unloaded = "modal/MODAL_Unloaded";
export const MODAL_AddOrderItemRow = "modal/AddOrderItemRow";
export const MODAL_SetField = "modal/MODAL_SetField";
export const MODAL_FORM_UNLOADED = "modal/MODAL_FORM_UNLOADED";

export const ADD_NAVIGATION_MENU = "ADD_NAVIGATION_MENU";
export const REMOVE_NAVIGATION_MENU = "ADD_NAVIGATION_MENU";

//
export const CURRENT_REGISTRATION_PATH = "CURRENT_REGISTRATION_PATH";

//systemError
export const ERROR_SHOW = "ERROR_SHOW";
export const ERROR_UNLOADED = "ERROR_UNLOADED";

//Auth
export const AUTH_LOGIN = "auth/AUTH_LOGIN";
export const AUTH_LOGOUT = "auth/AUTH_LOGOUT";

export const PROPERTY_CATEGORY = "PROPERTY_CATEGORY";

//sidebar
export const CHANNEL_LIST = "CHANNEL_LIST";
