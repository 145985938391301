import React, {useEffect, useState} from "react";
import * as EventsApi from "../../api/EventsApi";

import * as LinkFactory from "../../util/LinkFactory";
import Loader from "../common/Loader";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function EventsList() {

    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [events, setEvents] = useState([]);
    const [initial, setInitial] = useState({name: "", phone: ""});
    const [id, setId] = useState(null);

    useEffect(() => {
        EventsApi.getAll()
            .then(json => {
                console.log(json);
                setEvents(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleNew = data => {
        navigateTo();
    };

    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        EventsApi.deleteEventsById(id);

        /// then set id as null again
        setId(null);
        window.location.reload();
    };

    return (
        <>
            <CommonLayout>
                <main>

                </main>
            </CommonLayout>
        </>
    );
}

export default EventsList;
