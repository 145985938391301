import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as StudentApi from "../../api/StudentApi";
import Loader from "../common/Loader";
import {FILES_URL} from "../../util/LinkFactory";
import PageError from "../ErrorPage";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import TextAreaComponent from "../common/TextAreaComponent";
import * as ChannelApi from "../../api/ChannelApi";
import Swal from "sweetalert2";


const defaultData = {
    bio: "",
    email: "",
    firstName: "",
    lastName: "",
    height: "",
    section: "",
    school: "",
    weight: "",
};
toast.configure();

function EditProfile() {
    const [user,setUser] = useState(Utils.getUserFromLocalStorage())
    const [userInfo, setUserInfo] = useState(defaultData);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState([]);
    let fileBase64s = [];
    const [files, setFiles] = useState([]);
    const [editMode, setEditMode] = useState(false);


    useEffect(() => {
    }, []);


    const handleProfilePicInputChange = event => {
        fileBase64s = [];
        setFiles(fileBase64s);
        setSelectedFiles(event.target.files);
        Array.from(event.target.files).forEach(file => {
            fileToDataUri(file).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file.type, fileName: file.name});
            });
        });
        console.log(fileBase64s);
        setFiles(fileBase64s);
    };


    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });


    function proPicUpload() {
        console.log(files);
        let profilePicUploadDto = {
            photoBlob: files[0].blob,
            photoBlobType: files[0].type,
            userId: userInfo.userId
        };
        uploadProfilePicture(profilePicUploadDto).then(r => {
            toast("Profile Picture Uploaded!")
        });
    }

    async function uploadProfilePicture(profilePicUploadDto) {
        await StudentApi.uploadStudentProfilePicture(profilePicUploadDto).then(
            response => {
                flushFileUpload();
            }
        );
    }

    function flushFileUpload() {
        setFiles([]);
        setSelectedFiles([]);
        fileBase64s = [];
    }

    const handleEditProfile = () => {
        setUserInfo(user)
        setEditMode(!editMode)
    }

    const handleSubmit = (values) => {
        console.log(values);
        const body = {
             email : values.email,
             bio : values.bio,
             height : values.height,
             weight : values.weight,
             grade : values.grade,
             school : values.school,
             country : values.country && values.country.id,
             states : values.states && values.states.id,
        }
        ChannelApi.updateUserInfo(body).then(resp => {
            console.log(resp);
            if (resp.response && resp.response.status === 201){
                setUser(resp.response.data)
                Swal.fire('Profile updated !', '','success');
                setEditMode(false);
            }
        })
    }

    if (userInfo && !loader) {
        return (
            <div className=" student-profile">
                <CommonLayout headerName={"Student Info"}>
                    <main>
                        <div className=" pt-3 pb-3 p-2">
                            {error ? (
                                <PageError title={"No data to show"}/>
                            ) : (
                                <div className="card-default ">
                                    <div className="card-heading">
                                        <div className="title">
                                            <p>Profile</p>
                                        </div>
                                        <div>
                                            <button type="button" onClick={handleEditProfile}
                                                    className="btn-dark radius-25">
                                                {editMode ? 'Cancel' : 'Edit'}
                                            </button>
                                        </div>
                                    </div>

                                    <Formik initialValues={userInfo}
                                            enableReinitialize={true}
                                            onSubmit={handleSubmit}>
                                        {({props, values, setFieldValue}) => (
                                            <Form>

                                                <div className=" mx-auto">
                                                    <div className="table-responsive mt-4">
                                                        <table
                                                            className="table table-bordered"
                                                            id="dataTable"
                                                            width="100%"
                                                            cellSpacing="0">
                                                            <tbody>
                                                            <tr>
                                                                <td> First Name</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.firstName}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="firstName"
                                                                                placeholder="First Name"
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td> Last Name</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.lastName}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="lastName"
                                                                                placeholder="Last Name"
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td> Photo</td>
                                                                <td>
                                                                    {user?.imageUrl ? (
                                                                        <img
                                                                            style={{width: "200px", height: "auto"}}
                                                                            src={FILES_URL + user?.imageUrl}
                                                                            alt={user?.firstName}
                                                                        />
                                                                    ) : (
                                                                        "No photo available"
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td> Upload Photo</td>
                                                                <td>
                                                                    <div className="row m-0">
                                                                        <div className="col-6 p-0">
                                                                            <input className="custom-file-input"
                                                                                   id="file"
                                                                                   name="file"
                                                                                   type="file"
                                                                                   multiple
                                                                                   onChange={handleProfilePicInputChange}
                                                                            />
                                                                            <label className="custom-file-label"
                                                                                   htmlFor="file">Choose
                                                                                file</label>
                                                                            <div className="submit-area mt-4">
                                                                                {Array.from(selectedFiles).length > 0 ? Array.from(selectedFiles).map((data, index) => {
                                                                                    return (
                                                                                        <p className="mb-0 text-primary pt-1">{(index + 1) + ". " + data.name}</p>);
                                                                                }) : ""}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <button type="button"
                                                                                    className="btn-dark radius-25"
                                                                                    disabled={Array.from(selectedFiles).length === 0}
                                                                                    onClick={() => proPicUpload()}>
                                                                                <i className="fa fa-upload mr-2"/> Upload
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td> Email</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.email}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="email"
                                                                                placeholder="Email"
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td> Country</td>
                                                                <td>{user?.country ? user.country.country : ""}</td>
                                                            </tr>

                                                            <tr>
                                                                <td> State</td>
                                                                <td>{user?.states ? user.states.states : ""}</td>
                                                            </tr>

                                                            <tr>
                                                                <td> User Type</td>
                                                                <td>{user?.userType ? user.userType.userType : ""}</td>
                                                            </tr>

                                                            <tr>
                                                                <td> School</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.school}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="school"
                                                                                placeholder="School"
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td> Grade</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.grade}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="grade"
                                                                                placeholder="Grade"
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td> Height</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.height}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="height"
                                                                                placeholder="Height"
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Weight</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.weight}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="weight"
                                                                                placeholder="Weight"
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Bio</td>
                                                                <td>
                                                                    <div>
                                                                        {!editMode && user?.bio}
                                                                        {editMode && (
                                                                            <Field
                                                                                type="text"
                                                                                name="bio"
                                                                                placeholder="Bio"
                                                                                component={TextAreaComponent}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {editMode && (
                                                    <div className="card-footer">
                                                        <button type="submit" className="btn-success radius-25">Update
                                                        </button>
                                                    </div>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            )}
                        </div>
                    </main>
                </CommonLayout>
            </div>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default EditProfile;
