import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const getGradeBookForPrinciple = (marks, marksType) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grade-book-for-principal/" + marks + "/" + marksType
    );
};
export const getStatisticsForPrinciple = (body) => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/grade-book-for-principal",
        body
    );
};

export const getAttendanceReportForPrinciple = body => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/attendance-report-principal",
        body
    );
};
