import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveCourseMaterial = data => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/channel-materials", data);
};
export const saveAttachment = attachmentBody => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/channel-materials-attachments/",
        attachmentBody
    );
};

export const updateAttachment = assignment => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/channel-materials-attachments/",
        {
            ...assignment
        }
    );
};

export const getAttachmentListByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + `/channel-materials-by-channel-id/${id}`
    );
};
