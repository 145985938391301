import React from "react";
import CachingImage from "../../Image/cachingImage";
import {useNavigate} from "react-router-dom";
import * as LinkFactory from "../../../util/LinkFactory";
import ReactPlayer from 'react-player'

const SingleAttachmentComponent = props => {
    const attachment = props.imageurl
    const url = attachment.imageFileName ? attachment.imageFileName : attachment.videoFileName;
    const postId = props.id;
    let history = useNavigate();

    function navigateTo() {
        if (postId) {
            history(LinkFactory.postDetails + "/" + postId);
        } else if (url) {
            window.open(process.env.REACT_APP_FILES_URL + url, '_blank', 'rel=noopener noreferrer')
        }

    }

    return (
        <div className="w-100" onClick={() => navigateTo()}>
            <div className="grid-fixed-1 d-flex justify-content-center align-items-center">
                {(url?.includes(".jpeg") || url?.includes(".jpg") || url?.includes(".png") || url?.includes(".webp") || url?.includes(".gif")) ?
                    <CachingImage
                        src={process.env.REACT_APP_FILES_URL + url}
                        alt="Post Image"
                    /> :
                    (url?.includes(".mp4")) ?
                        <ReactPlayer playing={true} controls={true} volume={0}
                                     url={process.env.REACT_APP_FILES_URL + url}/>
                        : <a title="Download"
                             className="btn btn-sm btn-success radius-15 mx-2 my-1"
                             target="_blank"
                             href={process.env.REACT_APP_FILES_URL + url}>
                            <i className="fa fa-file-download"/>{" "}
                        </a>}
            </div>
        </div>);
};

export default SingleAttachmentComponent;
