import React, {useState} from "react";
import * as EmployeeApi from "../../api/EmployeeApi";
import {Field, Form, Formik} from "formik";

import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function EmployeeNew(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [employee, setEmployee] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        id: ""
    });

    const handleSubmit = data => {
        EmployeeApi.saveEmployee(data).then(data => {
            navigateTo(LinkFactory.employeeList);
        });
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Employee New</h1>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={employee}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {props => (
                                    <Form>
                                        <div className="table-responsive">
                                            <table
                                                className="table table-bordered"
                                                id="dataTable"
                                                width="100%"
                                                cellSpacing="0">
                                                <tbody>
                                                <tr>
                                                    <tr>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="firstName"
                                                                placeholder="firstName"
                                                                component={TextField}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="middleName"
                                                                placeholder="middleName"
                                                                component={TextField}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="lastName"
                                                                placeholder="lastName"
                                                                component={TextField}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="12">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-lg btn-primary float-right">
                                                            Save
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EmployeeNew;
