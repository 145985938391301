import React, {useEffect, useRef, useState} from "react";
import * as ChannelApi from "../../../api/ChannelApi";
import {Field, Form, Formik} from "formik";
import {FILES_URL} from "../../../util/LinkFactory";
import * as Utils from "../../../util/Utils";
import {connect, useDispatch} from "react-redux";
import {RotatingLines} from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import {toast} from "react-toastify";
import PostContainer from "./PostsContainer";
import CommonLayout from "../../layout/commonLayout";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import RightBar from "../../common/rightBar";

toast.configure()
const initialComment = {messageId: null, comment: "", file: null};

function PostMain(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const user = Utils.getUserFromLocalStorage();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const initialized = useRef(false);
    const [channel, setChannel] = useState({});

    const [message, setMessage] = useState([]);
    const [postList, setPostList] = useState([]);
    const [comment, setComment] = useState(initialComment);
    const [files, setFiles] = useState([]);
    const [selectedFileCount, setSelectedFileCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const cleanUpAll = () => {
        setHasMore(false);
        setPostList([]);
        setLoader(false);
        setPageNo(0);
        setMessage([]);
        setChannel({});
        setFiles([]);
    };


    useEffect(() => {
        getAllPostPageableBySportsType();
    }, [pageNo]);


    function getAllPostPageableBySportsType() {
        let httpParams = {"page": pageNo, "size": 5};
        ChannelApi.getPostPageableBySportsTypeId(params.id ? params.id : 0, httpParams)
            .then(response => {
                setPostList([...postList, ...response.data.content])
                if (response.data.content.length === 0) {
                    setHasMore(false);
                } else if (null === response.data.content.length || undefined === response.data.content.length) {
                    setHasMore(false);
                } else if (response.data.content.length === response.data.totalElements) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            })
            .catch(err => {
            });
    }

    function addPostToList(post) {
        ChannelApi.getChannelPostByPostId(post?.data?.id).then(response => {
            setPostList([response.data, ...postList]);
        }).catch(errors => {
            console.error(errors);
        })
    }

    function updatePageValue() {
        setPageNo(pageNo + 1);
    }

    const handleSubmit = (data, onSubmitProps) => {

        if (!Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(data.message)) {
            Swal.fire("Write something to post", "", "info");
            return;
        }

        const formData = new FormData();

        formData.append('sportsId', params.id);
        formData.append('postText', data.message);

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        setLoader(true);

        ChannelApi.createPostWithAttachment(formData)
            .then((response) => {
                console.log(response);
                onSubmitProps.resetForm();
                setSelectedFileCount(0);
                setPageNo(0);
                /*if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"])) {
                    addPostToList(response);
                } else
                    Swal.fire("Your post is waiting for approval", "", "info");*/
            })
            .catch(err => console.log(err))
            .finally(() => setLoader(false));
        setFiles([]);
        setMessage([]);

    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (<textarea
            {...field}
            type="text"
            rows="5"
            className="form-control mb-1"
            aria-label="Username"
            aria-describedby="basic-addon1"
        />);
    };

    const handleChange = event => {
        let newFiles = [];
        setSelectedFileCount(event.target.files.length);
        for (let i = 0; i < event.target.files.length; i++) {
            let f = event.target.files[i];
            newFiles.push(f)
        }
        setFiles(newFiles);
    };

    const fileToDataUri = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });


    const submitComment = (data, resetForm, post) => {
        console.log(data);
        if (!Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(data.comment)) {
            Swal.fire("Write something to comment", "", "info");
            return;
        }

        let channelMessage = {
            id: data.messageId
        };

        let groupMessageCommentDTO = {
            comment: data.comment, channelPosts: channelMessage
        };
        let groupMessageCommentDTOToPushInArray = {
            comment: data.comment, channelPosts: channelMessage, user: Utils.getUserFromLocalStorage()
        };
        const newPosts = [...postList];
        newPosts[postList.indexOf(post)].channelPostCommentDTOList.push(groupMessageCommentDTOToPushInArray);

        setPostList(newPosts);
        ChannelApi.saveMessageComment(groupMessageCommentDTO).then(response => {
            const newPosts = [...postList];
            newPosts[postList.indexOf(post)].channelPostCommentDTOList = response.data;
            setPostList(newPosts);
        });
        resetForm(initialComment);
    }

    const registerLike = data => {
    };

    const submitCommentState = data => {
        setComment(data);
    };

    const viewLikedUserModal = post => {
    };


    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>
                        <div id="contentArea">
                            <div className="block-center card-default">
                                <Formik
                                    initialValues={message}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}>
                                    {props => (<Form>
                                        <div className="card-default table-shadow box-border-sm p-0">

                                            <div className="add-post-area">
                                                <div className="top-section d-flex w-100">
                                                    <div className="avatar mr-3">
                                                        <img alt={"image"}
                                                             src={FILES_URL + user?.imageUrl}
                                                             onError={(e) => {
                                                                 e.target.src = "img/Avatar.png";
                                                             }}/>
                                                    </div>

                                                    <Field
                                                        type="text"
                                                        name="message"
                                                        placeholder="id"
                                                        readonly
                                                        className="form-control"
                                                        component={TextField}
                                                    />
                                                </div>

                                                <div className="input-default">

                                                    <div className="block-group">
                                                        <input
                                                            className="custom-file-input"
                                                            id="file"
                                                            name="file"
                                                            type="file"
                                                            multiple
                                                            onChange={handleChange}
                                                        />
                                                        {/*<div className="block-50 add-assets">*/}
                                                        <div className="block">
                                                            <label className="add-assets" htmlFor="file">
                                                                <div className="box-icon">
                                                                    <img src="img/photo-gallery.png" alt="Photo Icon" className="icon"/>
                                                                    <p>Photo/Video</p>
                                                                </div>
                                                                <div className="box-icon">
                                                                    <img src="img/live events.png" alt="Live Events Icon" className="icon"/>
                                                                    <p>Live Event</p>
                                                                </div>
                                                                <div className="box-icon">
                                                                    <img src="img/screen-share.png" alt="Screen Share Icon" className="icon"/>
                                                                    <p className="screen-share">Screen Share</p>
                                                                </div>


                                                            </label>

                                                            {/*</div>*/}
                                                            <div className="submit-area">
                                                                {selectedFileCount > 0 ? (
                                                                    <p>{selectedFileCount} {selectedFileCount > 1 ? 'attachments' : 'attachment'} selected</p>) : null}
                                                                {loader ? (<RotatingLines
                                                                    width="35"
                                                                    height="35"
                                                                    strokeColor="blue"
                                                                />) : (<button
                                                                    type="submit"
                                                                    className="btn-red-md float-right">
                                                                    Post
                                                                </button>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>)}
                                </Formik>

                                <InfiniteScroll
                                    dataLength={postList.length}

                                    next={updatePageValue}
                                    hasMore={hasMore}
                                    loader={<div className="mx-auto py-4 text-center">
                                        <RotatingLines width="50" height="50" strokeColor="blue"/>
                                    </div>}>

                                    <PostContainer
                                        user={user}
                                        submitComment={submitComment}
                                        initialComment={initialComment}
                                        registerLike={registerLike}
                                        postList={postList}
                                        comment={comment}
                                        viewLikedUserModal={viewLikedUserModal}
                                        submitCommentState={submitCommentState}
                                    />

                                </InfiniteScroll>

                                <br/>
                            </div>
                            <RightBar/>
                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

// export default PostMain;

const mapStateToProps = state => ({
    searchQuery: state.roomBoardSearchReducer.searchQuery,
    loading: state.roomBoardSearchReducer.loading,
    error: state.roomBoardSearchReducer.error,
    eventList: state.eventList,
    assignmentList: state.assignmentList,
    notificationAssignmentCounter: state.notificationCounter.notificationAssignmentCounter,
});

export default connect(mapStateToProps)(PostMain);
