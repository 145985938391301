import axios from "axios";
import Cookies from "js-cookie";
import * as Utils from "../util/Utils";
import {API_MAIN_ROOT} from "../util/LinkFactory";

const instance = axios.create({
    baseURL: API_MAIN_ROOT,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = Cookies.get("auth-token");
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/auth/authenticate" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await instance.post("/auth/get-access-token-by-refresh-token", {
                        refreshToken: Utils.getRefreshTokenFromLocalStorage(),
                    });
                    Utils.setAuthTokenCookie(rs?.data?.accessToken);
                    return instance(originalConfig);
                } catch (_error) {
                    console.error("Remember not worked !!!")
                    await instance.post(API_MAIN_ROOT + "/auth/logout", {"userId": Utils.getUserFromLocalStorage().id});
                    Cookies.remove("auth-token");
                    localStorage.removeItem("auth-token");
                    localStorage.removeItem("user");
                    localStorage.removeItem("channelId");
                    localStorage.removeItem("channelName");
                    localStorage.removeItem("rememberMeChecked");
                    // localStorage.clear();
                    window.location.pathname = "/login";
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

export default instance;
