import React, {useEffect, useState} from "react";
import * as PostApi from "../../api/PostApi";

import * as LinkFactory from "../../util/LinkFactory";
import Loader from "../common/Loader";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function PostList() {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [postText, setPostText] = useState([]);
    const [initial, setInitial] = useState({name: "", phone: ""});
    const [id, setId] = useState(null);

    useEffect(() => {
        PostApi.getAll()
            .then(json => {
                console.log(json);
                setPostText(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleView = data => {
        navigateTo(LinkFactory.postView + "/" + data.id);
    };

    const handleDoNotShow = data => {
        console.log(data);
        setId(data.id);
        PostApi.updatePostStatus(data.id, false);
        setTimeout(
            function () {
                window.location.reload();
            }.bind(this),
            1000
        );
    };
    const handleShow = data => {
        setId(data.id);
        PostApi.updatePostStatus(data.id, true);
        setTimeout(
            function () {
                window.location.reload();
            }.bind(this),
            1000
        );
    };

    const deleteData = () => {
        PostApi.deleteCountryById(id);

        /// then set id as null again
        setId(null);
        window.location.reload();
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Posts</h1>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Post</th>
                                            <th>User Name - Email</th>
                                            <th>Timestamp</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {postText.length > 0 ? (
                                            postText.map((data, index) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{data.postText}</td>
                                                        <td>
                                                            {data.user.firstName} {data.user.middleName}{" "}
                                                            {data.user.lastName} - {data.user.email}{" "}
                                                        </td>
                                                        <td>{data.timestamp}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => handleView(data)}
                                                            >
                                                                <i className="fa fa-eye"></i> View
                                                            </button>

                                                            {data.status == true ? (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    onClick={() => handleDoNotShow(data)}
                                                                >
                                                                    Hide
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success"
                                                                    onClick={() => handleShow(data)}
                                                                >
                                                                    Show
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <Loader/>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostList;
