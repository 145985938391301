import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";


export const save = evalQuestOpt => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question-option", evalQuestOpt);
};

export const update = evalQuestOpt => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/evaluation-question-option", evalQuestOpt);
};

export const search = evalQuestOpt => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question-option/search", evalQuestOpt);
};

export const deleteEvaluationQuestionOption = id => {
  return AxiosAgent.requests.delete(API_MAIN_ROOT + "/evaluation-question-option/"+ id);
};

export const getEvaluationQuestionOptionById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/evaluation-question-option/" + id);
};
