import React from "react";

/**
 * this is a text field component for formik, this is a fork of original text field component, but with reference field added for special cases
 * @param field
 * @param type
 * @param innerRef
 * @param touched
 * @param errors
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const TextFieldWithRefComponent = ({
                                       field,
                                       type = "text",
                                       innerRef,
                                       form: {touched, errors},
                                       ...rest
                                   }) => {
    return (
        <div className="input-group">
            <input
                type={type}
                ref={innerRef}
                className="form-control"
                aria-label="industry"
                aria-describedby="basic-addon1"
                {...field}
                {...rest}
            />
        </div>
    );
};

export default TextFieldWithRefComponent;
