import React, {useEffect, useState} from "react";
import SingleAttachmentComponent from "../Components/SingleAttachmentComponent";
import TwoAttachmentsComponent from "../Components/TwoAttachmentsComponent";
import ThreeAttachmentsComponent from "../Components/ThreeAttachmentsComponent";
import FourAttachmentComponent from "../Components/FourAttachmentComponent";
import MoreThanFourAttachmentComponent from "../Components/MoreThanFourAttachmentComponent";

export function PostAttachment({postInfo}) {

    const [post, setPost] = useState({})

    useEffect(() => {
        if (postInfo) {
            setPost(postInfo)
        }
    }, [postInfo]);

    return (
        <>
            {post?.postContentDTO?.length === 1 ? (
                <SingleAttachmentComponent
                    id={post?.c}
                    imageurl={post?.postContentDTO[0]}
                />
            ) : null}

            {post?.postContentDTO?.length === 2 ? (
                <TwoAttachmentsComponent
                    id={post?.post.id}
                    imageurl={post?.postContentDTO}
                />
            ) : null}

            {post?.postContentDTO?.length === 3 ? (
                <ThreeAttachmentsComponent
                    id={post?.post.id}
                    imageurl={post?.postContentDTO}
                />
            ) : null}
            {post?.postContentDTO?.length === 4 ? (
                <FourAttachmentComponent
                    id={post?.post.id}
                    imageurl={post?.postContentDTO}
                />
            ) : null}
            {post?.postContentDTO?.length > 4 ? (
                <MoreThanFourAttachmentComponent
                    id={post?.post.id}
                    imageurl={post?.postContentDTO}
                />
            ) : null}
        </>
    );
}

export default PostAttachment;
