import {fetchChannelsBegin, fetchChannelsFailure, fetchChannelsSuccess} from "../action/channelAction";
import * as ChannelApi from "../../../api/ChannelApi";
import * as Utils from "../../../util/Utils";

export function fetchChannelsApi() {
    return dispatch => {
        dispatch(fetchChannelsBegin());
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY"])) {
            ChannelApi.getChannelListForAdmin()
                .then(response => {
                    dispatch(fetchChannelsSuccess(response.data));
                })
                .catch(errors => {
                    dispatch(fetchChannelsFailure(errors));
                });
        } else if (Utils.checkRolePermission(["ROLE_USER"])) {
            ChannelApi.getChannelListByLoggedUser()
                .then(response => {
                    dispatch(fetchChannelsSuccess(response.data));
                })
                .catch(errors => {
                    dispatch(fetchChannelsFailure(errors));
                });
        }
    };
}
