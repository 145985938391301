import React, {useEffect, useState} from "react";
import * as SubjectApi from "../../api/SubjectApi";
import {Field, Form, Formik} from "formik";

import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function SubjectEdit(props) {
    const params = useParams();
    const [subject, setSubject] = useState({
        name: "",
        id: ""
    });
    useEffect(() => {
        SubjectApi.getSubjectById(params.id)
            .then(json => {
                console.log(json);
                setSubject(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        SubjectApi.updateSubject(data);
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Subject Edit</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-pencil-square-o mr-2"/>
                                Edit here
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={subject}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {props => (
                                        <Form>
                                            <div className="table-responsive">
                                                <table
                                                    className="table table-bordered"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0"
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="12">#</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="name"
                                                                placeholder="name"
                                                                component={TextField}
                                                            />
                                                        </td>

                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <button
                                                                className="btn btn-lg btn-primary"
                                                                type="submit"
                                                            >
                                                                Update
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default SubjectEdit;
