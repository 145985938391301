import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";
import {toast} from "react-toastify";

import * as LinkFactory from "../../util/LinkFactory";
import * as FacultyApi from "../../api/FacultyApi";
import {useNavigate, useParams} from "react-router-dom";

function FacultyEdit(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [faculty, setFaculty] = useState({
        name: ''
    });
    const notifyEditSuccess = () => toast.success("Successfully Updated!", {
        position: toast.POSITION.TOP_CENTER
    });

    useEffect(() => {
        FacultyApi.getFacultyById(params.id)
            .then(json => {
                console.log(json);
                setFaculty(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        FacultyApi.updateFaculty(data).then(data => {
            notifyEditSuccess();
            navigateTo(LinkFactory.facultyList);
        });
    };

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout>
                <main>
                    <div className="card-default faculty-edit container-responsive">
                        <div className="title-header mt-3">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Faculty Edit</p>
                            </div>
                        </div>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-header mt-4 c-grey">
                                <i className="fas fa-pencil-square-o mr-2"/>
                                Edit here below
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={faculty}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}>
                                    {props => (
                                        <Form>
                                            <div className="table-responsive">
                                                <table
                                                    className="table  "
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0"
                                                >
                                                    <thead className=" ">
                                                    <tr>
                                                        <th scope="col">Faculty Name</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="name"
                                                                placeholder="Faculty Name"
                                                                component={TextFieldComponent}
                                                            />
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <button
                                                    className="btn btn-red-md"
                                                    type="submit">
                                                    Update
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default FacultyEdit;
