import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import CommonLayout from "../layout/commonLayout";
import * as GradingApi from "../../api/GradingApi";
import {Field, FieldArray, Form, Formik} from "formik";
import {TextArea} from "semantic-ui-react";
import TextFieldComponent from "../common/TextFieldComponent";
import LoaderOverlay from "../common/LoaderOverlay";
import Swal from "sweetalert2";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate, useParams} from "react-router-dom";

function EvaluationMarksEntry(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [assignmentTaskMarksList, setAssignmentTaskMarksList] = useState([]);
    const [uploadLoader, setUploadLoader] = useState(false);
    useEffect(() => {
        loadAssignmentTaskAndSubmitByEvaluationId(params.id);
    }, []);

    function loadAssignmentTaskAndSubmitByEvaluationId(id) {
        setUploadLoader(true);
        GradingApi.getAssignmentTaskSubmitWithScoreByEvaluationId(id).then((res) => {
            setAssignmentTaskMarksList({list: res.data});
            setUploadLoader(false);
        })
    }

    function loadAssignmentTaskAndSubmitByEvaluationIdForUpdate(id) {
        setUploadLoader(true);
        GradingApi.getAssignmentTaskSubmitWithScoreByEvaluationId(id).then((res) => {
            setAssignmentTaskMarksList({list: res.data});
            setUploadLoader(false);
            Swal.fire("Success", "Done", "success");
            navigateTo(LinkFactory.allAssessmentList);
        })
    }


    async function handleScoreEntry(score, taskId, studentUserId) {
        return await GradingApi.evaluationMarksEdit({
            taskId: taskId,
            userId: studentUserId,
            marks: score
        }).then((res) => {
            return res
        });
    }

    function handleSubmit(value) {
        setUploadLoader(true);
        value.list.forEach(value => {
            value.taskWiseMarks.forEach(async v => {
                if (null != v.marksObtain && "null" !== v.marksObtain && "" !== v.marksObtain) {
                    const result = await handleScoreEntry(v.marksObtain, v.id, value.studentUser.id)
                }
                //;
            })
        });
        loadAssignmentTaskAndSubmitByEvaluationIdForUpdate(params.id);
    }

    const TextAreaComponent = ({field, form: {touched, errors, ...rest}}) => {
        return (
            <div className="input-group mb-3">
                <TextArea
                    {...field}
                    {...rest}
                    type="text"
                    cols="50"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout headerName={"Marks Entry"}>
                <main>
                    <div>
                        <div>
                            {uploadLoader ? <LoaderOverlay/> : null}
                            <Formik
                                initialValues={assignmentTaskMarksList}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}>
                                {({values}) => (
                                    <Form>

                                        <div className="table-responsive table-light table-shadow radius-15 mt-4">
                                            <table className="table table-light"
                                                   id="dataTable"
                                                   width="100%"
                                                   cellSpacing="0">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Student Name</th>
                                                    {
                                                        assignmentTaskMarksList?.list?.length > 0 ?
                                                            assignmentTaskMarksList?.list[0]?.taskWiseMarks.map((taskWiseMark, index) => {
                                                                return <th scope="col">
                                                                    Task {index + 1}
                                                                </th>
                                                            })
                                                            : null
                                                    }
                                                    <th scope="col" className="text-right">Total Score</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <FieldArray
                                                    name="list"
                                                    render={arrayHelpers => (<>
                                                        {values?.list?.length > 0 ?
                                                            values.list?.map((studentMark, mainIndex) => (
                                                                <tr key={mainIndex}>
                                                                    <td
                                                                        key={studentMark.studentUser.id + 1}>
                                                                        {studentMark.studentUser.firstName} {" "}
                                                                        {studentMark.studentUser.lastName}</td>
                                                                    {studentMark?.taskWiseMarks?.map((taskMark, index) => (
                                                                        <td key={index}>
                                                                            <Field
                                                                                name={`list[${mainIndex}].taskWiseMarks[${index}].marksObtain`}
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        </td>
                                                                    ))}
                                                                    <td
                                                                        key={studentMark.studentUser.id}>{studentMark.totalObtainedMarks}
                                                                    </td>
                                                                </tr>
                                                            )) : ""}
                                                    </>)}

                                                />
                                                <tr>
                                                    <td colSpan="12">
                                                        <div className="text-right w-100">
                                                            <button type="submit" className="btn btn-md btn-primary my-4 text-right">
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </Form>)}

                            </Formik>
                            <br/>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EvaluationMarksEntry;
