import React, {useEffect, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";

import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";
import * as LetterGradeMasterApi from "../../api/LetterGradeMasterApi";
import Swal from "sweetalert2";
import * as Utils from "../../util/Utils";
import LoaderOverlay from "../common/LoaderOverlay";

function LetterGradeNew(props) {
    const [loader, setLoader] = useState(true);
    const [letterGradeMain, setLetterGradeMain] = useState({
        grade: '',
        letterGrades: []
    });
    const [deleteList, setDeleteList] = useState([]);
    const [invalid, setInvalid] = useState(false);

    async function saveLetterGrade(payload) {
        return await LetterGradeMasterApi.saveLetterGradeMaster(payload).then(res => {
            return res;
        })
    }

    async function deleteLetterGrade(id) {
        return await LetterGradeMasterApi.deleteLetterGradeMasterById(id).then(res => {
            return res;
        })
    }

    function handleDeleteList(id) {
        var temp = deleteList;
        temp.push(id);
        setDeleteList(temp);
    }

    function deleteLetterGrades() {
        deleteList.forEach(async (value, index) => {
            const result = await deleteLetterGrade(value);
            if (index === (deleteList.length - 1)) {
                loadAllLetterGradeMaster(1);
            }
        });
        if (deleteList.length === 0) {
            loadAllLetterGradeMaster(1);
        }
        setDeleteList([]);
    }

    var BreakException = {};
    const handleSubmit = data => {
        setLoader(true);
        var ind = true;
        data.letterGrades.forEach((v, indx) => {
            if (Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(v.upperValue) === false ||
                Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(v.letterGradeName) === false ||
                Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(v.gradePoint) === false
            ) {
                setInvalid(true);
                ind = false;
                setLoader(false);
                Swal.fire("Failed", "Please Fill all the fields", "error");
                throw BreakException;
            }
            if (data.letterGrades.length === indx + 1 && ind === true) {
                data.letterGrades.forEach(async (value, index) => {
                    var payload;
                    if (null == value.id || '' === value.id)
                        payload = {
                            upperValue: value.upperValue,
                            letterGradeName: value.letterGradeName,
                            gradePoint: value.gradePoint,
                            id: value.id,
                            letterGradeMasterMain: {id: 1}
                        };
                    else
                        payload = {
                            upperValue: value.upperValue,
                            letterGradeName: value.letterGradeName,
                            gradePoint: value.gradePoint,
                            id: value.id
                        };
                    const result = await saveLetterGrade(payload);
                    if (index === (data.letterGrades.length - 1)) {
                        deleteLetterGrades();
                        setLoader(false);
                        Swal.fire("Success", "Letter Grade List Updated", "success");
                    }

                });
            }
        });


    };

    function loadAllLetterGradeMaster(id) {
        setLoader(true)
        LetterGradeMasterApi.getLetterGradeMasterListByMainId(id).then(response => {
            setLetterGradeMain({letterGrades: response.data})
            setLoader(false);
        });
    }

    useEffect(() => {
        loadAllLetterGradeMaster(1);
    }, []);


    return (
        <>
            <CommonLayout headerName="Letter Grades">
                <main>
                    <div className="ml-4">
                        <h3 className="mt-4 mb-4">Letter Grades</h3>
                        {loader ? <LoaderOverlay/> : null}
                        <div>
                            <Formik
                                initialValues={letterGradeMain}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({props, values}) => (
                                    <Form>
                                        <FieldArray
                                            name="letterGrades"
                                            render={arrayHelpers => (
                                                <div className="w-100">
                                                    {values.letterGrades && values.letterGrades.length > 0
                                                        ? values.letterGrades.map((letterGrade, index) => (
                                                            <span key={index} className="w-100">
                                                              <div className="form-row">
                                                                <div className="col-3">
                                                                  <div className="form-group input-default-white">
                                                                    <label>Letter Grade</label>
                                                                    <Field
                                                                        name={`letterGrades[${index}].letterGradeName`}
                                                                        component={TextFieldComponent}
                                                                    />
                                                                  </div>
                                                                </div>

                                                                <div className="col-3">
                                                                  <div className="form-group input-default-white">
                                                                    <label>Score</label>
                                                                    <Field
                                                                        name={`letterGrades[${index}].upperValue`}
                                                                        type="text"
                                                                        component={TextFieldComponent}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                  <div className="col-3">
                                                                  <div className="form-group input-default-white">
                                                                    <label>Grade Point</label>
                                                                    <Field
                                                                        name={`letterGrades[${index}].gradePoint`}
                                                                        type="text"
                                                                        component={TextFieldComponent}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                  <div className="col-3">
                                                                      <div className="form-group input-default-white mb-0 mt-2">
                                                                        <label>&nbsp;</label>
                                                                          <div className="input-group">
                                                                              <button
                                                                                  title="Delete"
                                                                                  className="btn btn-sm btn-danger radius-25"
                                                                                  type="button"
                                                                                  onClick={() => {
                                                                                      arrayHelpers.remove(index);
                                                                                      handleDeleteList(letterGrade.id);
                                                                                  }}>
                                                                                <i className="fa fa-trash"/>
                                                                                </button>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                            </span>
                                                        ))
                                                        : ""}
                                                    <div className="form-row mt-4">
                                                        <div className="col-9">
                                                            <div className="form-group input-default-white">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-dark-more radius-15 w-100"
                                                                    onClick={() =>
                                                                        arrayHelpers.push({
                                                                            id: "",
                                                                            letterGradeName: "",
                                                                            upperValue: ""
                                                                        })
                                                                    }
                                                                >
                                                                    <i className="fa fa-plus-circle mr-2"/>
                                                                    Add more
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <div className="form-row text-right">
                                            <div className="col-9">
                                                <button type="submit"
                                                        className="btn btn-dark radius-25 px-4 mt-4">
                                                    <i className="fa fa-chevron-circle-right mr-2"/>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default LetterGradeNew;
