import React, {useState} from "react";

import * as LinkFactory from "../../util/LinkFactory";
import CommonLayout from "../layout/commonLayout";
import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import * as FacultyApi from "../../api/FacultyApi";
import {toast} from "react-toastify";
import FormTitle from "../Typhography/formTitle";
import {useNavigate} from "react-router-dom";


const FacultyNew = () => {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [faculty, setFaculty] = useState(
        {name: ''}
    );
    const [createFacultyLoader, setCreateFacultyLoader] = useState(false);
    const notifySuccess = () => toast.success("Faculty Created!", {
        position: toast.POSITION.TOP_CENTER
    });

    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });

    const handleSubmit = data => {
        if (null == data.name || '' === data.name) {
            notifyValidation();
        } else {
            FacultyApi.saveFaculty(data).then(data => {
                notifySuccess();
                navigateTo(LinkFactory.facultyList);

            });
        }
    };

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout headerName={"New Faculty"}>
                <main>
                    <div className="card-default faculty-new container-responsive">
                        <div className="title-header mt-3">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Faculty New</p>
                            </div>
                        </div>
                        {/*{createFacultyLoader ? <LoaderOverlay/> : null}*/}
                        <di>
                            <Formik
                                initialValues={faculty}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}>
                                {props => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group input-default-white">
                                                    <FormTitle>Faculty Name</FormTitle>
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        placeholder="Faculty Name"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group input-default-white">
                                                    <FormTitle> &nbsp;</FormTitle>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-dark radius-25 px-4 d-block">
                                                        <i className="fa fa-save mr-2"/> Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </di>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}
export default FacultyNew;
