import React from "react";
import PropTypes from "prop-types";

const TextField = ({
                       label,
                       field,
                       form: {dirty, touched, errors},
                       ...other
                   }) => {
    const errorText = errors[field.name];
    const hasError = dirty && touched[field.name] && errorText !== undefined;
    return (
        <div className="form-group">
            <label htmlFor={field.name}>{label}</label>
            <input
                type="text"
                className="form-control"
                id={field.name}
                placeholder={label}
                {...field}
                {...other}
            />
            {hasError && <div>{errorText}</div>}
        </div>
    );
};

TextField.propTypes = {
    label: PropTypes.string.isRequired,
    field: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
    form: PropTypes.shape({
        dirty: PropTypes.bool,
        errors: PropTypes.object
    }).isRequired,
    variant: PropTypes.string,
    margin: PropTypes.oneOf(["none", "dense", "normal"])
};

TextField.defaultProps = {
    margin: "normal",
    variant: "outlined"
};
export default TextField;
