import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as EmployeeApi from "../../api/EmployeeApi";
import * as ChannelApi from "../../api/ChannelApi";
import * as ChannelPostRankingApi from "../../api/ChannelPostRankingApi";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import CommonLayout from "../layout/commonLayout";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {DeleteOutlineRounded} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import LoaderOverlay from "../common/LoaderOverlay";
import TextFieldComponent from "../common/TextFieldComponent";
import Swal from "sweetalert2";

import * as LinkFactory from "../../util/LinkFactory";


function ChannelRankingNew(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const slug = useParams();
    const [room, setRoom] = useState({
        channel: {},
        channelRanking: 0,
        globalInd: false,
        user: {}
    });

    const [moderatorSelectedOption, setModeratorSelectedOption] = React.useState([]);
    const [channel, setChannel] = React.useState([]);
    const [moderators, setModerators] = React.useState([]);
    const [studentTypeAheadResults, setStudentTypeAheadResults] = useState([]);
    const [moderatorTypeAheadResults, setModeratorTypeAheadResults] = useState([]);
    const [deletedModerators, setDeletedModerators] = useState([]);
    const [createChannelRankingLoader, setCreateChannelRankingLoader] = useState(false);
    const [isLoadingModerators, setIsLoadingModerators] = useState(false);

    useEffect(() => {
        ChannelApi.getChannelById(params.id)
            .then(json => {
                setChannel(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, [params.id]);

    const handleSubmit = values => {
        if (moderatorSelectedOption.length === 0) {
            Swal.fire("Failed to save", "Please select a user for ranking ", "error");
        }
        setCreateChannelRankingLoader(true);
        values.channel = channel;
        values.user = moderatorSelectedOption[0].fullObject;
        ChannelPostRankingApi.saveRanking(values).then(res => {
            setCreateChannelRankingLoader(false);
            if (res.status === 200 || res.status === 201) {
                Swal.fire("Saved", "Ranking Saved", "success");
            }
            handleChannelPostRankingView(params.id)
        })
    };

    const handleModeratorSearch = query => {
        setIsLoadingModerators(true);

        EmployeeApi.channelModeratorMemberUserByChannelIdSearch(query, params.id)
            .then(json => {
                const result = json?.response?.data || [];
                const options = result.map(i => ({
                    fullObject: i,
                    label: i.firstName + " " + i.lastName,
                    value: i.id
                }));
                setModeratorTypeAheadResults(options);
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoadingModerators(false));
    };
    const handleChannelPostRankingView = id => {
        navigateTo(LinkFactory.channelPostRankingView + "/" + id);
    };
    const typeaheadSelectModerator = data => {
        const deletedItems = moderators
            .filter(x => !moderatorSelectedOption.includes(x))
            .concat(moderatorSelectedOption.filter(x => !moderators.includes(x)));
        setModeratorSelectedOption(data);
    };

    const deleteModerator = id => {
        deletedModerators.push(id);
        const users = moderatorSelectedOption.filter(
            member => member.fullObject.id !== id
        );
        console.log(users);
        setModeratorSelectedOption(users?.length > 0 ? users : []);
    };


    const filterBy = () => true;
    return (
        <>
            <CommonLayout headerName={"New Post Ranking"}>
                <main>
                    <div>
                        <h3>New Post Ranking</h3>
                        {createChannelRankingLoader ? <LoaderOverlay/> : null}

                        <div className="card-default">
                            <div className="card-body">
                                <br/>
                                <div>
                                    <div className="card bg-light pt-3 pb-3 p-2">
                                        <div className="card-body">
                                            <Formik
                                                initialValues={room}
                                                enableReinitialize={true}
                                                onSubmit={handleSubmit}>
                                                {({props, values, setFieldValue}) => {
                                                    return (
                                                        <Form>
                                                            <label
                                                                className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                {" "}
                                                                Select moderator/member (only one could be selected a time)
                                                            </label>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <AsyncTypeahead
                                                                        size={"md"}
                                                                        filterBy={filterBy}
                                                                        id="async-example"
                                                                        isLoading={isLoadingModerators}
                                                                        labelKey="label"
                                                                        minLength={3}
                                                                        multiple={false}
                                                                        selected={moderatorSelectedOption}
                                                                        onSearch={handleModeratorSearch}
                                                                        options={moderatorTypeAheadResults}
                                                                        onChange={typeaheadSelectModerator}
                                                                        placeholder="Type moderator/member name here..."
                                                                        renderMenuItemChildren={option => (
                                                                            <>
                                                                                <div className="flex    py-1">
                                                                                    <div className="mb-2 text-info">
                                                                                        Name: <b>{option.label}</b>
                                                                                    </div>
                                                                                    {" "}
                                                                                    <div
                                                                                        className={"border-top-1  text-secondary"}>
                                                                                        Email:{option.fullObject.email}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label
                                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                                            {" "}
                                                                            Ranking{" "}
                                                                        </label>
                                                                        <Field
                                                                            type="number"
                                                                            name="ranking"
                                                                            onChange={e => {
                                                                                e.preventDefault();
                                                                                const {value} = e.target;
                                                                                const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                                                if (regex.test(value.toString())) {
                                                                                    setFieldValue("ranking", value);
                                                                                }
                                                                            }}
                                                                            placeholder="Type Ranking"
                                                                            component={TextFieldComponent}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="ranking"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {moderatorSelectedOption.length > 0 ? (
                                                                <div
                                                                    className="table-responsive table-light table-shadow radius-15">
                                                                    <table
                                                                        className="table table-light table-hover"
                                                                        id="dataTable"
                                                                        width="100%"
                                                                        cellSpacing="0">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" className="text-center">
                                                                                Name
                                                                            </th>
                                                                            <th scope="col" className="text-center">
                                                                                Email
                                                                            </th>
                                                                            <th scope="col" className="text-center">
                                                                                Actions
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {moderatorSelectedOption.length > 0
                                                                            ? moderatorSelectedOption.map(
                                                                                (member, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td className="text-center">
                                                                                                {member.fullObject.firstName}{" "}
                                                                                                {member.fullObject.lastName}
                                                                                            </td>

                                                                                            <td className="text-center">
                                                                                                {member.fullObject.email}
                                                                                            </td>
                                                                                            <td className="text-center ">
                                                                                                <DeleteOutlineRounded
                                                                                                    onClick={() =>
                                                                                                        deleteModerator(
                                                                                                            member.fullObject.id
                                                                                                        )
                                                                                                    }
                                                                                                    role={"button"}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )
                                                                            : ""}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="form-group">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-success radius-25 mt-4">
                                                                    <i className="fa fa-save mr-2"/>
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ChannelRankingNew;
