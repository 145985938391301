export const FETCH_CHANNELS_BEGIN = "FETCH_CHANNELS_BEGIN";
export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_FAILURE = "FETCH_CHANNELS_FAILURE";

export const fetchChannelsBegin = () => ({
    type: FETCH_CHANNELS_BEGIN
});

export const fetchChannelsSuccess = channels => ({
    type: FETCH_CHANNELS_SUCCESS,
    payload: {channels: channels}
});

export const fetchChannelsFailure = error => ({
    type: FETCH_CHANNELS_FAILURE,
    payload: {error}
});
