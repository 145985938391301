export const FETCH_NOTIFICATION_COUNTER_BEGIN = "FETCH_POST_NOTIFICATION_COUNTER_BEGIN";
export const FETCH_NOTIFICATION_COUNTER_SUCCESS = "FETCH_POST_NOTIFICATION_COUNTER_SUCCESS";
export const FETCH_POST_NOTIFICATION_COUNTER_FAILURE = "FETCH_POST_NOTIFICATION_COUNTER_FAILURE";

export const fetchNotificationCounterBegin = () => ({
    type: FETCH_NOTIFICATION_COUNTER_BEGIN
});

export const fetchNotificationCounterSuccess = notificationCounter => ({
    type: FETCH_NOTIFICATION_COUNTER_SUCCESS,
    payload: {
        notificationPostCounter: null != notificationCounter.postCount ? notificationCounter.postCount : "0",
        notificationChatCounter: null != notificationCounter.chatCount ? notificationCounter.chatCount : "0",
        notificationAssignmentCounter: null != notificationCounter.assignmentCount ? notificationCounter.assignmentCount : "0"
    }
});

export const fetchNotificationCounterFailure = error => ({
    type: FETCH_POST_NOTIFICATION_COUNTER_FAILURE,
    payload: {error}
});
