export const FETCH_ATTENDANCE_BEGIN = "FETCH_EVENTS_BEGIN";
export const FETCH_ATTENDANCE_SUCCESS = "FETCH_ATTENDANCE_SUCCESS";
export const ADD_ATTENDANCE_SUCCESS = "ADD_ATTENDANCE_SUCCESS";
export const FETCH_ATTENDANCE_FAILURE = "FETCH_ATTENDANCE_FAILURE";

export const fetchAssignmentBegin = () => ({
    type: FETCH_ATTENDANCE_BEGIN
});

export const fetchPreviousAssignmentsSuccess = assignments => ({
    type: FETCH_ATTENDANCE_SUCCESS,
    payload: {previousAssignments: assignments}
});
export const fetchUpcomingAssignmentsSuccess = assignments => ({
    type: FETCH_ATTENDANCE_SUCCESS,
    payload: {upcomingAssignments: assignments}
});
export const addUpcomingAssignmentsSuccess = assignments => ({
    type: ADD_ATTENDANCE_SUCCESS,
    payload: {upcomingAssignments: assignments}
});
export const addPreviousAssignmentsSuccess = assignments => ({
    type: ADD_ATTENDANCE_SUCCESS,
    payload: {previousAssignments: assignments}
});

export const fetchAssignmentsFailure = error => ({
    type: FETCH_ATTENDANCE_FAILURE,
    payload: {error}
});
