import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import * as EvaluationQuestionTypeApi from "../../../api/EvaluationQuestionTypeApi";
import Loader from "../../common/Loader";
import * as LinkFactory from "../../../util/LinkFactory";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


function EvaluationQuestionTypeList() {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [loader, setLoader] = useState(true);
  const [evalQuesTypeList, setEvalQuesTypeList] = useState([]);
  const [evaluationQuesType] = useState({
    questionType: "",
  });

  useEffect(() => {
    getEvaluationList();
  }, []);

  const notifyDelete = () => toast.success("Successfully Deleted!", {
    position: toast.POSITION.TOP_CENTER
  });

  const handleNew = data => {
    navigateTo(LinkFactory.evaluationQuestionType);
  };

  const handleEdit = data => {
    navigateTo(LinkFactory.evaluationQuestionType + "/" + data.id);
  }

  const handleDelete = data => {
    EvaluationQuestionTypeApi.deleteEvaluationQuestionType(data.id).then(()=> {
      notifyDelete();
      getEvaluationList();

    })
  }

  const getEvaluationList = () => {
    setLoader(true);
    EvaluationQuestionTypeApi.search(evaluationQuesType).then(res=>{
      setEvalQuesTypeList(res.data);
      setLoader(false);
    });
  }

  return (
    <>
      <CommonLayout headerName="Evaluation Question Type">
        <main>
          <div>
            <h3 className="mt-4 mb-4">
              Question Type List
              <button
                  type="button"
                  className="btn btn-outline-dark radius-25 float-right"
                  onClick={() => handleNew()}>
                <i className="fa fa-plus-circle mr-2"/>
                Create new Evaluation Question Type
              </button>
            </h3>

            <div className="table-responsive table-light table-shadow radius-15 mt-4">
              <table
                  className="table  table-light table-hover"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
              >
                <thead>
                <tr>
                  <th scope="col">Sl</th>
                  <th scope="col">Question Type</th>
                  <th scope="col" className="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                {undefined!==evalQuesTypeList && evalQuesTypeList?.length > 0
                  ? evalQuesTypeList.map((data, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{data?.questionType}</td>
                        <td className="text-right">
                          <button
                            type="button"
                            title="Edit"
                            className="btn btn-sm btn-primary"
                            onClick={() => handleEdit(data)}
                          >
                            <i className="fa fa-edit"/>
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            title="Delete"
                            className="btn btn-sm btn-danger"
                            data-toggle="modal"
                            data-target="#deleteModal"
                            onClick={() => handleDelete(data)}
                          >
                            <i className="fa fa-trash"/>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  : <td colSpan="12" className="text-center">
                    {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                  </td>}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationQuestionTypeList;
