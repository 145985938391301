import React from "react";
import {ErrorMessage, useField} from 'formik';

export const FormikTextField = ({label, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label htmlFor={field.name} className="font-weight-medium text-gray-25 text-lg-left mb-2">{label}</label>
            <input
                className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
                {...field} {...props}
                autocomplete="off"
            />
            <ErrorMessage component="div" name={field.name} className="error_val"/>
        </>
    )
}

