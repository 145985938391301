import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveGradeSetup = gradeSetup => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/grade-setup/", gradeSetup);
};

export const updateGradeSetup = gradeSetup => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/grade-setup/", gradeSetup);
};
export const getGradeSetupById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grade-setup/" + id);
};
export const deleteGradeSetupById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/grade-setup/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grade-setups");
};
