import React, {useEffect, useState} from "react";
import * as SemesterApi from "../../api/SemesterApi";

import * as LinkFactory from "../../util/LinkFactory";
import CommonLayout from "../layout/commonLayout";
import {toast} from "react-toastify";
import Loader from "../common/Loader";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";

function SemesterList() {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [loader, setLoader] = useState(true);
    const [institute, setInstitute] = useState([]);
    const [id, setId] = useState(null);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const notifyDelete = () => toast.success("Successfully Deleted!", {
        position: toast.POSITION.TOP_CENTER
    });

    function getSemesterList() {
        setLoader(true);
        const searchFields = {
            semesterName: '',
            page: pageNumber
        };
        SemesterApi.searchSemester(searchFields)
            .then(json => {
                setInstitute(json?.data?.content);
                setPageCount(json?.data?.totalPages);
                setLoader(false);
            })
            .catch(errors => {
                console.log(errors);
                setLoader(false);
            });
    }

    const changeSemesterPage = ({selected}) => {
        setPageNumber(selected);
    }

    useEffect(() => {
        getSemesterList();
    }, [pageNumber, setInstitute]);

    const handleEdit = data => {
        navigateTo(LinkFactory.semesterEdit + "/" + data.id);
    };
    const handleView = data => {
        navigateTo(LinkFactory.semesterView + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.semesterNew);
    };
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        SemesterApi.deleteSemesterById(id);
        setId(null);
        notifyDelete();
        window.location.reload();
    };

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout>
                <main>
                    <div className="card-default semester-list container-responsive">
                        <div className="title-header ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Semester</p>
                            </div>

                            <button
                                type="button"
                                className="btn btn-outline-dark radius-25 float-right"
                                onClick={() => handleNew()}>
                                <i className="fa fa-plus-circle mr-2"/>
                                Create new Semester
                            </button>
                        </div>

                        <div className="table-responsive table-light table-striped">
                            <table
                                className="table  table-light  table-hover"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0">
                                <thead>
                                <tr>
                                    {/*<th scope="col">Sl</th>*/}
                                    <th scope="col">Semester</th>
                                    <th scope="col">Start</th>
                                    <th scope="col">End</th>
                                    <th scope="col" width="15%" className=" ">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {institute.length > 0
                                    ? institute.map((data, index) => {
                                        return (
                                            <tr>
                                                {/*<td scope="row">{index + 1}</td>*/}
                                                <td>{data?.semesterName}</td>
                                                <td>{data?.markingPeriodStart}</td>
                                                <td>{data?.markingPeriodEnd}</td>
                                                <td className=" ">
                                                    <button
                                                        type="button"
                                                        title="View"
                                                        className="btn btn-sm bg-c-blue-light mr-1"
                                                        onClick={() => handleView(data)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="17" height="12" fill="none">
                                                            <path fill="#4575EE"
                                                                  d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                        </svg>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        title="Edit"
                                                        className="btn btn-sm bg-c-light-orange mr-1"
                                                        onClick={() => handleEdit(data)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="18" height="15" fill="none">
                                                            <path fill="#000"
                                                                  d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                        </svg>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        title="Delete"
                                                        className="btn btn-sm bg-c-red-light"
                                                        data-toggle="modal"
                                                        data-target="#deleteModal"
                                                        onClick={() => handleDelete(data)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="13" height="14" fill="none">
                                                            <path fill="#D81414"
                                                                  d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <td colSpan="12" className="text-center">
                                        {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                                    </td>}
                                </tbody>
                            </table>
                            <div className="page-navigation">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changeSemesterPage}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header  ">
                            <div className="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                    <path fill="#D81414"
                                          d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                                </svg>

                            </div>
                            <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>
                        </div>
                        <div className="modal-footer">

                            <button
                                type="button"
                                className=" "
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SemesterList;
