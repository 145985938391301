import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveGradingBreakdown = gradingBreakdown => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/grading-breakdown/",
        gradingBreakdown
    );
};

export const updateGradingBreakdown = gradingBreakdown => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/grading-breakdown/",
        gradingBreakdown
    );
};
export const getGradingBreakdownById = gradingBreakdownId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grading-breakdown/" + gradingBreakdownId
    );
};
export const getGradingBreakdownByGradingId = gradingId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grading-breakdown-by-grading-id/" + gradingId
    );
};
export const findGradingBreakdownByChannelIdAndCurrentTimePeriod = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT +
        "/grading-breakdown-by-channel-id-today-between-time-period/" +
        channelId
    );
};
export const findGradingBreakdownByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT +
        "/grading-breakdown-by-channel-id/" +
        channelId
    );
};
export const deleteGradingBreakdownById = gradingBreakdownId => {
    return AxiosAgent.requests.delete(
        API_MAIN_ROOT + "/grading-breakdown/" + gradingBreakdownId
    );
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading-breakdown");
};
