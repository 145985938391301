import React, {Component} from "react";

class FooterComponent extends Component {
    render() {
        return (
            <footer className="py-4 bg-light mt-auto">
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between small">
                        <div className="text-muted">Copyright &copy; Edooket 2022</div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterComponent;
