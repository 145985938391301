import * as ActionTypes from "../constants/ActionTypes";
import {
    MODAL_FORM_ERROR,
    MODAL_FORM_LOADING,
    MODAL_FORM_LOADING_COMPLETE,
    MODAL_FORM_UNLOADED,
    MODAL_SetField,
    MODAL_Unloaded,
    MODAL_UPDATE_FIELD_REGISTER
} from "../constants/ActionTypes";

export function showModal(heading, body, onSave, options = {}) {
    let initialFooterState = {
        visible: true,
        button1: "No",
        button2: "Yes"
    };
    const {className} = options;
    return {
        type: ActionTypes.Modal_SHOW,
        heading: heading,
        body: body,
        onSave: onSave,
        initialFooterState: initialFooterState,
        className: className
    };
}

export function showModalCustomFooter(heading, body, footer, options = {}) {
    let initialFooterState = {
        visible: false
    };
    const {className} = options;
    return {
        type: ActionTypes.Modal_SHOW,
        heading: heading,
        body: body,
        initialFooterState: initialFooterState,
        className: className,
        footer: footer
    };
}

export function showModalWithoutFooter(heading, body, options = {}) {
    let initialFooterState = {
        visible: false
    };
    const {className} = options;
    return {
        type: ActionTypes.Modal_SHOW,
        heading: heading,
        body: body,
        initialFooterState: initialFooterState,
        className: className
    };
}

export function closeModal() {
    return {type: ActionTypes.Modal_HIDE};
}

export function setFieldRegister(name, value) {
    return {type: MODAL_UPDATE_FIELD_REGISTER, key: name, value};
}

export function formError(errors) {
    return {type: MODAL_FORM_ERROR, payload: {errors: errors}};
}

export function loading() {
    return {type: MODAL_FORM_LOADING};
}

export function loadingComplete() {
    return {type: MODAL_FORM_LOADING_COMPLETE};
}

export function formUnloaded() {
    return {type: MODAL_FORM_UNLOADED};
}

export function unloaded() {
    return {type: MODAL_Unloaded};
}

export function setInForm(data) {
    return {type: MODAL_SetField, payload: data};
}
