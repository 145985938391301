import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../../common/TextFieldComponent";
import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import * as EvaluationQuestionTypeApi from "../../../api/EvaluationQuestionTypeApi";
import FormTitle from "../../Typhography/formTitle";
import * as LinkFactory from "../../../util/LinkFactory";
import {useNavigate} from "react-router-dom";


function EvaluationQuestionType (props) {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [evaluationQuesType, setEvaluationQuesType] = useState({
    questionType: "",
    id: null
  });
  const [disable, setDisable] = React.useState(false);
  const [evalQuesMainList, setEvalQuesMainList] = useState([]);

  const notifyValidation = () => toast.success("All fields required", {
    position: toast.POSITION.TOP_CENTER
  });
  const notifySuccess = () => toast.success("Evaluation question Created!", {
    position: toast.POSITION.TOP_RIGHT
  });
  const handleSubmit = value => {
    setDisable(true);
    if(null == value.questionType || '' === value.questionType) {
      notifyValidation();
      setDisable(false);
    } else {
      if(null == value.id) {
        EvaluationQuestionTypeApi.save(value).then(res => {
          notifySuccess();
          handleList();
        });
      } else {
        EvaluationQuestionTypeApi.update(value).then(res => {
          notifySuccess();
          handleList();
        });
      }
    }
  }

  const handleList = () => {
    navigateTo(LinkFactory.evaluationQuestionTypeList);
  };

  const getEvaluationQuestionById = id => {
    EvaluationQuestionTypeApi.getEvaluationQuestionTypeById(id).then(res=> {
      setEvaluationQuesType(res.data);
    });
  }

  useEffect(()=> {
    console.log(props?.match?.params?.id);
    if (props?.match?.params?.id) {
      getEvaluationQuestionById(props?.match?.params?.id)
    }
  }, [])

  return (
    <>
      <CommonLayout headerName="Evaluation Question Type">
        <main>
          <div>
            <h3 className="mt-4 mb-4">Evaluation Question</h3>
            <div>
              <Formik
                initialValues={evaluationQuesType}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({props, values, setFieldValue}) => (
                  <Form>
                    <div className="form-row">
                      <div className="col-4">
                        <div className="form-group input-default-white">
                          <FormTitle>Question Type</FormTitle>
                          <Field
                            type="text"
                            name="questionType"
                            placeholder="Question Type"
                            component={TextFieldComponent}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <label> &nbsp;</label>
                          <button
                              type="submit"
                              disabled={disable}
                              className="btn btn-dark radius-25 px-4 d-block">
                            <i className="fa fa-bookmark mr-2"/>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationQuestionType;
