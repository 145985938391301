import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveStates = states => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/states/", states);
};

export const updateStates = states => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/states/", states);
};
export const getStatesById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/states/" + id);
};
export const deleteStatesById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/states/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/states");
};
