import React, {useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Login from "./components/login/Login";
import * as LinkFactory from "./util/LinkFactory";
import "./style/AllStyle";
import CountryEdit from "./components/country/CountryEdit";
import CountryList from "./components/country/CountryList";
import CountryView from "./components/country/CountryView";

import StatesEdit from "./components/states/StatesEdit";
import StatesList from "./components/states/StatesList";
import StatesView from "./components/states/StatesView";

import StatesNew from "./components/states/StatesNew";
import CountryNew from "./components/country/CountryNew";
import PostList from "./components/Post/PostList";
import PostView from "./components/Post/PostView";
import SubjectList from "./components/Subject/SubjectList";
import SubjectEdit from "./components/Subject/SubjectEdit";
import SubjectView from "./components/Subject/SubjectView";
import SubjectNew from "./components/Subject/SubjectNew";
import EmployeeNew from "./components/employee/EmployeeNew";
import EmployeeList from "./components/employee/EmployeeList";
import EmployeeEdit from "./components/employee/EmployeeEdit";
import EmployeeView from "./components/employee/EmployeeView";
import ChannelNew from "./components/ChannelAdmin/ChannelNew";
import ClassSetupNew from "./components/ClassSetup/ClassSetupNew";
import ClassSetupList from "./components/ClassSetup/ClassSetupList";
import ClassSetupEdit from "./components/ClassSetup/ClassSetupEdit";
import ClassSetupView from "./components/ClassSetup/ClassSetupView";
import AssignmentSubmitEdit from "./components/AssignmentSubmit/AssignmentSubmitEdit";
import AssignmentSubmitView from "./components/AssignmentSubmit/AssignmentSubmitView";
import AssignmentSubmitNew from "./components/AssignmentSubmit/AssignmentSubmitNew";
import AssignmentListOfUser from "./components/Assignment/AssignmentListOfUser";
import AssignmentSubmittedView from "./components/Assignment/AssignmentSubmittedView";
import ChannelList from "./components/ChannelAdmin/ChannelList";
import ChannelView from "./components/ChannelAdmin/ChannelView";

import ClassList from "./components/ClassList/ClassList";
import EventsList from "./components/Events/EventsList";
import AssignmentTaskSubmittedView from "./components/Assignment/AssignmentTaskSubmittedView";
import EmployeeRegistration from "./components/employee/EmployeeRegistration";
import ChannelMaterialUpload from "./components/ChannelMaterial/ChannelMaterialUpload";
import ChannelMaterialView from "./components/ChannelMaterial/ChannelMaterialView";
import GradingNew from "./components/Grading/GradingNew";
import GradingBreakdownView from "./components/Grading/GradingBreakdownView";
import AssessmentListForEvaluation from "./components/Assignment/AssessmentListForEvaluation";
import AssessmentMarksEntry from "./components/Assignment/AssessmentMarksEntry";
import AllAssessmentList from "./components/Assignment/AllAssessmentList";
import GradeBook from "./components/Grading/GradeBook";
import AttendanceEntry from "./components/RoutineAndAttendance/AttendanceEntry";
import CalenderPage from "./components/ChannelClient/CalenderPage";
import GradingListByChannel from "./components/Grading/GradingListByChannel";
import GradingEvaluationEntry from "./components/Grading/GradingEvaluationEntry";
import RoutineEntry from "./components/RoutineAndAttendance/RoutineEntry";
import RoutineListByChannel from "./components/RoutineAndAttendance/RoutineListByChannel";
import RoutineDetails from "./components/RoutineAndAttendance/RoutineDetails";
import DirectMessage from "./components/DirectMessage/DIrectMessage";
import DirectMessageThreads from "./components/DirectMessage/DIrectMessageThread";
import ThreadedMessageIndex from "./components/MessageThread";
import MessagesThreadsIndex from "./components/MessageThread";
import DirectMessageFirstPage from "./components/DirectMessage/DIrectMessageFirstPage";
import AttendanceReport from "./components/RoutineAndAttendance/AttendanceReport";
import ChannelSinglePostView from "./components/ChannelClient/ChannelSinglePostView";
import ChannelApproval from "./components/ChannelAdmin/ChannelApproval";
import McqExam from "./components/Mcq/McqExam";
import McqNew from "./components/Mcq/McqNew";
import GetAllMcqQuestions from "./components/Mcq/GetAllMcqQuestions";
import AssignmentNewContainer from "./components/Assignment/AssignmentNewContainer";
import StudentsHistory from "./components/StudentsHistory";
import AttendanceHistory from "./components/AttendanceHistory";
import AttachmentList from "./components/StudyMaterials/AttachmentList";
import StudyMaterialsUploadPage from "./components/StudyMaterials/StudyMaterialsUploadPage";
import StudyMaterialsEditPage from "./components/StudyMaterials/StudyMaterialsEditPage";
import StudentsList from "./components/StudentsDetails/StudentsList";
import StudentEditForm from "./components/StudentsDetails/StudentEditForm";
import StudentDetails from "./components/StudentsDetails/StudentDetails";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SemesterNew from "./components/Semester/SemesterNew";
import SemesterView from "./components/Semester/SemesterView";
import SemesterList from "./components/Semester/SemesterList";
import SemesterEdit from "./components/Semester/SemesterEdit";
import AllNotificationList from "./components/Notification/AllNotificationList";
import UserProfile from "./components/Profiles/UserProfile";
import FollowerFollowing from "./components/Profiles/FollowerFollowing";
import RoutineEdit from "./components/RoutineAndAttendance/RoutineEdit";
import McqSubmissionList from "./components/Mcq/McqSubmissionList";
import McqSubmissions from "./components/Mcq/McqSubmissions";
import FacultyList from "./components/Faculty/FacultyList";
import FacultyNew from "./components/Faculty/FacultyNew";
import FacultyEdit from "./components/Faculty/FacultyEdit";
import DepartmentList from "./components/Department/DepartmentList";
import DepartmentNew from "./components/Department/DepartmentNew";
import ProgramList from "./components/Program/ProgramList";
import ProgramNew from "./components/Program/ProgramNew";
import GradingEvaluationEdit from "./components/Grading/GradingEvaluationEdit";
import EvaluationMarksEntry from "./components/Assignment/AssignmentMarkEdit";
import LetterGradeNew from "./components/LetterGrade/LetterGradeNew";
import StudentCourseHistory from "./components/StudentCourseHistory/StudentCourseHistory";
import UMRoleSetupNew from "./components/userManagement/UMRole/UMRoleSetupNew";
import EvaluationQuestionMain from "./components/evaluation/evaluationQuestionMain/EvaluationQuestionMain";
import EvaluationQuestionMainList from "./components/evaluation/evaluationQuestionMain/EvaluationQuestionMainList";
import UMRoleSetupList from "./components/userManagement/UMRole/UMRoleSetupList";
import UMUserRoleSetupNew from "./components/userManagement/UMUserRole/UMUserRoleSetupNew";
import EvaluationQuestionList from "./components/evaluation/evaluationQuestion/EvaluationQuestionList";
import EvaluationQuestion from "./components/evaluation/evaluationQuestion/EvaluationQuestion";
import EvaluationQuestionType from "./components/evaluation/EvaluationQuestionType/EvaluationQuestionType";
import EvaluationQuestionTypeList from "./components/evaluation/EvaluationQuestionType/EvaluationQuestionTypeList";
import EvaluationQuestionOptionList
        from "./components/evaluation/EvaluationQuestionOption/EvaluationQuestionOptionList";
import EvaluationQuestionOption from "./components/evaluation/EvaluationQuestionOption/EvaluationQuestionOption";
import EvaluationQuestionTeacher from "./components/evaluation/EvaluationQuestionOption/EvaluationQuestionTeacher";
import EvaluationInfoList from "./components/evaluation/EvaluationInfo/EvaluationInfoList";
import EvaluationInfo from "./components/evaluation/EvaluationInfo/EvaluationInfo";
import StudentRegistrationUpload from "./components/StudentsDetails/StudentRegistrationUpload";
import EvaluationResult from "./components/evaluation/EvaluationResult/EvaluationResult";
import ChannelRankingNew from "./components/ChannelPostRanking/ChannelRankingNew";
import ChannelPostRankingView from "./components/ChannelPostRanking/ChannelPostRankingView";
import EvaluationCompleteStudentList from "./components/ChannelMembers/EvaluationCompleteStudentList";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import EmailVerification from "./components/AccountVerification/EmailVerification";
import VerificationPage from "./components/AccountVerification/VerificationPage";
import FindYourAccountComponent from "./components/ForgetPassword/FindYourAccountComponent";
import ResetPasswordByOtpComponent from "./components/ForgetPassword/ResetPasswordByOtpComponent";
import NotFound from "./components/CommonLayouts/NotFound";
import EmployeeRegistrationUpload from "./components/employee/EmployeeRegistrationUpload";
import {messaging} from './firebaseMessaging';
import {onMessage} from 'firebase/messaging';
import {useDispatch} from "react-redux";
import ChannelCreateUpload from "./components/ChannelAdmin/ChannelCreateUpload";
import StudentsGradeBookComponent from "./components/StudentsGradeBook/StudentsGradeBookComponent";
import ChannelPostDetail from "./components/ChannelClient/ChannelPostDetail";
import ChannelBoard from "./components/ChannelClient/ChannelBoard/PostMain";
import ParentProfile from "./components/Profiles/ParentProfile";
import CourseWiseStudentsList from "./components/StudentsDetails/CourseWiseStudentsList";
import DeleteUser from "./components/Profiles/DeleteUser";
import Opportunities from "./components/Opportunities/Opportunities";
import Promotions from "./components/Promotions/Promotions";
import EditProfile from "./components/Profiles/EditProfile";
import UserRegistration from "./components/Registration/UserRegistration";
import LiveMatches from "./components/LiveMatches/LiveMatches";
import OpportunitiyDetails from "./components/Opportunities/OpportunitiyDetails";
import LiveStreams from "./components/LiveMatches/LiveStreams";
import {streamsPlayer} from "./util/LinkFactory";
import StreamPlayer from "./components/LiveMatches/StreamPlayer";

toast.configure();

function App(props) {
    const [firebaseToken, setFirebaseToken] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    // isPermitted() {
    //     const roles = [];
    //     // const rolesPassed = role;
    //     //  const userRoles =
    //     //this.state.roles = userRoles;
    //     // userRoles.forEach((data) => {
    //     //     console.log(data);
    //     //     roles.push(data.name);
    //     //     console.log(roles.toString());
    //     // })
    //     // rolesPassed.forEach((d) => {
    //     //     console.log(roles.indexOf(d));
    //     //     if (roles.indexOf(d) !== -1) {
    //     //         this.state.isAuthenticated = true;
    //     //     }
    //     // })
    //        // }

    onMessage(messaging, payload => {
        toast.info(payload.notification.body);
        // dispatch(fetchNotificationCounter());
        //     dispatch(fetchNotificationList());
        //     if (payload.notification.body.includes("has a new event notification")) {
        //             dispatch(fetchAllEvents());
        //     } else if (payload.data['assignmentId']) {
        //             dispatch(fetchAssessmentDTO(payload.data['assignmentId']));
        //     }
    });


    return (<Routes location={location} key={location.pathname}>
            <Route path={LinkFactory.loginPage} element={<Login/>}/>
            <Route path={LinkFactory.login} element={<Login/>}/>
            <Route path={LinkFactory.home} element={<ChannelBoard/>}/>
            <Route path={LinkFactory.countryNew} element={<CountryNew/>}/>
            <Route path={LinkFactory.country} element={<CountryList/>}/>
            <Route

                path={LinkFactory.country + "/:id"}
                element={<CountryEdit/>}
            />
            <Route

                path={LinkFactory.countryView + "/:id"}
                element={<CountryView/>}
            />

            <Route path={LinkFactory.statesNew} element={<StatesNew/>}/>
            <Route path={LinkFactory.states} element={<StatesList/>}/>
            <Route

                path={LinkFactory.states + "/:id"}
                element={<StatesEdit/>}
            />
            <Route

                path={LinkFactory.statesView + "/:id"}
                element={<StatesView/>}
            />

            <Route

                path={LinkFactory.emailVerification + "/:key"}
                element={<EmailVerification/>}
            />

            <Route
                path={LinkFactory.verifyEmail}
                element={<VerificationPage/>}
            />

            <Route path={LinkFactory.post} element={<PostList/>}/>
            <Route path={LinkFactory.postView + "/:id"} element={<PostView/>}/>
            <Route path={LinkFactory.channelPostView + "/:id"} element={<ChannelSinglePostView/>}/>
            <Route path={LinkFactory.postDetails + "/:id"} element={<ChannelPostDetail/>}/>
            <Route path={LinkFactory.changePassword} element={<ChangePassword/>}/>
            <Route path={LinkFactory.subjectNew} element={<SubjectNew/>}/>
            <Route path={LinkFactory.subject} element={<SubjectList/>}/>
            <Route path={LinkFactory.subject + "/:id"} element={<SubjectEdit/>}/>
            <Route path={LinkFactory.subjectView + "/:id"} element={<SubjectView/>}/>
            <Route path={LinkFactory.employeeNew} element={<EmployeeNew/>}/>
            <Route path={LinkFactory.employeeList} element={<EmployeeList/>}/>
            <Route

                path={LinkFactory.employeeList + "/:id"}
                element={<EmployeeEdit/>}
            />
            <Route

                path={LinkFactory.employeeView + "/:id"}
                element={<EmployeeView/>}
            />
            <Route

                path={LinkFactory.employeeRegistration}
                element={<EmployeeRegistration/>}
            />

            <Route

                path={LinkFactory.assignmentNew + "/:id"}
                element={<AssignmentNewContainer/>}
            />

            <Route

                path={LinkFactory.assignmentNew + "/:id/:assignmentId/:gradingEvaluationId/:gradingBreakdownId"}
                element={<AssignmentNewContainer/>}
            />

            <Route path={LinkFactory.newChannel} element={<ChannelNew/>}/>
            <Route path={LinkFactory.groupList} element={<ChannelList/>}/>
            <Route

                path={LinkFactory.newChannel + "/:id"}
                element={<ChannelNew/>}
            />

            <Route

                path={LinkFactory.newChannelXlsx}
                element={<ChannelCreateUpload/>}
            />
            <Route

                path={LinkFactory.groupView + "/:id"}
                element={<ChannelView/>}
            />
            <Route
                path={LinkFactory.channelBoard + "/:id"}
                element={<ChannelBoard/>}
            />

            <Route
                path={LinkFactory.channelBoard}
                element={<ChannelBoard/>}
            />

            <Route

                path={LinkFactory.findYourAccount}
                element={<FindYourAccountComponent/>}
            />

            <Route

                path={LinkFactory.resetPasswordByOtp}
                element={<ResetPasswordByOtpComponent/>}
            />

            <Route

                path={LinkFactory.classSetupNew}
                element={<ClassSetupNew/>}
            />
            <Route

                path={LinkFactory.studentCourseHistory + "/:rollNo"}
                element={<StudentCourseHistory/>}
            />
            <Route
                path={LinkFactory.studentCourseHistory}
                element={<StudentCourseHistory/>}
            />
            <Route
                path={LinkFactory.assignmentListByUser}
                element={<AssignmentListOfUser/>}
            />
            <Route

                path={LinkFactory.classSetup}
                element={<ClassSetupList/>}
            />
            <Route

                path={LinkFactory.classSetupEdit + "/:id"}
                element={<ClassSetupEdit/>}
            />
            <Route

                path={LinkFactory.classSetupView + "/:id"}
                element={<ClassSetupView/>}
            />

            <Route

                path={LinkFactory.channelStudyMaterialUpload + "/:id"}
                element={<ChannelMaterialUpload/>}
            />
            <Route

                path={LinkFactory.channelStudyMaterialView + "/:id"}
                element={<ChannelMaterialView/>}
            />
            <Route path={LinkFactory.channelList} element={<ChannelList/>}/>
            <Route

                path={LinkFactory.channelPostApproval + "/:id"}
                element={<ChannelApproval/>}
            />


            <Route

                path={LinkFactory.assignmentSubmit + "/:id"}
                element={<AssignmentSubmitNew/>}
            />
            <Route

                path={LinkFactory.assignmentSubmitList + "/:id"}
                element={<AssignmentListOfUser/>}
            />
            <Route

                path={LinkFactory.assignmentSubmitEdit + "/:id"}
                element={<AssignmentSubmitEdit/>}
            />
            <Route

                path={LinkFactory.assignmentSubmitView + "/:id"}
                element={<AssignmentSubmitView/>}
            />
            <Route

                path={LinkFactory.assignmentSubmittedView + "/:id"}
                element={<AssignmentSubmittedView/>}
            />
            <Route

                path={LinkFactory.assignmentTaskSubmittedView + "/:id"}
                element={<AssignmentTaskSubmittedView/>}
            />
            <Route

                path={LinkFactory.classListForLoggedInUser}
                element={<ClassList/>}
            />


            <Route path={LinkFactory.attendanceEntry + "/:id"} element={<AttendanceEntry/>}/>
            <Route path={LinkFactory.gradingNew + "/:id"} element={<GradingNew/>}/>
            <Route path={LinkFactory.employeeBulkRegistration} element={<EmployeeRegistrationUpload/>}/>
            <Route path={LinkFactory.gradingByChannelId + "/:id"} element={<GradingListByChannel/>}/>
            <Route path={LinkFactory.gradingBreakdownView + "/:id"} element={<GradingBreakdownView/>}/>
            <Route path={LinkFactory.gradingEvaluationEntry + "/:id"} element={<GradingEvaluationEntry/>}/>
            <Route path={LinkFactory.gradingEvaluationEdit + "/:id"} element={<GradingEvaluationEdit/>}/>
            <Route

                path={LinkFactory.gradingEvaluationMarksEntry + "/:id"}
                element={<EvaluationMarksEntry/>}
            />
            <Route

                path={LinkFactory.assessmentList + "/:id"}
                element={<AssessmentListForEvaluation/>}
            />
            <Route

                path={LinkFactory.attendanceReport + "/:id"}
                element={<AttendanceReport/>}
            />
            <Route

                path={LinkFactory.gradeBook + "/:id"}
                element={<GradeBook/>}
            />
            <Route

                path={LinkFactory.routineEntry + "/:id"}
                element={<RoutineEntry/>}
            />
            <Route

                path={LinkFactory.routineEdit + "/:id/:routineId"}
                element={<RoutineEdit/>}
            />
            <Route

                path={LinkFactory.routineListByChannelId + "/:id"}
                element={<RoutineListByChannel/>}
            />
            <Route
                path={LinkFactory.routineDetails + "/:id"}
                element={<RoutineDetails/>}
            />
            <Route path={LinkFactory.allAssessmentList + "/:id"}
                   element={<AllAssessmentList/>}
            />
            <Route path={LinkFactory.allAssessmentList}
                   element={<AllAssessmentList/>}
            />
            <Route

                path={LinkFactory.assessmentMarksEntry + "/:id/:assessmentId"}
                element={<AssessmentMarksEntry/>}
            />
            <Route

                path={LinkFactory.calenderPage}
                element={<CalenderPage/>}
            />
            <Route path={LinkFactory.message} element={<DirectMessage/>}/>
            <Route

                path={LinkFactory.messageList}
                element={<DirectMessageThreads/>}
            />
            <Route

                path={LinkFactory.messageThread}
                element={<MessagesThreadsIndex/>}
            />
            <Route

                path={LinkFactory.messageThread + "/:id"}
                element={<ThreadedMessageIndex/>}
            />
            <Route

                path={LinkFactory.messageFirstPage}
                element={<DirectMessageFirstPage/>}
            />

            <Route path={LinkFactory.mcqNew + "/:id"} element={<McqNew/>}/>
            <Route

                path={LinkFactory.mcqExam + "/:id"}
                element={<McqExam/>}
            />
            <Route

                path={LinkFactory.mcqSubmittedView + "/:id"}
                element={<McqSubmissionList/>}
            />
            <Route

                path={LinkFactory.mcqSubmissions + "/:mcqId/:userId"}
                element={<McqSubmissions/>}
            />
            <Route

                path={LinkFactory.allMcqQuestions + "/:id"}
                element={<GetAllMcqQuestions/>}
            />
            <Route

                path={LinkFactory.studentsHistory}
                element={<StudentsHistory/>}
            />

            <Route

                path={LinkFactory.studentsGradeBook}
                element={<StudentsGradeBookComponent/>}
            />

            <Route

                path={LinkFactory.attendanceHistory}
                element={<AttendanceHistory/>}
            />

            <Route

                path={LinkFactory.channelFileAttachmentUpload + "/:id"}
                element={<StudyMaterialsUploadPage/>}
            />

            <Route

                path={LinkFactory.channelStudyMaterials + "/:id"}
                element={<AttachmentList/>}
            />

            <Route

                path={LinkFactory.channelEditStudyMaterials + "/:id/:materialId"}
                element={<StudyMaterialsEditPage/>}
            />

            <Route

                path={LinkFactory.studentsList}
                element={<StudentsList/>}
            />
            <Route

                path={LinkFactory.studentRegistrationUpload}
                element={<StudentRegistrationUpload/>}
            />
            <Route

                path={LinkFactory.getStudent + "/:id"}
                element={<StudentEditForm/>}
            />
            <Route

                path={LinkFactory.getStudentDetails + "/:id"}
                element={<StudentDetails/>}
            />

            <Route path={LinkFactory.semesterNew} element={<SemesterNew/>}/>
            <Route

                path={LinkFactory.semesterView + "/:id"}
                element={<SemesterView/>}
            />
            <Route

                path={LinkFactory.semesterEdit + "/:id"}
                element={<SemesterEdit/>}
            />
            <Route

                path={LinkFactory.semesterList}
                element={<SemesterList/>}
            />

            <Route

                path={LinkFactory.facultyList}
                element={<FacultyList/>}
            />

            <Route

                path={LinkFactory.facultyNew}
                element={<FacultyNew/>}
            />

            <Route

                path={LinkFactory.facultyEdit + "/:id"}
                element={<FacultyEdit/>}
            />

            <Route

                path={LinkFactory.departmentList}
                element={<DepartmentList/>}
            />

            <Route

                path={LinkFactory.departmentNew}
                element={<DepartmentNew/>}
            />
            <Route

                path={LinkFactory.departmentNew + "/:id"}
                element={<DepartmentNew/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionMain}
                element={<EvaluationQuestionMain/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionMain + "/:id"}
                element={<EvaluationQuestionMain/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionMainList}
                element={<EvaluationQuestionMainList/>}
            />
            <Route

                path={LinkFactory.evaluationQuestion}
                element={<EvaluationQuestion/>}
            />
            <Route

                path={LinkFactory.evaluationQuestion + "/:id"}
                element={<EvaluationQuestion/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionList}
                element={<EvaluationQuestionList/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionOption}
                element={<EvaluationQuestionOption/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionOption + "/:id"}
                element={<EvaluationQuestionOption/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionOptionList}
                element={<EvaluationQuestionOptionList/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionType}
                element={<EvaluationQuestionType/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionType + "/:id"}
                element={<EvaluationQuestionType/>}
            />
            <Route

                path={LinkFactory.evaluationQuestionTypeList}
                element={<EvaluationQuestionTypeList/>}
            />
            <Route

                path={LinkFactory.evaluationCompleteStudentList + "/:channelId"}
                element={<EvaluationCompleteStudentList/>}
            />
            <Route

                path={LinkFactory.teacherEvaluation}
                element={<EvaluationQuestionTeacher/>}
            />
            <Route

                path={LinkFactory.teacherEvaluation + "/:id"}
                element={<EvaluationQuestionTeacher/>}
            />
            <Route

                path={LinkFactory.evaluationInfoList}
                element={<EvaluationInfoList/>}
            />
            <Route

                path={LinkFactory.evaluationInfo}
                element={<EvaluationInfo/>}
            />
            <Route

                path={LinkFactory.evaluationInfo + "/:id"}
                element={<EvaluationInfo/>}
            />
            <Route path={LinkFactory.evaluationResult + "/:id"} element={<EvaluationResult/>}/>
            <Route path={LinkFactory.programList} element={<ProgramList/>}/>
            <Route path={LinkFactory.programNew} element={<ProgramNew/>}/>
            <Route path={LinkFactory.programNew + "/:id"} element={<ProgramNew/>}/>
            <Route path={LinkFactory.allNotifications} element={<AllNotificationList/>}/>
            <Route path={LinkFactory.studentProfile} element={<UserProfile/>}/>
            <Route path={LinkFactory.teacherProfile} element={<FollowerFollowing/>}/>
            <Route path={LinkFactory.parentProfile} element={<ParentProfile/>}/>
            <Route path={LinkFactory.letterGradeNew} element={<LetterGradeNew/>}/>
            <Route path={LinkFactory.UserManagementRoleNew} element={<UMRoleSetupNew/>}/>
            <Route path={LinkFactory.UserManagementRoleList} element={<UMRoleSetupList/>}/>
            <Route path={LinkFactory.UserManagementUserRoleNew} element={<UMUserRoleSetupNew/>}/>
            <Route path={LinkFactory.channelRankingSetup + "/:id"} element={<ChannelRankingNew/>}/>

            <Route path={LinkFactory.channelPostRankingView + "/:id"} element={<ChannelPostRankingView/>}/>
            <Route path={LinkFactory.courseWiseStudentInfo + "/:id"} element={<CourseWiseStudentsList/>}/>
            <Route path={LinkFactory.deleteUser} element={<DeleteUser/>}/>
            <Route path={LinkFactory.followerFollowing} element={<FollowerFollowing/>}/>

            <Route path={LinkFactory.opportunities} element={<Opportunities/>}/>
            <Route path={LinkFactory.opportunities + '/:id'} element={<OpportunitiyDetails/>}/>
            <Route path={LinkFactory.promotions} element={<Promotions/>}/>
            <Route path={LinkFactory.editProfile} element={<EditProfile/>}/>
            <Route path={LinkFactory.editProfile} element={<EditProfile/>}/>
            <Route path={LinkFactory.userRegistration} element={<UserRegistration/>}/>
            <Route path={LinkFactory.eventsSetupList} element={<EventsList/>}/>
            <Route path={LinkFactory.liveMatches} element={<LiveMatches/>}/>
            <Route path={LinkFactory.liveStreams+ "/:id"} element={<LiveStreams/>}/>
            <Route path={LinkFactory.streamsPlayer+ "/:id"} element={<StreamPlayer/>}/>


            <Route path="*" element={<NotFound/>}/>
        </Routes>);

}

export default App;
