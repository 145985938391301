import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as LiveMatchApi from "../../api/LiveMatchApi";
import moment from "moment";
import {FILES_URL} from "../../util/LinkFactory";
import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";


function LiveMatches() {
    const history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [upcomingMatchList, setUpcomingMatchList] = useState([]);
    const [previousMatchList, setPreviousMatchList] = useState([]);


    useEffect(() => {
        getAllLiveMatchListByType('upcoming')
        getAllLiveMatchListByType('previous')
    }, []);

    function getAllLiveMatchListByType(type) {
        LiveMatchApi.getAllMatchByType({type})
            .then(resp => {
                console.log(resp);
                if (type === 'previous') setPreviousMatchList(resp.data?.data ? resp.data.data : [])
                if (type === 'upcoming') setUpcomingMatchList(resp.data?.data ? resp.data.data : [])
            })
    }

    const handleLiveMatchesRoute = (match) => {
        navigateTo(LinkFactory.liveStreams +"/"+ match.id);
    };

    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>

                        <div className="container">
                            <div className="row">

                                <div class="col-md-6">
                                    <div className="row">
                                        <h2>Upcoming Match</h2>
                                        {upcomingMatchList && upcomingMatchList.length ?
                                            upcomingMatchList.map((item, index) => (
                                                <div className="col-sm-12" key={index}>
                                                    <div className="card">
                                                        <div className="row card-body">
                                                            <div className="col-sm-6">
                                                                <h5 className="card-title">{item.title}</h5>
                                                                <p className="card-text">{item.location}</p>
                                                                <p className="card-text">{item.startTime ? moment(item.startTime).format('MMMM Do YYYY, h:mm') : ""}</p>
                                                                <button type="button" onClick={()=>handleLiveMatchesRoute(item)}
                                                                        className="btn btn-primary">Details
                                                                </button>
                                                            </div>
                                                            <div className="col-sm-6" style={{height:200, width:200, overflow: 'hidden'}}>
                                                                <img  src={FILES_URL + item.thumbnail}
                                                                      style={{
                                                                          height: '100%', // Fill the height of the parent
                                                                          width: '100%', // Fill the width of the parent
                                                                          objectFit: 'cover' // Maintain aspect ratio, cover the area
                                                                      }}
                                                                      alt={"thumbnail_"+index}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div className="row">
                                        <h2>Previous Match</h2>
                                        {previousMatchList && previousMatchList.length ?
                                            previousMatchList.map((item, index) => (
                                                <div className="col-sm-12" key={index}>
                                                    <div className="card">
                                                        <div className="row card-body">
                                                            <div className="col-sm-6">
                                                                <h5 className="card-title">{item.title}</h5>
                                                                <p className="card-text">{item.location}</p>
                                                                <p className="card-text">{item.startTime ? moment(item.startTime).format('MMMM Do YYYY, h:mm') : ""}</p>
                                                            </div>

                                                            <div className="col-sm-6" style={{height:100, width:100, overflow: 'hidden'}}>
                                                                <img  src={FILES_URL + item.thumbnail}
                                                                      style={{
                                                                          height: '100%', // Fill the height of the parent
                                                                          width: '100%', // Fill the width of the parent
                                                                          objectFit: 'cover' // Maintain aspect ratio, cover the area
                                                                      }}
                                                                      alt={"thumbnail_"+index}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

export default LiveMatches;
