import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveClassSetup = classSetup => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/class-setup/", classSetup);
};

export const updateClassSetup = classSetup => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/class-setup/", classSetup);
};
export const getClassSetupById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/class-setup/" + id);
};
export const deleteClassSetupById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/class-setup/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/class-setups");
};

export const saveClassSetupChild = classSetupSaveDTO => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/class-setup-child-dto/",
        classSetupSaveDTO
    );
};

export const getClassByLoggedInStudent = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/class-setup-by-logged-in-user/"
    );
};
export const getStudentListByClassSetupId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/student-list-by-class-setup-id/" + id
    );
};
