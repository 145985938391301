import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveGrading = grading => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/grading/", grading);
};

export const updateGrading = grading => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/grading/", grading);
};
export const getGradingById = gradingId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading/" + gradingId);
};
export const getGradingByChannelId = channelId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading-by-channel/" + channelId);
};
export const findGradingListByChannelId = gradingId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading-by-channel-id/" + gradingId);
};
export const findGradingBreakDownByChannelId = channelId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading-breakdown-by-channel-id/" + channelId);
};
export const deleteGradingById = gradingId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/grading/" + gradingId);
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading");
};
export const getAssignmentTaskSubmitWithScoreByEvaluationId = (evaluationId) => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/assignment-task-submit/submitted-tasks/" + evaluationId);
};

export const evaluationMarksEdit = entryDto => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/assignment-task-submit/evaluation-marks-entry/", entryDto);
};