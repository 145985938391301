import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";
import * as Utils from "../../util/Utils";
import * as UserApi from "../../api/UserApi";
import Swal from "sweetalert2";
import * as LinkFactory from "../../util/LinkFactory";
import {useDispatch} from "react-redux";

function DeleteUser() {

    let history = useNavigate();
    const [user, setUser] = useState({});
    const dispatch = useDispatch();

    function navigateTo(pathname) {
        history(pathname);
    }


    const handleSubmit = () => {

        UserApi.deleteUser(user?.id).then(() => {
            Swal.fire("User Deleted", "success");
            handleLogOut();
        })
    };
    const handleLogOut = () => {
        Utils.LogOut(dispatch);
        history(LinkFactory.loginPage)
    };
    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
    }, []);

    function goBack() {
        navigateTo(-1)
    }

    return (
        <>
            <CommonLayout headerName="Program">
                <main>
                    <div className="card-default program-new container-responsive">
                        <div className="title-header mt-3">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Delete My Profile</p>
                            </div>
                        </div>

                        <div>
                            <div className="col-4 col-btn">
                                <div className="form-group input-default-white">

                                    <button
                                        type="button"
                                        onClick={handleSubmit}
                                        className="btn btn-dark radius-25 px-4 d-block">
                                        <i className="fa fa-trash mr-2"/>
                                        Delete My Profile
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default DeleteUser;
