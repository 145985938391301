import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import FormTitle from "../Typhography/formTitle";
import {useNavigate, useParams} from "react-router-dom";
import CommonLayout from "../layout/commonLayout";
import * as yup from "yup";
import GenerateQuestions from "./GenerateQuestions";
import Select from "react-select";
import Error from "../Error";
import MarkdownEditor from "../MarkdownEditor";
import * as ChannelApi from "../../api/ChannelApi";
import * as McqApi from "../../api/McqApi";
import * as Utils from "../../util/Utils";

const initialState = {
    mcqQuestionDTOList: [
        {
            question: "",
            sl_no: 1,
            marks: 1,
            // isMultipleAnswer: false,
            mcqOptionsDTOList: [
                {
                    answer: "",
                    sl_no: 1,
                    isRight: false
                }
            ]
        }
    ]
};
const mySchema = yup.object({});

function GetAllMcqQuestions(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [assignment, setAssignment] = useState(initialState);
    const [description, setDescription] = useState("");
    const [isMinusMarkingEnabled, setIsMinusMarkingEnabled] = useState(true);
    const [customError, setCustomError] = useState(false);
    const [loader, setLoader] = useState(false);
    const formRef = useRef();
    const [preValue, setPreValue] = useState(null);
    const [channel, setChannel] = useState({});

    useEffect(() => {
        if (!Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"])) navigateTo("/");
    }, [history]);

    useEffect(() => {
        ChannelApi.getChannelById(params.id)
            .then(json => {
                if (json.response.status === 200 || json.response.status === 201) {
                    McqApi.getMcqByQuestionId(2)
                        .then(response => {
                            console.log(response, "mcq list");
                        })
                        .catch(errors => {
                            console.log(errors);
                        });

                    setChannel(json.data);
                    McqApi.findAllMcqByChannelId(params.id)
                        .then(response => {
                            console.log(response, "mcq list");
                        })
                        .catch(errors => {
                            console.log(errors);
                        });
                } else navigateTo("/");
            })
            .catch(errors => {
                console.log(errors);
                navigateTo("/");
            });
    }, [history, params.id]);

    const handleSubmit = values => {
        if (
            description === "<p></p>\n" ||
            description === "<p></p>" ||
            description.length < 1
        )
            setCustomError(true);
        else {
            const data = {...values};
            data.name = description;
            data.totalMark = 0;
            data.channel = channel;
            data.isMinusMarkingEnabled = isMinusMarkingEnabled;

            McqApi.saveMcqNew(data)
                .then(response => {
                    console.log(response, "espos");
                    if (
                        response &&
                        response.response &&
                        response.response.status === 201
                    ) {
                    }
                })
                .catch(er => console.log(er));
        }
    };

    useEffect(() => {
        if (preValue)
            setAssignment({
                mcqQuestionDTOList: preValue.mcqQuestionDTOList
            });
    }, [preValue]);

    const handleBack = () => {
        history(-1);
    };
    const handleMcqQuestionListAuto = useCallback(mcqQuestionDTOList => {
        setPreValue(mcqQuestionDTOList);
    }, []);

    const setQuestionDescriptionValueHandler = useCallback(
        value => {
            setDescription(value);
            if (
                description === "<p></p>\n" ||
                description === "<p></p>" ||
                description.length < 1
            )
                setCustomError(false);
        },
        [description]
    );

    console.log(description, "pre al");
    return (
        <Fragment>
            <CommonLayout>
                <div>
                    <div>
                        <h5>Create a new mcq question set</h5>
                        <br/>
                        <div className="mt-3">
                            <div className="form-group">
                                <FormTitle>Question Description</FormTitle>
                                <div className=" mb-3">
                                    <MarkdownEditor
                                        cbValue={setQuestionDescriptionValueHandler}
                                    />
                                    {/*<TextArea*/}
                                    {/*  rows={3}*/}
                                    {/*  type="text"*/}
                                    {/*  cols="40"*/}
                                    {/*  className="form-control"*/}
                                    {/*  aria-label="question"*/}
                                    {/*  aria-describedby="basic-addon1"*/}
                                    {/*  onChange={e => setDescription(e.target.value)}*/}
                                    {/*/>*/}
                                    {customError ? (
                                        <Error> Description can't be empty</Error>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="form-row  ">
                                <div className="col-12">
                                    <div className="form-group">
                                        <FormTitle>Negative Marking </FormTitle>

                                        <Select
                                            placeholder={isMinusMarkingEnabled ? "True" : "False"}
                                            value={isMinusMarkingEnabled}
                                            onChange={option =>
                                                setIsMinusMarkingEnabled(option.value)
                                            }
                                            options={[
                                                {value: true, label: "True"},
                                                {value: false, label: "False"}
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <GenerateQuestions mcqQuestionDTOList={handleMcqQuestionListAuto}/>
                        <br/>

                        <Formik
                            innerRef={formRef}
                            initialValues={assignment}
                            enableReinitialize={true}
                            onSubmit={handleSubmit}
                            validationSchema={mySchema}
                        >
                            {props => {
                                const {values, setFieldValue} = props;
                                return (
                                    <Form>
                                        <FieldArray
                                            name="mcqQuestionDTOList"
                                            render={questionArray => (
                                                <div className="card bg-light  shadow-sm mt-4">
                                                    {values.mcqQuestionDTOList.map(
                                                        (questionObject, findex) => (
                                                            <div className="card-body ">
                                                                <div className="form-row  ">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <FormTitle>
                                                                                Question {findex + 1}
                                                                            </FormTitle>

                                                                            <MarkdownEditor
                                                                                name={`mcqQuestionDTOList[${findex}].question`}
                                                                                cbValue={value =>
                                                                                    setFieldValue(
                                                                                        `mcqQuestionDTOList[${findex}].question`,
                                                                                        value
                                                                                    )
                                                                                }
                                                                            />

                                                                            {/*<Field*/}
                                                                            {/*  type="text"*/}
                                                                            {/*  name={`mcqQuestionDTOList[${findex}].question`}*/}
                                                                            {/*  placeholder={"Question"}*/}
                                                                            {/*  component={CustomMarkdown}*/}
                                                                            {/*/>*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <FormTitle>Serial No</FormTitle>

                                                                        <Field
                                                                            // disabled={true}
                                                                            type="number"
                                                                            name={`mcqQuestionDTOList[${findex}].sl_no`}
                                                                            placeholder="Serial No"
                                                                            component={TextFieldComponent}
                                                                        />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <FormTitle>Marks</FormTitle>
                                                                        <Field
                                                                            type="text"
                                                                            name={`mcqQuestionDTOList[${findex}].marks`}
                                                                            placeholder="Marks"
                                                                            component={TextFieldComponent}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <FieldArray
                                                                    name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList`}
                                                                    render={answerArray => (
                                                                        <div className="w-100 mt-2">
                                                                            <div
                                                                                className="form-row d-flex justify-content-between ">
                                                                                <h6>Answers</h6>
                                                                                <div className="form-group">
                                                                                    <button
                                                                                        type="button"
                                                                                        className={"btn btn-secondary p-2"}
                                                                                        onClick={() =>
                                                                                            answerArray.push({
                                                                                                sl_no: "",
                                                                                                answer: "",
                                                                                                isRight: false
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-plus-circle mr-2"/>
                                                                                        Add another answer
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row gap-2 ">
                                                                                <div className="col-2">
                                                                                    <FormTitle>Serial </FormTitle>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <FormTitle>Option</FormTitle>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <div>
                                                                                        <FormTitle>
                                                                                            Is Right Answer?
                                                                                        </FormTitle>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {values.mcqQuestionDTOList[findex] &&
                                                                                values.mcqQuestionDTOList[findex]
                                                                                    .mcqOptionsDTOList &&
                                                                                values.mcqQuestionDTOList[
                                                                                    findex
                                                                                    ].mcqOptionsDTOList.map(
                                                                                    (options, index) => (
                                                                                        <div
                                                                                            key={index}
                                                                                            className="w-100 border rounded py-3 px-3"
                                                                                        >
                                                                                            <div>
                                                                                                <div
                                                                                                    className="form-row gap-2 ">
                                                                                                    <div className="col-1">
                                                                                                        <Field
                                                                                                            // disabled={true}
                                                                                                            type="number"
                                                                                                            name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].sl_no`}
                                                                                                            placeholder="Serial"
                                                                                                            component={
                                                                                                                TextFieldComponent
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col-9">
                                                                                                        <MarkdownEditor
                                                                                                            size={"small"}
                                                                                                            name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].answer`}
                                                                                                            cbValue={value =>
                                                                                                                setFieldValue(
                                                                                                                    `mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].answer`,
                                                                                                                    value
                                                                                                                )
                                                                                                            }
                                                                                                        />

                                                                                                        {/*<Field*/}
                                                                                                        {/*  type="text"*/}
                                                                                                        {/*  name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].answer`}*/}
                                                                                                        {/*  placeholder="Answer"*/}
                                                                                                        {/*  component={*/}
                                                                                                        {/*    TextFieldComponent*/}
                                                                                                        {/*  }*/}
                                                                                                        {/*/>*/}
                                                                                                    </div>
                                                                                                    <div className="col-2">
                                                                                                        <Field
                                                                                                            type="checkbox"
                                                                                                            name={`mcqQuestionDTOList[${findex}].mcqOptionsDTOList[${index}].isRight`}
                                                                                                            placeholder="Right Answer"
                                                                                                            style={{
                                                                                                                marginLeft: 12,
                                                                                                                marginTop: 12,
                                                                                                                alignItems: "center",
                                                                                                                fontSize: "26px",
                                                                                                                size: "26px"
                                                                                                            }}
                                                                                                            // component={CheckBoxComponent}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        )
                                                    )}

                                                    <div className="form-group justify-content-center mx-auto">
                                                        <button
                                                            type="button"
                                                            className={"btn btn-primary py-2 px-4"}
                                                            onClick={() =>
                                                                questionArray.push({
                                                                    sl_no: values.mcqQuestionDTOList.length + 1,
                                                                    question: "",
                                                                    marks: 0,
                                                                    mcqOptionsDTOList: [
                                                                        {
                                                                            answer: "",
                                                                            sl_no: 1,
                                                                            isRight: false
                                                                        }
                                                                    ]
                                                                })
                                                            }
                                                        >
                                                            <i className="fa fa-plus-circle mr-2"/>
                                                            Add another Question
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        />

                                        {!loader ? (
                                            <div className="form-row d-flex justify-content-between ">
                                                <div className="form-group mt-2">
                                                    <button
                                                        onClick={handleBack}
                                                        type="button"
                                                        className="btn btn-lg  btn-danger float-left w-100">
                                                        <i className="fa fa-arrow-left mr-2  " style={{color: "white"}}
                                                        />{" "}
                                                        back
                                                    </button>
                                                </div>

                                                <div className="form-group mt-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-lg btn-success float-right w-100"
                                                    >
                                                        <i className="fa fa-save mr-2"/> Save
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </CommonLayout>
        </Fragment>
    );
}

export default GetAllMcqQuestions;
