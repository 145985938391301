import React, {Children, useEffect, useState} from "react";
import {Calendar, momentLocalizer} from "react-big-calendar";
import * as PropTypes from "prop-types";
import moment from "moment";
import {SingleEventDetails} from "./singleEventDetails";
import {getIsToday} from "../../util/DateUtils";

// example implementation of a wrapper
const ColoredDateCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: "transparent"
        }
    });

const eventStyleGetter = function (event, start, end, isSelected) {
    let style = {
        backgroundColor: "transparent",
        textAlign: "center",
        borderRadius: "0px",
        borderColor: "red",
        border: "none",
        margin: "0 0px"
    };
    return {
        style: style
    };
};

const HeaderCellContent = props => {
    const {date} = props;
    const dayOfWeek = date.getDay();

    const className = dayOfWeek === 0 ? "text-Title3" : "text-Title3";
    return (
        <div
            component="span"
            className={`'bg-transparent border-0  font-hind font-semibold text-base leading-20px' ${className}`}
        >
            {props.label}
        </div>
    );
};

const DateCellContent = props => {
    const {date} = props;
    const className = getIsToday(date) ? "mx-auto pt-0 " : "pt-3 ";
    const style = getIsToday(date) ? {color: "red", background: "white", width:"50px", height: "50px", lineHeight:"2.8", borderRadius: "50%", fontSize: "18px"} : {color: "Gray",fontWeight: '500'};
    return (
        <div
            component="span"
            style={style}
            className={`'  text-center  font-medium ' ${className}`}
        >
            {parseInt(props.label)}
        </div>
    );
};

export default function BigCalender(props) {
    const localizer = momentLocalizer(moment);
    const [events, setEvents] = useState([]);

    console.log(props.defaultDate, "check baltake");

    useEffect(() => {
        if (props.events && props.events.length) {
            let newData = props.events.map(function (data) {
                let datas = {};
                datas.id = data.id;
                datas.start = data.start;
                datas.end = data.end;
                datas.title = data.title;
                datas.description = data.description;
                datas.eventCount = props.events.filter(
                    obj => obj.eventCount === 1 && obj.id === data.id
                ).length;
                datas.assignmentCount = props.events.filter(
                    obj => obj.assignmentCount === 1 && obj.id === data.id
                ).length;
                datas.dataArray = props.events.filter(obj => obj.id === data.id);
                datas.color = data.colorHexCode;
                return datas;
            });
            let result = newData.reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            setEvents(result);
        }
    }, [props.events, props.defaultDate]);

    return (
        <Calendar
            localizer={localizer}
            popup
            showMultiDayTimes
            messages={{
                showMore: total => (
                    <div
                        style={{cursor: "pointer"}}
                        className="px-1 font-semibold text-12px text-PinkMain text-right"
                        onMouseOver={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        {`+${total} more`}
                    </div>
                )
            }}
            date={props.defaultDate}
            defaultView="month"
            events={events}
            // style={{height: "880px"}}
            components={{
                event: SingleEventDetails,
                dateCellWrapper: ColoredDateCellWrapper,
                month: {
                    header: HeaderCellContent,
                    dateHeader: DateCellContent
                }
            }}
            toolbar={false}
            eventPropGetter={event => ({
                style: {
                    backgroundColor: event.colorHexCode
                }
            })}
            views={{month: true}}
            tooltipAccessor={""}
            startAccessor="start"
            endAccessor="end"
        />
    );
}

BigCalender.propTypes = {
    defaultDate: PropTypes.any,
    events: PropTypes.arrayOf(PropTypes.any)
};
