import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveFaculty = faculty => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/faculty/", faculty);
};
export const updateFaculty = faculty => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/faculty/", faculty);
};
export const getFacultyById = facultyId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/faculty/" + facultyId);
};
export const deleteFacultyById = facultyId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/faculty/" + facultyId);
};
export const getAllFaculties = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/faculties");
};