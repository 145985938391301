import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import * as EvaluationQuestionApi from "../../../api/EvaluationQuestionApi";
import Loader from "../../common/Loader";
import * as LinkFactory from "../../../util/LinkFactory";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


function EvaluationQuestionList() {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [loader, setLoader] = useState(true);
  const [evalQuesList, setEvalQuesList] = useState([]);
  const [evaluationQues, setEvaluationQues] = useState({
    question: "",
  });

  useEffect(() => {
    getEvaluationList();
  }, []);

  const notifyDelete = () => toast.success("Successfully Deleted!", {
    position: toast.POSITION.TOP_CENTER
  });

  const handleNew = data => {
    navigateTo(LinkFactory.evaluationQuestion);
  };

  const handleEdit = data => {
    navigateTo(LinkFactory.evaluationQuestion + "/" + data.id);
  }

  const handleDelete = data => {
    EvaluationQuestionApi.deleteEvaluationQuestion(data.id).then(res=> {
      notifyDelete();
      getEvaluationList();

    })
  }

  const getEvaluationList = () => {
    setLoader(true);
    EvaluationQuestionApi.search(evaluationQues).then(res=>{
        setEvalQuesList(res.data);
      setLoader(false);
    });
  }

  return (
    <>
      <CommonLayout headerName="Evaluation Questions">
        <main>
          <div>
            <h3 className="mt-4 mb-4">
              Question List
              <button
                  type="button"
                  className="btn btn-outline-dark radius-25 float-right"
                  onClick={() => handleNew()}>
                <i className="fa fa-plus-circle mr-2"/>
                Create new Evaluation Question
              </button>
            </h3>

            <div className="table-responsive table-light table-shadow radius-15 mt-4">
              <table
                  className="table  table-light  table-hover"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
              >
                <thead>
                <tr>
                  <th scope="col">Sl</th>
                  <th scope="col">Question</th>
                  <th scope="col">Set</th>
                  <th scope="col">Question Type</th>
                  <th scope="col" className="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                {undefined!==evalQuesList && evalQuesList.length > 0
                  ? evalQuesList.map((data, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{data?.question}</td>
                        <td>{data?.evaluationQuestionMain?.name}</td>
                        <td>{data?.evaluationQuestionType?.questionType}</td>
                        <td className="text-right">
                          <button
                            type="button"
                            title="Edit"
                            className="btn btn-sm btn-primary"
                            onClick={() => handleEdit(data)}
                          >
                            <i className="fa fa-edit"/>
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            title="Delete"
                            className="btn btn-sm btn-danger"
                            data-toggle="modal"
                            data-target="#deleteModal"
                            onClick={() => handleDelete(data)}
                          >
                            <i className="fa fa-trash"/>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  : <td colSpan="12" className="text-center">
                    {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                  </td>}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationQuestionList;
