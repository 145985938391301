import React, {useEffect, useRef, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";

import "react-datepicker/dist/react-datepicker.css";
import * as SemesterApi from "../../api/SemesterApi";
import * as ChannelApi from "../../api/ChannelApi";
import TextFieldComponent from "../common/TextFieldComponent";
import * as LetterGradeMasterApi from "../../api/LetterGradeMasterApi";
import * as LetterGradeChannelApi from "../../api/LetterGradeChannelApi";
import CommonLayout from "../layout/commonLayout";

import * as LinkFactory from "../../util/LinkFactory";
import * as GradingBreakdownApi from "../../api/GradingBreakdownApi";
import * as GradingApi from "../../api/GradingApi";
import {Alert} from "react-bootstrap";
import Swal from "sweetalert2";
import LoaderOverlay from "../common/LoaderOverlay";
import FormTitle from "../Typhography/formTitle";
import {useNavigate, useParams} from "react-router-dom";


export default GradingNew;

function GradingNew(props) {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const params = useParams();
    const [channel, setChannel] = useState({});
    const [semester, setSemester] = useState({});
    const [saving, setSaving] = useState(false);
    const [totalScore, setTotalScore] = useState(0.0);
    const [scores, setScores] = useState([]);
    const [semesterList, setSemesterList] = useState([]);
    const [letterGradeMasterList, setLetterGradeMasterList] = useState(null);
    const [grading, setGrading] = useState({
        name: "",
        channel: "",
        subject: "",
        id: "",
        breakdowns: []
    });
    const letterGradeFrom = useRef(null);
    const gradingForm = useRef(null);

    const handleTotalScoreCalculation = (score, index) => {
        var temp = scores;
        temp[index] = parseFloat(score.currentTarget.value);
        setScores(temp);
        calculateTotalScore();
    };

    function calculateTotalScore() {
        setTotalScore(0);
        var ttlScore = 0.0;
        for (var i = 0; i < scores.length; i++) {
            ttlScore = ttlScore + parseFloat(scores[i]);
        }
        setTotalScore(ttlScore);
    }

    function loadAllSemester() {
        SemesterApi.getAll().then(response => {
            setSemesterList(response.data);
        });
    }

    function loadAllLetterGradeMaster(id) {
        LetterGradeMasterApi.getLetterGradeMasterListByMainId(id).then(response => {
            setLetterGradeMasterList({list: [...response.data]});
        });
    }

    function channelById() {
        ChannelApi.getChannelById(params.id)
            .then(json => {
                setChannel(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    const handleSemesterSelection = option => {
        console.log(option);
        setSemester(option)
    };
    const handleLetterGradeSubmit = value => {
        let letterGradeChannelSaveChildDtos = [];
        value.forEach(v => {
            letterGradeChannelSaveChildDtos.push({
                serial: v.serial,
                letterGradeName: v.letterGradeName,
                upperValue: v.upperValue,
                gradePoint: v.gradePoint
            })
        });
        let letterGradeMaster = {
            gradingId: grading.id,
            channelId: params.id,
            letterGradeChannelSaveChildDtoList: letterGradeChannelSaveChildDtos
        };
        if (null != letterGradeMaster.gradingId)
            LetterGradeChannelApi.save(letterGradeMaster).then(res => {
                console.log(res);
                if (res.response.status === 201 || res.response.status === 200)
                    Swal.fire("Saved", "success");
                else
                    Swal.fire("Failed", "error");
                redirectToGradingListPage();
            })
    }
    useEffect(() => {
        channelById();
        // loadAllSemester();
        loadAllLetterGradeMaster(1);
    }, []);

    useEffect(() => {
        if (null != grading.id && '' !== grading.id)
            letterGradeFrom.current.click();
    }, [grading]);

    const handleSubmit = value => {
        setSaving(true);
        let gradingDTO = {
            name: value.name,
            channel: channel
        };

        GradingApi.saveGrading(gradingDTO).then(response => {
            console.log(response.data);
            setGrading(response.data);
            value.breakdowns.forEach(breakdown => {
                let gradingBreakDownDTO = {
                    grading: "",
                    evaluationName: "",
                    fullScore: ""
                };
                gradingBreakDownDTO.grading = response.data;
                gradingBreakDownDTO.evaluationName = breakdown.evaluationName;
                gradingBreakDownDTO.fullScore = breakdown.fullScore;
                GradingBreakdownApi.saveGradingBreakdown(gradingBreakDownDTO).then((res) => {
                    setSaving(false);
                    if (res.response.status === 201 || res.response.status === 200)
                        Swal.fire("Grading Save complete", "success");
                    else
                        Swal.fire("Grading Save Failed", "error");
                });
            });
        });

    };

    const redirectToGradingListPage = () => {

        navigateTo(
            LinkFactory.gradingByChannelId + "/" + params.id
        );
    }

    const submitForms = () => {
        gradingForm.current.click();
    };

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout headerName={channel?.channelName}>
                <main>
                    <div className="card-default grading-new container-responsive">
                        {saving ? <LoaderOverlay/> : null}
                        <div className="title ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Evaluations</p>
                            </div>
                        </div>

                        {null == channel?.semester && 'class' === channel.channelType ?
                            <>
                                <Alert variant="danger">
                                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                                    <h6>
                                        Please set a Semester for this Room
                                    </h6>
                                </Alert>
                            </>


                            : ""}
                        <div>
                            <div>
                                <div className="row grading-table-edit">
                                    <div className="col-md-4">
                                        {letterGradeMasterList != null && (
                                            <Formik
                                                initialValues={letterGradeMasterList}
                                                onSubmit={values => {
                                                    handleLetterGradeSubmit(values.list)
                                                }}
                                                render={({values, errors, touched, handleReset}) => {
                                                    return (
                                                        <Form>
                                                            <h3> Grade Chart</h3>
                                                            <FieldArray
                                                                name="list"
                                                                render={arrayHelpers => (
                                                                    <div className="grading-table">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <div className="form-group input-default-white">
                                                                                    <p>Grade</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className=" form-group input-default-white">
                                                                                    <p>Marks</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="form-group input-default-white">
                                                                                    <p>Grade Point</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {values.list.length > 0 &&
                                                                            values.list.map((val, index) => (
                                                                                <div>
                                                                                    <div className="row" key={index}>
                                                                                        <div className="col-4">
                                                                                            <div className="form-group input-default-white">
                                                                                                <Field
                                                                                                    name={`list.${index}.letterGradeName`}
                                                                                                    type="text"
                                                                                                    readonly="true"
                                                                                                    placeholder="Letter Grade Name"
                                                                                                    component={TextFieldComponent}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <div className="form-group input-default-white">
                                                                                                <Field
                                                                                                    name={`list.${index}.upperValue`}
                                                                                                    type="text"
                                                                                                    readonly="true"
                                                                                                    placeholder="Score"
                                                                                                    component={TextFieldComponent}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <div className="form-group input-default-white">
                                                                                                <Field
                                                                                                    name={`list.${index}.gradePoint`}
                                                                                                    type="text"
                                                                                                    readonly="true"
                                                                                                    placeholder="Grade Point"
                                                                                                    component={TextFieldComponent}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            ))}
                                                                        {/*<div className="form-row">*/}
                                                                        {/*    <div className="col-12">*/}
                                                                        {/*        <div className="form-group">*/}
                                                                        {/*            <button*/}
                                                                        {/*                type="button"*/}
                                                                        {/*                className="btn btn-outline-dark-more radius-10 mt-4 w-100"*/}
                                                                        {/*                onClick={() =>*/}
                                                                        {/*                    arrayHelpers.push({*/}
                                                                        {/*                        letterGradeName: "",*/}
                                                                        {/*                        upperValue: "",*/}
                                                                        {/*                        gradePoint: ""*/}
                                                                        {/*                    })*/}
                                                                        {/*                }*/}
                                                                        {/*            >*/}
                                                                        {/*                <i className="fa fa-plus-circle mr-2"/>*/}
                                                                        {/*                Add more grade point*/}
                                                                        {/*            </button>*/}
                                                                        {/*        </div>*/}
                                                                        {/*    </div>*/}
                                                                        {/*</div>*/}
                                                                    </div>

                                                                )}
                                                            />

                                                            <button type="submit" ref={letterGradeFrom}
                                                                    className="hidden"
                                                                    style={{display: "none"}}>
                                                                Submit
                                                            </button>
                                                        </Form>
                                                    );
                                                }}
                                            />)}
                                    </div>
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-6">
                                        <Formik
                                            initialValues={grading}
                                            enableReinitialize={true}
                                            onSubmit={handleSubmit}>
                                            {({props, values}) => (
                                                <Form>
                                                    <div className="row">
                                                        {/*<div className="col-md-4">*/}
                                                        {/*    <p>Marking Period/Semester</p>*/}
                                                        {/*    <Select*/}
                                                        {/*        placeholder="Select Semester"*/}
                                                        {/*        onChange={handleSemesterSelection}*/}
                                                        {/*        getOptionLabel={option => option.semesterName}*/}
                                                        {/*        getOptionValue={option => option}*/}
                                                        {/*        options={semesterList || []}*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                        <div className="col-12">
                                                            <h5 className="text-right">Total Score : <b>{totalScore}</b> </h5>
                                                        </div>
                                                    </div>
                                                    <br/>

                                                    <FieldArray
                                                        name="breakdowns"
                                                        render={arrayHelpers => (
                                                            <div className="w-100">
                                                                {values.breakdowns && values.breakdowns.length > 0
                                                                    ? values.breakdowns.map((breakdown, index) => (
                                                                        <span key={index} className="w-100">
                                                              <div className="form-row">
                                                                <div className="col-5">
                                                                  <div className="form-group input-default-white">
                                                                    <label>Evaluation Name</label>
                                                                    <Field
                                                                        name={`breakdowns[${index}].evaluationName`}
                                                                        component={TextFieldComponent}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                <div className="col-5">
                                                                  <div className="form-group input-default-white">
                                                                    <label>Score</label>
                                                                    <Field
                                                                        name={`breakdowns[${index}].fullScore`}
                                                                        onBlur={e => {
                                                                            handleTotalScoreCalculation(e, index)
                                                                        }}
                                                                        component={TextFieldComponent}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                  <div className="col-2">
                                                                      <div className="form-group input-default-white">
                                                                        <FormTitle> &nbsp;</FormTitle>
                                                                          <button
                                                                              title="Delete"
                                                                              className="btn btn-outline-danger radius-25 d-block position-absolute right-0"
                                                                              type="button"
                                                                              onClick={() =>
                                                                                  arrayHelpers.remove(index)
                                                                              }>
                                                                          <i className="fa fa-trash"/>
                                                                        </button>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                            </span>
                                                                    ))
                                                                    : ""}
                                                                <div className="form-row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-dark-more radius-10 w-100 mt-4"
                                                                                onClick={() =>
                                                                                    arrayHelpers.push({
                                                                                        name: "",
                                                                                        evaluationName: "",
                                                                                        fullScore: ""
                                                                                    })
                                                                                }
                                                                            >
                                                                                <i className="fa fa-plus-circle mr-2"/>
                                                                                Add more evaluations
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                    <button type="submit" ref={gradingForm} className="hidden"
                                                            style={{display: "none"}}>
                                                        Submit
                                                    </button>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <button
                                            type="submit"
                                            disabled={null == channel?.semester}
                                            className="btn btn-dark radius-25 px-4 mt-5"
                                            onClick={submitForms}>
                                            <i className="fa fa-bookmark mr-2"/> Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayout>
        </>
    );
}
