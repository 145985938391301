import {FETCH_NOTIFICATION_LIST_BEGIN, FETCH_NOTIFICATION_LIST_SUCCESS, FETCH_POST_NOTIFICATION_LIST_FAILURE} from "../action/NotificationList";

const initialState = {
    assignmentNotifications: [],
    postNotifications: [],
    loading: false,
    error: null
};

export default function NotificationListReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_NOTIFICATION_LIST_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                assignmentNotifications: action.payload.assignmentNotifications,
                postNotifications: action.payload.postNotifications
            };

        case FETCH_POST_NOTIFICATION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                assignmentNotifications: null,
                postNotifications: null
            };
        default:
            return state;
    }
}
