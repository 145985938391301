import AxiosAgent from "./AxiosAgent";
import { API_MAIN_ROOT } from "../util/LinkFactory";


export const save = evaluationSubmission => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-submission", evaluationSubmission);
};

export const saveAll = evaluationSubmission => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-submission/save-all", evaluationSubmission);
};

export const update = evaluationSubmission => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/evaluation-submission", evaluationSubmission);
};

export const search = evaluationSubmission => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-submission/search", evaluationSubmission);
};

export const deleteEvaluationQuestionSubmission = id => {
  return AxiosAgent.requests.delete(API_MAIN_ROOT + "/evaluation-submission/"+ id);
};

export const getEvaluationQuestionSubmissionById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/evaluation-submission/" + id);
};

export const evaluationResult = evaluationSubmission => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-submission/result", evaluationSubmission);
};
