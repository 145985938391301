import React, {useEffect, useState} from "react";

import * as LinkFactory from "../../util/LinkFactory";
import CommonLayout from "../layout/commonLayout";
import * as StudentApi from "../../api/StudentApi";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";
import {toast} from "react-toastify";
import SelectDropdown from "../common/SelectDropwodn";
import * as FacultyApi from "../../api/FacultyApi";
import * as DepartmentApi from "../../api/DepartmentApi";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as ProgramApi from "../../api/ProgramApi";
import {FormikTextField} from "../FormikTextField";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";
import LinearProgress from "@mui/material/LinearProgress";
import * as ChannelApi from "../../api/ChannelApi";

toast.configure();

function StudentsList() {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [loader, setLoader] = useState(true);
    const [studentList, setStudentList] = useState([]);
    const [id, setId] = useState(null);
    const [faculties, setFaculties] = useState([]);
    const [faculty, setFaculty] = useState({});
    const [department, setDepartment] = useState({});
    const [program, setProgram] = useState({});
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [slNoCount, setSlNoCount] = useState(0);
    const [sessions, setSessions] = useState([]);


    const FILE_URL = SERVER_NAME_URL;
    const PhotoRootUrl = SERVER_NAME_URL;
    const notifyDelete = () => toast.success("Successfully Deleted!", {
        position: toast.POSITION.TOP_CENTER
    });

    useEffect(() => {
        searchStudentsList({});
        loadFaculty();
    }, [pageNumber, setStudentList]);

    function loadFaculty() {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties(json.data);
        });
    }

    const getStudentsList = () => {
        setLoader(true);
        StudentApi.getStudentsForAdmin()
            .then(json => {
                setLoader(false);
                setStudentList(json.data);
            })
            .catch(errors => {
                setLoader(false);
                console.error(errors);
            });
    };

    const changeStudentsPage = ({selected}) => {
        console.log(selected);
        setShowProgressBar(true);
        setPageNumber(selected);
    }

    const searchStudentsList = (search) => {
        setLoader(true);
        setShowProgressBar(false);
        StudentApi.searchStudents(
            {
                ...search,
                "searchTerm": search?.searchTerm,
                "page": pageNumber,
            }
        )
            .then(json => {
                setLoader(false);
                setShowProgressBar(false);
                setStudentList(json.data.content);
                setPageCount(json.data.totalPages);
                setSlNoCount(pageNumber)
            })
            .catch(errors => {
                setLoader(false);
                console.error(errors);
            });
    };

    const handleEdit = id => {
        navigateTo(LinkFactory.getStudent + "/" + id);
    };

    const handleResult = rollNo => {
        navigateTo(LinkFactory.studentCourseHistory + "/" + rollNo);
    };

    const handleView = id => {
        navigateTo(LinkFactory.getStudentDetails + "/" + id);
    };
    const handleDelete = (data) => {
        if (data.id) setId(data.id);
    };

    const setFacultyObj = value => {
        setFaculty(value);
        getDepartmentsByFaculty(value.id);
    };

    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    };

    const setDepartmentObj = value => {
        setDepartment(value);
        getProgramsByDepartmentId(value.id);
    };

    const getProgramsByDepartmentId = (departmentId) => {
        ProgramApi.getAllByDepartmentId(departmentId).then(json => {
            setPrograms(json.data);
        });
    };

    const handleSubmit = values => {
        console.log(values);
        const search = {
            facultyId: values?.facultyName?.id,
            departmentId: values?.department?.id,
            programId: values?.programName?.id,
            rollNo: values?.rollNo,
            contactNo: values?.contactNo,
            studentName: values?.studentName,
            session: values?.session?.name,
        };
        searchStudentsList(search);
    };

    const [search, setSearch] = useState({
        facultyName: {}, departmentName: {}, programName: {}, session: "", studentName: "", rollNo: "", contactNo: ""
    });

    const resetSearch = () => {
        getStudentsList();
    }

    const registrationByXlsx = () => {
        navigateTo(LinkFactory.studentRegistrationUpload);
    }

    const deleteData = () => {
        console.log(id);
        StudentApi.deleteStudentById(id).then((res) => {
            console.log(res);
            setId(null);
            notifyDelete();
            searchStudentsList({});
        });
    };

    function goBack() {
        navigateTo(-1)
    }

    function loadSession() {
        ChannelApi.getAllSession().then(json => {
            setSessions([...json?.data]);
        });
    }

    useEffect(() => {
        loadSession();
    }, []);

    return (<>
        <CommonLayout headerName={"Student's List"}>
            <main>
                <div className="card-default student-list container-responsive">
                    <div className="title-header ">
                        <div className="mb-0 d-flex align-items-center">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                <a onClick={goBack} className="pl-0 back-button">
                                    <i className="fa fa-chevron-left"> </i> </a>
                            </div>
                            <p>Student List</p>
                        </div>
                    </div>
                    <Formik
                        initialValues={search}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}>
                        {({props, values, setFieldValue}) => {
                            return (<Form>
                                <div className="row mx-4 mt-4">

                                    <div className="col-3">
                                        <div className="form-group input-default-white">
                                            <label className="font-weight-medium text-gray-25 text-lg-left mb-2">{" "}Select Faculty{" "}</label>
                                            <br/>
                                            <Field
                                                type="text"
                                                name="facultyName"
                                                placeholder={values?.name || "Select Faculty"}
                                                fieldName={"name"}
                                                label="facultyName"
                                                handleChangeSection={(fieldName, value) => {
                                                    setFacultyObj(value);
                                                }}
                                                required
                                                options={faculties || []}
                                                component={SelectDropdown}
                                            />
                                            <ErrorMessage
                                                name="facultyName"/>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group input-default-white">
                                            <label className="font-weight-medium text-gray-25 text-lg-left mb-2">{" "}Select Department{" "}</label>
                                            <Field
                                                type="text"
                                                name="departmentName"
                                                placeholder={values?.name || "Select Department"}
                                                fieldName={"name"}
                                                label="departmentName"
                                                handleChangeSection={(fieldName, value) => {
                                                    setFieldValue("department", value);
                                                    setDepartmentObj(value);
                                                }}

                                                options={departments || []}
                                                component={SelectDropdown}
                                            />
                                            <ErrorMessage name="departmentName"/>

                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group input-default-white">
                                            <label className="font-weight-medium text-gray-25 text-lg-left mb-2">{" "}Select Program{" "}
                                            </label>
                                            <Field
                                                type="text"
                                                name="programName"
                                                placeholder={values?.name || "Select Program"}
                                                fieldName={"name"}
                                                label="departmentName"
                                                handleChangeSection={(fieldName, value) => {
                                                    setFieldValue("programName", value);
                                                    setProgram(value);
                                                }

                                                }
                                                options={programs || []}
                                                component={SelectDropdown}
                                            />
                                            <ErrorMessage name="programName"/>

                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group input-default-white">
                                            <label className="font-weight-medium text-gray-25 text-lg-left mb-2">{" "}Select Session{" "}</label>
                                            <Field type="text"
                                                   name="session"
                                                   placeholder={values?.name || "Select Session"}
                                                   fieldName={"name"}
                                                   label="sessionName"
                                                   handleChangeSection={(fieldName, value) => {
                                                       setFieldValue("session", value);
                                                   }}
                                                   options={sessions || []}
                                                   component={SelectDropdown}/>
                                            <ErrorMessage name="session"/>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group input-default-white">
                                            <FormikTextField label="Name"
                                                             name="studentName"
                                                             type="text"/>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group input-default-white">
                                            <FormikTextField label="Roll No"
                                                             name="rollNo"
                                                             type="text"/>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group input-default-white">
                                            <FormikTextField label="Contact No"
                                                             name="contactNo"
                                                             type="text"/>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <label className="mb-2">&nbsp;</label>
                                        <div className="form-group input-default-white d-flex">
                                            <button
                                                title="Reset Fields"
                                                type="reset"
                                                onClick={resetSearch}
                                                className="btn btn-none-default radius-25">
                                                <i className="fa fa-refresh mr-2"/>
                                            </button>
                                            &nbsp;
                                            <button
                                                type="submit"
                                                className="btn btn-outline-dark radius-25 mr-2">
                                                <i className="fa fa-search mr-2"/>
                                                Search Students
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-3 mt-2">
                                        <div className="form-group input-default-white d-flex justify-content-end">
                                            <button
                                                type="button"
                                                onClick={registrationByXlsx}
                                                className="btn btn-dark radius-25 mr-2">
                                                <i className="fa fa-address-book mr-2"/>
                                                Excel Registration
                                            </button>
                                            &nbsp;
                                            <div>
                                                <a title="Download"
                                                   className="btn btn-dark radius-25"
                                                   target="_blank"
                                                   href={FILE_URL + 'student_registration_template.xlsx'}>
                                                    <i className="fa fa-file-download mr-2"/>
                                                    {"Download Sample"}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>);
                        }}
                    </Formik>
                    <div className="table-responsive table-light mb-5 mt-3">
                        {showProgressBar ? (<LinearProgress color="primary" />) : ""}
                        <table
                            className="table table-light table-hover table-striped"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0"
                        >
                            <thead>
                            <tr>
                                <th scope="row">Sl.</th>
                                <th scope="col">Name</th>
                                {/*<th scope="col">Education Info</th>*/}
                                <th scope="col">Contacts</th>
                                <th scope="col" className=" " width="15%">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {studentList.length > 0 ? (studentList.map((data, index) => {
                                return (<tr key={index}>
                                    <th scope="row">{ (index + 1) + (slNoCount * 10)}</th>
                                    <td>
                                        <div className="grid px-1">
                                            <div>
                                                <img
                                                    src={data?.user?.imageUrl ? PhotoRootUrl + data?.user?.imageUrl : "img/Avatar.png"}
                                                    style={{
                                                        width: "45px", height: "45px", objectFit: "cover"
                                                    }}
                                                    className="rounded-circle p-1"
                                                />
                                                {/*{data?.user?.imageUrl ? (*/}
                                                {/*    <Avatar*/}
                                                {/*        src={`${PhotoRootUrl}${data?.user?.imageUrl}`}*/}
                                                {/*    />*/}
                                                {/*) : (*/}
                                                {/*    ""*/}
                                                {/*)}*/}
                                                <span>
                                                                  {" "}
                                                    {data?.firstName} {data?.middleName}{" "}
                                                    {data?.lastName}
                                                                </span>
                                            </div>
                                            {/*<div>*/}
                                            {/*  Father Name :{" "}*/}
                                            {/*  <span> {data?.fatherName || "-----"}</span>*/}
                                            {/*</div>*/}
                                            {/*<div>*/}
                                            {/*  Mother Name :{" "}*/}
                                            {/*  <span>*/}
                                            {/*    {" "}*/}
                                            {/*    {data.motherName} {data.lastName}*/}
                                            {/*  </span>*/}
                                            {/*</div>*/}
                                        </div>
                                    </td>
                                    {/*<td>*/}
                                    {/*  <div className="grid py-2 px-1">*/}
                                    {/*    <div>*/}
                                    {/*      Grade : <span>{data.user.grade}</span>*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*      Section :{" "}*/}
                                    {/*      <span> {data?.user?.group || "-----"}</span>*/}
                                    {/*    </div>*/}
                                    {/*  </div>*/}
                                    {/*</td>*/}
                                    <td>
                                        <div className="grid px-1">
                                            <div>{data?.user?.phoneNo || "-----"}</div>
                                            <div>{data?.user?.email || "-----"}</div>
                                        </div>
                                    </td>

                                    <td className=" ">
                                        <button
                                            type="button"
                                            title="View"
                                            onClick={() => handleView(data.id)}
                                            className="btn btn-sm bg-c-blue-light mr-1"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="17" height="12" fill="none">
                                                <path fill="#4575EE"
                                                      d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                            </svg>
                                        </button>
                                        <button
                                            type="button"
                                            title="Delete"
                                            data-toggle="modal"
                                            data-target="#deleteModal"
                                            onClick={() => handleDelete(data)}
                                            className="btn btn-sm bg-c-red-light mr-1"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="13" height="14" fill="none">
                                                <path fill="#D81414"
                                                      d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                            </svg>
                                        </button>
                                        <button
                                            type="button"
                                            title="Edit"
                                            onClick={() => handleEdit(data.id)}
                                            className="btn btn-sm bg-c-light-orange mr-1"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="18" height="15" fill="none">
                                                <path fill="#000"
                                                      d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                            </svg>
                                        </button>
                                        <button
                                            type="button"
                                            title="Result"
                                            onClick={() => handleResult(data.rollNo)}
                                            className="btn btn-sm bg-c-light-green "
                                        >
                                            <i className="fa fa-address-book"/>
                                        </button>
                                    </td>
                                </tr>);
                            })) : <td colSpan="12" className="text-center">
                                {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                            </td>}
                            </tbody>
                        </table>
                        <div className="page-navigation">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changeStudentsPage}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    </div>

                </div>
            </main>
        </CommonLayout>

        <div
            className="modal fade"
            id="deleteModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="actionModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header  ">
                        <div className="circle-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                <path fill="#D81414"
                                      d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                            </svg>

                        </div>
                        <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>
                    </div>
                    <div className="modal-footer">

                        <button
                            type="button"
                            className=" "
                            data-dismiss="modal"
                            onClick={deleteData}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default StudentsList;
