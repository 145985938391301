import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../../common/TextFieldComponent";
import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import * as EvaluationQuestionMainApi from "../../../api/EvaluationQuestionMainApi";
import * as EvaluationQuestionApi from "../../../api/EvaluationQuestionApi";
import * as EvaluationQuestionOptionApi from "../../../api/EvaluationQuestionOptionApi";
import FormTitle from "../../Typhography/formTitle";
import SelectDropdown from "../../common/SelectDropwodn";
import * as LinkFactory from "../../../util/LinkFactory";
import Loader from "../../common/Loader";
import {useNavigate} from "react-router-dom";


function EvaluationQuestionOption(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [evaluationQuesOpt, setEvaluationQuesOpt] = useState({
        option: "",
        evaluationQuestion: {},
        evaluationQuestionMain: {},
        questionOptionTypeEnum: ""
    });
    const [evaluationQuesMain, setEvaluationQuesMain] = useState({
        name: "",
        globalInd: null
    });

    const [evaluationQues, setEvaluationQues] = useState({
        question: "",
        evaluationQuestionMain: null
    });
    const [evalQuesMainList, setEvalQuesMainList] = useState([]);
    const [questionOptionTypeList, setQuestionOptionTypeList] = useState([{questionOptionType: 'TEXTFIELD'}, {questionOptionType: 'RADIO'}]);
    const [evalQuesList, setEvalQuesList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [disable, setDisable] = React.useState(false);

    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifySuccess = () => toast.success("Evaluation question option Created!", {
        position: toast.POSITION.TOP_RIGHT
    });
    const handleSubmit = value => {
        console.log(value);
        value.questionOptionTypeEnum = value.questionOptionTypeEnum.questionOptionType;
        setLoader(true);
        setDisable(true);
        if (null == value.option || '' === value.option) {
            notifyValidation();
            setDisable(false);
        } else {
            if (null !== value.id || '' !== value.id) {
                EvaluationQuestionOptionApi.update(value).then(res => {
                    notifySuccess();
                    handleList();
                });
            } else {
                EvaluationQuestionOptionApi.save(value).then(res => {
                    notifySuccess();
                    handleList();
                });
            }
        }
        setLoader(false);
        setDisable(false);
    }

    const handleList = () => {
        navigateTo(LinkFactory.evaluationQuestionOptionList);
    };

    const getEvaluationMainList = () => {
        setLoader(true);
        EvaluationQuestionMainApi.search(evaluationQuesMain).then(res => {
            setEvalQuesMainList(res.data);
        });
        setLoader(false);
    }

    const getEvaluationQuestionList = () => {
        setLoader(true);
        EvaluationQuestionApi.search(evaluationQues).then(res => {
            setEvalQuesList(res.data);
        });
        setLoader(false);
    }

    const getEvaluationQuestionOptionById = id => {
        EvaluationQuestionOptionApi.getEvaluationQuestionOptionById(id).then(res => {
            setEvaluationQuesOpt(res.data);
            //evaluationQuesOpt.evaluationQuestionMain = res.data.evaluationQuestion.evaluationQuestionMain;
        });
    }

    useEffect(() => {
        if (props?.match?.params?.id) {
            getEvaluationQuestionOptionById(props?.match?.params?.id)
        }
    }, [])

    useEffect(() => {
        getEvaluationMainList();
    }, []);

    const handleEvaluationQuestionMain = (fieldName, value) => {
        console.log(value);
        if (null != value.id) {
            evaluationQues.evaluationQuestionMain = {id: value.id};
            getEvaluationQuestionList();
        }
    }

    const handleEvalQuesSelection = (fieldName, value) => {
        // evaluationQuesOpt.evaluationQuestion = value;
    }

    const handleQuestionType = (fieldName, value) => {
        console.log(value);
        // evaluationQuesOpt.questionOptionType = value.questionOptionType;
    }

    return (
        <>
            <CommonLayout headerName="New Question Option">
                <main>
                    <div>
                        <h3 className="mt-4 mb-4">Evaluation Question Option</h3>
                        {loader ? <Loader /> : ""}
                        <div>
                            <Formik
                                initialValues={evaluationQuesOpt}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({props, values, setFieldValue}) => (
                                    <Form>
                                        <div className="form-row">
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <FormTitle>Option</FormTitle>
                                                    <Field
                                                        type="text"
                                                        name="option"
                                                        placeholder="Option"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <FormTitle>Evaluation Question Set</FormTitle>
                                                    <Field
                                                        type="text"
                                                        name="evaluationQuestionMain"
                                                        placeholder="Select Evaluation Question Set"
                                                        fieldName={"name"}
                                                        handleChangeSection={handleEvaluationQuestionMain}
                                                        options={evalQuesMainList || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <FormTitle>Evaluation Question</FormTitle>
                                                    <Field
                                                        type="text"
                                                        name="evaluationQuestion"
                                                        placeholder="Select Evaluation Question"
                                                        fieldName={"question"}
                                                        handleChangeSection={handleEvalQuesSelection}
                                                        options={evalQuesList || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <FormTitle>Option Type</FormTitle>
                                                    <Field
                                                        type="text"
                                                        name="questionOptionTypeEnum"
                                                        placeholder="Select Option Type"
                                                        fieldName={"questionOptionType"}
                                                        handleChangeSection={handleQuestionType}
                                                        options={questionOptionTypeList || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group float-right">
                                                    <label> &nbsp;</label>
                                                    <button
                                                        type="submit"
                                                        disabled={disable}
                                                        className="btn btn-dark radius-25 px-4 d-block">
                                                        <i className="fa fa-bookmark mr-2"/>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EvaluationQuestionOption;
