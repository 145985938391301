import {
    ERROR_UNLOADED,
    FORM_CONCAT_ARRAY,
    FORM_ERROR,
    FORM_FIELD_INVESTMENT,
    FORM_FIELD_REGISTER,
    FORM_FILE_REMOVE,
    FORM_FILE_UPLOAD,
    FORM_FILE_UPLOAD_COMPLETED,
    FORM_FILE_UPLOAD_ERROR,
    FORM_LOADING,
    FORM_LOADING_COMPLETE,
    FORM_SET_FIELD,
    FORM_UNLOADED,
    FORM_UPDATE_ARRAY
} from "../constants/ActionTypes";

class FormActions {
    static setField(data) {
        return {type: FORM_SET_FIELD, payload: data};
    }

    static setFieldRegister(name, value) {
        return {type: FORM_FIELD_REGISTER, key: name, value};
    }

    static setFormField(name, value) {
        return {type: FORM_FIELD_INVESTMENT, key: name, value};
    }

    static unloaded() {
        // return {type: FORM_UNLOADED};
        return (dispatch, getState) => {
            dispatch({type: FORM_UNLOADED});
            dispatch({type: ERROR_UNLOADED});
        };
    }

    static errorUnloaded() {
        return (dispatch, getState) => {
            dispatch({type: ERROR_UNLOADED});
        };
    }

    static formUnloaded() {
        return (dispatch, getState) => {
            dispatch({type: FORM_UNLOADED});
        };
    }

    static formError(errors) {
        return {type: FORM_ERROR, payload: {errors: errors}};
    }

    static loading() {
        return {type: FORM_LOADING};
    }

    static loadingComplete() {
        return {type: FORM_LOADING_COMPLETE};
    }

    static filePushToArray(name, value) {
        return {
            type: FORM_FILE_UPLOAD,
            key: name,
            payload: {value: value, status: "uploadStarted"}
        };
    }

    static fileUploadDone(name, value, dto) {
        return {
            type: FORM_FILE_UPLOAD_COMPLETED,
            key: name,
            payload: {value: value, status: "uploadCompleted", dto: dto}
        };
    }

    static fileUploadError(name, value, dto) {
        return {
            type: FORM_FILE_UPLOAD_ERROR,
            key: name,
            payload: {value: value, status: "uploadError", dto: dto}
        };
    }

    static fileUploadClientError(name, value, errorMsg) {
        return {
            type: FORM_FILE_UPLOAD_ERROR,
            key: name,
            payload: {value: value, status: "uploadClientError", errorMsg: errorMsg}
        };
    }

    static fileRemove(name, value) {
        return {
            type: FORM_FILE_REMOVE,
            key: name,
            payload: {value: value, status: "fileRemoved"}
        };
    }

    static getCached(dispatch, cacheName) {
        const cached = sessionStorage.getItem(cacheName);
        if (cached) {
            dispatch(FormActions.setField(JSON.parse(cached)));
            return true;
        }
        return false;
    }

    static contactArray(arrayName, itemToStore) {
        return {type: FORM_CONCAT_ARRAY, key: arrayName, payload: itemToStore};
    }

    static updateItemInArray(arrayName, id, itemToStore) {
        return {
            type: FORM_UPDATE_ARRAY,
            id: id,
            key: arrayName,
            payload: itemToStore
        };
    }
}

export default FormActions;
