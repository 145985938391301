import React from "react";

const Error = ({children}) => {
    return (
        <div
            style={{
                lineHeight: "14px",
                fontSize: "12px",
                color: "#A52A2A",
                padding: "1px 0"
            }}
        >
            {" "}
            {children}
        </div>
    );
};
export default Error;
