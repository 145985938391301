import React, {useEffect, useState} from "react";
import * as StatesApi from "../../api/StatesApi";
import {Field, Form, Formik} from "formik";

import * as LinkFactory from "../../util/LinkFactory";
import SelectDropdown from "../common/SelectDropwodn";
import * as CountryApi from "../../api/CountryApi";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function StatesNew(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [states, setStates] = useState({states: "", id: ""});
    const [country, setCountry] = useState([]);
    useEffect(() => {
        CountryApi.getAll().then(json => {
            setCountry(json.data);
        });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        StatesApi.saveStates(data).then(data => {
            navigateTo(LinkFactory.states);
        });
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">States New</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-pencil-square-o mr-2"/>
                                Edit here
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={states}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {props => (
                                        <Form>
                                            <div className="table-responsive">
                                                <table
                                                    className="table table-bordered"
                                                    id="dataTable"
                                                    width="100%"
                                                    cellSpacing="0"
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="">States</th>
                                                        <th colSpan="">Country</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <label></label>
                                                            <Field
                                                                type="text"
                                                                name="states"
                                                                placeholder="states"
                                                                component={TextField}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Field
                                                                type="text"
                                                                name="country"
                                                                placeholder="Country"
                                                                fieldName={"country"}
                                                                options={country || []}
                                                                component={SelectDropdown}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="12">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-lg btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>
        </>
    );
}

export default StatesNew;
