import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const find = login => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/find-users/", login);
};

export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/users");
};
export const getAllAuthorities = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/users/authorities");
};
export const deleteUser = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/users/" + id);
};
