import {getFirstDayOfMonth, getFirstMonthDateObject, getLastDayOfMonth} from "./DateUtils";

export const monthWiseData = [
    {
        no: 1,
        name: " January ",
        startDate: getFirstDayOfMonth(1),
        endDate: getLastDayOfMonth(1),
        default: getFirstMonthDateObject(1)
    },
    {
        no: 2,
        name: " February",
        startDate: getFirstDayOfMonth(2),
        endDate: getLastDayOfMonth(2),
        default: getFirstMonthDateObject(2)
    },
    {
        no: 3,
        name: " March ",
        startDate: getFirstDayOfMonth(3),
        endDate: getLastDayOfMonth(3),
        default: getFirstMonthDateObject(3)
    },
    {
        no: 4,
        name: "April ",
        startDate: getFirstDayOfMonth(4),
        endDate: getLastDayOfMonth(4),
        default: getFirstMonthDateObject(4)
    },
    {
        no: 5,
        name: " May",
        startDate: getFirstDayOfMonth(5),
        endDate: getLastDayOfMonth(5),
        default: getFirstMonthDateObject(5)
    },
    {
        no: 6,
        name: "June",
        startDate: getFirstDayOfMonth(6),
        endDate: getLastDayOfMonth(6),
        default: getFirstMonthDateObject(6)
    },
    {
        no: 7,
        name: "July ",
        startDate: getFirstDayOfMonth(7),
        endDate: getLastDayOfMonth(7),
        default: getFirstMonthDateObject(7)
    },
    {
        no: 8,
        name: " August",
        startDate: getFirstDayOfMonth(8),
        endDate: getLastDayOfMonth(8),
        default: getFirstMonthDateObject(8)
    },
    {
        no: 9,
        name: "  September",
        startDate: getFirstDayOfMonth(9),
        endDate: getLastDayOfMonth(9),
        default: getFirstMonthDateObject(9)
    },
    {
        no: 10,
        name: "October",
        startDate: getFirstDayOfMonth(10),
        endDate: getLastDayOfMonth(10),
        default: getFirstMonthDateObject(10)
    },
    {
        no: 11,
        name: "November ",
        startDate: getFirstDayOfMonth(11),
        endDate: getLastDayOfMonth(11),
        default: getFirstMonthDateObject(11)
    },
    {
        no: 12,
        name: " December",
        startDate: getFirstDayOfMonth(12),
        endDate: getLastDayOfMonth(12),
        default: getFirstMonthDateObject(12)
    }
];
