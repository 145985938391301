import React, {useEffect, useState} from "react";

import * as LinkFactory from "../../util/LinkFactory";
import CommonLayout from "../layout/commonLayout";
import * as GradingApi from "../../api/GradingApi";
import Loader from "../common/Loader";
import * as Utils from "../../util/Utils";
import * as CourseMaterial from "../../api/CourseMaterialApi";
import {useNavigate, useParams} from "react-router-dom";

function AttachmentList(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [studyMaterials, setStudyMaterials] = useState([]);
    const [id, setId] = useState(null);
    useEffect(() => {
        CourseMaterial.getAttachmentListByChannelId(params.id)
            .then(json => {
                setStudyMaterials(json.data);
            })
            .catch(errors => {
                console.error(errors);
            });
    }, []);
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        GradingApi.deleteGradingById(id).then(data => {
            setId(null);
            window.location.reload();
        });
    };
    const handleDownload = files => {
        // console.log(files, "file");
        files.attachment.forEach(URL => window.open(URL));
    };
    const handleEdit = data => {
        const id = data.id || 12;
        navigateTo(
            LinkFactory.channelEditStudyMaterials +
            "/" +
            params.id +
            "/" +
            id
        );
    };

    const handleUploadAttachment = () => {
        navigateTo(
            LinkFactory.channelFileAttachmentUpload + "/" + params.id
        );
    };
    return (
        <>
            <CommonLayout headerName={Utils.getCurrentChannelName()}>
                <div>
                    <div className="my-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3 className="mt-4 mb-4">Study Materials</h3>
                            <button
                                type="button"
                                className="btn btn-outline-dark radius-25 float-right"
                                onClick={() => handleUploadAttachment()}
                            >
                                <i className="fa fa-upload mr-2"/>
                                Upload an attachment
                            </button>
                        </div>

                        <div className="table-responsive mt-4">
                            <table
                                className="table table-bordered"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead className="bg-light">
                                <tr>
                                    {/*<th scope="col">id</th>*/}
                                    <th scope="col">Name</th>
                                    <th scope="col">Attachment</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {studyMaterials.length > 0 ? (
                                    studyMaterials.map((data, index) => {
                                        return (
                                            <tr>
                                                {/*<th scope="row">{data.id}</th>*/}
                                                <td>{data.title}</td>
                                                <td>
                                                    <div
                                                        type={"button"}
                                                        onClick={() => handleDownload(data)}
                                                    >
                                                        {" "}
                                                        <i className="fa fa-download"/> Download{" "}
                                                    </div>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => handleEdit(data)}
                                                    >
                                                        <i className="fa fa-edit"/> Edit
                                                    </button>
                                                    {/*<button*/}
                                                    {/*  type="button"*/}
                                                    {/*  className="ml-2 btn btn-sm btn-danger"*/}
                                                    {/*  data-toggle="modal"*/}
                                                    {/*  data-target="#deleteModal"*/}
                                                    {/*  onClick={() => handleDelete(data)}*/}
                                                    {/*>*/}
                                                    {/*  <i className="fa fa-trash" /> Delete*/}
                                                    {/*</button>*/}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <Loader/>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AttachmentList;
