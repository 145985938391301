import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import {useLocation} from "react-router-dom";
import ReactHlsPlayer from "react-hls-player";

export default function StreamPlayer(props) {
    const [streamInfo, setStreamInfo] = useState(null);
    const location = useLocation();

    useEffect(() => {
        console.log(location);
        if (location.state) setStreamInfo(location.state);
    }, [location.state]);

    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>

                        <div className="container">
                            <div className="row">
                                <h2>{streamInfo?.streamTitle ? streamInfo.streamTitle : 'Test title'}</h2>
                                <div className="col-sm-12">
                                    {/*this url use for testing purpose*/}
                                    {/*https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8*/}
                                    <ReactHlsPlayer
                                        src="https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
                                        autoPlay={false}
                                        controls={true}
                                        width="100%"
                                        height="auto"
                                    />
                                </div>
                            </div>
                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

