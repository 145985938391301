import React, {useEffect} from "react";
import {toast} from "react-toastify";
import CommonLayout from "../layout/commonLayout";


toast.configure();

function Promotions() {


    useEffect(() => {
    }, []);


    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>
                        <h1>Promotions Content area</h1>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

export default Promotions;
