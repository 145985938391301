import React from "react";
import StudyMaterialUpload from "./StudyMaterialUpload";
import {useParams} from "react-router-dom";

function StudyMaterialsUploadPage(props) {
    const params = useParams();
    const initialState = {
        name: "",
        description: "",
        attachmentName: "",
        attachmentType: ""
    };
    const channelId = params.id;

    return (
        <StudyMaterialUpload initialState={initialState} channelId={channelId}/>
    );
}

export default StudyMaterialsUploadPage;
