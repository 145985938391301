import React, {useEffect, useState} from "react";
import * as ClassSetupApi from "../../api/ClassSetupApi";

import * as LinkFactory from "../../util/LinkFactory";

import {RotatingLines} from "react-loader-spinner";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function ClassList() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [classSetup, setClassSetup] = useState([]);
    const [id, setId] = useState(null);

    useEffect(() => {
        ClassSetupApi.getClassByLoggedInStudent()
            .then(json => {
                setClassSetup(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleEdit = data => {
        navigateTo(LinkFactory.classSetupEdit + "/" + data.id);
    };
    const handleViewAssignment = data => {
        navigateTo(LinkFactory.assignmentSubmitList + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.classSetupNew);
    };
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        ClassSetupApi.deleteClassSetupById(id);
        setId(null);
        window.location.reload();
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h2 className="mt-4">Class List</h2>

                        <div className="table-responsive mt-4">
                            <table
                                className="table table-bordered"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col">Class Name</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Teacher Name</th>
                                    <th scope="col">Grade</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {classSetup.length > 0 ? (
                                    classSetup.map((data, index) => {
                                        return (
                                            <tr>
                                                <td>{data.className}</td>
                                                <td>{data?.section?.sectionName}</td>
                                                <td>{data?.classAdmin?.firstName}</td>
                                                <td>{data?.grade?.gradeName}</td>
                                                <td className="text-right">
                                                    <button
                                                        type="button"
                                                        onClick={() => handleViewAssignment(data)}
                                                        className="btn btn-sm btn-info"
                                                    >
                                                        <i className="fa fa-eye"/> View Assignment
                                                    </button>
                                                    &nbsp;
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <div className="text-center position-absolute">
                                        <RotatingLines
                                            width="300"
                                            height="300"
                                            strokeColor="blue"
                                        />
                                    </div>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClassList;
