import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import {Form, Formik} from "formik";
import {FILES_URL} from "../../util/LinkFactory";
import Loader from "../common/Loader";
import moment from "moment";
import * as Utils from "../../util/Utils";
import LikedUserModal from "./LikedUserModal";
import * as ModalActions from "../../action/ModalActions";
import {useDispatch} from "react-redux";
import SingleAttachmentComponent from "./Components/SingleAttachmentComponent";
import TwoAttachmentsComponent from "./Components/TwoAttachmentsComponent";
import ThreeAttachmentsComponent from "./Components/ThreeAttachmentsComponent";
import FourAttachmentComponent from "./Components/FourAttachmentComponent";
import MoreThanFourAttachmentComponent from "./Components/MoreThanFourAttachmentComponent";
import CommonLayout from "../layout/commonLayout";
import CachingImage from "../Image/cachingImage";
import {useParams} from "react-router-dom";

const initialComment = {messageId: null, comment: "", file: null};

function ChannelSinglePostView(props) {
    const params = useParams();
    const dispatch = useDispatch();
    const [channel, setChannel] = useState({});
    const [hasError, setHasError] = useState(false);
    const [post, setPost] = useState({});
    const [comment, setComment] = useState(initialComment);
    const [postLoading, setPostLoading] = useState(true);
    const [user, setUser] = useState({});
    const [likedByCurrentUser, setLikedByCurrentUser] = useState(false);
    const [channelPostLikeLogList, setChannelPostLikeLogList] = useState([]);
    const [ourCommentArray, setOurCommentArray] = useState([]);

    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        setChannel({});
        setPost({});
        setPostLoading(true);
        ChannelApi.getChannelPostByPostId(params.id).then(response => {
            const status = response?.response?.status;
            if (status === 500) {
                console.error("got error ");
                setPostLoading(false);
                setHasError(true);
            } else {
                setPost(response.data);
                setOurCommentArray(response?.data?.channelPostCommentDTOList);
                setLikedByCurrentUser(response?.data?.likedByCurrentUser);
                setChannelPostLikeLogList(response?.data?.channelPostLikeLogList);
                setPostLoading(false);
            }
        }).catch(errors => {
            console.error(errors);
            setPostLoading(false);
            setHasError(true);
        })

        ;
    }, [params.id]);

    const submitComment = (data, resetForm, post) => {
        let channelMessage = {
            id: data.messageId
        };

        let groupMessageCommentDTO = {
            comment: data.comment,
            channelPosts: channelMessage
        };
        let groupMessageCommentDTOToPushInArray = {
            comment: data.comment,
            channelMessages: channelMessage,
            user: Utils.getUserFromLocalStorage()
        };
        let dummy = [...ourCommentArray];
        dummy.push(groupMessageCommentDTOToPushInArray);
        setOurCommentArray(dummy);
        ChannelApi.saveMessageComment(groupMessageCommentDTO).then(response => {
            setOurCommentArray(response.data);

        });
        resetForm(initialComment);
    };

    const registerLike = data => {

        let channelMessage = {
            id: data.groupMessageId
        };

        let ChannelPostLikeLogDTO = {
            likeEvent: !likedByCurrentUser,
            channelPosts: channelMessage,
            createUser: user
        };

        if (likedByCurrentUser === false) {
            setLikedByCurrentUser(!likedByCurrentUser);
            let likedList = [...channelPostLikeLogList];
            let afterLike = [ChannelPostLikeLogDTO, ...likedList];
            setChannelPostLikeLogList(afterLike);

        } else {
            setLikedByCurrentUser(!likedByCurrentUser);
            let likedList = [...channelPostLikeLogList]
            let afterDislike = likedList.filter((likeLog) => likeLog.createUser?.id !== user.id);
            setChannelPostLikeLogList(afterDislike);
        }

        ChannelApi.registerMessageLike(ChannelPostLikeLogDTO).then(response => {
            setChannelPostLikeLogList(response?.data);
            let currentUserLiked = response?.data.find(likeItem => likeItem.createUser.id === user?.id)
            setLikedByCurrentUser(!!currentUserLiked)
        });
    };
    const submitCommentState = data => {
        setComment(data);
    };
    const viewLikedUserModal = post => {
        console.log("hello");
        console.log(post);
        dispatch(
            ModalActions.showModalWithoutFooter(
                null,
                <LikedUserModal likeLogs={post?.channelPostLikeLogList}/>,
                {className: "link-user-modal"}
            )
        );
    };

    return (
        <>
            <CommonLayout id="layoutSidenav_content">
                <main>
                    <div id="contentArea">
                        <div className="block-center">
                            {!postLoading ?
                                (hasError ?
                                    <h2>Something went wrong</h2> :
                                    (
                                        <div className="card-default post-management-details container-responsive">
                                            <div className="view-post-area">
                                                <div className="">
                                                    <div className="post-content">
                                                        <div className="post-by">
                                                            <div className="avatar">
                                                                <CachingImage
                                                                    alt="Image"
                                                                    src={FILES_URL + post?.postBy?.imageUrl}
                                                                />
                                                            </div>
                                                            <div className="post-by-info">
                                                                <h6>{post?.postBy?.firstName}</h6>
                                                                <p>
                                                                    {post?.postDate != null
                                                                        ? moment(post?.postDate).format(
                                                                            "DD/MM/YY hh:mm a"
                                                                        )
                                                                        : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p>{post?.message}</p>
                                                        {post?.attachmentName?.length === 1 ? (
                                                            <SingleAttachmentComponent
                                                                id={post?.groupMessageId}
                                                                imageurl={post?.attachmentName[0]}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length === 2 ? (
                                                            <TwoAttachmentsComponent
                                                                id={post?.groupMessageId}
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length === 3 ? (
                                                            <ThreeAttachmentsComponent
                                                                id={post?.groupMessageId}
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length === 4 ? (
                                                            <FourAttachmentComponent
                                                                id={post?.groupMessageId}
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {post?.attachmentName?.length > 4 ? (
                                                            <MoreThanFourAttachmentComponent
                                                                id={post?.groupMessageId}
                                                                imageurl={post?.attachmentName}
                                                            />
                                                        ) : null}
                                                        {/*<SingleAttachmentComponent/>*/}

                                                        <div className="post-status"
                                                             onClick={() => {viewLikedUserModal(post);}}>
                                                            {channelPostLikeLogList?.length > 0 ?
                                                                channelPostLikeLogList.map((likedUser, index) => {
                                                                    if (index < 3)
                                                                        return (
                                                                            <span>{user?.id === likedUser?.createUser?.id ? "You" : likedUser?.createUser?.firstName}
                                                                                {(index >= 0 && index < 2 && index !== (channelPostLikeLogList.length - 1)) ?
                                                                                    ((channelPostLikeLogList.length > 1) ? ", " : null) : null}
                                                                                    </span>
                                                                        );})
                                                                : null}
                                                            {" "}
                                                            {channelPostLikeLogList.length > 3 ?
                                                                " and " + (channelPostLikeLogList.length - 3) + " others liked this"
                                                                : channelPostLikeLogList.length >= 1 ? " liked this" : null}
                                                        </div>

                                                        {/*<div className="like-comment-share">*/}
                                                        {/*    <div className="box" onClick={() => {*/}
                                                        {/*        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_STUDENT", "ROLE_EXAM_CONTROLLER"])) {*/}
                                                        {/*            registerLike(post);*/}
                                                        {/*        }*/}
                                                        {/*    }}>*/}
                                                        {/*        <i className={likedByCurrentUser ? "fa fa-thumbs-up" : "far fa-thumbs-up"}/>*/}
                                                        {/*        <p>*/}
                                                        {/*            /!*<b>{post.channelPostLikeLogList.length}</b> *!/*/}
                                                        {/*            Like*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}

                                                        {/*    <div className="box comment-count">*/}
                                                        {/*        /!*<i className="fa fa-comment-dots" />*!/*/}
                                                        {/*        <p><b>{post.channelPostCommentDTOList.length}</b>{" "}Comment</p>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        <div className="mb-2 mt-2">
                                                            {ourCommentArray?.length > 0
                                                                ? ourCommentArray?.map(
                                                                    (comment, index) => {
                                                                        return (
                                                                            <div className="comment-area">
                                                                                <div className="avatar">
                                                                                    <img
                                                                                        src={
                                                                                            FILES_URL +
                                                                                            comment?.user?.imageUrl
                                                                                        }
                                                                                    />
                                                                                </div>

                                                                                <div className="card-alt">
                                                                                    <h6>
                                                                                        {comment?.user?.firstName}{" "}
                                                                                        {comment?.user?.lastName}
                                                                                        <span>
                                                                                        {" "}
                                                                                            <small>{moment(comment?.createTimeDate).fromNow()}</small>
                                                                                    </span>
                                                                                    </h6>
                                                                                    <p>{comment?.comment}</p>
                                                                                    {/*<div className="like-comment-share">*/}
                                                                                    {/*    <div className="box">*/}
                                                                                    {/*        <img src="img/like.png"/>*/}
                                                                                    {/*        <p>{comment?.likedUserList?.length}</p>*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                                : null}
                                                        </div>
                                                        {Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_STUDENT", "ROLE_EXAM_CONTROLLER"]) ?
                                                            <Formik
                                                                initialValues={
                                                                    comment.post != null &&
                                                                    comment.messageId === post?.groupMessageId
                                                                        ? comment
                                                                        : initialComment
                                                                }
                                                                enableReinitialize={true}
                                                                onSubmit={(
                                                                    data,
                                                                    {
                                                                        setSubmitting,
                                                                        setErrors,
                                                                        setStatus,
                                                                        resetForm
                                                                    }
                                                                ) => {
                                                                    const value = {
                                                                        ...data,
                                                                        messageId: post?.groupMessageId
                                                                    };
                                                                    submitComment(value, resetForm, post);
                                                                }}
                                                            >
                                                                {({
                                                                      values,
                                                                      handleSubmit,
                                                                      setFieldValue
                                                                  }) => (
                                                                    <Form>
                                                                        <div className="add-comment-area">
                                                                            <div className="avatar">
                                                                                <CachingImage
                                                                                    alt="image"
                                                                                    src={FILES_URL + user?.imageUrl}
                                                                                />
                                                                            </div>
                                                                            <div className="input-default">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="comment"
                                                                                    aria-describedby="emailHelp"
                                                                                    name="comment"
                                                                                    placeholder="Write your comment here"
                                                                                    value={values.comment}
                                                                                    onChange={event => {
                                                                                        setFieldValue(
                                                                                            "comment",
                                                                                            event.target.value
                                                                                        );
                                                                                        submitCommentState({
                                                                                            ...values,
                                                                                            comment: event.target.value
                                                                                        });
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn-red-md float-right"
                                                                                >
                                                                                    <i className="fa fa-send"/>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : postLoading ? (<Loader/>) : null
                            }
                            <br/>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ChannelSinglePostView;
