import React, {useState} from "react";
import {CircleSpinnerOverlay} from "react-spinner-overlay";

const LoaderOverlay = () => {
    const [loading, setLoading] = useState(true);
    return (
        <>
            <CircleSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
            />
        </>
    );
};

export default LoaderOverlay;
