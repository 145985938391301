import React, {useEffect, useState} from "react";
import * as SportsTypeApi from "../../api/SportsTypeApi";
import {Field, Form, Formik} from "formik";

import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function AssignmentSubmitEdit(props) {
    const params = useParams();
    const [states, setStates] = useState({states: "", id: ""});
    const [country, setCountry] = useState([]);

    useEffect(() => {
        SportsTypeApi.getSportsTypeById(params.id)
            .then(json => {
                setStates(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        SportsTypeApi.updateSportsType(data);
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Sports type Edit</h1>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={states}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {props => (
                                    <Form>
                                        <div className="table-responsive table-light mt-4">
                                            <table className="table table-light"
                                                   id="dataTable"
                                                   width="100%"
                                                   cellSpacing="0"
                                            >
                                                <tr>
                                                    <td>Id</td>
                                                    <td>
                                                        <Field
                                                            type="number"
                                                            name="id"
                                                            placeholder="id"
                                                            readonly
                                                            component={TextField}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Sports Type</td>
                                                    <td>
                                                        <Field
                                                            type="text"
                                                            name="sportsType"
                                                            placeholder="sportsType"
                                                            component={TextField}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="12">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-lg btn-primary float-right"
                                                        >
                                                            Submit
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AssignmentSubmitEdit;
