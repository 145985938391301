import React, {useEffect} from "react";
import {convertToRaw, EditorState, ContentState, convertFromHTML} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "./index.css";

export default function MarkdownEditor({cbValue, name, value, size = "large"}) {
    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    );

    const save = async function* (data) {
        // Promise that waits for "time" milliseconds
        const wait = function (time) {
            return new Promise((a, r) => {
                setTimeout(() => a(), time);
            });
        };

        // Upload "data" to your server
        // Use XMLHttpRequest.send to send a FormData object containing
        // "data"
        // Check this question: https://stackoverflow.com/questions/18055422/how-to-receive-php-image-data-over-copy-n-paste-javascript-with-xmlhttprequest

        await wait(2000);
        // yields the URL that should be inserted in the markdown
        yield "https://picsum.photos/300";
        await wait(2000);

        // returns true meaning that the save was successful
        return true;
    };
    const onEditorStateChange = value => {
        setEditorState(value);
        const convertedHtml = draftToHtml(convertToRaw(value.getCurrentContent()));
        cbValue(convertedHtml);
    };
    let editorClassname;
    if (size === "large") editorClassname = "rdw-storybook-editor";
    else editorClassname = "rdw-storybook-editor-small";

    // edit mode
    useEffect((blocks, entityMap) => {

        if (value) {
            const editor = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value), entityMap))
            setEditorState(editor);
        }

    }, [value]);

    return (
        <div className="rdw-storybook-root">
            <Editor
                name={name}
                editorState={editorState}
                toolbarClassName="rdw-storybook-toolbar"
                wrapperClassName="rdw-storybook-wrapper"
                editorClassName={editorClassname}
                onEditorStateChange={onEditorStateChange}
            />
            {/*<textarea disabled value={draftToHtml(convertToRaw(editorState))} />*/}
        </div>
    );
}
