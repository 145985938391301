import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../../common/TextFieldComponent";
import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import * as EvaluationQuestionApi from "../../../api/EvaluationQuestionApi";
import * as EvaluationQuestionMainApi from "../../../api/EvaluationQuestionMainApi";
import * as EvaluationQuestionTypeApi from "../../../api/EvaluationQuestionTypeApi";
import FormTitle from "../../Typhography/formTitle";
import SelectDropdown from "../../common/SelectDropwodn";
import * as LinkFactory from "../../../util/LinkFactory";
import {useNavigate} from "react-router-dom";


function EvaluationQuestion (props) {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [evaluationQues, setEvaluationQues] = useState({
    question: "",
    evaluationQuestionMain: {},
    evaluationQuestionType: {}
  });
  const [disable, setDisable] = useState(false);
  const [evaluationQuesMain, setEvaluationQuesMain] = useState({
    name: "",
    globalInd: null
  });
  const [evalQuesMainList, setEvalQuesMainList] = useState([]);
  const [evalQuesTypeList, setEvalQuesTypeList] = useState([]);

  const notifyValidation = () => toast.success("All fields required", {
    position: toast.POSITION.TOP_CENTER
  });
  const notifySuccess = () => toast.success("Evaluation question Created!", {
    position: toast.POSITION.TOP_RIGHT
  });
  const handleSubmit = value => {
    setDisable(true);
    if(null == value.question || '' === value.question) {
      notifyValidation();
      setDisable(false);
    } else {
      if(null!==value.id || '' === value.id) {
        EvaluationQuestionApi.update(value).then(res => {
          notifySuccess();
          handleList();
        });
      } else {
        EvaluationQuestionApi.save(value).then(res => {
          notifySuccess();
          handleList();
        });
      }
    }
  }

  const handleList = () => {
    navigateTo(LinkFactory.evaluationQuestionList);
  };

  const getEvaluationMainList = () => {
    EvaluationQuestionMainApi.search(evaluationQuesMain).then(res=>{
      setEvalQuesMainList(res.data);
    });
  }

  const getEvaluationQuesTypeList = () => {
    EvaluationQuestionTypeApi.search(evaluationQuesMain).then(res=>{
      setEvalQuesTypeList(res.data);
    });
  }

  const getEvaluationQuestionById = id => {
    EvaluationQuestionApi.getEvaluationQuestionById(id).then(res=> {
      setEvaluationQues(res.data);
    });
  }

  useEffect(()=> {
    if (props?.match?.params?.id) {
      getEvaluationQuestionById(props?.match?.params?.id)
    }
  }, [])

  useEffect(()=> {
    getEvaluationMainList();
    getEvaluationQuesTypeList();
  }, []);

  const handleEvalSelection = (fieldName, value) => {
    evaluationQues.evaluationQuestionMain = value;
  }

  const handleEvalQuesTypeSelection = (fieldName, value) => {
    console.log(fieldName, value);
    evaluationQues.evaluationQuestionType = value;
  }

  return (
    <>
      <CommonLayout headerName="New Evaluation Question ">
        <main>
          <div>
            <h3 className="mt-4 mb-4">Question Details</h3>
            <div>
              <Formik
                initialValues={evaluationQues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({props, values, setFieldValue}) => (
                  <Form>
                    <div className="form-row">
                        <div className="col-4">
                          <div className="form-group input-default-white">
                            <FormTitle>Question</FormTitle>
                            <Field
                              type="text"
                              name="question"
                              placeholder="Question"
                              component={TextFieldComponent}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group input-default-white">
                            <FormTitle>Question Set</FormTitle>
                            <Field
                              type="text"
                              name="evaluationQuestionMain"
                              placeholder="Select Evaluation Question Set"
                              fieldName={"name"}
                              handleChangeSection={handleEvalSelection}
                              options={evalQuesMainList || []}
                              component={SelectDropdown}
                            />
                          </div>
                        </div>
                          <div className="col-4">
                            <div className="form-group input-default-white">
                              <FormTitle>Question Type</FormTitle>
                              <Field
                                  type="text"
                                  name="evaluationQuestionType"
                                  placeholder="Select Evaluation Question Type"
                                  fieldName={"questionType"}
                                  handleChangeSection={handleEvalQuesTypeSelection}
                                  options={evalQuesTypeList || []}
                                  component={SelectDropdown}
                              />
                            </div>
                          </div>
                      <div className="col-12">
                        <div className="form-group float-right">
                          <label> &nbsp;</label>
                          <button
                              type="submit"
                              disabled={disable}
                              className="btn btn-dark radius-25 px-4 d-block">
                            <i className="fa fa-bookmark mr-2"/>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationQuestion;
