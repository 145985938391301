import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import * as EvaluationQuestionOptionApi from "../../../api/EvaluationQuestionOptionApi";
import Loader from "../../common/Loader";
import * as LinkFactory from "../../../util/LinkFactory";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


function EvaluationQuestionOptionList() {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [loader, setLoader] = useState(true);
  const [evalQuesOptList, setEvalQuesOptList] = useState([]);
  const [evaluationQuesOpt, setEvaluationQuesOpt] = useState({
    option: ""
  });

  useEffect(() => {
    getEvaluationQuestionOptionList();
  }, []);

  const notifyDelete = () => toast.success("Successfully Deleted!", {
    position: toast.POSITION.TOP_CENTER
  });

  const handleNew = data => {
    navigateTo(LinkFactory.evaluationQuestionOption);
  };

  const handleEdit = data => {
    navigateTo(LinkFactory.evaluationQuestionOption + "/" + data.id);
  }

  const handleDelete = data => {
    EvaluationQuestionOptionApi.deleteEvaluationQuestionOption(data.id).then(res=> {
      notifyDelete();
      getEvaluationQuestionOptionList();
    })
  }

  const getEvaluationQuestionOptionList = () => {
    setLoader(true);
    EvaluationQuestionOptionApi.search(evaluationQuesOpt).then(res=>{
      setEvalQuesOptList(res.data);
      setLoader(false);
    });
  }

  return (
    <>
      <CommonLayout headerName="Evaluation Question Options">
        <main>
          <div>
            <h3 className="mt-4 mb-4">
              Question Option List
              <button
                  type="button"
                  className="btn btn-outline-dark radius-25 float-right"
                  onClick={() => handleNew()}>
                <i className="fa fa-plus-circle mr-2"/>
                Create New Question Option
              </button>
            </h3>

            <div className="table-responsive table-light table-shadow radius-15 mt-4">
              <table
                  className="table  table-light table-hover"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
              >
                <thead>
                <tr>
                  <th scope="col">Sl</th>
                  <th scope="col">Question Set</th>
                  <th scope="col">Question Type</th>
                  <th scope="col">Evaluation Question</th>
                  <th scope="col">Option</th>
                  <th scope="col" className="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                {undefined!==evalQuesOptList && evalQuesOptList && evalQuesOptList?.length > 0
                  ? evalQuesOptList.map((data, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{data?.evaluationQuestion?.evaluationQuestionMain.name}</td>
                        <td>{data?.evaluationQuestion?.evaluationQuestionType.questionType}</td>
                        <td>{data?.evaluationQuestion?.question}</td>
                        <td>{data?.option}</td>
                        <td className="text-right">
                          <button
                            type="button"
                            title="Edit"
                            className="btn btn-sm btn-primary"
                            onClick={() => handleEdit(data)}
                          >
                            <i className="fa fa-edit"/>
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            title="Delete"
                            className="btn btn-sm btn-danger"
                            data-toggle="modal"
                            data-target="#deleteModal"
                            onClick={() => handleDelete(data)}
                          >
                            <i className="fa fa-trash"/>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  : <td colSpan="12" className="text-center">
                    {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                  </td>}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationQuestionOptionList;
