import React, {useEffect, useState} from "react";
import * as LinkFactory from "../../../util/LinkFactory";
import * as UMApi from "../../../api/UMApi";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import * as ProgramApi from "../../../api/ProgramApi";
import Loader from "../../common/Loader";
import {useNavigate} from "react-router-dom";

function UMRoleSetupList() {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [loader, setLoader] = useState(true);
    const [roleList, setRoleList] = useState([]);
    const [id, setId] = useState(null);

    function getRoleSetupList() {
        setLoader(true);
        UMApi.getAllRole()
            .then(json => {
                setLoader(false);
                console.log(json);
                setRoleList(json.data);
            })
            .catch(errors => {
                setLoader(false);
                console.log(errors);
            });
    }

    useEffect(() => {
        getRoleSetupList();
    }, []);

    const notifyDelete = () => toast.success("Successfully Deleted!", {
        position: toast.POSITION.TOP_CENTER
    });

    const handleEdit = data => {
        // navigateTo(LinkFactory.programNew + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.UserManagementRoleNew);
    };
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        ProgramApi.deleteProgramById(id);
        setId(null);
        notifyDelete();
        window.location.reload();
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h2 className="mt-4">
                            Role Setup List
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={() => handleNew()}>
                                Create new Role Setup
                            </button>
                        </h2>

                        <div className="table-responsive">
                            <table
                                className="table table-bordered mt-4"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0">
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col">Sl</th>
                                    <th scope="col">Role Name</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {roleList.length > 0
                                    ? roleList.map((data, index) => {
                                        return (
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td>{data?.roleName}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => handleEdit(data)}>
                                                        <i className="fa fa-edit"></i> Edit
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger"
                                                        data-toggle="modal"
                                                        data-target="#deleteModal"
                                                        onClick={() => handleDelete(data)}>
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <td colSpan="12" className="text-center">
                                        {loader ? <Loader/> : <h5>No data found</h5>}
                                    </td>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UMRoleSetupList;
