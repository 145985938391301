import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import * as EvaluationInfoApi from "../../../api/EvaluationInfoApi";
import Loader from "../../common/Loader";
import * as LinkFactory from "../../../util/LinkFactory";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


function EvaluationInfoList() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [loader, setLoader] = useState(true);
    const [evalQuesInfoList, setEvalQuesInfoList] = useState([]);
    const [evaluationQuesInfo] = useState({
        startDate: "",
        endDate: "",
        semester: {}
    });

    useEffect(() => {
        getEvaluationList();
    }, []);

    const notifyDelete = () => toast.success("Successfully Deleted!", {
        position: toast.POSITION.TOP_CENTER
    });

    const handleNew = data => {
        navigateTo(LinkFactory.evaluationInfo);
    };

    const handleEdit = data => {
        navigateTo(LinkFactory.evaluationInfo + "/" + data.id);
    }

    const handleDelete = data => {
        EvaluationInfoApi.deleteEvaluationInfo(data.id).then(()=> {
            notifyDelete();
            getEvaluationList();

        })
    }

    const getEvaluationList = () => {
        setLoader(true);
        EvaluationInfoApi.search(evaluationQuesInfo).then(res=>{
            setEvalQuesInfoList(res.data);
            setLoader(false);
        });
    }

    return (
        <>
            <CommonLayout headerName="Evaluation Info  List">
                <main>
                    <div>
                        <h3 className="mt-4 mb-4">
                            Evaluation Info List
                            <button
                                type="button"
                                className="btn btn-outline-dark radius-25 float-right"
                                onClick={() => handleNew()}>
                                <i className="fa fa-plus-circle mr-2"/>
                                Create new Evaluation Info
                            </button>
                        </h3>

                        <div className="table-responsive table-light table-shadow mt-2 radius-15">
                            <table
                                className="table table-light table-hover"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead>
                                <tr>
                                    <th scope="col">Sl</th>
                                    <th scope="col">Semester</th>
                                    <th scope="col">Year</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {undefined!==evalQuesInfoList && evalQuesInfoList?.length > 0
                                    ? evalQuesInfoList.map((data, index) => {
                                        return (
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td>{data?.semester?.semesterName}</td>
                                                <td>{data?.semester?.year}</td>
                                                <td>{data?.startDateTime}</td>
                                                <td>{data?.endDateTime}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => handleEdit(data)}
                                                    >
                                                        <i className="fa fa-edit"></i> Edit
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger"
                                                        data-toggle="modal"
                                                        data-target="#deleteModal"
                                                        onClick={() => handleDelete(data)}
                                                    >
                                                        <i className="fa fa-trash"></i> Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : <td colSpan="12" className="text-center">
                                        {loader ? <Loader/> : <h5>No data found</h5>}
                                    </td>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EvaluationInfoList;
