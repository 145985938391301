import React, {useEffect} from "react";
import * as ChannelPostRankingApi from "../../api/ChannelPostRankingApi";
import CommonLayout from "../layout/commonLayout";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {DeleteOutlineRounded} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import LoaderOverlay from "../common/LoaderOverlay";

function ChannelPostRankingView(props) {
    const params = useParams();
    const slug = useParams();
    const [userList, setUserList] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [title, setTitle] = React.useState(false);


    useEffect(() => {
        setLoader(true);
        ChannelPostRankingApi.getChannelPostRankingsByChannelId(params.id)
            .then(json => {
                setLoader(false);
                setUserList(json.data);
            })
            .catch(errors => {
                setLoader(false);
                console.log(errors);
            });
    }, [params.id]);

    function deleteUser(id) {

    }

    return (<>
        <CommonLayout headerName={"Rankings"}>
            <main>
                <div>
                    <h3 className="mt-4 mb-4">Room Post Rankings</h3>
                    {loader ? <LoaderOverlay/> : null}

                    <div>
                        <div>
                                <div className="table-responsive table-light table-shadow radius-15">
                                    <table
                                        className="table table-light table-hover"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Ranking</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {userList.length > 0 ? userList.map((ranking, index) => {
                                            return (<tr key={index}>
                                                <td className="text-center">
                                                    {ranking.user.firstName}{" "}
                                                    {ranking.user.lastName}
                                                </td>

                                                <td className="text-center">
                                                    {ranking.user.email}
                                                </td>
                                                <td className="text-center">
                                                    {ranking.ranking}
                                                </td>
                                                <td className="text-center ">
                                                    <DeleteOutlineRounded
                                                        onClick={() => deleteUser(ranking.user.id)}
                                                        role={"button"}
                                                    />
                                                </td>
                                            </tr>);
                                        }) : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    </div>
                    <br/>
                </div>
            </main>
        </CommonLayout>
    </>);
}

export default ChannelPostRankingView;
