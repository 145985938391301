export const FETCH_NOTIFICATION_LIST_BEGIN = "FETCH_POST_NOTIFICATION_LIST_BEGIN";
export const FETCH_NOTIFICATION_LIST_SUCCESS = "FETCH_POST_NOTIFICATION_LIST_SUCCESS";
export const FETCH_POST_NOTIFICATION_LIST_FAILURE = "FETCH_POST_NOTIFICATION_LIST_FAILURE";

export const fetchNotificationListBegin = () => ({
    type: FETCH_NOTIFICATION_LIST_BEGIN
});

export const fetchNotificationListSuccess = notificationList => ({
    type: FETCH_NOTIFICATION_LIST_SUCCESS,
    payload: {
        assignmentNotifications: notificationList.assignmentNotifications,
        postNotifications: notificationList.postNotifications,
    }
});

export const fetchNotificationListFailure = error => ({
    type: FETCH_POST_NOTIFICATION_LIST_FAILURE,
    payload: {error}
});
