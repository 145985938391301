import * as NotificationsApi from "../../../api/NotificationsApi";
import {fetchNotificationCounterBegin, fetchNotificationCounterSuccess} from "../action/notificationCounterAction";

export function fetchNotificationCounter() {
    return dispatch => {
        dispatch(fetchNotificationCounterBegin());
        NotificationsApi.notificationCountForCurrentUser().then((res) => {
            dispatch(fetchNotificationCounterSuccess(res.data));
        });
    };
}
