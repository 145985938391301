import React, {useEffect, useState} from "react";
import * as SemesterApi from "../../api/SemesterApi";

import CommonLayout from "../layout/commonLayout";
import {useNavigate, useParams} from "react-router-dom";

function SemesterView(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [semester, setSemester] = useState([]);

    useEffect(() => {
        SemesterApi.getSemesterById(params.id)
            .then(json => {
                console.log(json);
                setSemester(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    function goBack() {
        navigateTo(-1)
    }
    
    return (
        <>
            <CommonLayout>
                <main>
                    <div className="card-default semester-view container-responsive">
                        <div className="title-header ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Semester</p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="table-responsive table-light mt-4">
                                    <table
                                        className="table table-light table-striped table-hover"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th scope="col">Semester Name</th>
                                            <th scope="col">Marking Period Start</th>
                                            <th scope="col">Marking Period End</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{semester.semesterName}</td>
                                            <td>{semester.markingPeriodStart}</td>
                                            <td>{semester.markingPeriodEnd}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default SemesterView;
