import {Field, Form, Formik} from "formik";
import FormTitle from "../Typhography/formTitle";
import TextFieldComponent from "../common/TextFieldComponent";
import React, {useState} from "react";
import {toast} from "react-toastify";
import * as Utils from "../../util/Utils";
import Agent from "../../api/Agent";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";


export default function ResetPasswordByOtpComponent() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [user, setSetUser] = useState({
        otp: "",
        confirmPassword: "",
        newPassword: "",
        email: Utils.getEmail()
    });

    const validationPassword = (password1, password2) => {
        console.log(password1, password2);
        if (password1 === '') {
            toast("Please enter Password");
            return false;
        } else if (password2 === '') {
            toast("Please enter confirm password");
        } else if (password1 !== password2) {
            toast("\nPassword did not match: Please try again...")
            return false;
        }
        return true;
    }

    const handleSubmit = (values) => {
        if (validationPassword(values.newPassword, values.confirmPassword)) {
            Agent.Auth.resetPassword(values).then(res => {
                console.log('reset-password', res.data);
                if(res.data.status===200) {
                    Utils.setEmail(res.data.userName);
                    navigateTo(LinkFactory.login);
                    toast("Password reset successfully!");
                } else {
                    toast("Error");
                }
            });
        }
    }

    return (
        <div className="reset-password bg-default d-flex">
            <div id="reg_layout">
                <div id="reg_content">
                    <div className="container">
                        <div className="brand">

                            <img src="img/Edooket.png" alt={"Edoocate logo"} className="mb-4"/>

                            <h3 className=" mb-4">Reset your password</h3>
                            <p className="mt-4">Enter a password</p>

                        </div>

                        <div className="card-default">
                            <Formik
                                initialValues={user}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({props, values, setFieldValue}) => (
                                    <Form>
                                        <div className="form-row">
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <FormTitle>Otp</FormTitle>
                                                    <Field
                                                        type="text"
                                                        name="otp"
                                                        placeholder="Otp"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <FormTitle>New Password</FormTitle>
                                                    <Field
                                                        type="password"
                                                        name="newPassword"
                                                        placeholder="New Password"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group ">
                                                    <FormTitle>Confirm Password</FormTitle>
                                                    <Field
                                                        type="password"
                                                        name="confirmPassword"
                                                        placeholder="Confirm Password"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group mt-3">
                                                    {/*<label> &nbsp;</label>*/}
                                                    <button type="submit" className="btn-deep-blue-lg box-btn">
                                                        <p>Submit</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        {/*<div className="bg-watermark">*/}
                        {/*    <img src="https://uxwing.com/wp-content/themes/uxwing/download/editing-user-action/lock-icon.png"/>*/}
                        {/*</div>*/}
                    </div>


                </div>

            </div>
            <div className="login-image">
                <div className="gradient-background">
                    <div className="h-100 flex-column d-flex justify-content-center align-item-center">
                        <div className="d-flex  justify-content-center">
                            <p>The first ever <span>smart school solution </span> to automate your whole institution</p>
                        </div>

                        <div className="image-container">
                            <img src="img/bottom_clouds.png" alt="bottom cloud" className="bottom_cloud"/>
                            <img src="img/bulb.png" alt="Bulb" className="bulb" />
                            <img src="img/clouds&sun.png" alt="cloud & sun" className="cloud-sun"/>
                            <img src="img/Paperplane.png"  alt="Paper Plane" className="paperplane"/>
                            <img src="img/man_riding_book.png" alt="Person with Books" className="person"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
}
