import React from "react";
import {TextArea} from "semantic-ui-react";

const TextAreaComponent = ({field, form: {touched, errors, ...rest}}) => {
    return (
        <div className="input-group mb-3">
            <TextArea
                {...field}
                {...rest}
                type="text"
                cols="50"
                className="form-control"
                aria-label="industry"
                aria-describedby="basic-addon1"
                placeholder="Details…"
            />
        </div>
    );
};

export default TextAreaComponent;
