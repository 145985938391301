import React, {useEffect, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import SelectDropdown from "../../common/SelectDropwodn";
import * as UserApi from "../../../api/UserApi";
import * as UMApi from "../../../api/UMApi";
import FormTitle from "../../Typhography/formTitle";
import TextFieldComponent from "../../common/TextFieldComponent";

function UMRoleSetupNew(props) {
    const [role, setRole] = useState({
        roleName: "",
        privileges: [""],
    });
    const [authorities, setAuthorities] = useState([]);
    const notifySuccess = () => toast.success("Role Setup Created!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    const handleSubmit = values => {
        console.log(values);
        const privs = [];
        values.privileges.forEach(value => {
            privs.push(value.privilege);
        })
        const roleSetup = {
            roleName: values.roleName,
            privileges: privs
        }
        console.log(roleSetup);
        UMApi.saveRoleSetup(roleSetup).then(json => {
            console.log(json.data);
            notifySuccess();
        })
    };

    function getAllAuthorities() {
        var privs = [];
        UserApi.getAllAuthorities().then(json => {
            json.data.forEach((r) => {
                console.log(r);
                privs.push({privilege: r});
            })
            setAuthorities(privs);
        });
    }

    function handleDeleteList(id) {

    }

    useEffect(() => {
        getAllAuthorities();

    }, []);

    return (
        <>
            <CommonLayout headerName="UM Role">
                <main>
                    <div>
                        <h3 className="mt-4">User Management Role</h3>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={role}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}>
                                {({props, values, setFieldValue}) => (
                                    <Form>
                                        <div className="form-row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <FormTitle>Role Name</FormTitle>
                                                    <Field
                                                        type="text"
                                                        name="roleName"
                                                        placeholder="Role Name"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <FieldArray
                                            name="privileges"
                                            render={arrayHelpers => (
                                                <div className="w-100">
                                                    {values.privileges && values.privileges.length > 0
                                                        ? values.privileges.map((p, index) => (
                                                            <span key={index} className="w-100">
                                                              <div className="form-row">
                                                                <div className="col-6">
                                                                  <div className="form-group">
                                                                    <label>Privilege</label>
                                                                    <Field
                                                                        type="text"
                                                                        id={`privileges[${index}]`}
                                                                        name={`privileges[${index}]`}
                                                                        placeholder="Select Authority"
                                                                        fieldName={`privilege`}
                                                                        options={authorities || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="form-row">
                                                                <div className="col-12">
                                                                  <div className="form-group">
                                                                    <button
                                                                        className={"btn btn-outline-danger radius-25"}
                                                                        type="button"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(index);
                                                                            handleDeleteList(p);
                                                                        }}>
                                                                      <i className="fa fa-trash mr-2"/>
                                                                      Delete
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </span>
                                                        ))
                                                        : ""}
                                                    <div className="form-row">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <button
                                                                    type="button"
                                                                    className={"btn btn-outline-dark radius-25"}
                                                                    onClick={() =>
                                                                        arrayHelpers.push("")
                                                                    }>
                                                                    <i className="fa fa-plus-circle mr-2"/>
                                                                    Add more
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <button type="submit"
                                                className="btn btn-lg btn-success">
                                            Submit
                                        </button>
                                    </Form>

                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default UMRoleSetupNew;
