import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import * as GradingCalculatedResultApi from "../../api/GradingCalculatedResultApi";
import * as StudentSgpaApi from "../../api/StudentSgpaApi";
import CommonLayout from "../layout/commonLayout";
import {toast} from "react-toastify";
import * as Utils from "../../util/Utils";
import {useNavigate, useParams} from "react-router-dom";
import * as GradingApi from "../../api/GradingApi";

toast.configure()

function GradeBook(props) {
    const params = useParams();
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [gradeBooks, setGradeBooks] = useState(null);
    const [breakdown, setBreakdown] = useState({});
    const [grading, setGrading] = useState(null);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    const imageBase = process.env.REACT_APP_FILES_URL;
    const currentGradingList = () => {
        GradingCalculatedResultApi.getGradingCalculatedResultByChannelId(params.id)
            .then(res => {
                console.log(res.data);
                setGradeBooks(res.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    const getGradeBookForThisRoom = (channelId) => {
        GradingApi.getGradingByChannelId(channelId).then(res => {
            console.log(res.response.status);
            if (res.response.status !== 500) {
                setGrading(res.data);
            }
        })
    }

    useEffect(() => {
        currentGradingList();
        getGradeBookForThisRoom(params.id);
        console.log('ass', grading);
    }, [setGradeBooks, setGrading]);

    const saveSuccess = (data) => toast("Successfully Saved!");
    const handleFinalize = values => {
        StudentSgpaApi.saveStudentSgpa(params.id)
            .then(res => {
                if (res) {
                    saveSuccess(res);
                    currentGradingList();
                    getGradeBookForThisRoom(params.id);
                }
            }).catch(errors => {
            console.log(errors);
        });
    }
    const handleMarksPopUpState = value => {
        console.log(value);
        setBreakdown(value);
    }

    function goBack() {
        navigateTo(-1)
    }

    return (
        <div className="grade-book">
            <CommonLayout headerName={"Grade Book"}>
                <main>
                    <div id="gradeBook" className="card-default container-responsive">
                        <div className="title mt-3 mb-3">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey d-flex align-items-center">
                                <a onClick={goBack} className="pl-0 back-button">
                                    <i className="fa fa-chevron-left pr-3"> </i> </a>
                                <p>Current Grading for {Utils.getCurrentChannelName()}</p>
                            </div>
                            {null !== grading && !grading?.isFinalized && Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_EXAM_CONTROLLER", "ROLE_FACULTY"]) ?
                                <button
                                    type="button"
                                    className="btn btn-red-md float-right"
                                    data-toggle="modal"
                                    data-target="#finalizeModal">
                                    <i className="fa fa-check mr-2"/>
                                    Finalized
                                </button>
                                : <div/>}
                        </div>


                        <div>
                            <div
                                className="table-responsive table-light  mt-4  table-col-scroll-custom">
                                <table
                                    className="table table-light table-hover"
                                    id="dataTable"
                                    width="100%">
                                    <thead>
                                    <tr>
                                        <th scope="col">Student Name</th>
                                        <th scope="col">Student ID</th>
                                        {gradeBooks?.gradingBreakdownList?.length > 0 ? (
                                            gradeBooks.gradingBreakdownList.map(
                                                (gradeBreakdown, index) => {
                                                    return (
                                                        <th scope="col">
                                                            {gradeBreakdown.evaluationName +
                                                                (null == gradeBreakdown.fullScore ? "" : "(" + gradeBreakdown.fullScore + ")")
                                                            }
                                                        </th>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div/>
                                        )}
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {gradeBooks?.gradingResultStudentDTOList?.length > 0
                                        ? gradeBooks.gradingResultStudentDTOList.map(
                                            (studentGradeBook, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>
                                                                <img
                                                                    alt={"Student Picture"}
                                                                    src={
                                                                        studentGradeBook?.student?.imageUrl
                                                                            ? imageBase + studentGradeBook?.student?.imageUrl
                                                                            : "img/Avatar.png"
                                                                    }
                                                                    style={{
                                                                        width: "35px",
                                                                        height: "35px",
                                                                        objectFit: "cover"
                                                                    }}
                                                                    className="rounded-circle p-1"
                                                                />
                                                                {studentGradeBook?.student?.firstName || ""}{" "}
                                                                {studentGradeBook?.student?.lastName}
                                                            </td>
                                                            <td>
                                                                {studentGradeBook?.studentId}
                                                            </td>

                                                            {studentGradeBook?.gradeBookBreakdownDTOList?.map(
                                                                (studentGradeBreakdown, index) => {
                                                                    return (
                                                                        <>
                                                                            <td>
                                                                                <span className="mr-1"> {studentGradeBreakdown.breakdownName ?
                                                                                    (studentGradeBreakdown.breakdownName !== "Obtained Score" ? studentGradeBreakdown.breakdownName : "") : null} </span>

                                                                                <span className="average-marks">({studentGradeBreakdown.breakdownCalculatedResult})</span>

                                                                                {studentGradeBreakdown?.gradeBookEvaluationMarksList?.length > 0 ?
                                                                                    <button
                                                                                        type="button"
                                                                                        title="Delete"
                                                                                        className="btn bg-c-blue-light"
                                                                                        data-toggle="modal"
                                                                                        data-target="#marksModal"
                                                                                        onClick={() => {
                                                                                            handleMarksPopUpState(studentGradeBreakdown)
                                                                                        }}
                                                                                    >
                                                                                        {(null == studentGradeBreakdown.obtainedScoreInfo ?
                                                                                            studentGradeBreakdown?.gradeBookEvaluationMarksList?.length !== 0 ? "  " + studentGradeBreakdown?.gradeBookEvaluationMarksList?.length + " Items" : ""
                                                                                            : ((null != studentGradeBreakdown.obtainedScoreInfo.obtainedLetterGrade ?
                                                                                                    " " + studentGradeBreakdown.obtainedScoreInfo.obtainedLetterGrade : "") + "(" + studentGradeBreakdown.obtainedScoreInfo?.gradePoint + ")" + " (" + (null != studentGradeBreakdown.obtainedScoreInfo?.obtainedScore ? studentGradeBreakdown.obtainedScoreInfo?.obtainedScore : "")
                                                                                                + "/" + (null != studentGradeBreakdown.obtainedScoreInfo?.fullScore ? studentGradeBreakdown.obtainedScoreInfo?.fullScore : "") + ")"))
                                                                                        }
                                                                                    </button>
                                                                                    : (null == studentGradeBreakdown.obtainedScoreInfo ?
                                                                                        studentGradeBreakdown?.gradeBookEvaluationMarksList?.length !== 0 ? "  " + studentGradeBreakdown?.gradeBookEvaluationMarksList?.length + " Items" : ""
                                                                                        : ((null != studentGradeBreakdown.obtainedScoreInfo.obtainedLetterGrade ?
                                                                                                " " + studentGradeBreakdown.obtainedScoreInfo.obtainedLetterGrade : "") + "(" + studentGradeBreakdown.obtainedScoreInfo?.gradePoint + ")" + " (" + (null != studentGradeBreakdown.obtainedScoreInfo?.obtainedScore ? studentGradeBreakdown.obtainedScoreInfo?.obtainedScore : "")
                                                                                            + "/" + (null != studentGradeBreakdown.obtainedScoreInfo?.fullScore ? studentGradeBreakdown.obtainedScoreInfo?.fullScore : "") + ")"))}

                                                                            </td>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </tr>
                                                    </>
                                                );
                                            }
                                        )
                                        : ""}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="marksModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="grading-modal modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {/*<div className="modal-header">*/}
                        {/*    <h5 className="modal-title">*/}
                        {/*        <b className="text-warning">&#9888;</b> Alert*/}
                        {/*    </h5>*/}
                        {/*    <button*/}
                        {/*        type="button"*/}
                        {/*        className="close"*/}
                        {/*        data-dismiss="modal"*/}
                        {/*        aria-label="Close">*/}
                        {/*        <span aria-hidden="true">&times;</span>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <div className="modal-body">
                            <div className="popup-header d-flex align-items-center justify-content-between">
                                <p>{breakdown?.breakdownName}</p>
                                <p>Total Marks : {breakdown?.breakdownFullScore}</p>
                            </div>

                            <table>
                                <thead>
                                <tr>
                                    {/*<th>#</th>*/}
                                    <th className="text-left">Item Name</th>
                                    <th>Marks</th>
                                </tr>
                                </thead>
                                <tbody>
                                {breakdown?.gradeBookEvaluationMarksList?.map((marks, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <td className="pl-2">{index + 1}.</td>
                                                <td className="text-left">{marks.evaluationName}</td>
                                            </td>

                                            <td>({marks.evaluationObtainedMarks ? marks.evaluationObtainedMarks : null} / {marks.evaluationFullMarks ? marks.evaluationFullMarks : null})</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>


                        </div>
                        <div className="footer-button">

                            <button
                                type="button"
                                className="btn btn-red-md"
                                data-dismiss="modal">
                                Ok
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="finalizeModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog grading-finalize-modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <svg width="44" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="22" cy="22" r="22" fill="#30B264"/>
                                    <path d="m12.572 19.905 7.333 8.38 13.619-13.618" stroke="#fff" stroke-width="5"
                                          stroke-linecap="round"/>
                                </svg>
                            </h5>

                        </div>
                        <div className="modal-body">Once finalized, you can no longer add/edit the assessment marks for this grade book. Are you sure to proceed?</div>
                        <div className="footer-button">
                            <button
                                onClick={() => handleFinalize()}
                                type="button"
                                className="btn btn-red-md"
                                data-dismiss="modal">
                                Finalize
                            </button>
                            <button
                                type="button"
                                className="btn btn-cancel"
                                data-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default GradeBook;
