import React, {useEffect, useState} from "react";
import * as AssessmentApi from "../../api/AssessmentApi";

import * as LinkFactory from "../../util/LinkFactory";
import * as Utils from "../../util/Utils";
import CommonLayout from "../layout/commonLayout";
import {useNavigate, useParams} from "react-router-dom";

function AssessmentListForEvaluation(props) {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const params = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [channel, setChannel] = useState({});
  const [assessmentList, setAssessmentList] = useState([]);

  useEffect(() => {
    AssessmentApi.getAssessmentListByChannelId(params.id).then(
        json => {
          setAssessmentList(json.data);
        }
    );
  }, []);

  const handleAssignmentCreatePage = () => {
    navigateTo(LinkFactory.assignmentNew + "/" + params.id);
  };

  const handleAssessmentRoute = assignment => {
    if (
      assignment.submitted === true ||
        Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"])
    )
      navigateTo(
          LinkFactory.assignmentSubmittedView + "/" + assignment.assignment.id
      );
    else if (Utils.checkRolePermission(["ROLE_USER"])) {
      navigateTo(
          LinkFactory.assignmentSubmit + "/" + assignment.assignment.id
      );
    }
  };
  const handleSubmit = values => {
    console.log(values);
    if (values.examType === "Assignment")
      navigateTo(
          LinkFactory.assignmentSubmittedView + "/" + values.assignment.id
      );
    else
      navigateTo(
          LinkFactory.assessmentMarksEntry +
          "/" +
          params.id +
          "/" +
          values.id
      );
  };

  return (
    <>
      <CommonLayout>
        <main>
          <div>
            <div className="card bg-light pt-3 pb-3 p-2">
              <div className="card-body">
                <div>
                  <table
                    className="table table-bordered mt-4"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead className="bg-light">
                      <tr>
                        <th scope="col">Assignment Type</th>
                        <th scope="col">Title</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assessmentList.length > 0
                        ? assessmentList.map((data, index) => {
                            return (
                              <tr>
                                <th scope="row">
                                  <p>{data.examType}</p>
                                </th>
                                <td>{data.title}</td>
                                <td className="text-right">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={() => handleSubmit(data)}
                                  >
                                    <i className="fa fa-eye"></i> Submit
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default AssessmentListForEvaluation;
