import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import CommonLayout from "../layout/commonLayout";
import {toast} from "react-toastify";
import TextFieldComponent from "../common/TextFieldComponent";
import SelectDropdown from "../common/SelectDropwodn";
import * as EmployeeApi from "../../api/EmployeeApi";
import * as EmployeeRoleApi from "../../api/EmployeeRoleApi";
import * as LinkFactory from "../../util/LinkFactory";
import {FILES_URL} from "../../util/LinkFactory";
import DatePicker from "react-datepicker";

import {useNavigate, useParams} from "react-router-dom";

function EmployeeEdit(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [image, setImage] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [employee, setEmployee] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        id: "",
        regId: "",
        dob: null,
        employeeRole: {},
        approveStatus: "",
        presentAddress: "",
        permanentAddress: "",
        bloodGroup: "",
        healthConcern: "",
        emergencyContact: "",
        emergencyContactPerson: "",
        photo: ""

    });
    const [bloodGroup, setBloodGroup] = useState([
        {bloodGroup: "A+"},
        {bloodGroup: "A-"},
        {bloodGroup: "B+"},
        {bloodGroup: "B-"},
        {bloodGroup: "O+"},
        {bloodGroup: "O-"},
        {bloodGroup: "AB+"},
        {bloodGroup: "AB-"}
    ]);
    const [employeeRoles, setEmployeeRoles] = useState([]);
    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });

    function getEmployeeROleList() {
        EmployeeRoleApi.getAll().then(json => {
            setEmployeeRoles(json.data);
        });
    }

    function getEmployeeById() {
        EmployeeApi.getEmployeeById(params.id)
            .then(json => {
                console.log(json);
                const dataSet = {
                    id: params.id,
                    firstName: json.data.firstName || "",
                    middleName: json.data.middleName || "",
                    lastName: json.data.lastName || "",
                    permanentAddress: json.data.permanentAddress || "",
                    presentAddress: json.data.presentAddress || "",
                    bloodGroup: {bloodGroup: json.data.bloodGroup} || "",
                    healthConcern: json.data.healthConcern || "",
                    emergencyContact: json.data.emergencyContact || "",
                    emergencyContactPerson: json.data.emergencyContactPerson || "",
                    phoneNo: json.data.phoneNo || "",
                    photo: json.data.photo || "",
                    email: json.data.user?.email || "",
                    dob: json.data.dob || "",
                    regId: json.data.regId || "",
                    employeeRole: json.data.employeeRole || {},
                    userId: json.data.user?.id,
                };
                setEmployee(dataSet);
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    useEffect(() => {
        getEmployeeROleList();
        getEmployeeById();
    }, []);

    const handleSubmit = values => {
        console.log(values);
        const dataSet = {
            id: params.id,
            firstName: values.firstName || "",
            middleName: values.middleName || "",
            lastName: values.lastName || "",
            permanentAddress: values.permanentAddress || "",
            presentAddress: values.presentAddress || "",
            bloodGroup: values.bloodGroup?.bloodGroup || "",
            healthConcern: values.healthConcern || "",
            emergencyContact: values.emergencyContact || "",
            emergencyContactPerson: values.emergencyContactPerson || "",
            phoneNo: values.phoneNo || "",
            email: values.email || "",
            dob: values.dob || "",
            regId: values.regId || "",
            employeeRole: values.employeeRole || {},
            photoAttachment: files[0]?.blob,
            photoAttachmentBlobType: files[0]?.type,
            userId: employee.userId
        };
        EmployeeApi.updateEmployee(dataSet).then(response => {
            notifyUpdate();
            navigateTo(LinkFactory.employeeList);
        });
    };
    let fileBase64s = [];
    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    const handleProfilePicInputChange = event => {
        fileBase64s = [];
        setFiles(fileBase64s);
        setSelectedFiles(event.target.files);
        Array.from(event.target.files).forEach(file => {
            fileToDataUri(file).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file.type, fileName: file.name});
            });
        });
        console.log(fileBase64s);
        setFiles(fileBase64s);
    };
    const DatePickerComponent = ({field, form: {touched, errors, setFieldValue}}) => {
        return (
            <div className="mr-2">
                <DatePicker
                    showTimeInput2
                    selected={Date.parse(field.value)}
                    placeholderText="Pick date"
                    onChange={value => setFieldValue(field.name, value)}
                    className="form-control btn-block w-100 overflow-auto "
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        );
    };

    return (
        <>
            <CommonLayout headerName={"Employee"}>
                <main>
                    <div>
                        <h3 className="mt-4">Employee Edit</h3>
                        <br/>
                        <div>
                                <div className="col-12 px-0 mb-3">
                                    {employee?.photo ? (
                                        <img
                                            style={{width: "200px", height: "auto"}}
                                            src={FILES_URL + employee?.photo}
                                        />
                                    ) : (
                                        "No photo available"
                                    )}
                                </div>
                                    <div className="col col-sm-12 col-md-6 px-0 mb-5 input-default-white">
                                        <input
                                            className="custom-file-input"
                                            id="file"
                                            name="file"
                                            type="file"
                                            multiple
                                            onChange={handleProfilePicInputChange}
                                        />
                                        <label className="custom-file-label"
                                               htmlFor="file">
                                            Choose file
                                        </label>
                                        <div className="submit-area"
                                             style={{marginTop: 20}}>
                                            {Array.from(selectedFiles).length > 0 ? Array.from(selectedFiles).map((data, index) => {
                                                return (
                                                    <p>{(index + 1) + ". " + data.name}</p>);
                                            }) : ""}
                                        </div>
                                    </div>
                                <Formik
                                    initialValues={employee}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {props => (
                                        <Form>
                                            <div className="row input-default-white">
                                                <div className="col-lg-4 col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            First Name{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Middle Name{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="middleName"
                                                            placeholder="Middle Name"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Last Name{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Email{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="email"
                                                            placeholder="Email"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Phone No{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="phoneNo"
                                                            placeholder="Phone No"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            {" "}
                                                            Date Of Birth{" "}
                                                        </label>
                                                        <Field
                                                            name="dob"
                                                            placeholder="Event Date"
                                                            component={DatePickerComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label className="form-label">
                                                        Employee Role
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        id="employeeRole"
                                                        name="employeeRole"
                                                        placeholder="Select Role"
                                                        fieldName={`employeeRole`}
                                                        options={employeeRoles || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            Permanent Address
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="permanentAddress"
                                                            placeholder="Permanent Address"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            Present Address
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="presentAddress"
                                                            placeholder="Present Address"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            Emergency Contact Person
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="emergencyContactPerson"
                                                            placeholder="Emergency Contact Person"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            Emergency Contact Number
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="emergencyContact"
                                                            placeholder="Emergency Contact Number"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            Blood Group
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="bloodGroup"
                                                            placeholder="Select Blood Group"
                                                            fieldName={"bloodGroup"}
                                                            options={bloodGroup || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label
                                                            className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                            Health Concern
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="healthConcern"
                                                            placeholder="Health Concern"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label className="font-weight-medium text-gray-25 text-lg-left mb-2">Employee ID</label>
                                                        <Field
                                                            type="text"
                                                            name="regId"
                                                            placeholder="Registration ID"
                                                            component={TextFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-right w-100">
                                                    <button type="submit" className="btn btn-dark radius-25 px-4 mt-4">
                                                        <i className="fa fa-chevron-circle-right mr-2"/>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EmployeeEdit;
