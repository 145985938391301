import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveSectionSetup = sectionSetup => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/section-setup/",
        sectionSetup
    );
};

export const updateSectionSetup = sectionSetup => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/section-setup/",
        sectionSetup
    );
};
export const getSectionSetupById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/section-setup/" + id);
};
export const deleteSectionSetupById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/section-setup/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/section-setups");
};
