import React, {useState} from "react";
import * as SubjectApi from "../../api/SubjectApi";
import {Field, Form, Formik} from "formik";

import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function SubjectNew(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [subject, setSubject] = useState({
        name: "",
        id: ""
    });

    const handleSubmit = data => {
        console.log(data);
        SubjectApi.saveSubject(data).then(data => {
            navigateTo(LinkFactory.subject);
        });
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Subject New</h1>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={subject}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {props => (
                                    <Form>
                                        <div className="table-responsive">
                                            <table
                                                className="table table-bordered"
                                                id="dataTable"
                                                width="100%"
                                                cellSpacing="0"
                                            >
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            placeholder="name"
                                                            component={TextField}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="12">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-lg btn-primary float-right"
                                                        >
                                                            Save
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default SubjectNew;
