import React, {useEffect, useState} from "react";
import {Field, FieldArray, Form, Formik} from "formik";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import SelectDropdown from "../../common/SelectDropwodn";
import * as UserApi from "../../../api/UserApi";
import * as UMApi from "../../../api/UMApi";

function UMUserRoleSetupNew(props) {
    const [userRole, setUserRole] = useState({
        user: "",
        umRoleDtoList: [""],
    });
    const [authorities, setAuthorities] = useState([]);
    const [userList, setUserList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const notifySuccess = () => toast.success("Role Setup Created!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    const handleSubmit = values => {
        console.log(values);
        // const privs = [];
        // values.privileges.forEach(value => {
        //     privs.push(value.privilege);
        // })
        // const roleSetup = {
        //     roleName: values.roleName,
        //     privileges: privs
        // }
        // console.log(roleSetup);
        UMApi.saveUserRoleSetup(values).then(json => {
            console.log(json.data);
            notifySuccess();
        })
    };

    function getAllAuthorities() {
        var privs = [];
        UserApi.getAllAuthorities().then(json => {
            json.data.forEach((r) => {
                console.log(r);
                privs.push({privilege: r});
            })
            setAuthorities(privs);
        });
    }

    function getAllUserList() {
        UserApi.getAll().then(json => {
            setUserList(json.data);
        });
    }

    function getAllRoleList() {
        UMApi.getAllRole().then(json => {
            setRoleList(json.data);
        });
    }

    function handleDeleteList(id) {

    }

    useEffect(() => {
        getAllAuthorities();
        getAllUserList();
        getAllRoleList();
    }, []);

    return (
        <>
            <CommonLayout headerName="User Role Setup">
                <main>
                    <div>
                        <h3 className="mt-4">User Role Setup</h3>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={userRole}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}>
                                {({props, values, setFieldValue}) => (
                                    <Form>
                                        <div className="form-row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>User</label>
                                                    <Field
                                                        type="text"
                                                        name="user"
                                                        placeholder="Select User"
                                                        fieldName={`firstName`}
                                                        options={userList || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <FieldArray
                                            name="umRoleDtoList"
                                            render={arrayHelpers => (
                                                <div className="w-100">
                                                    {values.umRoleDtoList && values.umRoleDtoList.length > 0
                                                        ? values.umRoleDtoList.map((role, index) => (
                                                            <span key={index} className="w-100">
                                                              <div className="form-row">
                                                                <div className="col-6">
                                                                  <div className="form-group">
                                                                    <label>Role</label>
                                                                    <Field
                                                                        type="text"
                                                                        id={`umRoleDtoList[${index}]`}
                                                                        name={`umRoleDtoList[${index}]`}
                                                                        placeholder="Select Role"
                                                                        fieldName={`roleName`}
                                                                        options={roleList || []}
                                                                        component={SelectDropdown}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="form-row">
                                                                <div className="col-12">
                                                                  <div className="form-group">
                                                                    <button
                                                                        className={"btn btn-outline-danger radius-25"}
                                                                        type="button"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(index);
                                                                            handleDeleteList(role);
                                                                        }}>
                                                                      <i className="fa fa-trash mr-2"/>
                                                                      Delete
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </span>
                                                        ))
                                                        : ""}
                                                    <div className="form-row">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <button
                                                                    type="button"
                                                                    className={"btn btn-outline-dark radius-25"}
                                                                    onClick={() =>
                                                                        arrayHelpers.push("")
                                                                    }>
                                                                    <i className="fa fa-plus-circle mr-2"/>
                                                                    Add more
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <button type="submit"
                                                className="btn btn-lg btn-success">
                                            Submit
                                        </button>
                                    </Form>

                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default UMUserRoleSetupNew;
