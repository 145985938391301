import React, {useEffect, useState} from "react";
import * as GradingEvaluationEntryApi from "../../api/GradingEvaluationEntryApi";
import * as GradingEvaluationApi from "../../api/GradingEvaluationApi";
import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function AssessmentMarksEntry(props) {
  const params = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [evaluation, setEvaluation] = useState({});
  const [memberList, setMemberList] = useState([]);
  const [studentAndMarks, setStudentAndMarks] = useState([]);

  useEffect(() => {
    // ChannelApi.getChannelMemberListByChannelId(params.id).then((json) => {
    //     setMemberList(json.data);
    // })

    GradingEvaluationApi.getGradingEvaluationById(
        params.assessmentId
    ).then(json => {
      setEvaluation(json.data);
      GradingEvaluationEntryApi.gettingMarksEntryByEvaluationId(
        json.data.id
      ).then(json => {
        setMemberList(json.data);
        const studentAndMarksTemp = [...studentAndMarks];
        json.data.forEach(v => {
          if (v.obtainedScore !== null) {
            let stdAndMarks = {
              student: v.studentUser,
              marks: v.obtainedScore
            };
            studentAndMarksTemp.push(stdAndMarks);
          }
        });
        setStudentAndMarks(studentAndMarksTemp);
      });
    });
  }, []);

  const handleSubmit = () => {
    studentAndMarks.forEach(studentAndMark => {
      if (studentAndMark.marks !== "" && studentAndMark.marks !== null) {
        let gradingEvaluationEntry = {
          gradingEvaluation: evaluation,
          studentUser: studentAndMark.student,
          obtainedScore: studentAndMark.marks
        };
        GradingEvaluationEntryApi.saveGradingEvaluationEntry(
          gradingEvaluationEntry
        ).then(res => {
          GradingEvaluationEntryApi.gettingMarksEntryByEvaluationId(
            evaluation.id
          ).then(json => {
            setMemberList(json.data);
          });
        });
      }
    });
  };

  const handleScoreEntry = (value, data) => {
    let stdAndMarks = {
      student: data.studentUser,
      marks: value
    };
    const studentAndMarksTemp = [...studentAndMarks];
    if (
      studentAndMarksTemp.findIndex(
        studentAndMarksTemp =>
          studentAndMarksTemp.student === stdAndMarks.student
      ) === -1
    ) {
      studentAndMarksTemp.push(stdAndMarks);
    } else
      studentAndMarksTemp[
        studentAndMarksTemp.findIndex(
          studentAndMarksTemp =>
            studentAndMarksTemp.student === stdAndMarks.student
        )
      ].marks = stdAndMarks.marks;
    setStudentAndMarks(studentAndMarksTemp);

    const memberListTemp = [...memberList];
    memberListTemp.forEach(member => {
      if (member.studentUser === data.studentUser) {
        member.obtainedScore = value;
        setMemberList(memberListTemp);
      }
    });
  };

  return (
    <>
      <CommonLayout>
        <main>
          <div>
            <br />
            <div className="card bg-light pt-3 pb-3 p-2">
              <div className="card-body">
                <table
                  className="table table-bordered mt-4"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead className="bg-light">
                    <tr>
                      <th scope="col">First Name</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberList.length > 0
                      ? memberList.map((data, index) => {
                          return (
                            <tr>
                              <td>{data.studentUser.firstName}</td>
                              <td>
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Enter here..."
                                  name={`score-${index}`}
                                  value={data.obtainedScore}
                                  onChange={event => {
                                    handleScoreEntry(event.target.value, data);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  onClick={() => handleSubmit()}
                >
                  <i className="fa fa-eye" /> Submit
                </button>
              </div>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default AssessmentMarksEntry;
