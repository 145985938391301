import React from "react";

export default function FormTitle({children, color}) {
    return (
        <label
            className="font-weight-medium text-gray-25 text-lg-left mb-2">
            {" "}
            {children}
        </label>
    );
}
