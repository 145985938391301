import React, { useCallback } from "react";
import { Button } from "@material-ui/core";

const PageError = ({
  title = "Error!",
  subTitle = "Sorry, something went wrong.",
  onCTAClick,
  ctaButtonText = "Try Again"
}) => {
  const handleCTAClick = useCallback(() => {
    if (onCTAClick) {
      onCTAClick();
    } else {
      window.location.reload();
    }
  }, [onCTAClick]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
        width: "100%",
        padding: "2rem",
        margin: "auto"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2rem"
        }}
      >
        <img
          style={{ width: "100px", height: "auto" }}
          src={"img/erroImage.png"}
          alt="error "
        />
      </div>
      <div
        style={{
          marginTop: 10,
          fontWeight: "bold",
          fontSize: "24px",
          lineHeight: "30px",
          textAlign: "center",
          letterSpacing: "-0.015em",
          color: "#220022",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {title}
      </div>
      <div
        style={{
          paddingBottom: 20,
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          lineHeight: "24px",
          textAlign: "center",
          letterSpacing: "-0.015em",
          color: "#202020"
        }}
      >
        {subTitle}
      </div>
      <Button onClick={handleCTAClick}>{ctaButtonText}</Button>
    </div>
  );
};

export default PageError;
