import React, {useEffect, useState} from "react";
import * as StatesApi from "../../api/StatesApi";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function StatesView(props) {
    const params = useParams();
    const [states, setStates] = useState([]);

    useEffect(() => {
        StatesApi.getStatesById(params.id)
            .then(json => {
                console.log(json);
                setStates(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">States View</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-pencil-square-o mr-2"/>
                                Edit here
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-light table-shadow mt-4">
                                    <table className="table table-light"
                                           id="dataTable"
                                           width="100%"
                                           cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Country</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{states.id}</td>
                                            <td>{states.states}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>

            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Id</th>
                    <td>{states.id}</td>
                </tr>
                <tr>
                    <th scope="col">States</th>
                    <td>{states.states}</td>
                </tr>
                </thead>
            </table>
        </>
    );
}

export default StatesView;
