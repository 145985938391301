import React, {useEffect, useState} from "react";
import * as StatesApi from "../../api/StatesApi";

import * as LinkFactory from "../../util/LinkFactory";

import Loader from "../common/Loader";
import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function StatesList() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [states, setStates] = useState([]);
    const [id, setId] = useState(null);
    useEffect(() => {
        StatesApi.getAll()
            .then(json => {
                console.log(json);
                setStates(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleEdit = data => {
        navigateTo(LinkFactory.states + "/" + data.id);
    };
    const handleView = data => {
        navigateTo(LinkFactory.statesView + "/" + data.id);
    };
    const handleNew = data => {
        navigateTo(LinkFactory.statesNew);
    };
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        StatesApi.deleteStatesById(id);

        /// then set id as null again
        setId(null);
        window.location.reload();
    };
    return (
        <>
            <CommonLayout id="layoutSidenav_content">
                <main>
                    <div>
                        <h1 className="mt-4">States</h1>
                        <ol className="breadcrumb mb-4">
                            <li className="breadcrumb-item active">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleNew()}
                                >
                                    Create new State
                                </button>
                            </li>
                        </ol>
                        <div className="card-body">
                            <div className="table-responsive table-light table-shadow mt-4">
                                <table className="table  table-light"
                                       id="dataTable"
                                       width="100%"
                                       cellSpacing="0"
                                >
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>States</th>
                                        <th>Country</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {states.length > 0 ? (
                                        states.map((data, index) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{data.id}</th>
                                                    <td>{data.states}</td>
                                                    <td>{data.country?.country}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-info"
                                                            onClick={() => handleView(data)}
                                                        >
                                                            <i className="fa fa-eye"/> View
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning"
                                                            onClick={() => handleEdit(data)}
                                                        >
                                                            <i className="fa fa-edit"/>
                                                            Edit
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            data-toggle="modal"
                                                            data-target="#deleteModal"
                                                            onClick={() => handleDelete(data)}
                                                        >
                                                            <i className="fa fa-trash"/> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <Loader/>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <b className="text-warning">&#9888;</b> Alert
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure you want to delete ?</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                            >
                                <b className="text-light">&#10094;</b> No, Go back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={deleteData}
                            >
                                <b className="text-light">&#128465;</b> Confirm Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StatesList;
