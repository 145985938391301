import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAM2muXrMmr9-bhMVDYRUtSv-GZW7r1SzU",
    authDomain: "edooket-push-notification.firebaseapp.com",
    projectId: "edooket-push-notification",
    storageBucket: "edooket-push-notification.appspot.com",
    messagingSenderId: "57288833218",
    appId: "1:57288833218:web:d893189d64dbb10941e35b",
    measurementId: "G-7WVPRW94YL"
};

const vapidKey = 'BAX71elK5ZOFICBnKggoIYKx4Q8tgI6fQw-zbMi2FtS6uT_xuqg04PZAv3QNrmTTKJMpZ7baoCE33UnSbRqdwm8';

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = () => {
    return getToken(messaging, {vapidKey})
        .then(currentToken => {
            if (currentToken) {
                console.log(currentToken);
                return currentToken;
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch(err => {
            console.log('An error occurred while retrieving token. ', err);
        });
};
