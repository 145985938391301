import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";


export const getAllByFacultyId = (facultyId) => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/departments/" + facultyId);
};
export const saveDepartment = faculty => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/department/", faculty);
};
export const updateDepartment = faculty => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/department/", faculty);
};
export const deleteDepartmentById = departmentId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/department/" + departmentId);
};
export const getDepartment = departmentId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/department/" + departmentId);
};
export const getAllDepartments = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/departments");
};

export const searchDepartment = (param) => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/departments/search", param);
};
