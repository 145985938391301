import React, {useEffect, useRef, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import Agent from "../../api/Agent";
import * as Utils from "../../util/Utils";
import FormActions from "../../action/FormActions";
import ListErrors from "../common/ListErrors";
import ValidationUtils from "../../util/ValidationUtils";
import {Error} from "../CommonLayouts/Common";
import * as LinkFactory from "../../util/LinkFactory";
import AuthActions from "../../action/AuthActions";

import Loader from "../common/Loader";
import {Link, useNavigate} from "react-router-dom";
import {ENVIRONMENT_NAME, PARENT_ID} from "../../constants/Constants";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {fetchChannelsApi} from "../../storeManager/channel/middleware/ChannelMiddleware";
import {
    fetchNotificationCounter
} from "../../storeManager/NotificationCounter/middleware/NotificationCounterMiddleware";
import instance from "../../api/InterceptorApi";

toast.configure()


const validateForm = (form, name) => {
    let errors = [];

    ValidationUtils.isNotBlank(name, errors, form, new Map([["lgEmail", "Field is required."], ["passwd", "Field is required."]]));

    return errors;
};


export default function Login() {

    let history = useNavigate();
    const navigate = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const dispatch = useDispatch();
    const {onChangeField, onValidation, onSubmit, passShowHide, setPassShowHide, form, errorMessage, setErrorMessage, isChecked, setIsChecked, handleOnChange} = LoginFormHooks();
    const ENV_NAME = ENVIRONMENT_NAME;
    const changeField = ev => onChangeField(ev.target.name, ev.target.value);
    const validate = (form, ev) => onValidation(form, ev.target.name);
    // const [errorMessage, setErrorMessage] = useState("All Is well");
    const changeFieldAndValidate = (form, ev) => {
        onChangeField(ev.target.name, ev.target.value);
        onValidation(form, ev.target.name);
    };

    const submitForm = form => ev => {
        ev.preventDefault();
        onSubmit(form);
    };

    const [showPassword, setShowPassword] = useState(false);

    const passwordInputRef = useRef(null);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    async function handleRememberMe() {
        console.log("handleRememberMe");
        const response = await instance.post("/auth/validate-remember-me-by-refresh-token", {
            refreshToken: Utils.getRefreshTokenFromLocalStorage(),
        });
        Utils.setAuthTokenCookie(response?.data?.accessToken);
        dispatch(AuthActions.addUser(response?.data.user));
        dispatch(fetchChannelsApi());
        dispatch(fetchNotificationCounter());
        if (null == response?.data.channel) {
            navigate(LinkFactory.home);
        }
        response?.data.user.authorities.forEach(authority => {
            if ((authority.name === "ROLE_STUDENT" || authority.name === "ROLE_ADMIN" || authority.name === "ROLE_EMPLOYEE" || authority.name === "ROLE_FACULTY" || authority.name === "ROLE_EXAM_CONTROLLER") && authority.name !== 'ROLE_PARENT') {
                if (null != response?.data.channel) {
                    navigate(LinkFactory.channelBoard + "/" + response?.data.channel.id);
                }
            } else if (authority.name === "ROLE_PARENT") {
                localStorage.setItem(PARENT_ID, JSON.stringify(response?.data.parentId));
            }
        });

    }

    useEffect(() => {
        showHide();
        if (Utils.isLoggedIn()) {
            dispatch(fetchChannelsApi());
            if (localStorage.getItem("channelId"))
                navigateTo(LinkFactory.channelBoard + "/" + localStorage.getItem("channelId"));
            else navigateTo(LinkFactory.home);
        } else if (Utils.isRememberMeChecked()) {
            console.log("&&&&&& remember me checked");
            handleRememberMe().then(() => {
            });
        }
        window.scrollTo({top: 0, left: 0});
    }, []);

    function showHide() {
        if (passShowHide === "password") {
            setPassShowHide("text");
        } else {
            setPassShowHide("password");
        }
        setTimeout(() => {
            setPassShowHide("password");
        }, 3000);
    }

    return (<div>
        <div>
            <div className="login-page bg-default d-flex">
                <div id="reg_layout">
                    <div id="reg_content">
                            <main>
                                <div id="registrations">
                                    <div id="login">
                                        <div className="container">
                                            <div className="brand">
                                                <div>
                                                    <img src="img/iungo-logo 2.png" alt="iungo logo" className=" "/>
                                                </div>


                                                <h2>WELCOME BACK</h2>
                                                <p>Log in to your account</p>
                                            </div>
                                            {form.loading ? <Loader/> : <React.Fragment/>}
                                            <div className="card-default">
                                                <div className="input-reg">
                                                    <ListErrors errors={form.errors}/>
                                                    <form onSubmit={submitForm(form)}>
                                                        <div className="block-group">
                                                            <div className="block">
                                                                <label className="form-label">
                                                                    Email
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Enter your email"
                                                                    id="lgEmail"
                                                                    autoComplete="email"
                                                                    name={"lgEmail"}
                                                                    value={form.lgEmail}
                                                                    onChange={ev => changeFieldAndValidate(form, ev)}
                                                                    onFocus={changeField}
                                                                    onBlur={ev => validate(form, ev)}
                                                                />
                                                                <Error field={"lgEmail"} errors={form.errors}/>
                                                            </div>
                                                        </div>

                                                        <div className="block-group">
                                                            <div className="block">
                                                                <label className="form-label">Password</label>
                                                                <div className="password-box">
                                                                    <input
                                                                        className="form-control"
                                                                        type={showPassword ? "text" : "password"}
                                                                        placeholder="Enter password"
                                                                        id="passwd"
                                                                        name="passwd"
                                                                        autoComplete="new-password"
                                                                        value={form.passwd}
                                                                        onChange={ev => changeFieldAndValidate(form, ev)}
                                                                        onFocus={changeField}
                                                                        onBlur={ev => validate(form, ev)}
                                                                        ref={passwordInputRef}
                                                                    />

                                                                    <svg
                                                                        className="password-icon"
                                                                        width="13"
                                                                        height="9"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        <path
                                                                            d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                            fill="#878787"/>
                                                                    </svg>
                                                                </div>
                                                                <Error field="passwd" errors={form.errors}/>
                                                            </div>
                                                        </div>
                                                        <div className="block-group">
                                                            <div className="block forget-pass-btn m-0">
                                                                <div className="remember-me">
                                                                    <input type="checkbox" id="remember_me"
                                                                           name="remember_me"
                                                                           value={form.remember_me}
                                                                           checked={isChecked}
                                                                           onChange={handleOnChange}

                                                                    />
                                                                    <label htmlFor="remember_me"> Remember me</label>
                                                                </div>

                                                                <Link className="forgot-password"
                                                                      to={LinkFactory.findYourAccount}>
                                                                    Forgot password?
                                                                </Link>
                                                            </div>
                                                        </div>


                                                        <div className="block-group">
                                                            <div className="block-50 m-auto">
                                                                <button
                                                                    type="submit"
                                                                    className="btn-deep-blue-lg box-btn"
                                                                    disabled={form?.errors?.length > 0 || validateForm(form).length > 0}>
                                                                    <p>Sign in</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <p className="wrong_credentials"> {errorMessage}</p>
                                                    </form>

                                                </div>
                                            </div>

                                            <div className="footer-instruction">
                                                <p>or</p>
                                                <div className="d-inline-block">
                                                    <button className="googleloginbutton">
                                                        <img src="img/google.png"/>
                                                        <div>
                                                            Continue with Google
                                                        </div>
                                                    </button>
                                                    <button className="facebookloginbutton mt-3">
                                                        <img src="img/facebook-icon 1.png"/>
                                                        <div>Continue with Facebook</div>
                                                    </button>
                                                    <p>Don't have account? <a onClick={()=>navigateTo(LinkFactory.userRegistration)}><strong>Sign up</strong></a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                    </div>
                </div>
                <div className="login-image">
                        <div className="img-box">
                                <img src="img/Right-Side.png" alt="man with basketball" className="basketballthrowing"/>
                        </div>
                </div>
            </div>
        </div>
    </div>);
}

function LoginFormHooks() {
    const [firebaseToken, setFirebaseToken] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const form = useSelector(state => state.form)
    const [passShowHide, setPassShowHide] = useState("password");
    const [errorMessage, setErrorMessage] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const onValidation = (form, name) => {
    };
    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };
    const onChangeField = (name, value) => dispatch(FormActions.setFieldRegister(name, value));
    const onSubmitFunc = dispatch => ({
        onSubmit: form => {
            Utils.sendRequest(dispatch, validateForm(form), () => {
                setErrorMessage("");
                Agent.Auth.login(form).then(function (json) {
                    console.log(json);
                    let user = json.data.user;
                    user.authorities = ['ROLE_USER']
                    if (!Utils.errorOrAlertExists(json, dispatch, true)) {
                        Utils.addUserToLocalStorage(user);
                        Utils.setAuthTokenCookie(json.response.headers.map.authorization);
                        dispatch(AuthActions.addUser(user));
                        const sportsTypeId = user.sportsType &&  user.sportsType.id ? user.sportsType.id : 1;
                        navigate(LinkFactory.channelBoard);
                    }
                });
            });
        },
    });

    const onSubmit = form => onSubmitFunc(dispatch).onSubmit(form);
    return {onChangeField, onValidation, onSubmit, navigate, passShowHide, setPassShowHide, form, errorMessage, setErrorMessage, isChecked, setIsChecked, handleOnChange};
}
