import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveEmployee = employee => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/employee/", employee);
};

export const updateEmployee = employee => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/employee/", employee);
};
export const getEmployeeById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/employee/" + id);
};
export const getEmployeeByUserId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/employee/by-user-id/" + id);
};
export const deleteEmployeeById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/employee/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/employee");
};
export const employeeSearchForTypeahead = query => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/employee/search/" + query);
};
export const channelModeratorMemberUserSearch = query => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel-moderator-member-user/search/" + query);
};
export const channelModeratorMemberUserByChannelIdSearch = (query, id) => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/channel-moderator-member-user-channel/search/" + query + "/" + id);
};
export const registerEmployee = student => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/employee-register/",
        student
    );
};
export const uploadEmployeeProfilePicture = (data) => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/employee/upload-profile-picture", data);
}

export const employeeRegistrationXlsx = (file) => {
    const formData = new FormData();
    formData.append('file',file)
    return  AxiosAgent.requests.postMultipart(API_MAIN_ROOT + `/employee/upload-xlsx`, formData);
}

export const searchAllEmployee = searchEmployee => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/employee/search", searchEmployee);
};

export const getBulkEmployeeExcel = () => {
    return AxiosAgent.requests.getV2(API_MAIN_ROOT + "/employee/bulk-employee-excel");
};
