import {FETCH_CHANNELS_BEGIN, FETCH_CHANNELS_FAILURE, FETCH_CHANNELS_SUCCESS} from "../action/channelAction";

const initialState = {
    channels: null,
    loading: false,
    error: null
};

export default function ChannelReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CHANNELS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_CHANNELS_SUCCESS:
            return {
                ...state,
                loading: false,
                channels: action.payload.channels
            };

        case FETCH_CHANNELS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                channels: null
            };

        default:
            return state;
    }
}
