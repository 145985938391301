import React, {useEffect, useState} from "react";
import Loader from "../common/Loader";
import * as AssignmentApi from "../../api/AssignmentApi";
import {Field, Form, Formik} from "formik";
import * as AssignmentTaskSubmitApi from "../../api/AssignmentTaskSubmitApi";
import {useDispatch} from "react-redux";
import MyModal from "../common/MyModal";
import * as Utils from "../../util/Utils";
import CommonLayout from "../layout/commonLayout";
import SingleAttachmentComponent from "../ChannelClient/Components/SingleAttachmentComponent";
import {useNavigate, useParams} from "react-router-dom";
import {convertUTCDateToLocalDate} from "../../util/DateUtils";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as ChannelApi from "../../api/ChannelApi";
import Swal from "sweetalert2";
import MarkdownEditor from "../MarkdownEditor";
import TextFieldComponent from "../common/TextFieldComponent";
import FormTitle from "../Typhography/formTitle";
import {toast} from "react-toastify";

const initialComment = {task: null, comment: "", file: null, taskIndex: null};
const editCommentsModel = {id: null, comments: ""};

function AssignmentTaskSubmittedView(props) {
    const params = useParams();
    const [assignmentTasks, setAssignmentTasks] = useState(null);
    const [comment, setComment] = useState(initialComment);
    const [user, setUser] = useState({});
    const dispatch = useDispatch();
    const [loaders, setLoaders] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedCommentIdToDelete, setSelectedCommentIdToDelete] = useState(null);
    const [showCommentEditModal, setShowCommentEditModal] = useState(false);
    const [selectedCommentToEdit, setSelectedCommentToEdit] = useState(editCommentsModel);
    const [score, setScore] = useState(null);
    const [showMarkEntryModal, setShowMarkEntryModal] = useState(false);
    const [selectedTaskForMarking, setSelectedTaskForMarking] = useState(null);
    const [selectedTaskIndexForMarking, setSelectedTaskIndexForMarking] = useState(null);
    const [editorResetValue, setEditorResetValue] = useState(null);

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }
    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        getAssignmentSubmitList(params.id);
    }, []);

    useEffect(() => {
        console.log("useffect for loader");
    }, [loaders]);

    const submitMarking = (task, index) => {
        console.log(task);
        setShowMarkEntryModal(true);
        setSelectedTaskForMarking(task);
        setSelectedTaskIndexForMarking(index);
        // AssignmentTaskSubmitApi.saveMarksByAssignmentTaskSubmitId(data.task.id, data.mark).then((response) => {
        //     console.log(response.data);
        // });
        // dispatch(ModalActions.showModalWithoutFooter(null, <AssignmentTaskScoreEntryPopup taskId={id}/>, {className: "modal-resend-email"}));
    };

    function getAssignmentSubmitList(id){
        if (id){
            AssignmentApi.getAssignmentSubmitListByTaskId(id)
                .then(response => {
                    setAssignmentTasks(response.data);
                    let x = [];
                    for (let i = 0; i < response.data.length; i++) {
                        x.push(false);
                    }
                    setLoaders(x);
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
    }

    const submitComment = (data, onSubmitProps) => {
        setEditorResetValue(null)
        console.log(comment);
        console.log("on click submit");
        console.log(data);
        let x = loaders;
        x[comment.taskIndex] = true;
        setLoaders(x);

        let assignmentTaskCommentSubmitDTO = {
            comments: data.comment, assignmentTaskSubmitAttachments: comment.task.assignmentTaskSubmitAttachments
        };
        AssignmentTaskSubmitApi.postCommentInAssignmentTaskSubmit(assignmentTaskCommentSubmitDTO).then(response => {
            setEditorResetValue("<p></p>\n")
            AssignmentApi.getAssignmentSubmitListByTaskId(params.id)
                .then(response => {
                    setAssignmentTasks(response.data);
                    let x = loaders;
                    x[comment.taskIndex] = false;
                    console.log(x);
                    setLoaders(x);
                })
                .catch(errors => {
                    console.log(errors);
                });
        });

    };

    const submitCommentState = data => {
        console.log(data);
        setComment(data);
    };

    function handleDeleteComment() {
        setShowDeleteModal(false);
        ChannelApi.deletePostById(selectedCommentIdToDelete).then((resp) => {
            showAlert('Post Deleted', 'success');
            // updatePostListAfterHideOrDelete();
        })
    }

    const showAlert = (title, type) => {
        Swal.fire({
            position: 'center',
            icon: type,
            title: title,
            showConfirmButton: true,
            confirmButtonText: 'Ok'
        })
    }

    const onClickDeleteComment = post => {
        // setSelectedPostId(post.groupMessageId);
        setShowDeleteModal(true)
    };

    function handleDeletePost() {
        // setShowDeleteModal(false);
        // ChannelApi.deletePostById(selectedPostId).then((resp) => {
        //     showAlert('Post Deleted');
        //     updatePostListAfterHideOrDelete();
        // })
    }


    function handleEditComment(data) {
        console.log(data);
        setShowCommentEditModal(true);
        setSelectedCommentToEdit(data);
    }

    function handleEditCommentConfirm() {
        // setShowDeleteModal(false);
        // ChannelApi.deletePostById(selectedPostId).then((resp) => {
        //     showAlert('Post Deleted');
        //     updatePostListAfterHideOrDelete();
        // })
    }

    const submitMark = (formData) => {
        if (!formData.score || !selectedTaskForMarking?.assignmentTaskSubmitAttachments?.assignmentTask?.score) {
            return
        } else if (+formData.score < 0 || +formData.score > +selectedTaskForMarking.assignmentTaskSubmitAttachments.assignmentTask.score) {
            toast.warning("Obtained score cannot be less than zero (0) or greater than the total marks.", {position: toast.POSITION.TOP_RIGHT})
            return;
        }

        AssignmentTaskSubmitApi.saveMarksByAssignmentTaskSubmitId(
            selectedTaskForMarking?.assignmentTaskSubmitAttachments?.id,
            formData.score
        ).then(response => {
            setShowMarkEntryModal(false);
            updateObtainedMark(formData.score)
            showAlert('Marks updated', 'success')
        });
    };

    function updateObtainedMark(obtainedMark){
        let taskList = [...assignmentTasks];
        taskList[selectedTaskIndexForMarking].assignmentTaskSubmitAttachments.marksObtain = obtainedMark;
        setAssignmentTasks([...taskList]);
    }

    function AssignmentCommentDeleteModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="global-repository">
                <Modal.Body>
                    <div className="modal-header mt-0">
                        <div className="circle-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                <path fill="#D81414"
                                      d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                            </svg>

                        </div>
                        <div className="modal-body pt-4 pb-0 text-center">Are you sure, you want to delete ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="delete-button">
                        <Button variant="success" onClick={()=>handleDeletePost()}>Delete</Button>
                    </div>
                    <div>
                        <Button onClick={props.onHide}>Cancel</Button>
                    </div>

                </Modal.Footer>
            </Modal>
        );
    }

    function AssignmentCommentEditModal(props) {
        return (
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="global-repository">
                <Modal.Body>
                    <Formik
                        initialValues={selectedCommentToEdit}
                        enableReinitialize={true}
                        onSubmit={submitComment}>
                        {({setFieldValue}) => (
                            <Form>
                                <div className="user-comment">
                                    <div className="add-comment-area">
                                        <div className="input-default">
                                            <MarkdownEditor
                                                name={`comments`}
                                                value={selectedCommentToEdit.comments}
                                                cbValue={value => {setFieldValue("comments", value);}}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </Form>)}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <div className="">
                        <Button variant="success" onClick={()=>handleEditCommentConfirm()}>Update</Button>
                    </div>
                    <div>
                        <Button onClick={props.onHide}>Cancel</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    function AssignmentMarkEntryModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="global-repository">
                <Modal.Body>
                    <Formik
                        initialValues={{score:null}}
                        enableReinitialize={true}
                        onSubmit={submitMark}>
                        {({setFieldValue}) => (
                            <Form>
                                <div className="user-comment">
                                    <div className="add-comment-area">
                                        <div className="input-default">
                                            <FormTitle>Obtained Marks</FormTitle>
                                            <Field
                                                name="score"
                                                placeholder="Score"
                                                type="number"
                                                component={TextFieldComponent}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group mt-2">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                    <div className="form-group mt-2">
                                        <button onClick={props.onHide} type="button" className="btn btn-secondary ml-2">
                                            {" "}
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Form>)}
                    </Formik>
                </Modal.Body>
            </Modal>
        );
    }

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <AssignmentCommentDeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
            />

            <AssignmentCommentEditModal
                show={showCommentEditModal}
                onHide={() => setShowCommentEditModal(false)}
            />

            <AssignmentMarkEntryModal
                show={showMarkEntryModal}
                onHide={() => setShowMarkEntryModal(false)}
            />

            <CommonLayout headerName={Utils.getCurrentChannelName()}>
            <MyModal/>
            <main>
                <div>
                    <div className="card-default bg-c-light-grey container-responsive">
                        <div className="m-2">
                            <div className="card-header bg-c-light-grey">
                                <a onClick={goBack} className="pl-0 back-button">
                                    <i className="fa fa-chevron-left pr-3"> </i> </a>
                            </div>
                            <div className="submission-search ">

                                <div className="submission">
                                    <h4>
                                        Submissions of : {assignmentTasks && assignmentTasks[0]?.assignmentTaskSubmitAttachments?.assignmentTask?.assignment?.name}
                                    </h4>
                                    <h5>Task/Question : <span dangerouslySetInnerHTML={{ __html: assignmentTasks && assignmentTasks[0]?.assignmentTaskSubmitAttachments?.assignmentTask?.name}}></span></h5>
                                </div>
                                <div className="search-sort">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"> </span>
                                        <input type="text" className="form-control" placeholder="Search"/>
                                    </div>
                                    <div className="sort-by">
                                        <button className="sort-by-button">
                                            <p>Sort by</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13"
                                                 fill="none">
                                                <path stroke="#475467" stroke-linecap="round"
                                                      stroke-linejoin="round" stroke-width="2"
                                                      d="M3.75 6.5h9.5M1.375 1.75h14.25m-9.5 9.5h4.75"/>
                                            </svg>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>1</li>
                                            <li>2</li>
                                            <li>3</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h6 className="my-3">List of Submitted Answer</h6>
                            </div>


                            <div id="assigmentTaskSubmitView">

                                {assignmentTasks && assignmentTasks.length > 0 ? assignmentTasks.map((task, index) => {
                                    return (<div className="card-default table-bordered table-shadow ans-card w-100">
                                        <div className=" d-flex p-2 justify-content-between">
                                            <div className="left-block ">
                                                <div className="heading">
                                                    <div className="post-by">
                                                        <div className="avatar">
                                                            <img
                                                                src={process.env.REACT_APP_FILES_URL + task.assignmentTaskSubmitAttachments?.createBy?.imageUrl}
                                                            />
                                                        </div>
                                                        <div className="post-by-info">
                                                            <h6 className=" ">
                                                                {task.assignmentTaskSubmitAttachments?.createBy?.firstName}{" "}
                                                                {task.assignmentTaskSubmitAttachments?.createBy?.lastName}
                                                            </h6>
                                                        </div>
                                                    </div>

                                                    <div className="submitted-time">
                                                        <p>Submitted on</p>
                                                        <span>{null != task?.assignmentTaskSubmitAttachments?.createDate ? convertUTCDateToLocalDate(new Date(task?.assignmentTaskSubmitAttachments?.createDate)) : ""} </span>
                                                    </div>


                                                </div>


                                                <div className="score">
                                                    {task.assignmentTaskSubmitAttachments.marksObtain ? (
                                                        <div className="marks"></div>
                                                    ) : (
                                                        <span>Marks not yet given</span>
                                                    )}

                                                    {(task.assignmentTaskSubmitAttachments.marksObtain != null && Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"])) ? (
                                                        <div className="state">
                                                            <div className=" ">
                                                                <div className="marks">
                                                                    <div>
                                                                        <p>
                                                                            Total Marks: <span>{task.assignmentTaskSubmitAttachments.assignmentTask.score} </span>
                                                                        </p>
                                                                        <p>
                                                                            Obtained Marks: <span>{task.assignmentTaskSubmitAttachments.marksObtain}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => submitMarking(task, index)}
                                                                        className=" ">
                                                                        <svg width="15" height="15" fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m12.996 6.636-1.604-1.558 1.076-1.045 1.604 1.558-1.076 1.045Zm-6.132 4.4V9.478l4-3.887 1.604 1.558-4 3.887H6.864ZM.072 7.369V5.903h5.283v1.466H.072Zm0-2.933V2.969h8.302v1.467H.072Zm0-2.933V.036h8.302v1.467H.072Z"
                                                                                fill="#4575EE"/>
                                                                        </svg>
                                                                        <p>Edit Marks</p>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) ? (
                                                            <div className="pr-2 ">
                                                                <div className="marks-section">
                                                                    {/*<span>Give Marks</span>*/}
                                                                    <span>Score</span>
                                                                    <p>
                                                                        Total Marks: <span className=" ">{task.assignmentTaskSubmitAttachments.assignmentTask.score} </span>
                                                                    </p>
                                                                </div>

                                                                <div className="input-group">
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           placeholder="Enter here..."
                                                                           aria-label="Score"
                                                                    />
                                                                    <button type="submit"
                                                                            className="btn btn-red-md ml-3"
                                                                            onClick={() => submitMarking(task, index)}>
                                                                        Submit
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        ) : null
                                                    )}

                                                </div>
                                            </div>
                                            <div className="right-block  box-border">
                                                <div className="ans-desc single-img-custom">
                                                    <p dangerouslySetInnerHTML={{ __html: task?.assignmentTaskSubmitAttachments?.name }}></p>
                                                    {null != task?.assignmentTaskSubmitAttachments?.attachmentName ?
                                                        <SingleAttachmentComponent imageurl={task?.assignmentTaskSubmitAttachments?.attachmentName}/> :
                                                        null}
                                                </div>

                                                <div className=" ">
                                                    {task.assignmentTaskCommentsList.length > 0 ? task.assignmentTaskCommentsList.map((taskComment, commentIndex) => {
                                                        return (<div className="comment-area" key={commentIndex}>
                                                            <div className="avatar">
                                                                <img
                                                                    src={process.env.REACT_APP_FILES_URL + taskComment.createBy?.imageUrl}
                                                                />
                                                            </div>

                                                            <div className="card-alt">
                                                                <div className="comments">
                                                                    <h6>
                                                                        {taskComment.createBy.firstName}{" "}
                                                                        {taskComment.createBy.lastName}
                                                                    </h6>
                                                                    <p dangerouslySetInnerHTML={{ __html: taskComment?.comments }}></p>
                                                                </div>
                                                                <div className="comment-buttons">

                                                                    <button onClick={()=>handleEditComment(taskComment)}
                                                                        type="button"
                                                                        title="Edit"
                                                                        className="btn btn-sm mr-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                                             height="15"
                                                                             fill="none">
                                                                            <path fill="#141944"
                                                                                  d="m16.652 8.92-2.01-2.011L15.99 5.56 18 7.57l-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM.45 9.866V7.972h6.623v1.892H.45Zm0-3.785V4.189h10.407V6.08H.45Zm0-3.785V.404h10.407v1.892H.45Z"/>
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        title="Delete"
                                                                        className="btn btn-sm mr-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                             height="14"
                                                                             fill="none">
                                                                            <path fill="#D81414"
                                                                                  d="M2.25 13.904c-.413 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H0v-1.5h3.75v-.75h4.5v.75H12v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.44-1.059.44h-7.5Zm7.5-11.25h-7.5v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75h-1.5v6.75Zm3 0h1.5v-6.75h-1.5v6.75Z"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>);
                                                    }) : null}
                                                    <Formik
                                                        initialValues={initialComment}
                                                        enableReinitialize={true}
                                                        onSubmit={submitComment}>
                                                        {({values, handleSubmit, setFieldValue}) => (
                                                            <Form>
                                                                <div className="user-comment">
                                                                    <div className="add-comment-area">
                                                                        <div className="avatar">
                                                                            <img src={process.env.REACT_APP_FILES_URL + user?.imageUrl}/>
                                                                        </div>

                                                                        <div className="input-default">
                                                                            <MarkdownEditor
                                                                                name={`assignmentTasks[${index}].comment`}
                                                                                cbValue={value => {setFieldValue("comment", value);
                                                                                    submitCommentState({
                                                                                        ...values,
                                                                                        comment: value,
                                                                                        task: task,
                                                                                        taskIndex: index
                                                                                    });
                                                                                }}
                                                                                value={editorResetValue}
                                                                            />
                                                                        </div>

                                                                        <div className="form-row d-flex justify-content-center align-items-center">
                                                                            {loaders[index] ? <Loader/> :
                                                                                <button
                                                                                    type="submit"
                                                                                    disabled={loaders[index]}
                                                                                    className="btn-red-md float-right">
                                                                                    Submit
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>)}
                                                    </Formik>
                                                </div>
                                            </div>

                                        </div>
                                    </div>);
                                }) : ""}
                                {!assignmentTasks ? <Loader/> : ""}
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </CommonLayout>
    </>);
}

export default AssignmentTaskSubmittedView;
