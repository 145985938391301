import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import * as ChannelApi from "../../api/ChannelApi";
import {Field, FieldArray, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import * as GradingApi from "../../api/GradingApi";
import CommonLayout from "../layout/commonLayout";
import LoaderOverlay from "../common/LoaderOverlay";
import * as RoutineApi from "../../api/RoutineApi";
import Swal from "sweetalert2";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate, useParams} from "react-router-dom";

function RoutineEntry(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [channel, setChannel] = useState({});
    const [loader, setLoader] = useState(false);
    // const [routineDays, setRoutineDays] = useState({});
    const [selectedGradingBreakdown, setSelectedGradingBreakdown] = useState({});
    const [weekdays, setWeekdays] = useState([{day: "Friday"}, {day: "Saturday"}, {day: "Sunday"}, {day: "Monday"}, {day: "Tuesday"}, {day: "Wednesday"}, {day: "Thursday"}]);
    const [hours, setHours] = useState([{hour: "00"}, {hour: "01"}, {hour: "02"}, {hour: "03"}, {hour: "04"}, {hour: "05"}, {hour: "06"}, {hour: "07"}, {hour: "08"}, {hour: "09"}, {hour: "10"}, {hour: "11"}, {hour: "12"}]);
    const [minutes, setMinutes] = useState([{minute: "00"}, {minute: "01"}, {minute: "02"}, {minute: "03"}, {minute: "04"}, {minute: "05"}, {minute: "06"}, {minute: "07"}, {minute: "08"}, {minute: "09"}, {minute: "10"}, {minute: "11"}, {minute: "12"}, {minute: "13"}, {minute: "14"}, {minute: "15"}, {minute: "16"}, {minute: "17"}, {minute: "18"}, {minute: "19"}, {minute: "20"}, {minute: "21"}, {minute: "22"}, {minute: "23"}, {minute: "24"}, {minute: "25"}, {minute: "26"}, {minute: "27"}, {minute: "28"}, {minute: "29"}, {minute: "30"}, {minute: "31"}, {minute: "32"}, {minute: "33"}, {minute: "34"}, {minute: "35"}, {minute: "36"}, {minute: "37"}, {minute: "38"}, {minute: "39"}, {minute: "40"}, {minute: "41"}, {minute: "42"}, {minute: "43"}, {minute: "44"}, {minute: "45"}, {minute: "46"}, {minute: "47"}, {minute: "48"}, {minute: "49"}, {minute: "50"}, {minute: "51"}, {minute: "52"}, {minute: "53"}, {minute: "54"}, {minute: "55"}, {minute: "56"}, {minute: "57"}, {minute: "58"}, {minute: "59"}]);
    const [amPm, setAmPm] = useState([{ampm: "AM"}, {ampm: "PM"}]);
    const [gradings, setGradings] = useState([]);
    const [routineDays, setRoutineDays] = useState({
        days: []
    });
    useEffect(() => {
        setRoutineDays({
            ...routineDays, days: [...routineDays.days, {
                dayName: "", startHour: "", startMinute: "", startAMPM: "", endHour: "", endMinute: "", endAMPM: ""
            }]
        });
    }, []);

    useEffect(() => {
        ChannelApi.getChannelById(params.id).then(json => {
            setChannel(json.data);
        });
        GradingApi.findGradingListByChannelId(params.id)
            .then(json => {
                console.log(json.data);
                setGradings(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleBreakdownSelected = (fieldName, value) => {
        setSelectedGradingBreakdown(value);
    };
    const handleSubmit = values => {
        setLoader(true);
        let routineDays = [];
        values.days.forEach(value => {
            routineDays.push({
                weekday: value.dayName.day,
                startTime: value.startHour.hour + ":" + value.startMinute.minute + " " + value.startAMPM.ampm,
                endTime: value.endHour.hour + ":" + value.endMinute.minute + " " + value.endAMPM.ampm,
            });
        });
        let routine = {
            channel: channel, routineDaysDTOList: routineDays
        };

        RoutineApi.saveRoutine(routine).then(response => {
            setLoader(false);
            if (response.response.status === 201 || response.response.status === 200) Swal.fire("New Routine saved", "success"); else Swal.fire("Routine save failed", "error");
            navigateTo(LinkFactory.routineListByChannelId + "/" + params.id);
        }).finally(() => {
            setLoader(false);
        });

    };

    function goBack() {
        navigateTo(-1)
    }
    return (<>
        <CommonLayout>
            <main>
                <div className="card-default routine-create">
                    <div className="title-header">
                        <div className="mb-0 d-flex align-items-center">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                <a onClick={goBack} className="pl-0 back-button">
                                    <i className="fa fa-chevron-left"> </i> </a>
                            </div>
                            <p>
                                Create New Routine for :{" "}
                                {channel.id !== null ? channel.channelName : ""}
                            </p>
                        </div>
                    </div>
                    <br/>
                    <div className=" ">
                        {loader ? <LoaderOverlay/> : null}
                        <div className="card-body">
                            <p>Select Days & Time</p>
                            <Formik
                                initialValues={routineDays}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}>
                                {({props, values}) => (<Form>
                                    <div>
                                        <FieldArray
                                            name="days"
                                            render={arrayHelpers => (<div className="routine-calendar-box d-flex align-items-center">
                                                {values.days && values.days.length > 0 ? values.days.map((day, index) => (
                                                    <span key={index} className="mt-3">
                                                                      <div className="routine-calendar">
                                                                          <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="10.5" r="9.75" fill="#fff" stroke="#4575EE" stroke-width="1.5"/><circle cx="10.5" cy="10.5" r="5.75" fill="#4575EE" stroke="#fff" stroke-width="1.5"/></svg>

                                                                        <div className=" ">
                                                                          <div className="d-flex flex-column align-items-center mt-2">
                                                                            <label>Weekday:</label>
                                                                            <Field
                                                                                type="text"
                                                                                id={`days[${index}].dayName`}
                                                                                name={`days[${index}].dayName`}
                                                                                placeholder="Select Weekday"
                                                                                fieldName={`day`}
                                                                                options={weekdays || []}
                                                                                component={SelectDropdown}
                                                                            />
                                                                          </div>
                                                                        </div>

                                                                          <div className="d-flex align-items-baseline">
                                                                              <div className=" mr-2">
                                                                          <div className=" d-flex flex-column align-items-center">
                                                                            <label>Start:</label>
                                                                              <div className=" ">
                                                                           <Field
                                                                               type="text"
                                                                               id={`days[${index}].startHour`}
                                                                               name={`days[${index}].startHour`}
                                                                               placeholder="HH"
                                                                               fieldName={`hour`}
                                                                               options={hours || []}
                                                                               component={SelectDropdown}
                                                                           />
                                                                              <Field
                                                                                  type="text"
                                                                                  id={`days[${index}].startMinute`}
                                                                                  name={`days[${index}].startMinute`}
                                                                                  placeholder="MM"
                                                                                  fieldName={`minute`}
                                                                                  options={minutes || []}
                                                                                  component={SelectDropdown}
                                                                              />
                                                                              <Field
                                                                                  type="text"
                                                                                  id={`days[${index}].startAMPM`}
                                                                                  name={`days[${index}].startAMPM`}
                                                                                  placeholder="AM/PM"
                                                                                  fieldName={`ampm`}
                                                                                  options={amPm || []}
                                                                                  component={SelectDropdown}
                                                                              />
                                                                                  </div>
                                                                          </div>
                                                                        </div>
                                                                         <div className="ml-2 ali">
                                                                          <div className="d-flex flex-column align-items-center mt-1">
                                                                            <label>End:</label>
                                                                              <div className=" ">
                                                                           <Field
                                                                               type="text"
                                                                               id={`days[${index}].endHour`}
                                                                               name={`days[${index}].endHour`}
                                                                               placeholder="HH"
                                                                               fieldName={`hour`}
                                                                               options={hours || []}
                                                                               component={SelectDropdown}
                                                                           />
                                                                              <Field
                                                                                  type="text"
                                                                                  id={`days[${index}].endMinute`}
                                                                                  name={`days[${index}].endMinute`}
                                                                                  placeholder="MM"
                                                                                  fieldName={`minute`}
                                                                                  options={minutes || []}
                                                                                  component={SelectDropdown}
                                                                              />
                                                                              <Field
                                                                                  type="text"
                                                                                  id={`days[${index}].endAMPM`}
                                                                                  name={`days[${index}].endAMPM`}
                                                                                  placeholder="AM/PM"
                                                                                  fieldName={`ampm`}
                                                                                  options={amPm || []}
                                                                                  component={SelectDropdown}
                                                                              />
                                                                                  </div>
                                                                          </div>
                                                                        </div>
                                                                          </div>
                                                                          <div className=" ">
                                                                        <div className=" ">
                                                                          <div className="form-group">
                                                                            <button
                                                                                className={"btn btn-danger"}
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.remove(index)}
                                                                            >
                                                                              {" "}
                                                                                <i className="fa fa-trash"/>{" "}
                                                                                Delete
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      </div>

                                                                    </span>)) : ""}
                                                <div className=" ">
                                                    <div className="mt-3 ">
                                                        <div className="form-group">
                                                            <button
                                                                type="button"
                                                                className={"btn btn-primary"}
                                                                onClick={() => arrayHelpers.push({
                                                                    dayName: "", time: ""
                                                                })}>
                                                                <i className="fa fa-plus-circle"/>
                                                                Add more day
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-red-md"
                                                >
                                                    <i className="fa fa-save"/> Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>)}
                            </Formik>
                        </div>
                    </div>
                </div>
            </main>
        </CommonLayout>
    </>);
}

export default RoutineEntry;
