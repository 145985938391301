import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const notificationListByUserIdAndTag = (channelId, tag) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/notification-by-tag/" + channelId + "/" + tag
    );
};

export const notificationCountForCurrentUser = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/notification/count/by-logged-in-user"
    );
};
export const searchNotificationPage = data => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/notification-by-tag", data);
};
export const updateNotificationReadStatus = data => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/notification/update-read-status", data);
};
export const updateAllNotificationReadStatus = data => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/notification/update-all-read-status", data);
};
export const clearNotification = (userId, tag) => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/notification/clear-notification/" + userId + "/" + tag);
};
export const notificationByTags = req => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/notification/notification-by-tags", req);
};
