import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";
import {useNavigate, useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";
import LinearProgress from "@mui/material/LinearProgress";
import * as LinkFactory from "../../util/LinkFactory";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import {Form, Formik} from "formik";
import {FormikTextField} from "../FormikTextField";

function ChannelApproval(props) {
    const params = useParams();
    const [postList, setPostList] = useState([]);
    const [postStatus, setPostStatus] = useState("");
    const [postId, setPostId] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [postIdToDelete, setPostIdToDelete] = useState(null);
    const [postType, setPostType] = useState('PENDING');

    const [search, setSearch] = useState({searchTerm: ""});
    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }


    useEffect(() => {
        loadPosts();
    }, [params.id, search]);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    }

    const loadPosts = () => {
        setShowProgressBar(true);
        ChannelApi.getChannelPostListWithoutAttachment(
            {
                channelId: params.id,
                page: pageNumber,
                postType:postType,
                searchTerm: search.searchTerm? search.searchTerm : null
            }
        ).then(
            json => {
                setPostList(json.data.content);
                setPageCount(json?.data?.totalPages);
                setShowProgressBar(false);
            }
        ).finally(()=>{
            setShowProgressBar(false);
        })
    };

    const handlePostStatusChangeRadioButton = event => {
        setPostStatus(event.target.value);
    };
    const handleChangeStatus = () => {
        var channelPostApprovalDTO = {
            postId: postId,
            approveStatus: postStatus
        };
        ChannelApi.channelPostApproval(channelPostApprovalDTO).then(data => {
            loadPosts();
        });
    };

    function handleDelete() {
        ChannelApi.deletePostById(postIdToDelete).then((resp) => {
            loadPosts();
            setShowDeleteModal(false);
            setPostIdToDelete(null);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Post Deleted',
                showConfirmButton: false,
                timer: 1200
            })
        })
    }

    const setSearchForChannelApprovalPage = async (value) => {
        setSearch(value);
    }

    useEffect(() => {
        loadPosts();
    }, [pageNumber, postType]);

    function goBack() {
        navigateTo(-1)
    }

    const redirectToPostDetail = post => {
        console.log(post);
        navigateTo(LinkFactory.channelPostView + "/" + post.id);
    };

    const onClickDelete = post => {
        setPostIdToDelete(post.id);
        setShowDeleteModal(true)
    };

    const handlePostStatusRadioButton = event => {
        setPostType(event.target.value);
    };

    function PostDeleteModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="global-repository"
            >
                <Modal.Body>
                    <div className="modal-header">
                        <div className="circle-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                <path fill="#D81414"
                                      d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                            </svg>

                        </div>
                        <div className="modal-body pt-4 pb-0 text-center">Are you sure, you want to delete ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="delete-button">
                        <Button variant="success" onClick={()=>handleDelete()}>Delete</Button>
                    </div>
                    <div>
                        <Button onClick={props.onHide}>Cancel</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="post-management">
            <CommonLayout headerName={Utils.getCurrentChannelName()}>
                <main>

                    <PostDeleteModal
                        show={showDeleteModal}
                        onHide={() => setShowDeleteModal(false)}
                    />

                    <div className="card-default post-management container-responsive ">
                        <div className="title-header ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Post Management</p>
                            </div>
                            {/*<div className="sort-by">
                                <button className="sort-by-button">
                                    <p>Sort by</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13"
                                         fill="none">
                                        <path stroke="#475467" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"
                                              d="M3.75 6.5h9.5M1.375 1.75h14.25m-9.5 9.5h4.75"/>
                                    </svg>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>1</li>
                                    <li>2</li>
                                    <li>3</li>
                                </ul>
                            </div>*/}
                            <Formik
                                initialValues={search}
                                enableReinitialize={true}
                                onSubmit={setSearchForChannelApprovalPage}
                            >
                                {({props, values, setFieldValue}) => {
                                    return (
                                        <Form>
                                            <div className="form-group mb-0 pr-4 has-search d-flex">
                                                                    <span
                                                                        className="fa fa-search form-control-feedback"> </span>
                                                <FormikTextField
                                                    // label="Search"
                                                    name="searchTerm"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search"
                                                />
                                            </div>

                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 post-management-tabs">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" onClick={handlePostStatusRadioButton}
                                           type="radio"
                                           value="PENDING"
                                           checked={postType === 'PENDING'}
                                           name="inlineRadioOptions"
                                           id="inlineRadio2r2" />
                                        <label className="form-check-label" htmlFor="inlineRadio2r2">Pending</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" onClick={handlePostStatusRadioButton}
                                           type="radio"
                                           name="inlineRadioOptions"
                                           value="APPROVED"
                                           id="inlineRadio22r1" />
                                        <label className="form-check-label" htmlFor="inlineRadio22r1">Approved</label>
                                </div>
                                {
                                    Utils.checkRolePermission(["ROLE_ADMIN"]) ?
                                        (<div className="form-check form-check-inline">
                                            <input className="form-check-input" onClick={handlePostStatusRadioButton}
                                                   type="radio"
                                                   value="DELETED"
                                                   name="inlineRadioOptions"
                                                   id="inlineRadio22r12" />
                                            <label className="form-check-label" htmlFor="inlineRadio22r12">Deleted</label>
                                        </div>) : null
                                }

                            </div>
                        </div>

                        <div className="mb-4">
                            <div>
                                {showProgressBar ? (<LinearProgress color="primary" />) : ""}
                                <div className="table-responsive table-light ">
                                    <table
                                        className="table table-light table-hover table-striped"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Post</th>
                                            <th>User</th>
                                                <th>Post Date</th>
                                                <th>Current Status</th>
                                                <th className=" " width="15%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {postList.length > 0
                                            ? postList.map((data, index) => {
                                                return (

                                                        <tr>
                                                            <td>{(index + 1) + (pageNumber) * 10}</td>
                                                            <td>{data.message}</td>
                                                            <td>{data.createBy.firstName}</td>
                                                            <td>{data.createDate}</td>
                                                            <td>{data.approveStatus}</td>
                                                            <td className=" ">
                                                                {postType === 'PENDING' ?
                                                                    (<button
                                                                            type="button"
                                                                            className="btn btn-sm bg-c-light-yellow mr-1"
                                                                            data-toggle="modal"
                                                                            data-target="#changeStatusModal"
                                                                            onClick={() => setPostId(data.id)}
                                                                            title="Change Status">
                                                                            <i className="fa fa-random"/>
                                                                        </button>
                                                                        ) : null
                                                                }
                                                                {postType !== 'DELETED' ?
                                                                    <button
                                                                        type="button"
                                                                        title="View Submits"
                                                                        className="btn btn-sm bg-c-red-light mr-1"
                                                                        onClick={() => onClickDelete(data)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="13" height="14" fill="none">
                                                                            <path fill="#D81414"
                                                                                  d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                                        </svg>
                                                                    </button> : null
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm bg-c-blue-light"
                                                                    onClick={() => redirectToPostDetail(data)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                         width="17" height="12" fill="none">
                                                                        <path fill="#4575EE"
                                                                              d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                );
                                            })
                                            : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="page-navigation">
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>


            <div
                className="modal fade"
                id="changeStatusModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body d-flex justify-content-center flex-column align-items-center">
                            <div className="circle-icon">
                                <img src="img/status-change.png" alt={"Status Change icon"}/>
                            </div>
                            <div>
                                <p>Are you sure you want to change status ?</p>
                                <div className="mt-3 d-flex flex-column align-items-baseline"
                                     onChange={handlePostStatusChangeRadioButton}>
                                    <label className="mb-2">
                                        <input type="radio" name="site_name" value="PENDING"/>
                                        <span className="pl-1"> Keep Pending </span>
                                    </label>

                                    <label className="mb-2">
                                        <input type="radio" name="site_name" value="APPROVED"/>
                                        <span className="pl-1"> Approve Post </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn confirm-button"
                                data-dismiss="modal"
                                onClick={handleChangeStatus}>
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="btn "
                                data-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChannelApproval;
