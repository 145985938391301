import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveRoleSetup = roleSetup => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/um-role/setup", roleSetup);
};
export const getAllRole = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/um-role/all");
}
export const saveUserRoleSetup = (userRoleSetup) => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/um-user-role/setup", userRoleSetup);
}