import React, {useEffect, useState} from "react";
import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  background: "transparent"
};
const thumb = {
  display: "grid",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  marginTop: 100
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden"
};
const img = {
  display: "block",
  width: "400px",
  height: "400px"
};

export default function FileUploader({ onChange }) {
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [fileType, setFileType] = useState(null);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    maxSize: 15728640
  });

  const files = acceptedFiles.map(file => (
    <div key={file.path}>
      {file.path} - {(file.size / 1024).toFixed(2)} kb
    </div>
  ));

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length) {
      onChange(acceptedFiles);
      if (acceptedFiles[0].type.match(/image\/*/)){
        setShowFilePreview(true);
        setFileType('');
      }else {
        setShowFilePreview(false);
        setFileType(acceptedFiles[0].type);
      }
    }else {
      setFileType('');
    }
  }, [acceptedFiles]);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <>
        <li key={file.path}>
          <ul style={{ color: "red" }}>
            {errors.map(e => (
              <li key={e.code}>{e.message}</li>
            ))}
          </ul>
        </li>
      </>
    );
  });

  const thumbs = acceptedFiles.map(file => (

    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt="photo"
          src={URL.createObjectURL(file)}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file);
          }}
        />
      </div>
    </div>
  ));

  return (
    <section className=" ">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />

        <div
          role="button"
          className="text-center bg-c-light-orange h-100">
          <p className=" ">
            <svg width="24" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.35 6.04A7.49 7.49 0 0 0 12 0C9.11 0 6.6 1.64 5.35 4.04A5.994 5.994 0 0 0 0 10c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96ZM19 14H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.47 5.47 0 0 1 12 2c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0 1 22 11c0 1.65-1.35 3-3 3ZM8 9h2.55v3h2.9V9H16l-4-4-4 4Z" fill="#E68F06"/></svg>{" "}
            Click here to choose files
          </p>
        </div>
      </div>
      <aside>
        {files && files.length ? (
          <div className="mt-3">
            File : <span></span>
            <p>{files}</p>
          </div>
        ) : (
          ""
        )}

        {fileRejectionItems && fileRejectionItems.length ? (
          <div className="mt-3" style={{ color: "black" }}>
            Rejected :<ul>{fileRejectionItems}</ul>
          </div>
        ) : (
          ""
        )}
      </aside>
      {showFilePreview ? (<aside style={thumbsContainer}>{thumbs}</aside>) : ("")}
      {fileType === 'application/pdf' ? (<aside><i className="far fa-file-pdf fa-lg"></i></aside>) : ("")}
      {fileType?.match(/application\/vnd\/*/) ? (<aside><i className="far fa-file-excel fa-lg"></i></aside>) : ("")}
    </section>
  );
}
