import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as AssignmentApi from "../../api/AssignmentApi";
import CommonLayout from "../layout/commonLayout";
import {getDateProperObject, getThisMonthNo, getTodayObject, getYear} from "../../util/DateUtils";
import {monthWiseData} from "../../util/commonDataset";
import BigCalender from "../Calendar/bigCalendar";
import MonthSlider from "../Calendar/monthSlider";
import {useNavigate} from "react-router-dom";

const CalendarPage = () => {
    const [monthStartDate, setMonthStartDate] = useState(getTodayObject());
    const [calenderEventList, setCalenderEventList] = useState([]);
    const [monthYear, setMonthYear] = useState({
        month: getThisMonthNo(),
        year: getYear()
    });
    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    useEffect(() => {
        if (monthYear) {
            let monthYearString = `${monthYear.month + "/" + monthYear.year}`;

            AssignmentApi.getCalenderData(monthYearString).then(res => {
                let events = res.data;
                for (let i = 0; i < events.length; i++) {
                    events[i].start = moment.utc(events[i].start).toDate();
                    events[i].end = moment.utc(events[i].end).toDate();
                    events[i].id = new Date(
                        moment.utc(events[i].start).toDate()
                    ).getUTCDate();
                    events[i].assignmentCount =
                        events[i].calenderEventType.length &&
                        events[i].calenderEventType === "assignment"
                            ? 1
                            : 0;
                    events[i].eventCount =
                        events[i].calenderEventType.length &&
                        events[i].calenderEventType === "event"
                            ? 1
                            : 0;
                }
                setCalenderEventList(events);
            });
        }
    }, [monthYear]);

    const handleMonthCb = useCallback((month, year, start, end, defaultDate) => {
        if (month) {
            setMonthYear({
                month: month,
                year: year
            });

            const fullMonth = month < 10 ? "0" + month : month;
            const newDate = "01-" + fullMonth + "-" + year;

            console.log();
            setMonthStartDate(getDateProperObject(newDate, "DD-MM-YYYY"));
        }
    }, []);

    function goBack() {
        navigateTo(-1)
    }

    return (
        <CommonLayout headerName={"Activity Calender"}>
            <div className="activity-calender card-header container-responsive">
                <div className="title ">
                    <div className="mb-0 d-flex align-items-center">
                        <div className="card-header p-0 m-0 mr-1 bg-c-light-grey">
                            <a onClick={goBack} className="pl-0 pr-5 back-button">
                                <i className="fa fa-chevron-left"> </i> </a>
                        </div>
                        <svg width="17" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16 9.075V6.78c0-1.428 0-2.142-.273-2.688-.24-.48-.622-.87-1.092-1.114C14.1 2.7 13.4 2.7 12 2.7H5c-1.4 0-2.1 0-2.635.278-.47.244-.853.635-1.093 1.114C1 4.638 1 5.352 1 6.78v7.14c0 1.428 0 2.142.272 2.688.24.48.623.87 1.093 1.114C2.9 18 3.6 18 5 18h3.917M16 7.8H1M11.833 1v3.4M5.167 1v3.4M13.5 17.15v-5.1M11 14.6h5"
                                stroke="#475467" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p>Activity Calender</p>
                    </div>
                </div>
                <div className="calender-table">
                    <div className="calender-header">
                        <div className="mx-auto flex items-center">
                            <MonthSlider
                                selected={getThisMonthNo()}
                                month={monthWiseData.map(obj => ({
                                    key: obj.no,
                                    value: obj.name,
                                    no: obj.no
                                }))}
                                monthCb={handleMonthCb}
                            />
                        </div>
                        <div className="indicator-icons">
                            <div className="d-flex">
                                <div className="box-icon bg-c-blue-light">
                                    <svg width="10" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 2.875 3.615 6 9 1" stroke="#4575EE" stroke-width="2"/>
                                    </svg>
                                </div>
                                <p>Assignment</p>
                            </div>
                            <div className="d-flex">
                                <div className="box-icon bg-c-light-orange">
                                    <svg width="10" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 2.875 3.615 6 9 1" stroke="#E68F06" stroke-width="2"/>
                                    </svg>
                                </div>
                                <p>Event</p>
                            </div>
                        </div>
                    </div>
                    <div className="activity-calender-body">
                        <BigCalender defaultDate={monthStartDate} events={calenderEventList}/>
                    </div>
                </div>

            </div>

        </CommonLayout>
    );
};

export default CalendarPage;
