import {FETCH_ATTENDANCE_BEGIN, FETCH_EVENTS_FAILURE, FETCH_EVENTS_SUCCESS} from "../action/EventAction";

const initialState = {
    previousEvents: null,
    upcomingEvents: null,
    loading: false,
    error: null
};

export default function EventReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ATTENDANCE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                previousEvents: action.payload.previousEvents,
                upcomingEvents: action.payload.upcomingEvents
            };

        case FETCH_EVENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                previousEvents: null,
                upcomingEvents: null
            };

        default:
            return state;
    }
}
