import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import * as GradingApi from "../../api/GradingApi";
import * as GradingBreakdownApi from "../../api/GradingBreakdownApi";
import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";
import {useNavigate, useParams} from "react-router-dom";

function GradingBreakdownView(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    //grading-id will be passed here and it pull data from there
    const [grading, setGrading] = useState({});
    const [gradingBreakdown, setGradingBreakdown] = useState({});
    //  const [grading, setGrading] = useState({});

    useEffect(() => {
        GradingApi.getGradingById(params.id)
            .then(res => {
                setGrading(res.data);
                GradingBreakdownApi.getGradingBreakdownByGradingId(
                    params.id
                ).then(json => {
                    setGradingBreakdown(json.data);
                });
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);
    const handleEdit = data => {
        console.log(data);
        console.log("hello");
    };

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout headerName={Utils.getCurrentChannelName()}>
                <main>
                    <div className="card-default grading-breakdown container-responsive">
                        <div className="title ">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>
                                    Grading breakdowns
                                </p>
                                <h6>
                                    <p>{grading?.semester?.semesterName}</p>
                                </h6>
                            </div>

                        </div>

                        <div>
                            <div className="table-responsive table-light mt-5">
                                <table
                                    className="table table-light table-striped table-hover"
                                    id="dataTable"
                                    width="100%"
                                    cellSpacing="0"
                                >
                                    <thead>
                                    <tr>
                                        <th scope="col">Exam Name</th>
                                        <th scope="col">Score</th>
                                        {/*<th scope="col">Action</th>*/}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {gradingBreakdown.length > 0
                                        ? gradingBreakdown.map((gradingBreak, index) => {
                                            return (
                                                <tr>
                                                    <td>{gradingBreak.evaluationName}</td>
                                                    <td>{gradingBreak.fullScore}</td>
                                                    {/*<td className="text-right">*/}
                                                    {/*    <button*/}
                                                    {/*        type="button"*/}
                                                    {/*        className="btn btn-sm btn-info"*/}
                                                    {/*        onClick={() => handleEdit(gradingBreak)}*/}
                                                    {/*    >*/}
                                                    {/*        <i className="fa fa-pencil"></i> Edit*/}
                                                    {/*    </button>*/}
                                                    {/*</td>*/}
                                                </tr>
                                            );
                                        })
                                        : ""}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default GradingBreakdownView;
