import AxiosAgent from "./AxiosAgent";
import { API_MAIN_ROOT } from "../util/LinkFactory";


export const save = evalQuestType => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question-type", evalQuestType);
};

export const update = evalQuestType => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/evaluation-question-type", evalQuestType);
};

export const search = evalQuestType => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question-type/search", evalQuestType);
};

export const deleteEvaluationQuestionType = id => {
  return AxiosAgent.requests.delete(API_MAIN_ROOT + "/evaluation-question-type/"+ id);
};

export const getEvaluationQuestionTypeById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/evaluation-question-type/" + id);
};
