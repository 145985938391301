import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import {Form, Formik} from "formik";
import {FILES_URL} from "../../util/LinkFactory";
import Loader from "../common/Loader";
import moment from "moment";
import * as Utils from "../../util/Utils";
import LikedUserModal from "./LikedUserModal";
import * as ModalActions from "../../action/ModalActions";
import {useDispatch} from "react-redux";
import CommonLayout from "../layout/commonLayout";
import CachingImage from "../Image/cachingImage";
import {useParams} from "react-router-dom";
import MediaViewComponent from "./Components/MediaViewComponent";
import {getSinglePostById} from "../../api/ChannelApi";
import {convertUTCDateToLocalDatePostFormat} from "../../util/DateUtils";
import Swal from "sweetalert2";

const initialComment = {messageId: null, comment: "", file: null};

function ChannelPostDetail(props) {
    const params = useParams();
    const dispatch = useDispatch();
    const [channel, setChannel] = useState({});
    const [hasError, setHasError] = useState(false);
    const [post, setPost] = useState({});
    const [comment, setComment] = useState(initialComment);
    const [ourCommentArray, setOurCommentArray] = useState([]);
    const [postLoading, setPostLoading] = useState(true);
    const [likedByCurrentUser, setLikedByCurrentUser] = useState(false);
    const [channelPostLikeLogList, setChannelPostLikeLogList] = useState([]);

    const [user, setUser] = useState({});


    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        setChannel({});
        setPost({});
        setPostLoading(true);
        ChannelApi.getSinglePostById(params.id).then(response => {
            const status = response?.response?.status;
            if (status === 500) {
                console.error("got error ");
                setPostLoading(false);
                setHasError(true);
            } else {
                console.log(response.data);
                setPost(response.data);
                setOurCommentArray(response?.data?.channelPostCommentDTOList);
                // setLikedByCurrentUser(response?.data?.likedByCurrentUser);
                // setChannelPostLikeLogList(response?.data?.channelPostLikeLogList);
                setPostLoading(false);
            }
        }).catch(errors => {
            console.error(errors);
            setPostLoading(false);
            setHasError(true);
        })

        ;
    }, [params.id]);

    const submitComment = (data, resetForm) => {
        console.log(data);
        if (!Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(data.comment)) {
            Swal.fire("Write something to comment", "", "info");
            return;
        }

        let user = Utils.getUserFromLocalStorage();

        const params = {comment: data.comment, id: data.messageId};

        let groupMessageCommentDTOToPushInArray = {
            comment: data.comment,
            firstName: user.firstName,
            lastName: user.lastName,
            imageUrl: user.imageUrl,
            createTimeDate: new Date()
        };

        ChannelApi.savePostComment(params).then(response => {
            let postTemp = {...post};
            postTemp.channelPostCommentDTOList.push(groupMessageCommentDTOToPushInArray);
            setPost(postTemp);
        });

        resetForm(initialComment);
    };

    const registerLike = data => {
        console.log(data);
        const params = {id: data.post.id};
        ChannelApi.registerLike(params).then(resp => {
            let postTemp = {...post};
            postTemp.likeByUser = !postTemp.likeByUser;
            postTemp.post.likeCount = postTemp.likeByUser ? (+postTemp.post.likeCount) + 1 : (+postTemp.post.likeCount) - 1;
            console.log(postTemp);
            setPost(postTemp);
        })
    };

    const submitCommentState = data => {
        setComment(data);
    };


    return (
        <>
            <CommonLayout id="layoutSidenav_content">
                <main>
                    <div id="contentArea">
                        <div className="block-center post-details container-responsive">
                            {!postLoading ?
                                (hasError ?
                                    <h2>Something went wrong</h2> :
                                    (<div className="card-default">
                                            <div className="view-post-area">
                                                <div className="">
                                                    <div className="post-content d-flex">
                                                        <div className="media-section-1">
                                                            <div className="media-section col-8">
                                                                {post?.postContentDTO.map((data, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <MediaViewComponent
                                                                                imageurl={data}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="post-content-section col-4">
                                                            <div className="post-by">
                                                                <div className="avatar">
                                                                    <CachingImage
                                                                        alt="Image"
                                                                        src={FILES_URL + post?.post.user.imageUrl}
                                                                    />
                                                                </div>
                                                                <div className="post-by-info">
                                                                    <h6>{post?.post.user.firstName} {post?.post.user.lastName}</h6>
                                                                    <p>{convertUTCDateToLocalDatePostFormat(post?.post?.timestamp ? new Date(post?.post?.timestamp) : null)}</p>
                                                                </div>
                                                            </div>
                                                            <p>{post.post.postText}</p>


                                                            <div className="post-status">{""}</div>

                                                            <div className="like-comment-share">
                                                                <div className="box" onClick={() => {
                                                                    registerLike(post);
                                                                }}>
                                                                    <i className={post?.likeByUser ? "fa fa-thumbs-up" : "far fa-thumbs-up"}/>
                                                                    <p>
                                                                        <b>{post?.post.likeCount ? post?.post.likeCount : ""}</b> Likes
                                                                    </p>
                                                                </div>

                                                                <div className="box">
                                                                    <p>
                                                                        <b>{post?.channelPostCommentDTOList.length ? post?.channelPostCommentDTOList.length : ""}</b>{" Comments"}
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div
                                                                className="commentbox-area d-flex flex-column justify-content-between ">
                                                                <div
                                                                    className="mb-2 mt-2"
                                                                >
                                                                    {ourCommentArray.length > 0
                                                                        ? ourCommentArray?.map(
                                                                            (comment, index) => {
                                                                                return (
                                                                                    <div className="comment-area">
                                                                                        <div className="avatar">
                                                                                            <img
                                                                                                src={FILES_URL + comment?.imageUrl}/>
                                                                                        </div>

                                                                                        <div className="card-alt">
                                                                                            <h6>
                                                                                                {comment?.firstName}{" "}{comment?.lastName}
                                                                                                <span>{" "}
                                                                                                    <small>
                                                                                                        {null != comment?.createTimeDate ? moment(comment?.createTimeDate).fromNow() : null}
                                                                                                    </small>
                                                                                                </span>
                                                                                            </h6>
                                                                                            <p>{comment?.comment}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )
                                                                        : null}
                                                                </div>
                                                                <div className="comment-box">

                                                                    <Formik
                                                                        initialValues={comment.post != null && comment.messageId === post?.groupMessageId ? comment : initialComment}
                                                                        enableReinitialize={true}
                                                                        onSubmit={(
                                                                            data, {
                                                                                setSubmitting,
                                                                                setErrors,
                                                                                setStatus,
                                                                                resetForm
                                                                            }
                                                                        ) => {
                                                                            const value = {
                                                                                ...data,
                                                                                messageId: post?.post.id
                                                                            };
                                                                            submitComment(value, resetForm);
                                                                        }}
                                                                    >
                                                                        {({
                                                                              values,
                                                                              handleSubmit,
                                                                              setFieldValue
                                                                          }) => (
                                                                            <Form>
                                                                                <div className="add-comment-area">
                                                                                    <div className="avatar">
                                                                                        <CachingImage
                                                                                            alt="image"
                                                                                            src={FILES_URL + user?.imageUrl}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="input-default">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="comment"
                                                                                            aria-describedby="emailHelp"
                                                                                            name="comment"
                                                                                            placeholder="Write your comment here"
                                                                                            value={values.comment}
                                                                                            onChange={event => {
                                                                                                setFieldValue(
                                                                                                    "comment",
                                                                                                    event.target.value
                                                                                                );
                                                                                                submitCommentState({
                                                                                                    ...values,
                                                                                                    comment: event.target.value
                                                                                                });
                                                                                            }}
                                                                                        />

                                                                                    </div>
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn-red-md float-right ml-1"
                                                                                    >
                                                                                        <i className="fa fa-send"/>
                                                                                    </button>
                                                                                </div>
                                                                            </Form>
                                                                        )}
                                                                    </Formik>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : postLoading ? (<Loader/>) : null
                            }
                            <br/>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ChannelPostDetail;
