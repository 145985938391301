import React from "react";
import {Popover} from "@mui/material";
// import Popover from "@material-ui/core/Popover";

// const useStyles = makeStyles(theme => ({
//     typography: {
//         padding: theme.spacing(2)
//     },
//     popover: {
//         pointerEvents: "none"
//     },
//     paper: {
//         padding: theme.spacing(1)
//     }
// }));

export function SingleEventDetails({event}) {
    // const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div
            aria-owns={open ? "mouse-over-popover" : undefined}
            style={{}}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            //onMouseLeave={handlePopoverClose}
        >
            <div className="d-flex my-1 bg-c-blue-light radius-10" style={{fontSize: 12, color: '#4575EE', justifyContent: 'center', alignItems: 'center'}}>
                {event.assignmentCount > 0 ? (
                    <span className="p-1 px-2 fw-600"> Assignment: {event.assignmentCount} </span>
                ) : (
                    ""
                )}
            </div>
            <div className="d-flex my-1 bg-c-light-orange radius-10" style={{fontSize: 12, color: '#E58F06', justifyContent: 'center', alignItems: 'center'}}>
                {event.eventCount > 0 ? <span className="p-1 px-2 fw-600"> Event: {event.eventCount} </span> : ""}
            </div>
            <Popover
                id="mouse-over-popover"

                open={open}
                anchorEl={anchorEl}
                PaperProps={{onMouseLeave: handlePopoverClose}}
                onClose={handlePopoverClose}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <div className="py-3 px-3 radius-25">
                    {event.dataArray &&
                    event.dataArray.map((item, index) => (
                        <div
                            className="small"
                            style={{color: item.colorHexCode}}
                            key={index}
                        >
                            <div>Title: {item.title} </div>
                            <div className="py-1"> {item.description}</div>
                        </div>
                    ))}
                </div>
            </Popover>
        </div>
    );
}
