import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveAssignment = assignment => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/assignment/", assignment);
};
export const saveAssignmentWithTasks = assignment => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/assignment/save", assignment);
};
export const saveAssignmentTask = assignmentTask => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/assignment-task-save/",
        assignmentTask
    );
};

export const saveAssignmentTaskSubmitAttachments = assignmentTaskSubmit => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/assignment-task-submit-attachments/",
        assignmentTaskSubmit
    );
};

export const draftAssignmentTaskSubmitAttachments = assignmentTaskSubmit => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/assignment-task-submit-attachments/",
        assignmentTaskSubmit
    );
};

export const getAssignmentSubmitListByTaskId = taskId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-submitted-list-by-task-id/" + taskId
    );
};

export const updateAssignment = assignment => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/assignment/", assignment);
};
export const getAssignmentById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/assignment/" + id);
};

export const getAssignmentByGradingEvaluationId = gradingEvaluationId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/assignment-by-gradingEvaluationId/" + gradingEvaluationId);
};

export const getAssignmentTaskListByAssignmentId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-task-by-assignment-id/" + id
    );
};

export const getAssignmentTaskAndLoggedInUsersSubmissionListByAssignmentId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-task-and-submission-by-assignment-id/" + id
    );
};


export const findAssignmentTaskSubmitAttachments = assignmentTaskSubmitAttachmentsRequestDto => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/find-assignment-task-submit-attachments/", assignmentTaskSubmitAttachmentsRequestDto
    );
};
export const findAssignmentListByChannelId = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-by-channel-id/" + id
    );
};

export const getAssignmentListByLoggedInId = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-list-by-logged-in-user/"
    );
};
export const deleteAssignmentById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/assignment/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/assignments");
};
export const findAllAssignmentTaskSubmitByAssignmentIdAndStatus = assignmentId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/find-all-assignment-task-submit-by-assignment/" + assignmentId);
};
export const assignmentReportByAssignmentId = assignmentId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-report/" + assignmentId
    );
};
export const findUpcomingAssignmentListByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/upcoming-assignment-by-channel-id/" + channelId
    );
};
export const findPreviousAssignmentListByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/previous-assignment-by-channel-id/" + channelId
    );
};
export const getCalenderData = monthYear => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/get-calender-data/" + monthYear
    );
};

export const getArchivedQuestionPageable = payload => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/archived-question/by/logged-in-user", payload);
};

export const getArchivedMcqQuestionPageable = payload => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/archived-mcq-question", payload);
};

export const deleteArchivedQuestionByEvaluationId = evaluationId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/archived-question/delete-by-evaluation-id/" + evaluationId);
};

export const checkAssignmentDeadlineAndDuplicateSubmission = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/assignment/validate-submission/" + id);
};
