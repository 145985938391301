import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";


export const createRefreshToken = userInfo => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/auth/create-refresh-token", userInfo);
};

export const logoutUser = userInfo => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/auth/logout", userInfo);
};

export const getAccessTokenByRefreshToken = tokenRefreshRequest => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/auth/get-access-token-by-refresh-token", tokenRefreshRequest);
};

export const getRefreshTokenByUserId = userId => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/auth/get-refresh-token-by-userId", userId);
};

export const changePassword= forgetPasswordDTO => {
    return AxiosAgent.requests.put("/auth/change-password", forgetPasswordDTO);
};
