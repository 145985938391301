export const API_MAIN_ROOT = process.env.REACT_APP_JAVA_MAIN_APP_URL;
export const API_MAIN = process.env.REACT_APP_JAVA_MAIN_APP_FILE_URL;

export const API_INV_ROOT = process.env.REACT_APP_JAVA_APP_URL;

export const NG_ROOT = process.env.REACT_APP_ANGULAR_APP_URL;
export const FILES_URL = process.env.REACT_APP_FILE_URL
    ? process.env.REACT_APP_FILE_URL
    : process.env.REACT_APP_FILES_URL;

export const Ng = "/#";

export const home = "/home";

export const loginPage = "/";

export const login = "/login";

export const dashboard = "/dashboard";

export const register = "/register";

export const verifyOtp = "/verify-otp";

export const forgotPassword = "/forgot-password";

export const resetPassword = "/reset/finish";

export const address = "/address";

export const propertyBrowse = "/home";

export const myWallet = "/wallet-investor-client";

export const myDashboard = "/dashboard-smart-crowd";

export const country = "/country";

export const countryView = "/country-view";

export const countryNew = "/country-new";

export const states = "/states";

export const statesView = "/states-view";

export const emailVerification = "/confirm-email";

export const verifyEmail = "/verify-email";

export const statesNew = "/states-new";

export const promotion = "/promotion";

export const company = "/company";

export const industry = "/industry";

export const sportsTypeNew = "/sports-type-new";

export const sportsType = "/sports-type";

export const sportsTypeView = "/sports-type-view";

export const userTypeNew = "/user-type-new";

export const userType = "/user-type";

export const userTypeView = "/user-type-view";

export const adminPostNew = "/admin-post-new";

export const adminPost = "/admin-post";

export const adminPostView = "/admin-post-view";

export const post = "/post";

export const postView = "/post-view";

export const channelPostView = "/post";

export const postDetails = "/post-details";

export const changePassword = "/change-password";

export const subjectNew = "/subject-new";

export const subject = "/subject";

export const subjectView = "/subject-view";

export const employeeNew = "/employee-new";

export const employeeList = "/employee-list";

export const employeeRegistration = "/employee-registration";

export const employeeBulkRegistration = "/employee-bulk-registration";

export const employeeView = "/employee-view";

export const assignmentNew = "/assignment-new";

export const assignment = "/assignment";

export const newChannel = "/create-room";
export const newChannelXlsx = "/create-room-xlsx";

export const editChannel = "/edit-channel";

export const groupList = "/group-list";

export const groupEdit = "/group-edit";

export const groupView = "/group-view";

export const channelBoard = "/posts";

export const findYourAccount = "/find-you-account"

export const resetPasswordByOtp = "/reset-password-otp"

export const channelStudyMaterialUpload = "/channel-material-upload";

export const channelStudyMaterialView = "/channel-material-view";

export const channelList = "/room-list";

export const assignmentView = "/assignment-view";

export const classSetupNew = "/class-setup-new";

export const studentCourseHistory = "/student-course-history";

export const classSetup = "/class-setup";

export const classSetupEdit = "/class-setup-edit";

export const classSetupView = "/class-setup-view";

export const assignmentSubmit = "/assignment-submit";

export const assignmentSubmitList = "/assignment-submit-list";

export const assignmentSubmitEdit = "/assignment-submit";

export const assignmentSubmitView = "/assignment-submit";

export const assignmentSubmittedView = "/assignment-submitted-view";

export const assignmentTaskSubmittedView = "/assignment-task-submitted-view";

export const assignmentListByUser = "/assignment-by-user";

export const classListForLoggedInUser = "/class-list";

export const eventsNew = "/events-setup-new";

export const eventsSetupList = "/events-setup";

export const eventsEdit = "/events-setup-edit";

export const eventsSetupView = "/events-setup-view";

export const eventsSetupViewForUser = "/events-view-user";

export const allEventsList = "/all-events";

export const gradingNew = "/grading-new";

export const gradingByChannelId = "/grading-by-channel-id";

export const gradingBreakdowns = "/grading-break";

export const gradingBreakdownView = "/grading-breakdown-view";

export const gradingEvaluationEntry = "/grading-evaluation-entry";

export const gradingEvaluationEdit = "/grading-evaluation-edit";

export const gradingEvaluationMarksEntry = "/marks-entry";

export const attendanceEntry = "/attendance-entry";

export const attendanceReport = "/attendance-report";

export const gradeBook = "/grade-book";

export const assessmentList = "/assessment-list";

export const allAssessmentList = "/all-assessment-list";

export const assessmentMarksEntry = "/assessment-marks-entry";

export const calenderPage = "/calender-page";

export const routineEntry = "/routine-entry";

export const routineEdit = "/routine-edit";

export const routineListByChannelId = "/routine-list-by-channel-id";

export const routineDetails = "/routine-details";

export const messageFirstPage = "/direct-message-first";

export const message = "/direct-message-new";

export const messageList = "/direct-message-list";

export const messageThread = "/message-thread";

export const channelPostApproval = "/channel-post-approval";

export const mcqNew = "/mcq-new";

export const mcqExam = "/mcq-exam";

export const allMcqQuestions = "/mcq-by-channel";

export const mcqSubmittedView = "/mcq-submitted-view";

export const mcqSubmissions = "/mcq-submissions";

export const studentsHistory = "/students-history";
export const studentsGradeBook = "/students-grade-book";

export const attendanceHistory = "/attendance-history";

export const channelFileAttachmentUpload = "/channel-upload-study-materials";

export const channelStudyMaterials = "/channel-attachments-list";

export const channelEditStudyMaterials = "/channel-edit-study-materials";

export const studentsList = "/students";

export const studentRegistrationUpload = "/student-registration-upload";

export const getStudent = "/student-edit";

export const getStudentDetails = "/student-details";

export const studentProfile = "/profile";

export const teacherProfile = "/teacher-profile";
export const parentProfile = "/parent-profile";

export const semesterList = "/semester-list";

export const semesterEdit = "/semester-edit";

export const semesterView = "/semester-view";

export const semesterNew = "/semester-new";

export const facultyNew = "/faculty-new";

export const facultyList = "/faculty-list";

export const facultyEdit = "/faculty-edit";

export const departmentList = "/department-list";

export const departmentNew = "/department-new";

export const evaluationQuestion = "/evaluation-question-new";

export const evaluationQuestionList = "/evaluation-question-list";

export const evaluationQuestionOption = "/evaluation-question-option-new";

export const evaluationQuestionOptionList = "/evaluation-question-option-list";

export const evaluationQuestionType = "/evaluation-question-type";

export const evaluationQuestionTypeList = "/evaluation-question-type-list";

export const evaluationQuestionMain = "/evaluation-question-main-new";

export const evaluationQuestionMainList = "/evaluation-question-main-list";

export const teacherEvaluation = "/teacher-evaluation";

export const evaluationInfoList = "/evaluation-info-list";

export const evaluationInfo = "/evaluation-info";

export const evaluationResult = "/evaluation-result";

export const evaluationCompleteStudentList = "/evaluation-complete-student-list";

export const programList = "/program-list";

export const programNew = "/program-new";

export const allNotifications = "/all-notifications";

export const privacyPolicy = "/privacy-policy";

export const letterGradeNew = "/letter-grade-master";

export const UserManagementRoleNew = "/um-role-new";

export const UserManagementRoleList = "/um-role-list";

export const UserManagementUserRoleNew = "/um-user-role";

export const channelRankingSetup = "/room-post-ranking-setup";

export const channelPostRankingView = "/room-post-ranking-room-view";

export const courseWiseStudentInfo = "/student-info";

export const deleteUser = "/delete-user";

export const followerFollowing = "/follower-following";

export const opportunities = "/opportunities";

export const promotions = "/promotions";
export const editProfile = "/edit-profile";
export const userRegistration = "/sign-up";
export const liveMatches = "/live-matches";
export const liveStreams = "/live-streams";
export const streamsPlayer = "/stream";



export const getFullUrl = url =>
    process.env.REACT_APP_ANGULAR_APP_URL + process.env.PUBLIC_URL + url;

export const goToUrl = url =>
    (window.location.href = process.env.PUBLIC_URL + url);

export const goToNg = url => (window.location.href = NG_ROOT + Ng + url);
