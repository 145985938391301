import React from "react";

const TextFieldComponent = ({
                                field,
                                type = "text",
                                form: {touched, errors},
                                ...rest
                            }) => {
    return (
        <div className="input-group">
            <input
                type={type}
                className="form-control"
                aria-label="industry"
                aria-describedby="basic-addon1"
                {...field}
                {...rest}
            />
        </div>
    );
};

export default TextFieldComponent;
