import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveEmployeeRole = employeeRole => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/employee-role/",
        employeeRole
    );
};

export const updateEmployeeRole = employeeRole => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/employee-role/",
        employeeRole
    );
};
export const getEmployeeRoleById = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/employee-role/" + id);
};
export const deleteEmployeeRoleById = id => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/employee-role/" + id);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/employee-role");
};
