import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveMcqNew = data => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/mcq/save-mcq", data);
};

export const findAllMcqByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/mcq/get-all-by-channel-id/" + channelId
    );
};
export const getMcqByQuestionId = questionId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/mcq/" + questionId);
};
// mcq/{mcqMainId}
// mcq/save-answer

export const updateGrading = grading => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/grading/", grading);
};
export const getGradingById = gradingId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading/" + gradingId);
};

export const findGradingBreakDownByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grading-breakdown-by-channel-id/" + channelId
    );
};
export const deleteGradingById = gradingId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/grading/" + gradingId);
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading");
};
export const getSubmissionList = (mainId, userId) => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/mcq/submission-list/" + mainId + "/" + userId);
};
export const getSubmissionReport = (mainId) => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/mcq/submission-report/" + mainId);
};
export const findMcqMain = (mainId) => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/mcq/main/" + mainId);
};

export const submitMcqAnswer = (id, status) => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + `/mcq/save-answer/${id}/${status}`
    );
};
export const finalMcqSubmit = (id) => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + `/mcq/final-submit/${id}`
    );
};
