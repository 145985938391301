import React from "react";
import * as Utils from "../../util/Utils";
import Loader from "../common/Loader";

function Error(props) {
    const {errors, field} = props;
    const FieldError = ({field, error}) => {
        if (field === error.field) {
            return (
                <div className="validation-error">
                    <small className={"error_text_danger"}>{error.message}</small>
                </div>
            );
        } else {
            return <div/>;
        }
    };
    return (
        <div>
            {errors &&
            errors.map(error => <FieldError field={field} error={error}/>)}
        </div>
    );
}

function NumberField(props) {
    return (
        <input
            type={"text"}
            {...props}
            onPaste={event => {
                let paste = (event.clipboardData || window.clipboardData).getData(
                    "text"
                );
                if (/^[0-9]*$/.test(paste) === false) {
                    event.preventDefault();
                }
            }}
            onInput={event => {
                if (
                    event.target.value.length > props.maxLength ||
                    +event.target.value > props.maxval ||
                    +event.target.value < props.minval ||
                    /^[0-9]*$/.test(event.target.value) === false
                ) {
                    event.target.value = event.target.value.substring(
                        0,
                        event.target.value.length - 1
                    );
                }
            }}
        />
    );
}

function NameField(props) {
    return (
        <input
            type={"text"}
            {...props}
            onKeyPress={event =>
                event.target.value.length >= props.maxLength
                    ? event.preventDefault()
                    : true
            }
            onInput={event => {
                if (event.target.value === " ") {
                    event.target.value = event.target.value.substring(
                        0,
                        event.target.value.length - 1
                    );
                }
            }}
            onPaste={event => {
                let paste = (event.clipboardData || window.clipboardData).getData(
                    "text"
                );
                if (
                    Utils.hasNumber(paste) ||
                    new RegExp("^[ a-zA-Z-_'.]*$").test(paste) === false
                ) {
                    event.preventDefault();
                }
            }}
            onKeyDown={event => {
                if (new RegExp("^[ a-zA-Z-_'.]*$").test(event.key) === false) {
                    event.preventDefault();
                }
            }}
        />
    );
}

function NoSpecialCharacterField(props) {
    return (
        <input
            type={"text"}
            {...props}
            onInput={event => {
                if (event.target.value === " ") {
                    event.target.value = event.target.value.substring(
                        0,
                        event.target.value.length - 1
                    );
                }
            }}
            onPaste={event => {
                let paste = (event.clipboardData || window.clipboardData).getData(
                    "text"
                );
                if (new RegExp("^[ 0-9a-zA-Z-_~.]*$").test(paste) === false) {
                    event.preventDefault();
                }
            }}
            onKeyDown={event => {
                if (new RegExp("^[ 0-9a-zA-Z-_~.]*$").test(event.key) === false) {
                    event.preventDefault();
                }
            }}
        />
    );
}

function FormLoader(props) {
    const {form} = props;
    return (
        <React.Fragment>
            {form.loading ? <Loader/> : <React.Fragment/>}
        </React.Fragment>
    );
}

export {Error, FormLoader, NumberField, NameField, NoSpecialCharacterField};
