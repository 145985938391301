import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as StudentApi from "../../api/StudentApi";
import * as GradingCalculatedResultApi from "../../api/GradingCalculatedResultApi";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";
import {useNavigate, useParams} from "react-router-dom";
import {FILES_URL} from "../../util/LinkFactory";
import PageError from "../ErrorPage";
import * as ChannelApi from "../../api/ChannelApi";
import GradingTree from "../Grading/graddingTree";
import * as Utils from "../../util/Utils";

const defaultData = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    grade: "",
    section: "",
    fatherName: "",
    motherName: "",
    permanentAddress: "",
    presentAddress: "",
    photoName: "",
    regId: "",
    emergencyContactPerson: "",
    emergencyContact: "",
    bloodGroup: "",
    uuid: "",
    userId: null
};

function StudentDetails() {
    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const params = useParams();
    const [studentInfo, setStudentInfo] = useState(defaultData);
    const [loader, setLoader] = useState(false);
    const [gradingLoader, setGradingLoader] = useState(false);
    const [error, setError] = useState(false);
    const [channelList, setChannelList] = useState(false);
    const [gradeBooks, setGradeBooks] = useState(null);
    const [resultHistory, setResultHistory] = useState(null);
    let PhotoRootUrl;

    useEffect(() => {
        if (params.id) getStudentInfo(params.id);
    }, [params]);

    const getChannelLIst = id => {
        console.log("get channel list");
        ChannelApi.getChannelListByUserId(id).then(
            json => {
                setChannelList(json.data);
            }
        )
    }
    const generateReportForResultSheetCalculationByUserId = userId => {
        GradingCalculatedResultApi.generateReportForResultSheetCalculationByUserId(userId).then(response => {
            console.log(response)
            if (response.response.status === 200) {
                let blob = new Blob([response.data], {type: 'application/pdf'}),
                    url = window.URL.createObjectURL(blob)

                window.open(url)
            }
        })
    }
    const exportReport = () => {
        generateReportForResultSheetCalculationByUserId(Utils.getUserFromLocalStorage().id);
    }
    const gradeBookListByUserId = userId => {
        setGradingLoader(true);
        GradingCalculatedResultApi.getGradingForCalculatedResultByUserId(userId).then(res => {
            console.log(res.data);
            setGradeBooks(res.data);
            setGradingLoader(false);
        })
    }
    const resultSheetCalculationByStudentId = userId => {
        GradingCalculatedResultApi.resultSheetCalculationByStudentId(userId).then(res => {
            console.log(res.data);
            setResultHistory(res.data);
        })
    }
    const channelTypeConversion = channelType => {
        if (channelType === 'class')
            return "Class"
        else if (channelType === 'co-currullar')
            return "Co - Curricular"
        else if (channelType === 'organizational')
            return "Institute Room"
    }

    const getStudentInfo = id => {
        setLoader(true);
        StudentApi.getStudentInfoById(id)
            .then(json => {
                PhotoRootUrl = SERVER_NAME_URL + json.data.photoName;
                getChannelLIst(json.data.userId);
                gradeBookListByUserId(json.data.userId);
                resultSheetCalculationByStudentId(json.data.userId);
                let response = json.data || defaultData;
                setStudentInfo(response);
            })
            .catch(errors => {
                setError(true);
            })
            .finally(() => setLoader(false));
    };

    function goBack() {
        navigateTo(-1)
    }

    if (studentInfo && !loader) {
        return (
            <>
                <CommonLayout headerName={"Student Information"}>
                    <main>
                        <div className="card-default student-details container-responsive">
                            <div className="  pb-3">
                                {error ? (
                                    <PageError title={"No data to show"}/>
                                ) : (
                                    <div>
                                        <div className="title-header ">
                                            <div className="mb-0 d-flex align-items-center">
                                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                                    <a onClick={goBack} className="pl-0 back-button">
                                                        <i className="fa fa-chevron-left"> </i> </a>
                                                </div>
                                                <h4 className="avatar fw-600">
                                                    <img
                                                        style={{
                                                            width: "70px",
                                                            height: "70px",
                                                            marginRight: "10px"
                                                        }}
                                                        onError="this.src='img/Avatar.png';"
                                                        src={studentInfo?.photoName ? FILES_URL + studentInfo?.photoName : "img/Avatar.png"}
                                                        className="rounded-circle p-1"
                                                    />
                                                    {studentInfo?.firstName} {studentInfo?.middleName}{" "}
                                                    {studentInfo?.lastName}
                                                </h4>
                                            </div>
                                        </div>

                                        <div className="tab-blue">
                                            <div className="tabs">
                                                <ul
                                                    className="nav nav-pills mb-4 mx-4 pl-0"
                                                    id="pills-tab3"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            id="pills-assignment-upcoming"
                                                            data-toggle="pill"
                                                            href="#pills-assignment"
                                                            role="tab"
                                                            aria-controls="pills-assignment"
                                                            aria-selected="true"
                                                        >
                                                            Student Info
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            id="pills-assignment-inprogress"
                                                            data-toggle="pill"
                                                            href="#pills-inprogress"
                                                            role="tab"
                                                            aria-controls="pills-inprogress"
                                                            aria-selected="false"
                                                        >
                                                            Enrolled Rooms Info
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            id="pills-student-grade"
                                                            data-toggle="pill"
                                                            href="#pills-grade"
                                                            role="tab"
                                                            aria-controls="pills-grade"
                                                            aria-selected="false"
                                                        >
                                                            Grade Book
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            id="pills-student-result"
                                                            data-toggle="pill"
                                                            href="#pills-result"
                                                            role="tab"
                                                            aria-controls="pills-result"
                                                            aria-selected="false"
                                                        >
                                                            Result Sheet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div
                                                        className="tab-pane fade show active"
                                                        id="pills-assignment"
                                                        role="tabpane2"
                                                        aria-labelledby="pills-assignment-upcoming"
                                                    >
                                                        <div className=" mx-auto">
                                                            <div
                                                                className="table-responsive table-light table-shadow ">
                                                                <table
                                                                    className="table table-light table-hover"
                                                                    id="dataTable"
                                                                    width="100%"
                                                                    cellSpacing="0">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td width="30%"> Student Full Name</td>
                                                                        <td>
                                                                            {studentInfo?.firstName} {studentInfo?.middleName}{" "}
                                                                            {studentInfo?.lastName}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Student Photo</td>
                                                                        <td>
                                                                            {studentInfo?.photoName ? (
                                                                                <img
                                                                                    style={{
                                                                                        width: "100px",
                                                                                        height: "auto"
                                                                                    }}
                                                                                    className="radius-10"
                                                                                    src={FILES_URL + studentInfo?.photoName}
                                                                                    alt={studentInfo?.firstName}
                                                                                />
                                                                            ) : (
                                                                                "No photo available"
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Father Name</td>
                                                                        <td>{studentInfo?.fatherName}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> Mother Name</td>
                                                                        <td>{studentInfo?.motherName}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> Grade</td>
                                                                        <td>{studentInfo?.grade?.gradeName}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> Section</td>
                                                                        <td>{studentInfo?.section?.sectionName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Faculty</td>
                                                                        <td>{studentInfo?.department?.faculty?.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Department</td>
                                                                        <td>{studentInfo?.department?.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Session</td>
                                                                        <td>{studentInfo?.session}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> Permanent Address</td>
                                                                        <td>{studentInfo?.permanentAddress}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> Present Address</td>
                                                                        <td>{studentInfo?.presentAddress}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> Emergency Contact Person</td>
                                                                        <td>{studentInfo?.emergencyContactPerson}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> Emergency Contact Number</td>
                                                                        <td>{studentInfo?.emergencyContact}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Blood Group</td>
                                                                        <td>{studentInfo?.bloodGroup}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Health Concern</td>
                                                                        <td>{studentInfo?.healthConcern}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Contact Number</td>
                                                                        <td>{studentInfo?.contactNo}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="tab-pane fade"
                                                        id="pills-inprogress"
                                                        role="tabpane2"
                                                        aria-labelledby="pills-assignment-inprogress"
                                                    >
                                                        <div
                                                            className="table-responsive table-light table-shadow table-striped ">
                                                            <table
                                                                className="table table-light table-hover"
                                                                id="dataTable"
                                                                width="100%"
                                                                cellSpacing="0"
                                                            >
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">id</th>
                                                                    <th scope="col">Room Name</th>
                                                                    <th scope="col">Room Type</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {channelList.length > 0
                                                                    ? channelList.map((data, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <td>{data.channelName}</td>
                                                                                <td>{channelTypeConversion(data.channelType)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : ""}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="tab-pane fade"
                                                        id="pills-grade"
                                                        role="tabpane3"
                                                        aria-labelledby="pills-student-grade"
                                                    >
                                                        <h5 className="mt-4 ml-4 mb-3">Grade Book</h5>
                                                        {!gradingLoader ?
                                                            <div>
                                                                {gradeBooks?.length > 0 ? (
                                                                    gradeBooks.map((gradeBook, index) => {
                                                                        return <>
                                                                            <div
                                                                                className="table-responsive table-light  border-bottom  mb-5">
                                                                                <table
                                                                                    className="table table-light table-hover mb-0"
                                                                                    id="dataTable"
                                                                                    width="100%"
                                                                                    cellSpacing="1"
                                                                                >
                                                                                    <thead>
                                                                                    <tr className="text-muted">
                                                                                        <th scope="col">Room Name</th>
                                                                                        {gradeBook?.gradingBreakdownList?.length > 0 ? (
                                                                                            gradeBook.gradingBreakdownList.map(
                                                                                                (gradeBreakdown, index) => {
                                                                                                    return (
                                                                                                        <th scope="col">
                                                                                                            {gradeBreakdown.evaluationName +
                                                                                                                (null != gradeBreakdown.fullScore ? "(" +
                                                                                                                    gradeBreakdown.fullScore +
                                                                                                                    ")" : "")}
                                                                                                        </th>
                                                                                                    );
                                                                                                }
                                                                                            )
                                                                                        ) : (
                                                                                            <th scope="col">
                                                                                                No result information
                                                                                                found
                                                                                            </th>
                                                                                        )}
                                                                                    </tr>
                                                                                    </thead>

                                                                                    <tbody>
                                                                                    {gradeBook?.gradingResultStudentDTOList?.length > 0
                                                                                        ? gradeBook.gradingResultStudentDTOList.map(
                                                                                            (studentGradeBook, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={index}>
                                                                                                            <td>
                                                                                                                {gradeBook?.channel?.channelName || ""}
                                                                                                            </td>

                                                                                                            {studentGradeBook?.gradeBookBreakdownDTOList.map(
                                                                                                                (studentGradeBreakdown, index) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <td>
                                                                                                                                <GradingTree
                                                                                                                                    gradeBooks={studentGradeBreakdown}
                                                                                                                                />
                                                                                                                            </td>
                                                                                                                        </>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        : <td>
                                                                                            {gradeBook?.channel?.channelName || ""}
                                                                                        </td>}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </>;
                                                                    })) : ""
                                                                }

                                                            </div> : <Loader/>}

                                                    </div>
                                                    <div
                                                        className="tab-pane fade"
                                                        id="pills-result"
                                                        role="tabpane2"
                                                        aria-labelledby="pills-student-result">
                                                        <h3>CGPA: {resultHistory ? Utils.twoDecimalPoint(resultHistory?.cgpa) : 0.0}
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-dark radius-25 float-right"
                                                                onClick={() => exportReport()}
                                                            >
                                                                <i className="fa fa-check-circle mr-2"/>
                                                                Export Result Sheet
                                                            </button>
                                                        </h3>
                                                        {resultHistory && resultHistory?.resultSemesterDtoList?.length > 0 ? (
                                                            resultHistory?.resultSemesterDtoList?.map((result, index) => {
                                                                return <>
                                                                    <div className="table-responsive mt-4">
                                                                        <h6 className="mb-4 fw-600">{result.semesterName + " " + new Date().getFullYear(result.semesterYear) + " (SGPA " + Utils.twoDecimalPoint(result.sgpa) + ")"}</h6>
                                                                        <table
                                                                            className="table"
                                                                            id="dataTable"
                                                                            width="100%"
                                                                            cellSpacing="0">

                                                                            <thead className="bg-light">
                                                                            <tr>
                                                                                <th scope="col">Subject</th>
                                                                                <th scope="col">GPA</th>
                                                                                <th scope="col">Grade</th>
                                                                                <th scope="col">Obtained Score</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {result.resultSubjectWiseDtoList.length > 0 ? result.resultSubjectWiseDtoList.map((data, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <th scope="row">{data.subjectName}</th>
                                                                                        <td>{Utils.oneDecimalPointWithRounding(data?.obtainedScore?.gradePoint)}</td>
                                                                                        <td>{data?.obtainedScore?.obtainedLetterGrade}</td>
                                                                                        <td>{"(" + Utils.oneDecimalPointWithRounding(data.obtainedScore.obtainedScore) + "/" + Utils.oneDecimalPointWithRounding(data.obtainedScore.fullScore) + ")"}</td>
                                                                                    </tr>
                                                                                );
                                                                            }) : ""
                                                                            }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </>;

                                                            })) : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </main>
                </CommonLayout>
            </>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default StudentDetails;
