import React from "react";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";

function RightBar(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const handleClickAllOpportunities = () =>{
        navigateTo(LinkFactory.opportunities)
    }

    const handleClickAllPromotions = () =>{
        navigateTo(LinkFactory.promotions)
    }

    return (
        <>
            <div className="block-right card-default">
                <div className="card-default-transparent">
                    <div className="rightbar-card">
                        <div className="d-flex justify-content-center mb-1">
                            <h5>Opportunities</h5>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/company.png" alt="Company Icon" className="icon"/>
                                    <strong>Company:</strong>
                                </div>
                                    <p> Pro holmes sports...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/opportunity.png" alt="Opportunity Icon" className="icon"/>
                                    <strong>Opportunity:</strong>
                                </div>
                                    <p>Graduate Assistant...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/industry.png" alt="Industry Icon" className="icon"/>
                                    <strong>Industry:</strong>
                                </div>
                                    <p>General Labor</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/expiration-date.png" alt="Date Icon" className="icon"/>
                                    <strong>Expiration Date:</strong>
                                </div>
                                    <p> 2024-1-20</p>
                            </div>
                        </div>
                        <div className="description">
                            <h4>Description</h4>
                            <p>The central focus for the position is to coordinate and assist with all aspects of the
                                team by performing the...<a href="#">Read more</a></p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn-red-md view-more" onClick={handleClickAllOpportunities}>View 12 more</button>
                        </div>
                    </div>
                    <div className="rightbar-card">
                        <div className="d-flex justify-content-center mb-1">
                            <h5>Promotions</h5>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/company.png" alt="Company Icon" className="icon"/>
                                    <strong>Company:</strong>
                                </div>
                                <p> Pro holmes sports...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/opportunity.png" alt="Opportunity Icon" className="icon"/>
                                    <strong>Opportunity:</strong>
                                </div>
                                <p>Graduate Assistant...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/industry.png" alt="Industry Icon" className="icon"/>
                                    <strong>Industry:</strong>
                                </div>
                                <p>General Labor</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/expiration-date.png" alt="Date Icon" className="icon"/>
                                    <strong>Expiration Date:</strong>
                                </div>
                                <p> 2024-1-20</p>
                            </div>
                        </div>
                        <div className="description">
                            <h4>Description</h4>
                            <p>The central focus for the position is to coordinate and assist with all aspects of the
                                team by performing the...<a href="#">Read more</a></p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn-red-md view-more" onClick={handleClickAllPromotions}>View 12 more</button>
                        </div>
                    </div>
                    <div className="rightbar-card">
                        <div className="d-flex justify-content-center mb-1">
                            <h5>Upcoming Events</h5>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/event-name.png" alt="Event Name Icon" className="icon"/>
                                    <strong>Event Name:</strong>
                                </div>
                                <p> Pro holmes sports...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/Event-venue.png" alt="Event Venue Icon" className="icon"/>
                                    <strong>Event Venue:</strong>
                                </div>
                                <p>Graduate Assistant...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/expiration-date.png" alt="Date Icon" className="icon"/>
                                    <strong>Event Date:</strong>
                                </div>
                                <p> 2024-1-20</p>
                            </div>
                        </div>
                        <div className="description">
                            <h4>Description</h4>
                            <p>The central focus for the position is to coordinate and assist with all aspects of the
                                team by performing the...<a href="#">Read more</a></p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn-red-md view-more">View 12 more</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default RightBar;
