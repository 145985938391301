import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import * as ModalActions from "../../action/ModalActions";

const mapStateToProps = state => ({...state.modal});

const mapDispatchToProps = dispatch => ({
    handleClose: () => dispatch(ModalActions.closeModal())
});

class MyModal extends Component {
    constructor() {
        super();
    }

    render() {
        const {
            visible,
            heading,
            body,
            onSave,
            initialFooterState,
            footer
        } = this.props;
        return (
            <div>
                <Modal
                    show={visible}
                    onHide={this.props.handleClose}
                    dialogClassName={this.props.className}
                >
                    <Modal.Header closeButton>
                        {/*<Modal.Title>{heading}</Modal.Title>*/}
                        {heading}
                    </Modal.Header>
                    <Modal.Body>{body}</Modal.Body>
                    {initialFooterState && initialFooterState.visible ? (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.props.handleClose}>
                                {initialFooterState.button1}
                            </Button>
                            <Button variant="primary" onClick={onSave}>
                                {initialFooterState.button2}
                            </Button>
                        </Modal.Footer>
                    ) : (
                        <div/>
                    )}
                    {footer ? <Modal.Footer>{footer}</Modal.Footer> : <div/>}
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyModal);
