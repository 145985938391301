import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";
import Cookies from "js-cookie";

export const getPostWithContentsById = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/post-with-contents-by-id/" + id
    );
};
export const updatePostStatus = (id, status) => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/post-status-update/" + id + "/" + status
    );
};
export const deleteCountryById = userId => {
    return AxiosAgent.requests.delete(API_MAIN_ROOT + "/country/" + userId);
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/posts");
};
export const getAllAdminPosts = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/admin-posts");
};
export const savePost = async (postText, files) => {
    const headers = new Headers();
    headers.append("Authorization", Cookies.get("auth-token"));
    const body = new FormData();
    body.append("postText", postText);
    for (let i = 0; i < files.length; i++) {
        body.append("files", files[i]);
    }
    const requestOptions = {
        method: "POST",
        headers: headers,
        body: body,
        redirect: "follow"
    };
    return await fetch(API_MAIN_ROOT + `/main-post`, requestOptions);
};
