import React, {useEffect, useState} from "react";
import * as RoutineApi from "../../api/RoutineApi";

import * as LinkFactory from "../../util/LinkFactory";

import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";
import Loader from "../common/Loader";
import {useNavigate, useParams} from "react-router-dom";
import * as GradingApi from "../../api/GradingApi";

function RoutineListByChannel(props) {
    const params = useParams();

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [routines, setRoutines] = useState([]);
    const [channel, setChannel] = useState({});
    const [loader, setLoader] = useState(true);
    const [gradebook, setGradebook] = useState(null);

    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });

    useEffect(() => {
        loadList();
        getGradeBookForThisRoom(params?.id);
    }, []);

    const loadList = () => {
        setLoader(true);
        RoutineApi.getRoutineListByChannelId(params.id)
            .then(json => {
                setRoutines(json.data);
            })
            .catch(errors => {
                console.log(errors);
            })
            .finally(setLoader(false));
    };
    const handleView = data => {
        navigateTo(LinkFactory.routineDetails + "/" + data.id);
    };
    const handleStatusUpdate = data => {
        let routineStatusUpdateDTO = {
            routineId: data.id, status: !data.status
        };
        RoutineApi.routineStatusUpdate(routineStatusUpdateDTO).then(response => {
            console.log(response);
            loadList();
        });
    };

    const getGradeBookForThisRoom = (channelId) => {
        GradingApi.getGradingByChannelId(channelId).then(res => {
            console.log(res.response.status);

            if (res.response.status !== 500) {
                setGradebook(res.data);
            }
        })
    }

    const handleNewRoutine = () => {
        navigateTo(LinkFactory.routineEntry + "/" + params.id);
    };
    const handleEditRoutine = (data) => {
        navigateTo(LinkFactory.routineEdit + "/" + params.id + "/" + data.id);
    };

    function goBack() {
        navigateTo(-1)
    }

    return (<>
        <CommonLayout headerName={Utils.getCurrentChannelName()}>
            <main>
                <div className="card-default routine-list">
                    <div className="title-header ">
                        <div className="mb-0 d-flex align-items-center">
                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                <a onClick={goBack} className="pl-0 back-button">
                                    <i className="fa fa-chevron-left"> </i> </a>
                            </div>
                            <p>Routine</p>
                        </div>
                        <div>
                            {routines.length === 0 && Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_EXAM_CONTROLLER"]) ? <button
                                type="button"
                                className="btn btn-red-md float-right"
                                onClick={() => handleNewRoutine()}>
                                <svg width="15" height="16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.429 15.5V9.071H0V6.93h6.429V.5H8.57v6.429H15V9.07H8.571V15.5H6.43Z" fill="#fff"/>
                                </svg>
                                Create New Routine
                            </button> : null}
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className="table-responsive table-light ">
                                <table className="table table-light"
                                       id="dataTable"
                                       width="100%"
                                       cellSpacing="0">
                                    <thead>
                                    <tr>
                                        {/*<th scope="col">id</th>*/}
                                        <th scope="col">Semester</th>
                                        {/*<th scope="col">Making Period Start</th>*/}
                                        {/*<th scope="col">Making Period End</th>*/}
                                        <th scope="col">Status</th>
                                        <th scope="col">Semester Timeline</th>
                                        <th scope="col" width="15%" className=" ">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {routines.length > 0 ? routines.map((data, index) => {
                                            return (<tr>
                                                {/*<th scope="row">{data.id}</th>*/}
                                                <td>{data?.channel?.semester?.semesterName}</td>
                                                {/*<td>{data.grading.start}</td>*/}
                                                {/*<td>{data.grading.end}</td>*/}
                                                <td>{data?.status ? "Active" : "Inactive"}</td>
                                                <td> Jan 23 - Oct 23</td>
                                                <td className=" ">
                                                    <button
                                                        type="button"
                                                        title="View"
                                                        className="btn btn-sm bg-c-blue-light mr-1"
                                                        onClick={() => handleView(data)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="17" height="12" fill="none">
                                                            <path fill="#4575EE"
                                                                  d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                        </svg>
                                                    </button>
                                                    &nbsp;
                                                    {Utils.isUserHasGradingFinalizedAccessPermissionCheckByGradeBook(gradebook) ?
                                                        <button
                                                            type="button"
                                                            title="Edit"
                                                            className="btn btn-sm bg-c-light-orange mr-1"
                                                            onClick={() => handleEditRoutine(data)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 width="18" height="15" fill="none">
                                                                <path fill="#000"
                                                                      d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                            </svg>
                                                        </button> : null}
                                                    &nbsp;
                                                    {Utils.isUserHasGradingFinalizedAccessPermissionCheckByGradeBook(gradebook) ?
                                                        <button
                                                            type="button"
                                                            title="Active/Inactive"
                                                            className="btn btn-sm p-0"
                                                            onClick={() => handleStatusUpdate(data)}>
                                                            <div aria-hidden="true">
                                                                {!data.status && (
                                                                    <i className="fa fa-toggle-off" style={{
                                                                        color: "white",
                                                                        transition: "2s",
                                                                        background: "red",
                                                                        fontSize: "15px",
                                                                        height: "29px",
                                                                        width: "31px",
                                                                        borderRadius: "4px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}/>
                                                                )}
                                                                {data.status && (
                                                                    <i className="fa fa-toggle-on" style={{
                                                                        color: "white",
                                                                        transition: "2s",
                                                                        background: "limegreen",
                                                                        fontSize: "15px",
                                                                        height: "29px",
                                                                        width: "31px",
                                                                        borderRadius: "4px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}/>
                                                                )}
                                                            </div>
                                                            {data.status ? "Inactive" : "Active"}
                                                        </button> : null}
                                                </td>
                                            </tr>);
                                        }) :
                                        <td colSpan="12" className="text-center">
                                            {loader ? (
                                                <Loader/>
                                            ) : (
                                                <div className="no-routine">
                                                    <img src="img/no-routine.png" alt="No routine has yet created"/>
                                                    <p>No Routine has yet Created</p>
                                                </div>
                                            )}
                                        </td>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </CommonLayout>
    </>);
}

export default RoutineListByChannel;
