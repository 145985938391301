import {ERROR_SHOW, ERROR_UNLOADED} from "../constants/ActionTypes";

class SystemErrorAction {
    static setError(error) {
        return {type: ERROR_SHOW, error: error, category: "error"};
    }

    static setSuccess(msg) {
        return {type: ERROR_SHOW, error: msg, category: "success"};
    }

    static removeError() {
        return {type: ERROR_UNLOADED};
    }
}

export default SystemErrorAction;
