import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const getAssessmentListByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assessment-by-channel-id/" + channelId
    );
};
export const getPreviousAssessmentListByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/previous-assessment-by-channel-id/" + channelId
    );
};
export const getUpcomingAssessmentListByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/upcoming-assessment-by-channel-id/" + channelId
    );
};
export const allUpcomingAssessmentsByLoggedInUser = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/all-upcoming-assessment-by-logged-in-user"
    );
};
export const allUpcomingAssessmentsByLoggedInUserAndChannelId = (channelId) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/all-upcoming-assessment-by-logged-in-user/" + channelId
    );
};
export const allUpcomingAssessmentsByLoggedInUserAndStatusAndChannelId = (channelId) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/all-upcoming-assessment-by-logged-in-user-and-status/" + channelId
    );
};
export const allPreviousAssessmentsByLoggedInUser = () => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/all-previous-assessment-by-logged-in-user"
    );
};
export const allPreviousAssessmentsByLoggedInUserAndChannelId = (channelId) => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/all-previous-assessment-by-logged-in-user/" + channelId
    );
};

export const allAssignmentByLoggedInUser = (param) => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/all-assignment-by-logged-in-user", param
    );
};

export const allAssessmentsByLoggedInUserAndStatus = (param) => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/all-assessment-by-logged-in-user-and-status", param
    );
};

export const AssessmentsStatusUpdate = id => {
    return AxiosAgent.requests.put(API_MAIN_ROOT + "/grading-evaluation/archive", id);
};
export const getAssessmentDTO = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-dto/" + id
    );
};

export const getAssignmentPageableByTypeAndChannelId = (payload) => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/all-assessment/by/channel-id", payload);
};
