import React, {useEffect, useState} from "react";
import * as StatesApi from "../../api/StatesApi";
import * as CountryApi from "../../api/CountryApi";
import {Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function StatesEdit(props) {
    const params = useParams();
    const [states, setStates] = useState({states: "", id: ""});
    const [country, setCountry] = useState([]);

    useEffect(() => {
        StatesApi.getStatesById(params.id)
            .then(json => {
                console.log(json);
                setStates(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });

        CountryApi.getAll().then(json => {
            setCountry(json.data);
        });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        StatesApi.updateStates(data);
    };

    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Country Edit</h1>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={states}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {props => (
                                    <Form>
                                        <div className="table-responsive">
                                            <table
                                                className="table table-bordered"
                                                id="dataTable"
                                                width="100%"
                                                cellSpacing="0"
                                            >
                                                <thead>
                                                <tr>
                                                    <th colSpan="12">#</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <label></label>
                                                        <Field
                                                            type="number"
                                                            name="id"
                                                            placeholder="id"
                                                            readonly
                                                            component={TextField}
                                                        />
                                                    </td>
                                                    <td>
                                                        <label></label>
                                                        <Field
                                                            type="text"
                                                            name="states"
                                                            placeholder="States"
                                                            component={TextField}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Field
                                                            type="text"
                                                            name="country"
                                                            placeholder="Country"
                                                            fieldName={"country"}
                                                            options={country || []}
                                                            component={SelectDropdown}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="12">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-lg btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>
        </>
    );
}

export default StatesEdit;
