import AxiosAgent from "./AxiosAgent";
import { API_MAIN_ROOT } from "../util/LinkFactory";


export const save = evalQuestMain => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question-main", evalQuestMain);
};

export const update = evalQuestMain => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/evaluation-question-main", evalQuestMain);
};

export const search = evalQuestMain => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question-main/search", evalQuestMain);
};

export const deleteEvaluationQuestion = id => {
  return AxiosAgent.requests.delete(API_MAIN_ROOT + "/evaluation-question-main/"+ id);
};

export const getEvaluationQuestionMainById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/evaluation-question-main/" + id);
};
