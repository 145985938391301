import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";
import Loader from "../common/Loader";
import {SERVER_NAME_URL} from "../../constants/Constants";

import * as LinkFactory from "../../util/LinkFactory";
import Swal from "sweetalert2";
import LoaderOverlay from "../common/LoaderOverlay";
import {Alert} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {convertUTCDateToLocalDate} from "../../util/DateUtils";
import * as GradingApi from "../../api/GradingApi";
import LinearProgress from "@mui/material/LinearProgress";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FileSaver from "file-saver";

function ChannelMaterialView(props) {

    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [channelMaterials, setChannelMaterials] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [channel, setChannel] = useState({});
    const [gradebook, setGradebook] = useState(null);
    const [deleteMaterialId, setDeleteMaterialId] = useState(null);
    const [tobeDeletedSingleMaterial, setTobeDeletedSingleMaterial] = useState(null);
    const FILE_URL = SERVER_NAME_URL;
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [singleItemDeleting, setSingleItemDeleting] = useState(false);
    const changePage = ({selected}) => {
        console.log(selected);
        setPageNumber(selected);
    }

    function handleViewDetailsButton(data) {
        setFileList(data.attachment);
        setModalShow(true)
    }

    const searchChannelMaterials = (search) => {
        setShowProgressBar(true);
        ChannelApi.searchChannelMaterials({
            "searchTerm": search?.searchTerm, 'channelId': params.id, "page": pageNumber,
        })
            .then(json => {
                setChannelMaterials(json.data.content);
                setPageCount(json.data.totalPages);
                setShowProgressBar(false);
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoader(false)
                setShowProgressBar(false);
            });
    }

    const getGradeBookForThisRoom = (channelId) => {
        GradingApi.getGradingByChannelId(channelId).then(res => {
            if (res.response.status !== 500) {
                setGradebook(res.data);
            }
        })
    }

    function deleteSingleAttachment(attachment) {
        setTobeDeletedSingleMaterial(attachment)
        setDeleteModalShow(true);
    }

    function confirmDeleteSingleAttachment() {
        setSingleItemDeleting(true);
        if (tobeDeletedSingleMaterial)
            ChannelApi.deleteChannelMaterialByAttachmentId(tobeDeletedSingleMaterial.attachmentId).then(json => {
                setModalShow(false);
                setDeleteModalShow(false);
                setDeleteLoader(false);
                setSingleItemDeleting(false);
                searchChannelMaterials();
                Swal.fire("Delete", "File deleted", "success");
            })
    }

    function confirmDeleteAllMaterial() {
        setDeleteLoader(true);
        if (deleteMaterialId)
            ChannelApi.deleteChannelMaterialById(deleteMaterialId).then(json => {
                searchChannelMaterials();
                setDeleteLoader(false);
                Swal.fire("Delete", "Material deleted", "success");
            });

    }

    function deleteAllMaterial(materialId) {
        setDeleteMaterialId(materialId);
    }

    useEffect(() => {
        setLoader(true);
        searchChannelMaterials();
        ChannelApi.getChannelById(params.id)
            .then(json => {
                setChannel(json.data);
            });
        getGradeBookForThisRoom(params.id);

    }, []);

    const isUserHasUploadMaterialPermission = () => {
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_EXAM_CONTROLLER"])) {
            return true;
        } else if (null == gradebook?.isFinalized && Utils.checkRolePermission(["ROLE_FACULTY", "ROLE_PRINCIPAL"])) {
            return true;
        }
        return false;
    }
    const handleNew = data => {
        navigateTo(LinkFactory.channelStudyMaterialUpload + "/" + params.id);
    };

    useEffect(() => {
        searchChannelMaterials();
    }, [pageNumber]);

    function downloadAll(files) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            var url = FILE_URL + (null != files[i].attachmentLocation ? files[i].attachmentLocation : files[i].attachmentName);
            console.log(url);
            FileSaver.saveAs(url, files[i].attachmentName);
        }
    }


    function DeleteSingleItemModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <div className="modal-content-without-border">
                        <div className="circle-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                <path fill="#D81414"
                                      d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>

                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className=" "
                        disabled={singleItemDeleting}
                        onClick={() => confirmDeleteSingleAttachment()}>
                        Delete
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => setDeleteModalShow(false)}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="global-repository"
            >
                <Modal.Body>
                    {fileList?.map((file, index) => {
                        return (<>
                                <span className="mx-2 my-1">
                                    <div className="attachment-box d-flex align-items-center">
                                        <div className="w-100 ">
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <p> {file.attachmentName}</p>
                                                <div className="d-flex">
                                                    <a title="Download"
                                                       className="btn bg-c-blue-light mr-1"
                                                       target="_blank"
                                                       href={FILE_URL + (null != file.attachmentLocation ? file.attachmentLocation : file.attachmentName)}>
                                                    <svg width="15"
                                                         height="14"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.875 14c-.516 0-.957-.171-1.325-.514C.183 13.142 0 12.73 0 12.25V9.625h1.875v2.625h11.25V9.625H15v2.625c0 .481-.184.893-.551 1.236a1.868 1.868 0 0 1-1.324.514H1.875ZM7.5 10.5 2.812 6.125l1.313-1.269 2.438 2.275V0h1.875v7.131l2.437-2.275 1.313 1.269L7.5 10.5Z"
                                                        fill="#4575EE"/>
                                                     </svg>
                                                        {" "}
                                                </a>
                                                    {Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY", "ROLE_PRINCIPAL", "ROLE_EXAM_CONTROLLER"]) ?
                                                        <a title="Delete Attachment"
                                                           className="btn bg-c-red-light"
                                                           onClick={() => deleteSingleAttachment(file)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 width="13" height="14" fill="none">
                                                                <path fill="#D81414"
                                                                      d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                            </svg>
                                                            {" "}
                                                        </a>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                        </>);
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    if (!loader) {
        return (<>
            <CommonLayout headerName={Utils.getCurrentChannelName()}>
                <main>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                    <DeleteSingleItemModal
                        show={deleteModalShow}
                        onHide={() => setDeleteModalShow(false)}
                    />
                    {channelMaterials ? <div className="global-repository card-default container-responsive">

                        <div className="title-header">
                            <div className="mb-0 d-flex">
                                <div className="card-header p-0 m-0 mr-2 bg-c-light-grey">
                                    <a href=" " className="pl-0 mb-2 back-button">
                                        <i className="fa fa-chevron-left mr-2"> </i> </a>
                                </div>
                                <h3 className="pl-0"> {channel.channelType === 'class' ? 'Study Materials' : 'Global Repository'}</h3>
                            </div>

                            {isUserHasUploadMaterialPermission() ? <span className="upload-materials ">
                                        <button
                                            type="button"
                                            className="btn bg-c-blue-light float-right"
                                            onClick={() => handleNew()}>
                                            <svg width="24" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                                d="M19.35 6.04A7.49 7.49 0 0 0 12 0C9.11 0 6.6 1.64 5.35 4.04A5.994 5.994 0 0 0 0 10c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96ZM19 14H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.47 5.47 0 0 1 12 2c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0 1 22 11c0 1.65-1.35 3-3 3ZM8 9h2.55v3h2.9V9H16l-4-4-4 4Z"
                                                fill="#4575EE"/></svg>
                                            Click here to upload files
                                        </button>
                                    </span> : null}
                            {Utils.checkRolePermission(["ROLE_STUDENT"]) ?
                                <div className="sort-by d-flex align-items-center mr-4">
                                    <button className="sort-by-button">
                                        <p>Sort by</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13"
                                             fill="none">
                                            <path stroke="#475467" stroke-linecap="round"
                                                  stroke-linejoin="round" stroke-width="2"
                                                  d="M3.75 6.5h9.5M1.375 1.75h14.25m-9.5 9.5h4.75"/>
                                        </svg>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>Most Downloaded</li>
                                        <li>Least Uploaded</li>
                                        <li>Date Uploaded</li>
                                    </ul>
                                </div> : null}
                        </div>

                        {deleteLoader ? <LoaderOverlay/> : null}
                        <div className=" ">
                            <div>
                                {showProgressBar ? (<LinearProgress color="primary"/>) : ""}
                                <div className="table-responsive  table-light table-striped ">
                                    <table
                                        className="table table-light table-hover"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            {/*<th scope="col">id</th>*/}
                                            <th scope="col">Asset Title</th>
                                            <th scope="col">Upload Date</th>
                                            <th scope="col">Attachment</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {channelMaterials.length > 0 ? (channelMaterials.map((data, i) => {
                                            return (<tr key={i}>
                                                <td>{data.title}</td>
                                                <td>{convertUTCDateToLocalDate(new Date(data.createTimeDate))}</td>
                                                <td>
                                                    <div className="d-flex flex-wrap">

                                                        <button
                                                            title="View"
                                                            type="button"
                                                            data-toggle="modal"
                                                            data-target="#viewAttachments"
                                                            onClick={() => {
                                                                handleViewDetailsButton(data)
                                                            }}
                                                            className="btn btn-sm bg-c-blue-light view-items-btn ">
                                                            <span>{data?.attachment?.length} Items</span>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className=" " width="15%">
                                                    <button
                                                        title="download"
                                                        type="button"
                                                        className="btn btn-sm bg-c-blue-light"
                                                        onClick={() => {
                                                            downloadAll(data?.attachment)
                                                        }}
                                                    >
                                                        <svg width="15" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M1.875 14c-.516 0-.957-.171-1.325-.514C.183 13.142 0 12.73 0 12.25V9.625h1.875v2.625h11.25V9.625H15v2.625c0 .481-.184.893-.551 1.236a1.868 1.868 0 0 1-1.324.514H1.875ZM7.5 10.5 2.812 6.125l1.313-1.269 2.438 2.275V0h1.875v7.131l2.437-2.275 1.313 1.269L7.5 10.5Z"
                                                                fill="#4575EE"/>
                                                        </svg>
                                                    </button>
                                                    <button
                                                        title="Delete Row"
                                                        type="button"
                                                        className="btn btn-sm ml-2 bg-c-red-light"
                                                        data-toggle="modal"
                                                        target="_blank"
                                                        data-target="#deleteModal"
                                                        onClick={() => deleteAllMaterial(data.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="13" height="14" fill="none">
                                                            <path fill="#D81414"
                                                                  d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                        </svg>
                                                    </button>

                                                </td>
                                            </tr>);
                                        })) : (<>
                                            <tr>
                                                <Alert variant="info">
                                                    <h6>
                                                        No Attachment found for this room
                                                    </h6>
                                                </Alert>
                                            </tr>
                                        </>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="page-navigation">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    </div> : <>
                        <Alert variant="info">
                            <h6>
                                No Attachment found for this room
                            </h6>
                        </Alert>
                    </>}
                </main>
            </CommonLayout>

            <div
                className="modal fade global-repository"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header  ">
                            <div className="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                    <path fill="#D81414"
                                          d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                                </svg>

                            </div>
                            <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className=" "
                                data-dismiss="modal"
                                onClick={() => confirmDeleteAllMaterial()}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
    return <Loader/>;
}

export default ChannelMaterialView;
