import React, {Fragment} from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import {animated, useSpring} from "react-spring";
import {withStyles} from "@mui/styles";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{width: 14, height: 14}}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z"/>
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: {opacity: 0, transform: "translate3d(20px,0,0)"},
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
        }
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool
};

const StyledTreeItem = withStyles(theme => ({
    iconContainer: {
        "& .close": {
            opacity: 0.3
        }
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed `
    }
}))(props => <TreeItem {...props} TransitionComponent={TransitionComponent}/>);

// const useStyles = makeStyles({
//     root: {
//         height: "auto",
//         flexGrow: 1,
//         maxWidth: 400
//     }
// });

function getLabel(first, second) {
    return first ? first : 0 + "/" + second ? second : 0;
}

export default function GradingTree({gradeBooks}) {
    // const classes = useStyles();
    console.log(gradeBooks);
    return (
        <TreeView

            // defaultExpanded={['1']}
            defaultCollapseIcon={<MinusSquare/>}
            defaultExpandIcon={<PlusSquare/>}
            defaultEndIcon={<CloseSquare/>}>
            <StyledTreeItem
                nodeId={gradeBooks.unqId}
                label={
                    gradeBooks.breakdownName ?
                        (gradeBooks.breakdownName !== "Obtained Score" ? gradeBooks.breakdownName : "") + (null == gradeBooks.obtainedScoreInfo ?
                            ("(" + gradeBooks.breakdownCalculatedResult + "/" + gradeBooks.breakdownFullScore + ")")
                            : ((null != gradeBooks.obtainedScoreInfo.obtainedLetterGrade ?
                                    " " + gradeBooks.obtainedScoreInfo.obtainedLetterGrade : "") + "(" + gradeBooks.obtainedScoreInfo.gradePoint + ")" + " (" + (null != gradeBooks.obtainedScoreInfo.obtainedScore ? gradeBooks.obtainedScoreInfo.obtainedScore : "")
                                + "/" + (null != gradeBooks.obtainedScoreInfo.fullScore ? gradeBooks.obtainedScoreInfo.fullScore : "") + ")"))
                        : null
                }>
                {(gradeBooks && gradeBooks.gradeBookEvaluationMarksList)
                    ? gradeBooks.gradeBookEvaluationMarksList &&
                    gradeBooks.gradeBookEvaluationMarksList.map((marks, index) => {
                        return (
                            <Fragment key={index}>
                                <StyledTreeItem
                                    nodeId={marks.unqId}
                                    label={
                                        marks.evaluationName
                                            ? (marks.evaluationName +
                                                "(" +
                                                (marks.evaluationObtainedMarks ? marks.evaluationObtainedMarks : null) +
                                                "/" +
                                                (marks.evaluationFullMarks ? marks.evaluationFullMarks : null) +
                                                ")")
                                            : null
                                    }>
                                    {null == marks.obtainedScoreInfo && marks &&
                                        marks.gradeBookEvaluationTaskWiseMarksList &&
                                        marks.gradeBookEvaluationTaskWiseMarksList.map(
                                            markList => {
                                                return (
                                                    <Fragment key={markList.unqId}>
                                                        <StyledTreeItem
                                                            nodeId={markList.unqId.toString()}
                                                            label={
                                                                markList.taskName
                                                                    ? (markList.taskName +
                                                                        "(" +
                                                                        markList.taskObtainedMarks +
                                                                        "/" +
                                                                        markList.taskFullMarks +
                                                                        ")")
                                                                    : null
                                                            }
                                                        />
                                                    </Fragment>
                                                );
                                            }
                                        )}

                                </StyledTreeItem>
                            </Fragment>
                        );
                    })
                    : null}
            </StyledTreeItem>
        </TreeView>
    );
}
