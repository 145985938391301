import React, {useEffect, useState} from "react";
import * as ChannelApi from "../../api/ChannelApi";
import * as GradingBreakdownApi from "../../api/GradingBreakdownApi";
import CommonLayout from "../layout/commonLayout";
import AssignmentForm from "./AssignmentForm";
import McqNew from "../Mcq/McqNew";
import {isEmpty} from "lodash";
import {useNavigate, useParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import * as AssignmentApi from "../../api/AssignmentApi";
import * as GradingEvaluationApi from "../../api/GradingEvaluationApi";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import LinearProgress from "@mui/material/LinearProgress";
import {toast} from "react-toastify";

const initialExamTypes = [
    {value: "exam", label: "Taken"},
    {value: "assignment", label: "Regular"},
    {value: "mcq", label: "MCQ / True-False"}
];

function AssignmentNewContainer(props) {
    const [initValue, setInitValue] = useState({
        examType: {},
        evaluationName: {}

    });
    const params = useParams();
    let history = useNavigate();
    const [channel, setChannel] = useState({});
    const [assignment, setAssignment] = useState({});
    const [gradingBreakdowns, setGradingBreakdowns] = useState([]);
    const [selectedGradingBreakdown, setSelectedGradingBreakdown] = useState({});
    const [gradingEvaluation, setGradingEvaluation] = useState({});
    const [selectedAssessmentType, setSelectedAssessmentType] = useState(null);
    const [assignmentTask, setAssignmentTask] = useState([]);
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [allAssignmentArchivedList, setAllAssignmentArchivedList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [selectedArchivedQuestion, setSelectedArchivedQuestion] = useState(false);
    const [selectedMcqArchivedQuestion, setSelectedMcqArchivedQuestion] = useState(false);


    function navigateTo(pathname) {
        history(pathname);
    }

    const getAssignmentById = () => {
        if (params.gradingEvaluationId){
            AssignmentApi.getAssignmentByGradingEvaluationId(params.gradingEvaluationId)
                .then(response => {
                    if (response && response.response && response.response.status === 200) {
                        setAssignment(response.data);
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
    }

    const setEditValue = () => {
        if (params.id && params.assignmentId && params.gradingEvaluationId) {
            handleExamTypeSelection(initialExamTypes.at(1));
            let formValue = {
                examType : initialExamTypes.at(1),
                evaluationName : gradingBreakdowns.find(value => value.id == params.gradingBreakdownId)
            };
            setInitValue(formValue);
            AssignmentApi.getAssignmentTaskListByAssignmentId(params.assignmentId)
                .then(taskListResponse => {
                    setAssignmentTask(taskListResponse.data);
                })
                .catch(errors => {
                    console.log(errors);
                });

        }
    }

    const getGradingEvaluation = () => {
        if(null!=params.gradingEvaluationId) {
            GradingEvaluationApi.getGradingEvaluationById(
                params.gradingEvaluationId
            ).then(json => {
                setGradingEvaluation(json.data);
            });
        }
    }

    useEffect(() => {
        setEditValue();
        getGradingEvaluation();
        getAssignmentById();
    }, [gradingBreakdowns]);

    function handleSubmit() {}

    useEffect(() => {
        ChannelApi.getChannelById(params.id)
            .then(json => {
                setChannel(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
        GradingBreakdownApi.findGradingBreakdownByChannelId(
            params.id
        ).then(res => {
            setGradingBreakdowns(res.data[0].gradingBreakdownList);
        });
    }, [params.id]);

    const handleExamTypeSelection = option => {
        setSelectedAssessmentType(option.value);
    };
    const handleEvaluationSelection = option => {
        setSelectedGradingBreakdown(option);
    };

    const handleQuestionArchive = () => {
        getArchivedQuestion();
    };

    function goBack() {
        navigateTo(-1)
    }

    const changePage = ({selected}) => {
        setPageNumber(selected);
    }

    useEffect(() => {
        getArchivedQuestion();
    }, [pageNumber]);

    function getArchivedQuestion() {

        if (!selectedAssessmentType) {
            toast.warning("Please select Assignment Type before import from archive !", {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }

        setShowArchiveModal(true);

        if (selectedAssessmentType === "assignment") {
            setShowProgressBar(true);
            AssignmentApi.getArchivedQuestionPageable(
                {
                    page: pageNumber,
                    searchTerm: selectedAssessmentType
                }
            ).then(
                json => {
                    setAllAssignmentArchivedList(json.data.content);
                    setPageCount(json?.data?.totalPages);
                    setShowProgressBar(false);
                }
            ).finally(() => {
                setShowProgressBar(false);
            })

        } else if (selectedAssessmentType === "mcq") {
            setShowProgressBar(true);
            setAllAssignmentArchivedList([]);
            AssignmentApi.getArchivedMcqQuestionPageable(
                {page: pageNumber,}
            ).then(
                json => {
                    setAllAssignmentArchivedList(json.data.content);
                    setPageCount(json?.data?.totalPages);
                    setShowProgressBar(false);
                }
            ).finally(() => {
                setShowProgressBar(false);
            })
        }
    }

    const handleArchiveQuesSelect = (data)=>{

        if(selectedAssessmentType === "assignment"){
            setSelectedArchivedQuestion(data)
        }else {
            setSelectedMcqArchivedQuestion(data)
        }
        setShowArchiveModal(false);
    }

    function onHideArchiveModal(){
        setShowArchiveModal(false);
        setAllAssignmentArchivedList([]);
    }

    function QuestionArchiveListModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="global-repository">
                <Modal.Body>
                    <div className="tab-heading ">
                        <p>Question Archive</p>
                        <div className="table-responsive table-light">
                            {showProgressBar ? (<LinearProgress color="primary" />) : ""}
                            <table className="table table-light table-hover"
                                   id="dataTable"
                                   width="100%"
                                   cellSpacing="0">
                                <thead>
                                <tr>
                                    <th scope="col">Semester</th>
                                    <th scope="col">Course Name</th>
                                    <th scope="col">Evaluation Name</th>
                                    <th scope="col">Assignment Title</th>
                                    <th scope="col" className="text-center">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {allAssignmentArchivedList.length > 0 ?
                                    allAssignmentArchivedList.map((data, index) => {
                                        return <>
                                            <tr>
                                                <td>{data?.channel?.semester?.semesterName}</td>
                                                <td>{data?.channel?.channelName}</td>
                                                <td>{data?.gradingEvaluation?.gradingBreakdown?.evaluationName}</td>
                                                <td>{data?.gradingEvaluation?.title}</td>
                                                <td className="text-center pl-0">
                                                    <button title="View" onClick={()=>handleArchiveQuesSelect(data)}
                                                            type="button"
                                                            className="btn btn-sm bg-c-blue-light mr-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             width="17" height="12" fill="none">
                                                            <path fill="#4575EE"
                                                                  d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    })
                                    : <td colSpan="12" className="text-center">
                                        {showProgressBar ? <h5>Loading...</h5> : <h5>No data found</h5>}
                                    </td>
                                }
                                </tbody>
                            </table>
                            <div className="page-navigation">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <QuestionArchiveListModal
                show={showArchiveModal}
                onHide={() => onHideArchiveModal()}
            />

            <CommonLayout headerName={"Assignment"}>
                <main>
                    <div className="card-default create-assignment container-responsive">
                        <div className="assignment-heading  ">
                            <div className="assignment-header d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className=" mr-3 bg-c-light-grey">
                                        <a onClick={goBack} className=" back-button">
                                            <i className="fa fa-chevron-left"> </i> </a>
                                    </div>
                                    <p>
                                        Create new Assignment for :{" "}
                                        {channel.id !== null ? channel.channelName : ""}
                                    </p>
                                </div>
                                <button
                                    onClick={handleQuestionArchive}
                                    title="Import from archive question"
                                    type="button"
                                    className="btn  bg-c-blue-light mr-1">
                                <span>
                                    <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                                        d="M11.22 7.97a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06L8 9.69V1.5a.75.75 0 0 1 1.5 0v8.19l1.72-1.72Z"
                                        fill="#4575EE"/><path
                                        d="M17.5 9.5a.75.75 0 1 0-1.5 0 7.25 7.25 0 1 1-14.5 0 .75.75 0 0 0-1.5 0 8.75 8.75 0 0 0 17.5 0Z"
                                        fill="#4575EE"/></svg>
                                    Import from archive question
                                </span>
                                </button>
                            </div>
                        </div>

                        <div className=" ">
                            <div className="card-body">

                                <div className="left-col">
                                    <Formik
                                        initialValues={initValue}
                                        enableReinitialize={true}
                                        onSubmit={handleSubmit}>
                                        {({props, values, setFieldValue}) => {
                                            return (
                                                <Form>
                                                    <div className=" ">
                                                        <div className="form-group">
                                                            <p>Evaluation</p>
                                                            <Field
                                                                type="text"
                                                                placeholder="Select Evaluation"
                                                                label="evaluationName"
                                                                handleChangeSection={(fieldName, value) =>handleEvaluationSelection(value) }
                                                                fieldName={"evaluationName"}
                                                                name="evaluationName"
                                                                options={gradingBreakdowns || []}
                                                                component={SelectDropdown}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" ">
                                                        <div className="form-group">
                                                            <p>Assignment Type</p>
                                                            <Field
                                                                type="text"
                                                                label="label"
                                                                placeholder="Select Assessment Type"
                                                                fieldName={"label"}
                                                                handleChangeSection={(fieldName, value) =>handleExamTypeSelection(value) }
                                                                name="examType"
                                                                options={initialExamTypes || []}
                                                                component={SelectDropdown}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </div>

                                {selectedAssessmentType && selectedAssessmentType === "exam" ? (
                                    <AssignmentForm
                                        channel={channel}
                                        selectedGradingBreakdown={selectedGradingBreakdown}
                                        selectedAssessmentType={selectedAssessmentType}
                                    />
                                ) : (
                                    ""
                                )}
                                {selectedAssessmentType &&
                                selectedAssessmentType === "assignment" ? (
                                    <AssignmentForm
                                        assignmentTask={assignmentTask}
                                        assignment={assignment}
                                        gradingEvaluation={gradingEvaluation}
                                        channel={channel}
                                        selectedGradingBreakdown={selectedGradingBreakdown}
                                        selectedAssessmentType={selectedAssessmentType}
                                        selectedArchivedQuestion={selectedArchivedQuestion}
                                    />
                                ) : (
                                    ""
                                )}

                                {selectedAssessmentType &&
                                selectedAssessmentType === "mcq" &&
                                !isEmpty(selectedGradingBreakdown) ? (
                                    <McqNew selectedGradingBreakdown={selectedGradingBreakdown}
                                            channel={channel}
                                            selectedMcqArchivedQuestion={selectedMcqArchivedQuestion}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AssignmentNewContainer;
