import {FETCH_ROOM_BOARD_SEARCH_BEGIN, FETCH_ROOM_BOARD_SEARCH_FAILURE, FETCH_ROOM_BOARD_SEARCH_SUCCESS} from "../action/roomBoardSearchAction";

const initialState = {
    loading: false,
    error: null,
    searchQuery: null
};

export default function RoomBoardSearchReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ROOM_BOARD_SEARCH_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ROOM_BOARD_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                searchQuery: action.payload.searchQuery
            };
        case FETCH_ROOM_BOARD_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                searchQuery: null
            };
        default:
            return state;
    }
}
