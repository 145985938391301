import AxiosAgent from "./AxiosAgent";
import { API_MAIN_ROOT } from "../util/LinkFactory";


export const save = evalInfo => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-info", evalInfo);
};

export const update = evalInfo => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/evaluation-info", evalInfo);
};

export const search = evalInfo => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-info/search", evalInfo);
};

export const deleteEvaluationInfo = id => {
  return AxiosAgent.requests.delete(API_MAIN_ROOT + "/evaluation-info/"+ id);
};

export const getEvaluationInfoById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/evaluation-info/" + id);
};
