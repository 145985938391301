import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../../common/TextFieldComponent";
import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import * as EvaluationQuestionMainApi from "../../../api/EvaluationQuestionMainApi";
import * as LinkFactory from "../../../util/LinkFactory";
import FormTitle from "../../Typhography/formTitle";
import Loader from "../../common/Loader";
import {useNavigate} from "react-router-dom";


function EvaluationQuestionMain (props) {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [evaluationQuesMain, setEvaluationQuesMain] = useState({
    name: "",
    globalInd: false,
    status: false
  });

  const notifyValidation = () => toast.success("All fields required", {
    position: toast.POSITION.TOP_CENTER
  });
  const notifySuccess = () => toast.success("Evaluation question name Created!", {
    position: toast.POSITION.TOP_CENTER
  });

  const handleList = () => {
    navigateTo(LinkFactory.evaluationQuestionMainList);
  };

  const handleSubmit = values => {
    setDisable(true);
    if(null == values.name || '' === values.name) {
      notifyValidation();
      setDisable(false);
    } else {
      setLoader(true);
      const evalQuesMain = values;
      evalQuesMain.globalInd = values.globalInd? 1: 0;
      if(null == values.id) {
        EvaluationQuestionMainApi.save(evalQuesMain).then(res => {
          notifySuccess();
          handleList();
        });
      } else {
        EvaluationQuestionMainApi.update(evalQuesMain).then(res => {
          notifySuccess();
          handleList();
        });
      }
      setLoader(false);
    }
    setDisable(false);
  }

  const getEvaluationQuestionMainById = id => {
    EvaluationQuestionMainApi.getEvaluationQuestionMainById(id).then(res=> {
      setEvaluationQuesMain(res.data);
    });
  }

  useEffect(()=> {
    console.log(props?.match?.params?.id);
    if (props?.match?.params?.id) {
      getEvaluationQuestionMainById(props?.match?.params?.id)
    }
  }, [])

  function handleCheckBox(event) {
      console.log(event?.target?.checked);
      evaluationQuesMain.globalInd = event?.target?.checked;
  }

  function handleStatus(event) {
    evaluationQuesMain.status = event?.target?.checked;
  }

  return (
    <>
      <CommonLayout headerName="New Evaluation Question Set">
        <main>
          <div>
            <h3 className="mt-4 mb-4">Question Set Details</h3>
            {loader ? <Loader/> : ""}
            <div>
              <Formik
                initialValues={evaluationQuesMain}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({props, values, setFieldValue}) => (
                  <Form>
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group input-default-white">
                          <FormTitle>Name</FormTitle>
                            <Field
                              type="text"
                              name="name"
                              placeholder="Name"
                              component={TextFieldComponent}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group input-default-white">
                          <FormTitle>Global Indicator</FormTitle>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              onChange={(e) => {
                                handleCheckBox(e)
                              }}
                              style={{
                                marginLeft: 12,
                                marginTop: 12,
                                alignItems: "center",
                                fontSize: "26px",
                                size: "26px"
                              }}
                              className="mr-4"
                              // component={CheckBoxComponent}
                            />

                          <FormTitle>Active Status</FormTitle>
                          <input
                              type="checkbox"
                              defaultChecked={false}
                              onChange={(e) => {
                                handleStatus(e)
                              }}
                              style={{
                                marginLeft: 12,
                                marginTop: 12,
                                alignItems: "center",
                                fontSize: "26px",
                                size: "26px"
                              }}
                              // component={CheckBoxComponent}
                          />
                          <div className="form-group float-right">
                            <button
                                type="submit"
                                disabled={disable}
                                className="btn btn-dark radius-25 px-4 d-block">
                              <i className="fa fa-bookmark mr-2"/>
                              Save
                            </button>
                          </div>
                        </div>

                      </div>
                      <div className="col-6">

                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationQuestionMain;
