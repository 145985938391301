import React, {useEffect, useState} from "react";
import * as Utils from "../../util/Utils";
import * as McqApi from "../../api/McqApi";
import CommonLayout from "../layout/commonLayout";
import Loader from "../common/Loader";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate, useParams} from "react-router-dom";

function McqSubmissionList(props) {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const params = useParams();
    const [submissionReport, setSubmissionReport] = useState([]);
    const [mcqMain, setMcqMain] = useState({});
    useEffect(() => {
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) === true) {
            McqApi.findMcqMain(params.id).then(res => {
                setMcqMain(res.data);
            }).catch(errors => {
                console.log(errors);
            }).finally();
            McqApi.getSubmissionReport(params.id)
                .then(response => {
                    setSubmissionReport(response.data);
                })
                .catch(errors => {
                    console.log(errors);
                }).finally();
        }

    }, []);

    function handleMcqSubmissions(userId) {
        navigateTo(LinkFactory.mcqSubmissions + "/" + params.id + "/" + userId);
    }

    function disableEnable(data) {
        return data.isSubmitted !== true;
    }

    function createMarkup(html) {
        console.log(html);
        return {__html: html};
    }

    return (
        <>
            <CommonLayout headerName={"MCQ Submission List"}>
                <main>
                    <div className="mcq-submission-list">
                        <div className="card-default">
                            <div className="table-responsive w-50 rounded">
                                <table
                                    className="table table-hover rounded"
                                    id="dataTable"
                                    cellSpacing="0"
                                >
                                    <tr className="">
                                        <th className="bg-assignment-light border-0 p-3">
                                            Name
                                        </th>
                                        <td className="fw-600 table-light c-blue border-0 p-3"
                                            dangerouslySetInnerHTML={createMarkup(mcqMain.name)}>

                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <br/>
                            {Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"]) === true ? (
                                <div>
                                    <h4>Mcq Submission Status</h4>
                                    <div className="table-responsive rounded">
                                        <table
                                            className="table table-bordered table-hover"
                                            id="dataTable"
                                            width="100%"
                                            cellSpacing="0"
                                        >
                                            <thead>
                                            <tr>
                                                <th>Student name</th>
                                                <th>Submission Status</th>
                                                <th>View</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {submissionReport.length > 0 ? (
                                                submissionReport.map((report, index) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                {report?.user?.firstName}{" "}
                                                                {report?.user?.lastName}
                                                            </td>

                                                            <td>
                                                                {" "}
                                                                {report.isSubmitted == true
                                                                    ? "Submitted"
                                                                    : "Not Submitted"}{" "}
                                                            </td>
                                                            <td>
                                                                {report.isSubmitted == true ?
                                                                    <button
                                                                        type="button"
                                                                        className="btn-md bg-c-blue radius-25"
                                                                        disabled={disableEnable(report)}
                                                                        onClick={() =>
                                                                            handleMcqSubmissions(report?.user?.id)
                                                                        }>
                                                                        <i className="fa fa-eye"></i> View Submission
                                                                    </button> : null}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <Loader/>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default McqSubmissionList;