export const FETCH_ATTENDANCE_BEGIN = "FETCH_EVENTS_BEGIN";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

export const fetchEventsBegin = () => ({
    type: FETCH_ATTENDANCE_BEGIN
});

export const fetchEventsSuccess = events => ({
    type: FETCH_EVENTS_SUCCESS,
    payload: {upcomingEvents: events.upcomingEvents, previousEvents: events.previousEvents}
});

export const fetchEventsFailure = error => ({
    type: FETCH_EVENTS_FAILURE,
    payload: {error}
});
