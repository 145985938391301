import React, {useEffect, useState} from "react";
import * as Utils from "../../../util/Utils";
import CommonLayout from "../../layout/commonLayout";
import Loader from "../../common/Loader";
import {evaluationResult} from "../../../api/EvaluationQuestionSubmissionApi";
import {useParams} from "react-router-dom";

function EvaluationResult(props) {
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [evaluationSubmissionList, setEvaluationSubmissionList] = useState([]);
  const [evaluationNonCategorizedResultDTOList, setEvaluationNonCategorizedResultDTOList] = useState([]);

  useEffect(() => {
    getEvaluationResultByChannel();
  }, []);

  const getEvaluationResultByChannel = () => {
    var evaluationSubmission = {"channel": {"id": params.id}};
    evaluationResult(evaluationSubmission).then(res => {
      console.log(res.data);
      setEvaluationSubmissionList(res.data.evaluationCategorizedResultDTOList);
      setEvaluationNonCategorizedResultDTOList(res.data.evaluationNonCategorizedResultDTOList);
      setLoader(false);
    });
  };

  return (
    <>
        <CommonLayout headerName={Utils.getCurrentChannelName()}>
        <main>
          <div>
            <h3 className="mt-4">Evaluation Result</h3>

            {undefined !== evaluationSubmissionList && evaluationSubmissionList?.length > 0
              ? evaluationSubmissionList.map((data, index) => {
                return (
                    <div className="table-responsive table-light table-shadow radius-15 mt-4">
                      <table className="table table-light table-hover"
                             id="dataTable"
                             width="100%"
                             cellSpacing="0">
                        <thead>

                        <th scope="col">{data?.questionType}</th>
                        {(undefined !== data?.evaluationResultDTOList && data.evaluationResultDTOList?.length > 0) ?
                            data?.evaluationResultDTOList.map((data1, index1) => {
                              return (

                            <th scope="col">{data1?.option}</th>
                          );
                        }) : <th>{index}</th>
                      }
                      </thead>
                      <tbody>
                      <tr>
                        <td className="">
                          {data?.question}
                        </td>

                        {(undefined !== data.evaluationResultDTOList && data.evaluationResultDTOList?.length > 0) ?
                          data.evaluationResultDTOList.map((data2, index2) => {
                            return (
                              <td>{data2?.percentage}</td>
                            );
                          }) : <td>{index}</td>
                        }

                      </tr>

                      </tbody>
                    </table>
                  </div>);
              })
              : <div colSpan="12" className="text-center">
                {loader ? <Loader className="my-5" /> : ""}</div>}

            {undefined !== evaluationNonCategorizedResultDTOList && evaluationNonCategorizedResultDTOList?.length > 0
              ? evaluationNonCategorizedResultDTOList.map((data, ind) => {
                return (
                    <>
                      <h5 className="mt-4 font-weight-bold">{data?.question}</h5>
                      <div className="table-responsive table-light table-shadow radius-15 mt-4">
                        <table className="table  table-light table-hover"
                               id="dataTable"
                               width="100%"
                               cellSpacing="0">
                          <tbody>
                          <tr>
                            <td><b>{ind + 1}</b></td>
                            <td>{data?.answer}</td>
                          </tr>
                          </tbody>
                        </table>
                    </div>
                  </>);
              }) : ""}
            <br />
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationResult;
