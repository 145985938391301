import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveAssignment = assignment => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/assignment-task-submit/",
        assignment
    );
};
export const updateAssignment = assignment => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/assignment-task-submit/",
        assignment
    );
};
export const getAssignmentById = id => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/assignment-task-submit/" + id
    );
};
export const deleteAssignmentById = id => {
    return AxiosAgent.requests.delete(
        API_MAIN_ROOT + "/assignment-task-submit/" + id
    );
};
export const getAll = userId => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/assignments");
};
export const postCommentInAssignmentTaskSubmit = assignmentTaskComments => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/assignment-task-comments/",
        assignmentTaskComments
    );
};
export const getCommentsByAssignmentTaskId = id => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/assignment-task-comments-by-assignment-task-id/" + id
    );
};
export const saveMarksByAssignmentTaskSubmitId = (id, grade) => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/assignment-task-submit-grading-insert/" + id + "/" + grade
    );
};
