import React, {useEffect, useState} from "react";
import * as PostApi from "../../api/PostApi";

import FooterComponent from "../Nav/footer";
import Loader from "../common/Loader";
import {DefaultPlayer as Video} from "react-html5video";
import "react-html5video/dist/styles.css";
import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function PostView(props) {
    const params = useParams();
    const [post, setPost] = useState([]);
    const [id, setId] = useState(null);
    useEffect(() => {
        PostApi.getPostWithContentsById(params.id)
            .then(json => {
                console.log(json);
                setPost(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Post Details</h1>
                        <br/>

                        <div className="">
                            <h4 className="mb-3">{post !== [] ? post.post?.postText : ""}</h4>
                            <div className="d-flex justify-content-start grid">
                                {post != null && post.postContentDTO?.length > 0 ? (
                                    post.postContentDTO?.map((data, index) => {
                                        return data.type === "image" ? (
                                            <img
                                                src={
                                                    process.env.REACT_APP_FILES_URL +
                                                    data.imageFileName
                                                }
                                                alt="new"
                                                className=""
                                            />
                                        ) : (
                                            <Video
                                                autoPlay
                                                loop
                                                muted
                                                controls={[
                                                    "PlayPause",
                                                    "Seek",
                                                    "Time",
                                                    "Volume",
                                                    "Fullscreen"
                                                ]}
                                                src={
                                                    process.env.REACT_APP_FILES_URL +
                                                    data.videoFileName
                                                }
                                                className="video"
                                            ></Video>
                                        );
                                    })
                                ) : (
                                    <Loader/>
                                )}
                            </div>
                        </div>

                        {/*<div className="card mb-4">*/}
                        {/*    <div className="card-body">*/}
                        {/*        <h2>{post !== [] ? post.post?.postText : ''}</h2>*/}

                        {/*        {(post != null && post.postContentDTO?.length > 0) ?*/}
                        {/*            post.postContentDTO?.map((data, index) => {*/}
                        {/*                return (*/}
                        {/*                    data.type === 'image' ?*/}
                        {/*                        <img height={300} width={400}*/}
                        {/*                             src={'https://45.126.127.97:8081/files/' + data.imageFileName}*/}
                        {/*                             alt="new" className="image-thumbnail"*/}
                        {/*                        /> :*/}
                        {/*                        <div style={{height:300, width:400}}>*/}
                        {/*                            <Video autoPlay loop muted*/}
                        {/*                                   controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}*/}
                        {/*                                   src={'https://45.126.127.97:8081/files/' + data.videoFileName}*/}
                        {/*                            >*/}
                        {/*                            </Video>*/}
                        {/*                        </div>*/}
                        {/*                )*/}
                        {/*            }) : <Loader/>}*/}

                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>
        </>
    );
}

export default PostView;
