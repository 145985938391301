import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as EmployeeApi from "../../api/EmployeeApi";
import Loader from "../common/Loader";
import {PARENT_ID, SERVER_NAME_URL} from "../../constants/Constants";
import {FILES_URL} from "../../util/LinkFactory";
import PageError from "../ErrorPage";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import * as ParentsApi from "../../api/ParentsApi";
import * as StudentApi from "../../api/StudentApi";
import * as StudentParentLookupApi from "../../api/StudentParentLookupApi";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import Swal from "sweetalert2";

const defaultData = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    permanentAddress: "",
    presentAddress: "",
    photoName: "",
    regId: "",
    emergencyContactPerson: "",
    emergencyContact: "",
    bloodGroup: "",
    userId: null,
    phoneNo: null,
    email: null,
};

function ParentProfile() {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    let fileBase64s = [];
    let PhotoRootUrl;

    const [employeeInfo, setEmployeeInfo] = useState(defaultData);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState("");
    const [isLoadingChild, setIsLoadingChild] = useState(false);
    const [studentTypeAheadResults, setStudentTypeAheadResults] = useState([]);
    const [childSelectedOption, setChildSelectedOption] = React.useState([]);
    const [students, setStudents] = React.useState([]);
    const [addFlag, setAddFlag] = useState(false);
    const filterBy = () => true;


    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        getEmployeeInformation(Utils.getUserFromLocalStorage().id);
    }, [addFlag]);

    const channelTypeConversion = channelType => {
        if (channelType === 'class')
            return "Class"
        else if (channelType === 'co-currullar')
            return "Co - Curricular"
        else if (channelType === 'organizational')
            return "Institute Room"
    }

    const handleStudentSearch = query => {
        setIsLoadingChild(true);

        StudentApi.getStudentInformationByUUID(query)
            .then(json => {
                const result = json?.response?.data || [];
                const options = result.map(i => ({
                    fullObject: i,
                    label: i.firstName + " " + i.lastName,
                    value: i.studentId
                }));
                setStudentTypeAheadResults(options);
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoadingChild(false));
    };

    const getEmployeeInformation = id => {
        setLoader(true);
        ParentsApi.getParentsByUserId(id)
            .then(json => {
                PhotoRootUrl = SERVER_NAME_URL + json.data.photoName;
                let response = json.data || defaultData;
                setEmployeeInfo(response);
            })
            .catch(errors => {
                setError(true);
            })
            .finally(() => setLoader(false));
    };

    const handleProfilePicInputChange = event => {
        fileBase64s = [];
        setFiles(fileBase64s);
        setSelectedFiles(event.target.files);
        setFileName(event.target.files[0].name);
        Array.from(event.target.files).forEach(file => {
            fileToDataUri(file).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file.type, fileName: file.name});
            });
        });
        setFiles(fileBase64s);
    };

    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });

    const typeaheadSelectStudent = data => {
        const deletedItems = students
            .filter(x => !childSelectedOption.includes(x))
            .concat(childSelectedOption.filter(x => !students.includes(x)));
        setChildSelectedOption(data);
    };

    const addStudent = () => {
        const lookupList = [];
        for (const obj of childSelectedOption) {
            const param = {
                studentId : obj.value,
                parentId: localStorage.getItem(PARENT_ID)
            }
            lookupList.push(param);
        }

        StudentParentLookupApi.saveAll(lookupList).then(res => {
            if (res.response.status === 201 || res.response.status === 200) {
                setAddFlag(!addFlag);
                Swal.fire("Student has been added!", "success");
            } else
                Swal.fire("Registration Failed", "error");
        });
    }


    function proPicUpload() {
        let profilePicUploadDto = {
            photoBlob: files[0].blob,
            photoBlobType: files[0].type,
            userId: employeeInfo.user.id
        };
        uploadProfilePicture(profilePicUploadDto).then(r => {
            toast("Profile Picture Uploaded!")
        });
    }

    async function uploadProfilePicture(profilePicUploadDto) {
        await ParentsApi.uploadParentsProfilePicture(profilePicUploadDto).then(
            response => {
                flushFileUpload();
                getEmployeeInformation(Utils.getUserFromLocalStorage().id);
            }
        );
    }

    function flushFileUpload() {
        setFiles([]);
        setSelectedFiles([]);
        fileBase64s = [];
    }

    function goBack() {
        navigateTo(-1)
    }
    if (employeeInfo && !loader) {
        return (
            <>
                <CommonLayout headerName={"Parent's Information"}>
                    <main>
                        <div>
                            {error ? (
                                <PageError title={"No data to show"}/>
                            ) : (
                                <div className="card-default teachers-information container-responsive">
                                    <div className="title">
                                        <div className="mb-0 d-flex align-items-center">
                                            <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                                <a onClick={goBack} className="pl-0 back-button">
                                                    <i className="fa fa-chevron-left"> </i> </a>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="tab-blue">
                                        <div className="tabs">
                                            <ul className="nav nav-pills mb-4 pl-0"
                                                id="pills-tab3"
                                                role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="pills-parents-info-tab"
                                                        data-toggle="pill"
                                                        href="#pills-parents-info"
                                                        role="tab"
                                                        aria-controls="pills-parents-info"
                                                            aria-selected="true">
                                                        Parent's Information
                                                        </a>
                                                    </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="pills-assignment-inprogress"
                                                        data-toggle="pill"
                                                        href="#pills-inprogress"
                                                        role="tab"
                                                        aria-controls="pills-inprogress"
                                                        aria-selected="false"
                                                    >
                                                        Student Add
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-parents-info"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-upcoming">
                                                    <div className="tab-heading mx-auto">
                                                        <p>Parent's Information</p>

                                                        <div className="teachers-info-tab table-responsive table-light">
                                                            <table
                                                                className="table table-light table-hover"
                                                                id="dataTable">
                                                                <tbody>
                                                                <tr>
                                                                    <td>Parent's Info</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Name</td>
                                                                    <td>
                                                                        {employeeInfo?.firstName}{" "}{employeeInfo?.middleName}{" "}{employeeInfo?.lastName}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>{employeeInfo?.user?.login}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td> Phone No.</td>
                                                                    <td>{employeeInfo?.user?.phoneNo}</td>
                                                                </tr>

                                                                <tr>
                                                                    {/*<td> Teacher's Photo</td>*/}
                                                                    <td>Photo</td>
                                                                    <td>
                                                                        {employeeInfo?.user?.imageUrl ? (
                                                                            <img
                                                                                style={{
                                                                                    width: "60px",
                                                                                    height: "60px",
                                                                                    objectFit: "cover",
                                                                                    borderRadius: "5px"
                                                                                }}
                                                                                src={FILES_URL + employeeInfo?.user?.imageUrl}
                                                                                alt={employeeInfo?.firstName}
                                                                            />
                                                                        ) : (
                                                                            "No photo available"
                                                                        )}
                                                                        <button onClick={() => {document.getElementById('file').click()}}>
                                                                            {fileName ? fileName : "Select Photo"}
                                                                        </button>
                                                                        <input
                                                                            id="file"
                                                                            name="file"
                                                                            type="file"
                                                                            style={{display: 'none'}}
                                                                            onChange={handleProfilePicInputChange}/>
                                                                        <button
                                                                            title="Upload"
                                                                            type="button"
                                                                            disabled={Array.from(selectedFiles).length === 0}
                                                                            onClick={() => proPicUpload()}
                                                                            className="btn btn-light radius-50-percent">
                                                                            <i className="fa fa-cloud-upload-alt"/>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-inprogress"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-inprogress"
                                                >

                                                    <AsyncTypeahead
                                                        size={"lg"}
                                                        filterBy={filterBy}
                                                        id="async-example"
                                                        isLoading={isLoadingChild}
                                                        labelKey="label"
                                                        minLength={3}
                                                        multiple={true}
                                                        selected={childSelectedOption}
                                                        onSearch={handleStudentSearch}
                                                        options={studentTypeAheadResults}
                                                        onChange={typeaheadSelectStudent}
                                                        placeholder="Type Student UUID here..."
                                                        renderMenuItemChildren={option => (
                                                            <>
                                                                <div className="flex py-1">
                                                                    <div className="mb-2 text-info">
                                                                        Name: <b>{option.label}</b>
                                                                    </div>
                                                                    {/*{" "}
                                                                    <div
                                                                        className={"border-top-1  text-secondary"}>
                                                                        Email:{option.value}
                                                                    </div>*/}
                                                                </div>
                                                            </>
                                                        )}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={addStudent}
                                                        className="btn btn-dark radius-25 mt-4 px-4">
                                                        Add
                                                    </button>
                                                    <div className="table-responsive table-light">
                                                    <table
                                                        className="table table-light table-striped table-hover"
                                                        id="dataTable"
                                                        width="100%"
                                                        cellSpacing="0"
                                                    >
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Sl</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">UUID</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {employeeInfo?.studentList?.length > 0
                                                            ? employeeInfo.studentList.map((data, index) => {
                                                                return (
                                                                    <tr>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{data?.firstName+" "+data?.lastName}</td>
                                                                        <td>{data?.uuid}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                            : <td colSpan="12" className="text-center">
                                                                {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                                                            </td>}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </div>
                    </main>
                </CommonLayout>
            </>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default ParentProfile;
