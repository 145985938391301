import React, {useEffect, useState} from "react";
import * as Utils from "../../util/Utils";
import ListGroup from "react-bootstrap/ListGroup";
import Loader from "../common/Loader";
import CommonLayout from "../layout/commonLayout";
import * as NotificationsApi from "../../api/NotificationsApi";

import * as LinkFactory from "../../util/LinkFactory";
import {Tooltip as ReactTooltip} from 'react-tooltip'
import moment from "moment/moment";
import * as ChannelApi from "../../api/ChannelApi";
import {convertUTCDateToLocalDate, dateTimeForNotification} from "../../util/DateUtils";
import SkeletonView from "../SkeletonView";
import * as EventsApi from "../../api/EventsApi";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";

function AllNotificationList(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [assignmentNotifications, setAssignmentNotifications] = useState([]);
    const [postNotifications, setPostNotifications] = useState([]);
    const [assignmentLoading, setAssignmentLoading] = useState(true);
    const [smallCalenderData, setSmallCalenderData] = useState([]);
    const [upcomingEventsList, setUpcomingEventsList] = useState({});
    const [previousEventsList, setPreviousEventsList] = useState({});
    const [upcomingEventsLoading, setUpcomingEventsLoading] = useState(true);
    const [previousEventsLoading, setPreviousEventsLoading] = useState(true);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [assignmentPageNumber, setAssignmentPageNumber] = useState(0);
    const [assignmentPageCount, setAssignmentPageCount] = useState(0);
    const [searchTermAssignment, setSearchTermAssignment] = useState({searchTerm: ""});

    const [searchTermPostEvent, setSearchTermPostEvent] = useState({searchTerm: ""});
    const [showProgressBar, setShowProgressBar] = useState(false);


    const handleAllEvents = () => {
    };
    const [postLoading, setPostLoading] = useState(
        true
    );

    const changeAssignmentPage = ({selected}) => {
        console.log(selected);
        setAssignmentPageNumber(selected);
    }

    const loadAssignmentNotificationsPage = (value) => {
            setAssignmentLoading(true);
        console.log('t',value);
        NotificationsApi.searchNotificationPage(
            {
                "tags": ["assignment"],
                "page":assignmentPageNumber,
                "searchTerm": value?.searchTerm
            }
        ).then( json => {
            setAssignmentNotifications(json.data.content);
            setAssignmentPageCount(json.data.totalPages);
            setAssignmentLoading(false);
        });
    };
    const handleEventRoute = data => {
        if (null != data.eventType && !data.eventType.includes("EVALUATION")) {
            if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"])) navigateTo(LinkFactory.eventsSetupView + "/" + data.id); else if (Utils.checkRolePermission(["ROLE_USER"])) navigateTo(LinkFactory.eventsSetupViewForUser + "/" + data.id);
        } else {
            if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_FACULTY","ROLE_EXAM_CONTROLLER"])) navigateTo(LinkFactory.teacherEvaluation + "/" + data.channel.id); else if (Utils.checkRolePermission(["ROLE_USER"])) navigateTo(LinkFactory.teacherEvaluation + "/" + data.channel.id);
        }
    };
    const loadEventNotifications = () => {
        setPostLoading(true);
        NotificationsApi.notificationListByUserIdAndTag(
            Utils.getUserFromLocalStorage().id,
            "event"
        ).then(json => {
            let eventNotifications = [...postNotifications];
            eventNotifications.push(json.data);
            setPostNotifications(eventNotifications);
            setPostLoading(false);
        });
    };
    const changePage = ({selected}) => {
        console.log(selected);
        setPageNumber(selected);
    }

    const loadPostNotificationsPage = (value) => {
        setPostLoading(true);
        NotificationsApi.searchNotificationPage(
            {
                "tags": ["post","event"],
                "page":pageNumber,
                "searchTerm": value?.searchTerm
            }
        ).then(json => {
            console.log('pstNoti',json.data);
            setPostNotifications(json.data.content);
            setPageCount(json.data.totalPages);
            setPostLoading(false);
        });
    };

    const updateNotificationReadStatus = notification => {
        let notificationReadStatusDto = {
            id: notification.id,
            readStatus: true
        };
        NotificationsApi.updateNotificationReadStatus(notificationReadStatusDto).then(res => {
            console.log(res.data);
        })
    }
    const redirectionController = notification => {
        console.log(notification);
        updateNotificationReadStatus(notification);
        if (notification.tag === "assignment") {
            if (notification.assignmentInd === true) {
                if (notification.assignmentSubmittedInd === true) {
                    navigateTo(LinkFactory.assignmentSubmittedView + "/" + notification.url);
                } else {
                    navigateTo(LinkFactory.assignmentSubmit + "/" + notification.url);
                }
            }
            if (notification.mcqInd === true) {
                if (notification.assignmentSubmittedInd === true) {
                    navigateTo(LinkFactory.mcqSubmittedView + "/" + notification.url);
                } else {
                    navigateTo(LinkFactory.mcqExam + "/" + notification.url);
                }
            }
        } else if (notification.tag === "event") {
            if (notification.eventInd) {
                if (notification.url.includes("/")) {
                    let eventId = notification.url.split("/")[1];
                    navigateTo(LinkFactory.eventsSetupViewForUser + "/" + eventId);
                } else
                    navigateTo(
                        LinkFactory.eventsSetupViewForUser + "/" + notification.url
                    );
            }
        } else if (
            notification.tag === "post" ||
            notification.tag === "post-comment" ||
            notification.tag === "post-like" ||
            notification.postInd === true
        ) {
            if (notification.url.includes("/")) {
                let postId = notification.url.split("/")[1];
                navigateTo(LinkFactory.channelPostView + "/" + postId);
            } else
                navigateTo(LinkFactory.channelPostView + "/" + notification.url);
        }
    };
    const handleCalender = () => {
        navigateTo(LinkFactory.calenderPage);
    };

    function loadSmallCalenderData() {
        ChannelApi.getSmallCalenderDataAll().then(calenderData => {
            console.log(calenderData.data);
            setSmallCalenderData(calenderData.data);
        });
    }

    function loadEventData() {
        setUpcomingEventsLoading(true);
        EventsApi.findAllUpcomingEventsByLoggedInUser().then(eventListResponse => {
            setUpcomingEventsList(eventListResponse.data);
            setUpcomingEventsLoading(false);
        });
        setPreviousEventsLoading(true);
        EventsApi.findAllPreviousEventsByLoggedInUser().then(eventListResponse => {
            setPreviousEventsList(eventListResponse.data);
            setPreviousEventsLoading(false);
        });
    }

    useEffect(() => {
        loadPostNotificationsPage();
    }, [pageNumber, setPostNotifications]);

    useEffect(() => {
        loadAssignmentNotificationsPage();
    }, [assignmentPageNumber, setAssignmentNotifications]);

    useEffect(() => {
        loadSmallCalenderData();
        loadEventData();
    }, []);

    return (
        <>
            <CommonLayout headerName={"All Notifications"}>
                <div id="allNotificationPage">

                    <main>
                        {/*<h3 className="mt-4">All Notifications</h3>*/}
                        <div id="contentArea">
                            <div className="block-center">

                                <div className="card-default">
                                    {/*<button onClick={() => history(-1)}>Go back</button>*/}
                                    <button
                                        className="card-header bg-c-light-grey"
                                        onClick={() => history(-1)}>

                                        <a className="pl-0 back-button">
                                            <i className="fa fa-chevron-left"> </i> </a>
                                    </button>

                                    <div className="tab-default">
                                        <div className="tabs">
                                            <ul
                                                className="nav nav-pills mb-4 d-flex"
                                                id="pills-tab2"
                                                role="tablist">
                                                <li className="nav-item d-flex">
                                                    <a
                                                        className="nav-link d-flex align-items-baseline fw-600 active"
                                                        id="pills-assignment-upcoming"
                                                        data-toggle="pill"
                                                        href="#pills-assignment"
                                                        role="tab"
                                                        aria-controls="pills-assignment"
                                                        aria-selected="true">
                                                        Assignment
                                                        <a className="notify-dot active">
                                                            <span>12</span>
                                                        </a>
                                                    </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link d-flex align-items-baseline fw-600"
                                                    id="pills-assignment-inprogress"
                                                    data-toggle="pill"
                                                    href="#pills-inprogress"
                                                    role="tab"
                                                    aria-controls="pills-inprogress"
                                                    aria-selected="false">
                                                    Post/Event
                                                    <a className="notify-dot">
                                                        <span>123</span>
                                                    </a>
                                                </a>
                                            </li>


                                                {/*<div className="search-sort d-flex  align-items-center">*/}
                                                {/*    <Formik*/}
                                                {/*        initialValues={searchTermAssignment && searchTermPostEvent}*/}
                                                {/*        enableReinitialize={true}*/}
                                                {/*        onSubmit={loadAssignmentNotificationsPage && loadPostNotificationsPage}>*/}
                                                {/*        {({props, values, setFieldValue}) => {*/}
                                                {/*            return (<Form>*/}

                                                {/*                <div className="form-group mb-0 pr-4 has-search d-flex">*/}
                                                {/*                    <span*/}
                                                {/*                        className="fa fa-search form-control-feedback"> </span>*/}
                                                {/*                    <FormikTextField*/}
                                                {/*                        // label="Search"*/}
                                                {/*                        name="searchTerm"*/}
                                                {/*                        type="text"*/}
                                                {/*                        className="form-control"*/}
                                                {/*                        placeholder="Search"*/}
                                                {/*                    />*/}
                                                {/*                </div>*/}

                                                {/*            </Form>)*/}
                                                {/*        }}*/}
                                                {/*    </Formik>*/}

                                                {/*    <div className="sort-by">*/}
                                                {/*        <div className="sort-by-header">*/}
                                                {/*            <p>Sort by</p>*/}
                                                {/*            <svg xmlns="http://www.w3.org/2000/svg" width="17"*/}
                                                {/*                 height="13" fill="none">*/}
                                                {/*                <path stroke="#475467" stroke-linecap="round"*/}
                                                {/*                      stroke-linejoin="round"*/}
                                                {/*                      stroke-width="2"*/}
                                                {/*                      d="M3.75 6.5h9.5M1.375 1.75h14.25m-9.5 9.5h4.75"/>*/}
                                                {/*            </svg>*/}
                                                {/*        </div>*/}

                                                {/*    </div>*/}

                                                {/*</div>*/}

                                            </ul>
                                            <div className="tab-content all-notification-section" id="pills-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-assignment"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-upcoming">


                                                    {/*<Formik*/}
                                                    {/*    initialValues={searchTermAssignment}*/}
                                                    {/*    enableReinitialize={true}*/}
                                                    {/*    onSubmit={loadAssignmentNotificationsPage}>*/}
                                                    {/*    {({props, values, setFieldValue}) => {*/}
                                                    {/*        return (<Form>*/}

                                                    {/*            <div className="form-group input-default-white">*/}
                                                    {/*                <FormikTextField label="Search"*/}
                                                    {/*                                 name="searchTerm"*/}
                                                    {/*                                 type="text" />*/}
                                                    {/*            </div>*/}

                                                    {/*        </Form>)*/}
                                                    {/*    }}*/}
                                                    {/*</Formik>*/}

                                                    {assignmentNotifications &&
                                                    assignmentNotifications?.length > 0 ? (
                                                        assignmentNotifications.map((data, index) => {
                                                            return (
                                                                <ListGroup key={index}>
                                                                    <ListGroup.Item
                                                                        onClick={() => redirectionController(data)}>
                                                                        <div>
                                                                            <p>
                                                                                {data?.readStatus === false ?
                                                                                    <i className=" "/> :
                                                                                    <i className=" "/>}
                                                                                <span
                                                                                    style={{color: 'black'}}>{data?.body}</span>
                                                                                {/*<span> <strong> SPL Thesis </strong>  has a new MCQ submission for Class Lectures , by Austin</span>*/}

                                                                            </p>
                                                                        </div>

                                                                        <div>
                                                                            <p>
                                                                                <span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span>
                                                                            </p>
                                                                            {/*<a> 3 mins*/}
                                                                            {/*    ago</a> /!*for between 2 days we will show 5 mins ago, 5 hours ago, a day ago, 2 days ago*!/*/}
                                                                            {/*<a> 12/03/2023</a> /!*for more than 48 hours we will show 12/03/2023*!/*/}
                                                                        </div>


                                                                    </ListGroup.Item>
                                                                </ListGroup>
                                                            );
                                                        })
                                                    ) : postLoading ? (
                                                        <Loader/>
                                                    ) : (
                                                        <ListGroup>
                                                            <ListGroup.Item>
                                                                <h6>No Assignment Notifications</h6>
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    )}
                                                    <div className="page-navigation mt-4">
                                                        <ReactPaginate
                                                            previousLabel={"Previous"}
                                                            nextLabel={"Next"}
                                                            pageCount={assignmentPageCount}
                                                            onPageChange={changeAssignmentPage}
                                                            containerClassName={"paginationBttns"}
                                                            previousLinkClassName={"previousBttn"}
                                                            nextLinkClassName={"nextBttn"}
                                                            disabledClassName={"paginationDisabled"}
                                                            activeClassName={"paginationActive"}
                                                        />
                                                    </div>

                                                </div>

                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-inprogress"
                                                    role="tabpane2"
                                                    aria-labelledby="pills-assignment-inprogress">

                                                    {/*<Formik*/}
                                                    {/*    initialValues={searchTermPostEvent}*/}
                                                    {/*    enableReinitialize={true}*/}
                                                    {/*    onSubmit={loadPostNotificationsPage}>*/}
                                                    {/*    {({props, values, setFieldValue}) => {*/}
                                                    {/*        return (<Form>*/}

                                                    {/*            <div className="form-group input-default-white">*/}
                                                    {/*                <FormikTextField label="Search"*/}
                                                    {/*                                 name="searchTerm"*/}
                                                    {/*                                 type="text" />*/}
                                                    {/*            </div>*/}

                                                    {/*        </Form>)*/}
                                                    {/*    }}*/}
                                                    {/*</Formik>*/}

                                                    {postNotifications?.length > 0 ? (
                                                        postNotifications.map((data, index) => {
                                                            return (
                                                                <ListGroup key={index}>
                                                                    <ListGroup.Item
                                                                        onClick={() => redirectionController(data)}>
                                                                        <div>
                                                                            <p>
                                                                                {data?.readStatus === false ?
                                                                                    <i className=" "/> :
                                                                                    <i className=" "/>}
                                                                                <span
                                                                                    style={{color: 'black'}}>{data?.body}</span>
                                                                                {/*<span> <strong> Austin </strong> has liked a post </span>*/}

                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p>
                                                                                <span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span>
                                                                            </p>

                                                                            {/*<a> 7 hours*/}
                                                                            {/*    ago</a> /!*for between 2 days we will show 5 mins ago, 5 hours ago, a day ago, 2 days ago*!/*/}
                                                                            {/*<a> 12/03/2023</a> /!*for more than 48 hours we will show 12/03/2023*!/*/}
                                                                        </div>


                                                                    </ListGroup.Item>
                                                                </ListGroup>
                                                            );
                                                        })
                                                    ) : assignmentLoading ? (
                                                        <Loader/>
                                                    ) : (
                                                        <ListGroup>
                                                            <ListGroup.Item>
                                                                <h6>No Post Notifications</h6>
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    )}

                                                    <div className="page-navigation">
                                                        <ReactPaginate
                                                            previousLabel={"Previous"}
                                                            nextLabel={"Next"}
                                                            pageCount={pageCount}
                                                            onPageChange={changePage}
                                                            containerClassName={"paginationBttns"}
                                                            previousLinkClassName={"previousBttn"}
                                                            nextLinkClassName={"nextBttn"}
                                                            disabledClassName={"paginationDisabled"}
                                                            activeClassName={"paginationActive"}
                                                        />
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-right card-default px-3">
                                <div className="card-default-transparent pt-3">
                                    <div className="card-heading">
                                        <div className="title">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" fill="none">
                                                <path fill="#475467" fill-rule="evenodd"
                                                      d="M6.429 16.11a.99.99 0 0 1-.314.718c-.201.19-.474.297-.758.297-.284 0-.557-.107-.757-.297a.99.99 0 0 1-.314-.719V4.937A.99.99 0 0 1 4.6 4.22c.2-.19.473-.297.757-.297.284 0 .557.107.758.297.2.19.314.45.314.718V16.11Zm-4.286 0a.99.99 0 0 1-.314.718c-.2.19-.473.297-.758.297-.284 0-.556-.107-.757-.297A.99.99 0 0 1 0 16.108V9a.99.99 0 0 1 .314-.718c.2-.19.473-.298.757-.298.285 0 .557.107.758.298.201.19.314.449.314.718v7.11Zm6.428 0V6.968a.99.99 0 0 1 .314-.718c.201-.19.474-.298.758-.298.284 0 .556.107.757.298.201.19.314.448.314.718v9.14a.99.99 0 0 1-.314.718c-.2.191-.473.298-.757.298-.284 0-.557-.107-.758-.297a.99.99 0 0 1-.314-.719Zm4.286-14.22a.99.99 0 0 1 .314-.718c.2-.19.473-.297.758-.297.284 0 .556.107.757.297.201.19.314.45.314.719v14.218a.99.99 0 0 1-.314.718c-.2.191-.473.298-.757.298-.285 0-.557-.107-.758-.297a.99.99 0 0 1-.314-.719V1.891Z"
                                                      clip-rule="evenodd"/>
                                            </svg>
                                            <h6>Recent Activities</h6>
                                        </div>
                                        <div>
                                            <button
                                                data-tip data-for="calender"
                                                onClick={() => handleCalender()}
                                            >
                                                <svg className="mr-0" xmlns="http://www.w3.org/2000/svg" width="16"
                                                     height="16" fill="none">
                                                    <path fill="#475467"
                                                          d="M1.59 15.238c-.437 0-.812-.15-1.123-.448A1.435 1.435 0 0 1 0 13.714V3.048c0-.42.156-.778.467-1.077a1.562 1.562 0 0 1 1.123-.447h.795V.762c0-.216.076-.397.23-.543A.784.784 0 0 1 3.18 0c.225 0 .414.073.567.22a.719.719 0 0 1 .228.542v.762h6.36V.762c0-.216.077-.397.23-.543A.784.784 0 0 1 11.13 0c.226 0 .415.073.567.22a.72.72 0 0 1 .229.542v.762h.794c.438 0 .812.15 1.124.448.312.298.467.657.467 1.076v4.571h-1.59V6.095H1.59v7.62h5.564v1.523H1.59Zm0-10.667h11.13V3.048H1.59V4.57Zm13.615 6.858-1.69-1.62.577-.552a.772.772 0 0 1 .556-.21c.226 0 .411.07.557.21l.576.553c.146.14.219.317.219.533a.707.707 0 0 1-.219.533l-.576.553Zm-6.46 4.19v-.914a.763.763 0 0 1 .239-.553l3.975-3.81 1.69 1.62-3.976 3.81a.797.797 0 0 1-.268.17.855.855 0 0 1-.308.058h-.954a.39.39 0 0 1-.278-.114.358.358 0 0 1-.12-.267Z"/>
                                                </svg>
                                            </button>
                                            <ReactTooltip id="calender" place="left" effect="solid" type="dark">
                                                Activity Calender
                                            </ReactTooltip>
                                    </div>
                                </div>
                                <div id="calender">
                                    <div className="block-group">
                                        {smallCalenderData.length > 0 ? smallCalenderData.map((smallCalenderData, index) => {
                                            return (<div className="block-25">
                                                <div className="date">
                                                    {/*<h4>{" "}{moment(smallCalenderData.date).format("MM/DD")}*/}
                                                    {/*</h4>*/}
                                                    <h4>{" "}{moment(smallCalenderData.date).format("Do MMM YY")}
                                                    </h4>
                                                </div>
                                                <div className="reminder">
                                                    <p className="event mb-1">{smallCalenderData.eventsCount} Events</p>
                                                    <p className="assignment">{smallCalenderData.assignmentsCount} Assignment</p>
                                                </div>

                                            </div>);
                                        }) : null}
                                    </div>
                                </div>
                                </div>
                                <hr/>
                                <div className="card-default-transparent">
                                    <div className="card-heading">
                                        <div className="title">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" fill="none">
                                                <path fill="#475467"
                                                      d="M12.857 7.766V3.33c0-.813-.643-1.478-1.428-1.478h-.715V.375H9.286v1.478H3.57V.375H2.143v1.478h-.714C.643 1.853 0 2.518 0 3.331v10.347c0 .813.643 1.478 1.429 1.478h5v-1.478h-5v-7.39h10v1.478h1.428ZM11.43 4.809h-10V3.331h10V4.81Zm-2.4 8.87a2.582 2.582 0 0 0 1.052 1.439 2.435 2.435 0 0 0 1.706.378 2.481 2.481 0 0 0 1.53-.866c.393-.47.61-1.07.612-1.69 0-1.427-1.122-2.588-2.5-2.588-.679 0-1.3.281-1.75.74h1.035V12.2H7.857V9.244H8.93V10.3a3.513 3.513 0 0 1 2.5-1.057C13.4 9.244 15 10.899 15 12.939a3.77 3.77 0 0 1-.926 2.473 3.532 3.532 0 0 1-2.288 1.194 3.485 3.485 0 0 1-2.472-.697 3.711 3.711 0 0 1-1.385-2.23h1.1Z"/>
                                            </svg>
                                            <h6>Events</h6>
                                        </div>

                                        <div className="d-flex align-items-center">

                                            <div className="action ml-3">
                                                <div className="add-more">
                                                    <button
                                                        data-tip
                                                        data-for="allEvents"
                                                        onClick={() => handleAllEvents()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11"
                                                             fill="none">
                                                            <path fill="#475467"
                                                                  d="M0 10.834V9.056h11.556v1.778H0Zm14.756-.89L10.31 5.5l4.445-4.444L16 2.3l-3.2 3.2L16 8.7l-1.244 1.245ZM0 6.39V4.611h8.889V6.39H0Zm0-4.444V.167h11.556v1.778H0Z"/>
                                                        </svg>
                                                        See All
                                                    </button>
                                                    <ReactTooltip
                                                        id="allEvents"
                                                        place="left"
                                                        effect="solid"
                                                        type="dark">
                                                        All Events
                                                    </ReactTooltip>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="tab-orange">
                                        <div className="tabs">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="pills-home-tab"
                                                        data-toggle="pill"
                                                        href="#pills-home"
                                                        role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true">
                                                    Upcoming
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="pills-profile-tab"
                                                    data-toggle="pill"
                                                    href="#pills-profile"
                                                    role="tab"
                                                    aria-controls="pills-profile"
                                                    aria-selected="false">
                                                    Previous
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="pills-home"
                                                role="tabpanel"
                                                aria-labelledby="pills-home-tab">
                                                {Object.keys(upcomingEventsList).length > 0 ? (upcomingEventsList.map((data, index) => {
                                                    return (<ListGroup>
                                                        <ListGroup.Item
                                                            onClick={() => handleEventRoute(data)}>
                                                            <h6 className="mb-1">{data.eventName}</h6>
                                                            <h6 className="mb-1"> {data?.channel?.channelName} </h6>
                                                            <p>
                                                        <span>
                                                           {convertUTCDateToLocalDate(new Date(data.eventStartTime))} - {convertUTCDateToLocalDate(new Date(data.eventEndTime))}
                                                            {/*{(new Date(data.eventStartTime))} - {(new Date(data.eventEndTime))}*/}
                                                        </span>
                                                            </p>
                                                        </ListGroup.Item>
                                                    </ListGroup>);
                                                })) : upcomingEventsLoading ? (<SkeletonView count={8}/>) : (<ListGroup>
                                                    <ListGroup.Item>
                                                        <h6>No Upcoming Events</h6>
                                                    </ListGroup.Item>
                                                </ListGroup>)}
                                            </div>

                                            <div
                                                className="tab-pane fade"
                                                id="pills-profile"
                                                role="tabpanel"
                                                aria-labelledby="pills-profile-tab">
                                                {Object.keys(previousEventsList).length > 0 ? (previousEventsList.map((data, index) => {
                                                    return (<ListGroup>
                                                        <ListGroup.Item
                                                            onClick={() => handleEventRoute(data)}>
                                                            <h6 className="mb-1">{data.eventName}</h6>
                                                            <h6 className="mb-1"> {data?.channel?.channelName} </h6>
                                                            <p>
                                                        <span>
                                                             {convertUTCDateToLocalDate(new Date(data.eventStartTime))} - {convertUTCDateToLocalDate(new Date(data.eventEndTime))}
                                                        </span>
                                                            </p>
                                                        </ListGroup.Item>
                                                    </ListGroup>);
                                                })) : previousEventsLoading ? (<SkeletonView count={8}/>) : (<ListGroup>
                                                    <ListGroup.Item>
                                                        <h6>No Previous Events</h6>
                                                    </ListGroup.Item>
                                                </ListGroup>)}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        </div>
                    </main>
                </div>
            </CommonLayout>


        </>
    );
}

export default AllNotificationList;
