import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as ChannelApi from "../../api/ChannelApi";
import SkeletonView from "../SkeletonView";

function SideNav(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [sportsTypeList, setSportsTypeList] = useState([]);

    useEffect(() => {
        console.log(sportsTypeList.length);
        if (!sportsTypeList.length)
        fetchSportsTypeList();
    }, []);

    function fetchSportsTypeList() {
        ChannelApi.getAllSportsTypes()
            .then(resp => {
                if (resp.data) {
                    setSportsTypeList(resp.data)
                }
            })
    }

    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav border-right-1">
                    <div className="profile-menu d-flex flex-column">
                        <div className="top-profile-section d-flex">
                            <img src="img/maleStudent.svg" alt="profile picture" className="profile-pic"/>
                            <div className="profile-details">
                                <h6>MyEuroLife</h6>
                                <p>Dhaka, Bangladesh</p>
                                <p>200lb | 6ft | 16 Grade</p>
                            </div>
                        </div>
                        <div className="profile-bio">
                            <p>
                                User, a 200-pound, 6-foot tall student, attends 16th grade at Dhanmondi Government Boys’
                                High School.
                                Passionate about......
                                <a href="#">See More</a>
                            </p>
                        </div>
                        <div className="profile-stats">
                            <div className="follower-following d-flex justify-content-between">
                                <div className=" ">
                                    <p><strong>999+</strong></p>
                                    <p>Followings</p>
                                </div>
                                <div className=" ">
                                    <p><strong>999+</strong></p>
                                    <p>Followers</p>
                                </div>
                            </div>
                            <div className="social-media-links d-flex justify-content-center">
                                <a href="#" className="social-icon"><img src="img/instagram-icon.png" alt="Instagram"/></a>
                                <a href="#" className="social-icon"><img src="img/tiktok-icon.png" alt="TikTok"/></a>
                                <a href="#" className="social-icon"><img src="img/facebook-icon.png" alt="Facebook"/></a>
                                <a href="#" className="social-icon"><img src="img/twitter-icon.png" alt="Twitter"/></a>
                            </div>
                        </div>
                    </div>


                    <div className="sb-sidenav-menu">
                        <div className="nav">

                            {!props.loading ? (
                                <div className="">
                                    {sportsTypeList && sportsTypeList
                                            .map((data, index) => (
                                                <a className="nav-link sidenav-border" key={index}>
                                                    <button
                                                        type="button"
                                                        className="dropdown-sidenav py-3"
                                                        onClick={() =>
                                                            navigateTo("/posts/" + data.id)
                                                        }>
                                                        <div>
                                                            <h6 className="  mb-0 mt-1">{data.sportsType}</h6>
                                                        </div>
                                                    </button>
                                                </a>
                                            ))
                                    }
                                </div>
                            ) : (
                                <div className="w-100">
                                    <SkeletonView count={17}/>
                                </div>
                            )}
                        </div>
                    </div>

                </nav>
            </div>
        </>
    );
}

export default SideNav;