import React, {useEffect, useState} from "react";
import * as StudentApi from "../../api/StudentApi";
import * as GradeSetupApi from "../../api/GradeSetupApi";
import * as SectionSetupApi from "../../api/SectionSetupApi";
import * as ClassSetupApi from "../../api/ClassSetupApi";
import {Field, Form, Formik} from "formik";

import {TextArea} from "semantic-ui-react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import SelectDropdown from "../common/SelectDropwodn";
import {DataGrid} from "@material-ui/data-grid";
import CommonLayout from "../layout/commonLayout";

function ClassSetupNew(props) {
    const [classSetup, setClassSetup] = useState({
        className: "",
        gradeSetup: "",
        sectionSetup: "",
        classAdmin: null,
        id: ""
    });
    const [gradeSetup, setGradeSetup] = useState([]);
    const [sectionSetup, setSectionSetup] = useState([]);
    const [selectedOption, setSelectedOption] = React.useState([]);
    const [student, setStudents] = useState([]);
    const [grade, setGrade] = useState(null);
    const [section, setSection] = useState(null);
    const [classSetupSaveDTO, setClassSetupChild] = useState({
        classSetupId: "",
        studentsIdList: ""
    });
    useEffect(() => {
        StudentApi.getAll().then(json => {
            setStudents(json.data);
        });
    }, []);
    const columns = [
        {field: "firstName", headerName: "First name", width: 130},
        {field: "lastName", headerName: "Last name", width: 130}
    ];

    useEffect(() => {
        GradeSetupApi.getAll().then(json => {
            setGradeSetup(json.data);
        });
    }, []);

    useEffect(() => {
        SectionSetupApi.getAll().then(json => {
            setSectionSetup(json.data);
        });
    }, []);

    const handleSubmit = values => {
        var studentIds = [];
        selectedOption.forEach(value => {
            console.log(value.id);
            studentIds.push(value.id);
        });

        ClassSetupApi.saveClassSetup(values).then(response => {
            console.log(response.data);
            setClassSetupChild({
                classSetupId: response.data.id,
                studentsIdList: studentIds
            });
            const data = {
                classSetupId: response.data.id,
                studentsIdList: studentIds
            };
            console.log(data);
            console.log(JSON.stringify(classSetupSaveDTO));
            ClassSetupApi.saveClassSetupChild(data).then(response => {
                console.log(response);
            });
        });
    };
    const handleChange = event => {
        setSelectedOption(event.target.value);
    };
    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    const TextAreaComponent = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <TextArea
                    {...field}
                    type="text"
                    cols="50"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    const DatePickerComponent = ({
                                     field,
                                     form: {touched, errors, setFieldValue}
                                 }) => {
        return (
            <div className="input-group mb-3">
                <DatePicker
                    selected={Date.parse(field.value)}
                    onChange={value =>
                        setFieldValue(
                            field.name,
                            moment(value)
                                .tz("Asia/Dhaka")
                                .format("YYYY-MM-DD")
                        )
                    }
                    placeholderText="barishal"
                    className="form-control btn-block"
                    isClearable
                    dateFormat="MM/dd/yyyy"
                />
            </div>
        );
    };

    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });

    const renderChecked = (values, user) => {
        if (values.classAdmin.findIndex(item => item.login === user.login) > -1) {
            return true;
        } else {
            return false;
        }
    };
    let personName;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const handleChangeSection = (fieldName, value) => {
        setSection(value);
    };
    const handleChangeGroup = (fieldName, value) => {
        setGrade(value);
    };

    useEffect(() => {
        if (grade !== null && section == null) {
            StudentApi.getStudentListByGroupId(grade.id).then(json => {
                setStudents(json.data);
            });
        } else if (grade !== null && section !== null) {
            StudentApi.getStudentListBySectionAndGroupId(section.id, grade.id).then(
                json => {
                    setStudents(json.data);
                }
            );
        }
    }, [grade, section]);

    useEffect(() => {
        console.log("classSetup.sectionSetup");
        console.log(classSetup.sectionSetup);
    }, [classSetup]);

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Create Class</h1>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={classSetup}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({props, values}) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p>Class name</p>
                                                <Field
                                                    type="text"
                                                    name="className"
                                                    placeholder="Class Name"
                                                    component={TextField}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Field
                                                    type="text"
                                                    name="gradeSetup"
                                                    placeholder="Select Grade"
                                                    fieldName={"gradeName"}
                                                    label="Grade"
                                                    handleChangeSection={handleChangeGroup}
                                                    options={gradeSetup || []}
                                                    component={SelectDropdown}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <Field
                                                    type="text"
                                                    name="sectionSetup"
                                                    placeholder="Select Section"
                                                    fieldName={"sectionName"}
                                                    label="Section"
                                                    handleChangeSection={handleChangeSection}
                                                    options={sectionSetup || []}
                                                    component={SelectDropdown}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div style={{height: 400, width: "100%"}}>
                                                <DataGrid
                                                    rows={student}
                                                    columns={columns}
                                                    pageSize={5}
                                                    checkboxSelection
                                                    onSelectionChange={param => {
                                                        setSelectedOption(param.rows);
                                                    }}
                                                    onRowSelected={params => {
                                                        if (params.isSelected) {
                                                            setSelectedOption([
                                                                ...selectedOption,
                                                                params.data.id
                                                            ]);
                                                        } else {
                                                            const index = selectedOption.findIndex(
                                                                item => item.id === params.data.id
                                                            );
                                                            if (index > -1) {
                                                                const newArray = [...selectedOption];
                                                                newArray.splice(index, 1);
                                                                setSelectedOption(newArray);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {JSON.stringify(selectedOption)}

                                        <button
                                            type="submit"
                                            className="btn btn-lg btn-primary float-right"
                                        >
                                            Save
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ClassSetupNew;
