import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as StudentApi from "../../api/StudentApi";
import Loader from "../common/Loader";
import {FILES_URL} from "../../util/LinkFactory";
import PageError from "../ErrorPage";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import TextAreaComponent from "../common/TextAreaComponent";
import * as ChannelApi from "../../api/ChannelApi";
import Swal from "sweetalert2";


const defaultData = {
    bio: "",
    email: "",
    firstName: "",
    lastName: "",
    height: "",
    section: "",
    school: "",
    weight: "",
};
toast.configure();

function UserProfile() {
    const [user,setUser] = useState(Utils.getUserFromLocalStorage())
    const [userInfo, setUserInfo] = useState(defaultData);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState([]);
    let fileBase64s = [];
    const [files, setFiles] = useState([]);
    const [editMode, setEditMode] = useState(false);


    useEffect(() => {
    }, []);


    const handleProfilePicInputChange = event => {
        fileBase64s = [];
        setFiles(fileBase64s);
        setSelectedFiles(event.target.files);
        Array.from(event.target.files).forEach(file => {
            fileToDataUri(file).then(data => {
                fileBase64s.push({blob: data.split("base64,")[1], type: file.type, fileName: file.name});
            });
        });
        console.log(fileBase64s);
        setFiles(fileBase64s);
    };


    const fileToDataUri = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });


    function proPicUpload() {
        console.log(files);
        let profilePicUploadDto = {
            photoBlob: files[0].blob,
            photoBlobType: files[0].type,
            userId: userInfo.userId
        };
        uploadProfilePicture(profilePicUploadDto).then(r => {
            toast("Profile Picture Uploaded!")
        });
    }

    async function uploadProfilePicture(profilePicUploadDto) {
        await StudentApi.uploadStudentProfilePicture(profilePicUploadDto).then(
            response => {
                flushFileUpload();
            }
        );
    }

    function flushFileUpload() {
        setFiles([]);
        setSelectedFiles([]);
        fileBase64s = [];
    }

    const handleEditProfile = () => {
        setUserInfo(user)
        setEditMode(!editMode)
    }

    const handleSubmit = (values) => {
        console.log(values);
        const body = {
             email : values.email,
             bio : values.bio,
             height : values.height,
             weight : values.weight,
             grade : values.grade,
             school : values.school,
             country : values.country && values.country.id,
             states : values.states && values.states.id,
        }
        ChannelApi.updateUserInfo(body).then(resp => {
            console.log(resp);
            if (resp.response && resp.response.status === 201){
                setUser(resp.response.data)
                Swal.fire('Profile updated !', '','success');
                setEditMode(false);
            }
        })
    }

    if (userInfo && !loader) {
        return (
            <div className=" student-profile">
                <CommonLayout headerName={"Student Info"}>
                    <main>
                        <div className="  pt-3 pb-3 p-2 profile-edit d-flex justify-content-center">
                            {error ? (
                                <PageError title={"No data to show"}/>
                            ) : (
                                <div className="card-default ">
                                    <div className="card-heading">
                                        <div className="title">
                                            {/*<p>Profile</p>*/}
                                        </div>
                                        <div>
                                            <button type="button" onClick={handleEditProfile}
                                                    className="btn-red-lg radius-25">
                                                {editMode ? 'Cancel' : 'Edit'}
                                            </button>
                                        </div>
                                    </div>

                                    <Formik initialValues={userInfo}
                                            enableReinitialize={true}
                                            onSubmit={handleSubmit}>
                                        {({props, values, setFieldValue}) => (
                                            <Form>

                                                <div className="profile-container mx-auto">
                                                    <div className="profile-header">
                                                        <div className="profilepic-section">
                                                            <div className="profile-pic">
                                                                <img
                                                                    src={user?.imageUrl ? `${FILES_URL}${user.imageUrl}` : "default-pic-url"}
                                                                    alt={user?.firstName}
                                                                />
                                                            </div>
                                                            <div className="">
                                                                {editMode && (
                                                                    <div className="upload-photo">
                                                                        <input
                                                                            className="custom-file-input"
                                                                            id="file"
                                                                            name="file"
                                                                            type="file"
                                                                            onChange={handleProfilePicInputChange}
                                                                        />
                                                                        <label className="custom-file-label" htmlFor="file">Choose file</label>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-red-md radius-25 mt-2"
                                                                            // disabled={!selectedFile}
                                                                            onClick={() => proPicUpload()}
                                                                        >
                                                                            <i className="fa fa-upload mr-2" /> Upload
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>


                                                        <div className="profile-info">
                                                            <div className="profile-name">
                                                                <div>
                                                                    <div className="d-flex mb-2">
                                                                        <div className="mr-2">
                                                                            <p> First Name</p>
                                                                            <div>
                                                                                {!editMode && user?.firstName}
                                                                                {editMode && (
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="firstName"
                                                                                        placeholder="First Name"
                                                                                        component={TextFieldComponent}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p> Last Name</p>
                                                                            <div>
                                                                                {!editMode && user?.lastName}
                                                                                {editMode && (
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="lastName"
                                                                                        placeholder="Last Name"
                                                                                        component={TextFieldComponent}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p> Email</p>
                                                                    <div>
                                                                        <div>
                                                                            {!editMode && user?.email}
                                                                            {editMode && (
                                                                                <Field
                                                                                    type="text"
                                                                                    name="email"
                                                                                    placeholder="Email"
                                                                                    component={TextFieldComponent}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="profile-bio">
                                                                {!editMode ? (
                                                                    <p>{user?.bio}</p>
                                                                ) : (
                                                                    <Field
                                                                        type="text"
                                                                        name="bio"
                                                                        placeholder="Bio"
                                                                        component={TextAreaComponent}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="profile-details">
                                                        <div className="detail-item">
                                                            <label>Height</label>
                                                            <p>{!editMode ? user?.height : <Field type="text" name="height" placeholder="Height" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Weight</label>
                                                            <p>{!editMode ? user?.weight : <Field type="text" name="weight" placeholder="Weight" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Grade</label>
                                                            <p>{!editMode ? user?.grade : <Field type="text" name="grade" placeholder="Grade" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>School</label>
                                                            <p>{!editMode ? user?.school : <Field type="text" name="school" placeholder="School" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Country</label>
                                                            <p>{!editMode ? user?.country?.country : <Field type="text" name="country" placeholder="Country" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>State</label>
                                                            <p>{!editMode ? user?.states?.states : <Field type="text" name="state" placeholder="State" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Contact Telephone</label>
                                                            <p>{!editMode ? user?.telephone : <Field type="text" name="telephone" placeholder="Telephone" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Business Founder</label>
                                                            <p>{!editMode ? user?.founder : <Field type="text" name="founder" placeholder="Founder" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Website Address</label>
                                                            <p>{!editMode ? user?.website : <Field type="text" name="website" placeholder="Website" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Offering</label>
                                                            <p>{!editMode ? user?.offering : <Field type="text" name="offering" placeholder="Offering" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Population</label>
                                                            <p>{!editMode ? user?.population : <Field type="text" name="population" placeholder="Population" component={TextFieldComponent} />}</p>
                                                        </div>
                                                        <div className="detail-item">
                                                            <label>Business Type</label>
                                                            <p>{!editMode ? user?.businessType : <Field type="text" name="businessType" placeholder="Business Type" component={TextFieldComponent} />}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                                {editMode && (
                                                    <div className="card-footer d-flex justify-content-center">
                                                        <button type="submit" className="btn-red-md radius-25">Update
                                                        </button>
                                                    </div>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            )}
                        </div>
                    </main>
                </CommonLayout>
            </div>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default UserProfile;
