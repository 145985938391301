import React from "react";
import StudyMaterialUpload from "./StudyMaterialUpload";
import {useParams} from "react-router-dom";

function StudyMaterialsEditPage(props) {
    const params = useParams();
    const initialState = {
        name: "",
        description: "",
        attachmentName: "",
        attachmentType: ""
    };
    const channelId = params.id;
    const materialId = params.materialId;
    const channelStudyMaterials = {};

    return (
        <StudyMaterialUpload
            channelStudyMaterials={channelStudyMaterials}
            materialId={materialId}
            initialState={initialState}
            channelId={channelId}
        />
    );
}

export default StudyMaterialsEditPage;
