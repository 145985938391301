import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import * as DepartmentApi from "../../api/DepartmentApi";
import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";

import * as LinkFactory from "../../util/LinkFactory";
import {toast} from "react-toastify";
import * as FacultyApi from "../../api/FacultyApi";
import SelectDropdown from "../common/SelectDropwodn";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../common/Loader";

function DepartmentNew(props) {
    const params = useParams();

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const [department, setDepartment] = useState({
        name: "",
        faculty: {},
    });
    const [saving, setSaving] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const notifySuccess = () => toast.success("Department Created!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    const handleSubmit = values => {
        setSaving(true)
        if (
            (null == values.name || '' === values.name) ||
            (Object.keys(values.faculty).length === 0)) {
            notifyValidation();
        } else {
            let data = {};
            let id = params.id;
            if (params.id) {
                data = {
                    faculty: null != values.faculty ? values.faculty : null,
                    name: values.name,
                    id: parseInt(params.id)
                }
                DepartmentApi.updateDepartment(data)
                    .then(data => {
                        notifyUpdate();
                        setSaving(false);
                        navigateTo(LinkFactory.departmentList);
                    });
            } else {
                data = {
                    faculty: null != values.faculty ? values.faculty : null,
                    name: values.name
                }
                DepartmentApi.saveDepartment(data)
                    .then(data => {
                        notifySuccess();
                        setSaving(false);
                        navigateTo(LinkFactory.departmentList);
                    });
            }
        }
    };

    const setFacultyObj = value => {
        getDepartmentsByFaculty(value.id);
    }

    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    }

    useEffect(() => {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties(json.data);
            if (params.id) {
                DepartmentApi.getDepartment(params.id).then(res => {
                    setDepartment({
                        name: res.data.name,
                        faculty: res.data.faculty
                    })
                })
            }


        });

    }, []);

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout headerName="Department">
                <main>
                    <div className="card-default department-new container-responsive">
                        <div className="title-header">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>{null != params.id ? "Edit " : ""}Department </p>
                            </div>
                        </div>

                        <div>
                            <Formik
                                initialValues={department}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({props, values, setFieldValue}) => (
                                    <Form>

                                        <div className="form-row">
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <label>Faculty</label>
                                                    <Field
                                                        type="text"
                                                        name="faculty"
                                                        placeholder={values?.name || "Select Faculty"}
                                                        fieldName={"name"}
                                                        label="faculty"
                                                        handleChangeSection={(fieldName, value) => {
                                                            setFieldValue("faculty", value);
                                                            setFacultyObj(value)
                                                        }}
                                                        required
                                                        options={faculties || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <label>Department Name</label>
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        placeholder="Department Name"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    {/*<label> &nbsp;</label>*/}
                                                    <button
                                                        disabled={saving}
                                                        type="submit"
                                                        className="btn btn-dark radius-25 mt-2">
                                                        <i className="fa fa-bookmark mr-2"/>
                                                        Save
                                                    </button>
                                                    {saving ? <Loader className="my-5"/> : ""}
                                                </div>
                                            </div>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default DepartmentNew;
