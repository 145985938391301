import CommonLayout from "../layout/commonLayout";
import {Field, Form, Formik} from "formik";
import FormTitle from "../Typhography/formTitle";
import React, {useState} from "react";
import * as Utils from "../../util/Utils";
import {toast} from "react-toastify";
import {changePassword} from "../../api/AuthApi";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";


function ChangePassword () {
    let history = useNavigate();
    const dispatch = useDispatch();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [user, setSetUser] = useState({
        id: Utils.getUserFromLocalStorage().id,
        oldPassword: "",
        confirmPassword: "",
        newPassword: ""
    });

    const validationPassword = (password1, password2) => {
        console.log(password1, password2);
        if (password1 === '') {
            toast("Please enter Password");
            return false;
        }

        else if (password2 === '') {
            toast("Please enter confirm password");
        }

        else if (password1 !== password2) {
            toast ("\nPassword did not match: Please try again...")
            return false;
        }
        return true;
    }
    
    const handleSubmit = (values) => {
        if(validationPassword(values.newPassword, values.confirmPassword)) {
            changePassword(values).then(response => {
                toast(response.data);
                handleLogOut();
            }, err => {
                console.log(err.data);
            });
        }
    }

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevState) => !prevState);
    };

    const handleLogOut = () => {
        Utils.LogOut(dispatch);
        history(LinkFactory.loginPage)
    };


    function goBack() {
        navigateTo(-1)
    }

    return (
        <>
            <CommonLayout headerName="Change Password">
                <main>
                    <div className=" card-default change-password container-responsive">
                        <div className="title-header">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Change Password</p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center pass-box">
                            <div className="changepass-box">
                                <div>
                                    <h3>Change Password</h3>
                                    <p>Your password must be at least 6 characters and should include a combination of numbers, letters and special characters (!$@%).</p>
                                </div>

                                <Formik
                                    initialValues={user}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {({props, values, setFieldValue}) => (
                                              <Form>
                                            <div className=" ">
                                                <div className=" ">
                                                    <div className="password-box form-group input-default-white">
                                                        <FormTitle>Current Password</FormTitle>
                                                        <Field
                                                            className="form-control"
                                                            type={passwordVisible ? "text" : "password"}
                                                            name="oldPassword"
                                                            placeholder="Current Password"
                                                            // component={TextFieldComponent}
                                                        />
                                                        <svg
                                                            className="password-icon"
                                                            width="13" height="9" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            <path
                                                                d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                fill="#878787"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className=" ">
                                                    <div className="password-box form-group input-default-white">
                                                        <FormTitle>New Password</FormTitle>
                                                        <Field
                                                            className="form-control"
                                                            type={passwordVisible ? "text" : "password"}
                                                            name="newPassword"
                                                            placeholder="New Password"
                                                            /*component={TextFieldComponent}*/
                                                        />
                                                        <svg
                                                            className="password-icon"
                                                            width="13" height="9" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            <path
                                                                d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                fill="#878787"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className=" ">
                                                    <div className="password-box form-group input-default-white">
                                                        <FormTitle>Confirm Password</FormTitle>
                                                        <Field
                                                            className="form-control"
                                                            type={passwordVisible ? "text" : "password"}
                                                            name="confirmPassword"
                                                            placeholder="Confirm Password"
                                                            // component={TextFieldComponent}
                                                        />
                                                        <svg
                                                            className="password-icon"
                                                            width="13" height="9" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            <path
                                                                d="M6.5 2.7c.47 0 .921.19 1.254.527.332.338.519.796.519 1.273 0 .477-.187.935-.52 1.273A1.76 1.76 0 0 1 6.5 6.3c-.47 0-.921-.19-1.254-.527A1.814 1.814 0 0 1 4.727 4.5c0-.477.187-.935.52-1.273A1.76 1.76 0 0 1 6.5 2.7Zm0-2.7c2.955 0 5.478 1.866 6.5 4.5C11.978 7.134 9.455 9 6.5 9S1.022 7.134 0 4.5C1.022 1.866 3.545 0 6.5 0ZM1.288 4.5a5.862 5.862 0 0 0 2.14 2.408A5.738 5.738 0 0 0 6.5 7.8c1.086 0 2.15-.31 3.071-.893a5.862 5.862 0 0 0 2.14-2.408 5.862 5.862 0 0 0-2.14-2.408A5.738 5.738 0 0 0 6.5 1.199c-1.086 0-2.15.31-3.071.893A5.862 5.862 0 0 0 1.289 4.5Z"
                                                                fill="#878787"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className=" w-100">
                                                    <div className="form-group input-default-white w-100">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-dark radius-25 px-4 text-center d-block w-100">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                        {/*<div className="bg-watermark">*/}
                        {/*    <img src="https://uxwing.com/wp-content/themes/uxwing/download/editing-user-action/lock-icon.png"/>*/}
                        {/*</div>*/}
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ChangePassword;
