import React from "react";
import {useLocation} from "react-router-dom";
import ThreadedMessage from "./ThreadedMessage";

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function MessagesThreadsIndex(props) {
    let query = useQuery();
    const receiverId = query.get("u");
    const threadId = query.get("th");
    return <ThreadedMessage threadId={threadId} receiverId={receiverId}/>;
}

export default MessagesThreadsIndex;
