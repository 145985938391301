import React from "react";
import Skeleton from "@mui/lab/Skeleton";

const SkeletonView = ({ type = "line", count = 1 }) => {
  const items = Array.from({ length: count }, () =>
    Math.floor(Math.random() * count)
  );

  return (
    <div className="align-content-center">
      {type === "line" &&
        items.map(item => (
          <div className="mx-2 w-100">
            <Skeleton height={32} animation="wave" />
          </div>
        ))}
      {type === "post" &&
        items.map(item => (
          <div className="mx-1">
            <Skeleton variant="circle" width={40} height={40} />
            <br />
            <Skeleton variant="rect" height={140} />
          </div>
        ))}
    </div>
  );
};

export default SkeletonView;
