import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveGradingEvaluationEntry = gradingEvaluationEntry => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/grading-evaluation-entry/",
        gradingEvaluationEntry
    );
};

export const updateGradingEvaluationEntry = gradingEvaluationEntry => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/grading-evaluation-entry/",
        gradingEvaluationEntry
    );
};
export const getGradingEvaluationEntryById = gradingEvaluationEntryId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grading-evaluation-entry/" + gradingEvaluationEntryId
    );
};
export const deleteGradingEvaluationEntryById = gradingEvaluationEntryId => {
    return AxiosAgent.requests.delete(
        API_MAIN_ROOT + "/grading-evaluation-entry/" + gradingEvaluationEntryId
    );
};
export const gettingMarksEntryByEvaluationId = gradingEvaluationId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT +
        "/grading-evaluation-entry-user-list-by-evaluation-id/" +
        gradingEvaluationId
    );
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading-evaluation-entry");
};
