import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import {getLastDayOfMonth, getYear} from "../../util/DateUtils";
import {monthWiseData} from "../../util/commonDataset";

const NextArrow = ({onClick}) => {
    return (
        <div role="button" className="px-3 calender-month-header" onClick={onClick}>
            <h3 className="box-icon">
                <svg width="23" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM22 7H0v2h22V7Z"
                        fill="#878787"/>
                </svg>
            </h3>
        </div>
    );
};

const PrevArrow = ({onClick}) => {
    return (
        <div role="button" className="px-3 calender-month-header" onClick={onClick}>
            <h3 className="box-icon">
                <svg width="23" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM1 9h22V7H1v2Z"
                        fill="#878787"/>
                </svg>
            </h3>
        </div>
    );
};

export default function MonthSlider({
                                        selected,
                                        monthCb,
                                        position = "center"
                                    }) {
    const [activeMonth, setActiveMonth] = useState(parseInt(selected));
    const [year, setYear] = useState(getYear());

    const handlePre = useCallback(() => {
        if (activeMonth <= 1) {
            setActiveMonth(12);
            let newYear = parseInt(year) - 1;
            setYear(newYear);
        } else {
            let prev = activeMonth - 1;
            setActiveMonth(prev);
        }
    }, [activeMonth, year]);

    const handleNext = useCallback(() => {
        if (activeMonth < 12) {
            let next = activeMonth + 1;
            setActiveMonth(next);
        } else {
            let newYear = parseInt(year) + 1;
            setYear(newYear);
            setActiveMonth(1);
        }
    }, [activeMonth, year]);

    const getMonthCallback = useCallback(
        (activeMonth, year, start, end, defaultDate) => {
            monthCb(activeMonth, year, start, end, defaultDate);
        },
        []
    );

    useEffect(() => {
        if (activeMonth || year) {
            let start = year + "-" + activeMonth + "-" + 1;
            let end = getLastDayOfMonth(activeMonth, year);
            let defaultDate = moment(
                year + "-" + activeMonth + "-" + 1,
                "YYYY-MM-DD"
            ).toDate();
            monthCb(activeMonth, year, start, end, defaultDate);
        }
    }, [activeMonth, year]);

    return (
        <div className={`calender-month mx-auto`}>
            <PrevArrow onClick={handlePre}/>
            <h5 className="calender-date">
                <div
                    className="px-3align-items-center font-weight-bold"
                    style={{userSelect: "none"}}
                >
                    {activeMonth &&
                    monthWiseData.filter(obj => obj.no === activeMonth) &&
                    monthWiseData.filter(obj => obj.no === activeMonth)[0] &&
                    monthWiseData.filter(obj => obj.no === activeMonth)[0].name}
                    <span className="pl-2">{year && parseInt(year)}</span>
                </div>
            </h5>
            <NextArrow onClick={handleNext}/>
        </div>
    );
}
