import _ from "lodash";
import React from "react";

export default class ValidationUtils {
    static passwordValidationMsg =
        "Password must contain at least one special character, one number, one uppercase and one lowercase letter.";

    static isNotBlank = (name, errors, form, map) => {
        for (let [key, value] of map.entries()) {
            let fieldValue = _.get(form, key);
            if (
                (name === undefined && fieldValue === undefined) ||
                fieldValue === ""
            ) {
                errors.push({field: key, message: value});
            }
        }
    };

    static isBlank = fieldValue => {
        return fieldValue === undefined || fieldValue === "";
    };

    static isEmpty = fieldValue => {
        return fieldValue === "";
    };

    static hasOnlySpecialCharater(val) {
        const pattern = /^[^a-zA-Z0-9]+$/;
        return pattern.test(val);
    }

    static firstLetterOnlySpecialCharater(val) {
        if (!this.isBlank(val)) {
            var res = val.substr(0, 1);
            return res;
        }
    }

    static validateFirstName = (fieldName, fieldValue, errors) => {
        let patternName = new RegExp("^[ a-zA-Z-_'.]*$");
        if (!this.isBlank(fieldValue) && fieldValue.length < 2) {
            errors.push({
                field: fieldName,
                message: "First name should be at least 2 characters"
            });
        } else if (
            !this.isBlank(fieldValue) &&
            patternName.test(fieldValue) === false
        ) {
            errors.push({
                field: fieldName,
                message: "First name only allow letters and some symbols (-, _, ', .)."
            });
        } else if (this.hasOnlySpecialCharater(fieldValue)) {
            errors.push({
                field: fieldName,
                message: "Please input correct name"
            });
        } else if (
            this.hasOnlySpecialCharater(
                this.firstLetterOnlySpecialCharater(fieldValue)
            )
        ) {
            errors.push({
                field: fieldName,
                message: "Please input correct name"
            });
        }
        return errors;
    };

    static validateLastName = (fieldName, fieldValue, errors) => {
        let patternName = new RegExp("^[ a-zA-Z-_'.]*$");
        if (!this.isBlank(fieldValue) && fieldValue.length < 2) {
            errors.push({
                field: fieldName,
                message: "Last name should be at least 2 characters"
            });
        } else if (
            !this.isBlank(fieldValue) &&
            patternName.test(fieldValue) === false
        ) {
            errors.push({
                field: fieldName,
                message: "Last name only allow letters and some symbols (-, _, ', .)."
            });
        } else if (this.hasOnlySpecialCharater(fieldValue)) {
            errors.push({
                field: fieldName,
                message: "Please input correct name"
            });
        } else if (
            this.hasOnlySpecialCharater(
                this.firstLetterOnlySpecialCharater(fieldValue)
            )
        ) {
            errors.push({
                field: fieldName,
                message: "Please input correct name"
            });
        }
        return errors;
    };

    static validateMiddleName = (fieldName, fieldValue, errors) => {
        let patternName = new RegExp("^[ a-zA-Z-_'.]*$");
        if (!this.isBlank(fieldValue) && patternName.test(fieldValue) === false) {
            errors.push({
                field: fieldName,
                message: "Middle name only allow letters and some symbols (-, _, ', .)."
            });
        }
    };

    static validateMaidenName = (fieldName, fieldValue, errors) => {
        let patternName = new RegExp("^[ a-zA-Z-_'.]*$");
        if (!this.isBlank(fieldValue) && patternName.test(fieldValue) === false) {
            errors.push({
                field: fieldName,
                message: "Maiden name only allow letters and some symbols (-, _, ', .)."
            });
        }
        if (!this.isBlank(fieldValue) && fieldValue.length < 2) {
            errors.push({
                field: fieldName,
                message: "Mother’s maiden name should be at least 2 characters"
            });
        }
    };

    static validateDayField = (fieldName, fieldValue, errors) => {
        if (!this.isBlank(fieldValue)) {
            if (fieldValue < 1 || fieldValue > 31) {
                errors.push({
                    field: fieldName,
                    message: "Day should be in 1-31 range."
                });
            }
        }
    };

    static validateMonthField = (fieldName, fieldValue, errors) => {
        if (!this.isBlank(fieldValue) && (fieldValue < 1 || fieldValue > 12)) {
            errors.push({
                field: fieldName,
                message: "Month should be in 1-12 range."
            });
        }
    };

    static leapYear(year) {
        return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    }

    static calculateLeapYear = (day, month, year, errors) => {
        if (day && month && year) {
            if (month == 2 && day > 29) {
                errors.push({
                    field: "day",
                    message: "Please input correct date"
                });
            } else if (month == 2 && day == 29) {
                if (!this.leapYear(year)) {
                    errors.push({
                        field: "day",
                        message: "Please input correct date"
                    });
                }
            } else if (
                (month == 4 || month == 6 || month == 9 || month == 11) &&
                day > 30
            ) {
                errors.push({
                    field: "day",
                    message: "Please input correct date"
                });
            }
        }
    };

    static validateYearField = (fieldName, fieldValue, errors) => {
        if (!this.isBlank(fieldValue)) {
            if (fieldValue.length !== 4) {
                errors.push({
                    field: fieldName,
                    message: "Year should be 4 digits."
                });
            }
            if (!this.validateMinimumAge(fieldValue)) {
                errors.push({
                    field: fieldName,
                    message: "Age has to be more than 18."
                });
            }
            if (fieldValue <= 1900) {
                errors.push({
                    field: fieldName,
                    message: "Year should be greater than 1900"
                });
            }
        }
    };

    static validatePhoneNumber = (fieldName, fieldValue, errors) => {
        if (!this.isBlank(fieldValue)) {
            if (fieldValue.length > 10) {
                errors.push({
                    field: fieldName,
                    message: "Your phone number should not more than 10 digits."
                });
            } else if (fieldValue.length < 6) {
                errors.push({
                    field: fieldName,
                    message: "Your phone number is required to be at least 6 digits."
                });
            } else if (new RegExp("^[0-9]*$").test(fieldValue) === false) {
                errors.push({
                    field: fieldName,
                    message: "Your phone number has to be digits"
                });
            }
        }
    };

    static validateEmail = (fieldName, fieldValue, errors) => {
        let patternEmail = new RegExp("^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$");
        if (!this.isBlank(fieldValue)) {
            if (patternEmail.test(fieldValue) === false) {
                errors.push({
                    field: fieldName,
                    message: "Please enter valid email."
                });
            }
        }
    };

    static validatePassWord = (fieldName, fieldValue, errors) => {
        var reg =
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ \"',~_|;:=<>(+)/.#?!@$%^&*-]).{8,}$";
        if (!this.isBlank(fieldValue)) {
            let patternPass = new RegExp(reg);
            if (fieldValue.length < 8) {
                errors.push({
                    field: "password",
                    message: "Your password is required to be at least 8 characters."
                });
            } else if (fieldValue.length > 50) {
                errors.push({
                    field: "password",
                    message: "Your password cannot be longer than 50 characters."
                });
            } else if (patternPass.test(fieldValue) === false) {
                errors.push({
                    field: "password",
                    message: ValidationUtils.passwordValidationMsg
                });
            }
        }
    };

    static validateTermsAndCondition = (fieldName, fieldValue, errors) => {
        if (fieldValue === false) {
            errors.push({
                field: "iAgree",
                message: "Please accept Smart Crowd's terms of services to signup"
            });
        }
    };

    static validateMatchPassWord = (fieldName, fieldValue, errors) => {
        if (!this.isBlank(fieldValue.password)) {
            let patternPass = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[#@$!%*?&_.^&])[A-Za-z\\d@$!%*?&#_.^&]{8,}$"
            );
            if (fieldValue.password.length < 8) {
                errors.push({
                    field: "password",
                    message: "Your password is required to be at least 8 characters."
                });
            } else if (fieldValue.password.length > 50) {
                errors.push({
                    field: "password",
                    message: "Your password cannot be longer than 50 characters."
                });
            } else if (patternPass.test(fieldValue.password) === false) {
                errors.push({
                    field: "password",
                    message: ValidationUtils.passwordValidationMsg
                });
            }
        }
        if (!this.isBlank(fieldValue.confirmPassword)) {
            let patternPass = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[#@$!%*?&_.^&])[A-Za-z\\d@$!%*?&#_.^&]{8,}$"
            );
            if (fieldValue.confirmPassword.length < 8) {
                errors.push({
                    field: "confirmPassword",
                    message: "Your password is required to be at least 8 characters."
                });
            } else if (fieldValue.confirmPassword.length > 50) {
                errors.push({
                    field: "confirmPassword",
                    message: "Your password cannot be longer than 50 characters."
                });
            } else if (patternPass.test(fieldValue.confirmPassword) === false) {
                errors.push({
                    field: "confirmPassword",
                    message: "Confirm " + ValidationUtils.passwordValidationMsg
                });
            }
        }
    };

    static classFieldError = (errors, fieldName) => {
        let result = _.find(errors, {field: fieldName});
        return result !== undefined ? "field-error" : "";
    };

    static validateMinimumAge = year => {
        return new Date().getFullYear() - year >= 18;
    };
}
