import React from "react";
import CachingImage from "../../Image/cachingImage";
import {useNavigate} from "react-router-dom";
import * as LinkFactory from "../../../util/LinkFactory";

const MoreThanFourAttachmentComponent = props => {
    const attachments = props.imageurl
    const urls = attachments.map(function(attachment) {
        return attachment.imageFileName ? attachment.imageFileName : attachment.videoFileName;
    });
    const postId = props.id;
    let history = useNavigate();

    function navigateTo() {
        history(LinkFactory.postDetails + "/" + postId);
    }

    return (
        <div className="w-100">
            <div className="grid-fixed-over-4">
                {urls.length > 0
                    ? urls.map((attachment, index) => {
                        if (index <= 3)
                            if (index <= 2)
                                return (
                                    <div onClick={() => navigateTo()}>
                                        {(attachment?.includes(".jpeg") || attachment?.includes(".jpg") || attachment?.includes(".png") || attachment?.includes(".webp") || attachment?.includes(".gif")) ?
                                            <CachingImage
                                                src={process.env.REACT_APP_FILES_URL + attachment}
                                                alt="Post Image"
                                            /> :
                                            <a title="Download"
                                               className="btn btn-sm btn-success radius-15 mx-2 my-1"
                                               target="_blank"
                                               href={process.env.REACT_APP_FILES_URL + attachment}>
                                                <i className="fa fa-file-download"/>{" "}
                                            </a>}
                                    </div>
                                )
                        if (index === 3)
                            return (
                                <div onClick={() => navigateTo()} className="attachment-div">
                                    <div className="count-badge">{urls.length > 4 ? "+" + (urls.length - 4) : ""}</div>
                                    {(attachment?.includes(".jpeg") || attachment?.includes(".jpg") || attachment?.includes(".png") || attachment?.includes(".webp") || attachment?.includes(".gif")) ?
                                        <CachingImage
                                            src={process.env.REACT_APP_FILES_URL + attachment}
                                            alt="Post Image"
                                        />
                                        :
                                        <div>
                                            {urls.length > 4 ? "+" + (urls.length - 4) : ""}
                                            <a title="Download"
                                               className="btn btn-sm btn-success radius-15 mx-2 my-1"
                                               target="_blank"
                                               href={process.env.REACT_APP_FILES_URL + attachment}>
                                                <i className="fa fa-file-download"/>{" "}
                                            </a>
                                        </div>
                                    }
                                </div>
                            )
                    })
                    : null}
            </div>
        </div>
    );
};

export default MoreThanFourAttachmentComponent;
