import React, {useEffect, useState} from "react";
import * as CountryApi from "../../api/CountryApi";

import FooterComponent from "../Nav/footer";
import CommonLayout from "../layout/commonLayout";
import {useParams} from "react-router-dom";

function CountryView(props) {
    const params = useParams();
    const [country, setCountry] = useState([]);

    useEffect(() => {
        CountryApi.getCountryById(params.id)
            .then(json => {
                console.log(json);
                setCountry(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Country View</h1>
                        <br/>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table
                                        className="table table-bordered"
                                        id="dataTable"
                                        width="100%"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Country</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{country.id}</td>
                                            <td>{country.country}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
            </CommonLayout>
        </>
    );
}

export default CountryView;
