import React, {useState} from "react";
import {Form, Formik} from "formik";

import "react-datepicker/dist/react-datepicker.css";
import CommonLayout from "../layout/commonLayout";
import * as Utils from "../../util/Utils";

import * as LinkFactory from "../../util/LinkFactory";
import LoaderOverlay from "../common/LoaderOverlay";
import * as StudentApi from "../../api/StudentApi";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function StudentRegistrationUpload(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [files, setFiles] = useState();
    const [studentRegistration, setStudentRegistration] = useState({});
    const [uploadLoader, setUploadLoader] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const changeHandler = (event) => {
        setSelectedFiles(event.target.files);
        setFiles(event.target.files[0]);
    };

    const notifyError = (msg) => toast.error(msg, {
        position: toast.POSITION.TOP_CENTER
    });

    async function handleSubmit(values) {
        if(files!==undefined) {
            setUploadLoader(true);
            StudentApi.studentRegistrationXlsx(files).then(res => {
                console.log(res.data);
                console.log(res);
                if (res.data.anyError) {
                    notifyError(res.data.responseMessage);
                } else if (res.response.status === 500) {
                    notifyError(res.response.data.detail);
                } else {
                    navigateTo(LinkFactory.studentsList);
                    toast.success("Successfully Registered!");
                }
            });
            setUploadLoader(false);
        } else {
            notifyError('File cannot be empty!');
        }
    }

    return (
        <>
            <CommonLayout headerName={Utils.getCurrentChannelName()}>
                <main>
                    <div>
                        <h3 className="mt-4 mb-4"> Student Registration Upload </h3>
                        {uploadLoader ? <LoaderOverlay/> : null}
                        <div>
                            <div>
                                <Formik
                                    initialValues={studentRegistration}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}>
                                    {({props, values}) => (
                                        <Form>
                                            <div className="row ml-0 mt-5">
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <input
                                                            className="custom-file-input"
                                                            id="file"
                                                            name="file"
                                                            type="file"
                                                            onChange={changeHandler}
                                                        />
                                                        <label className="custom-file-label" htmlFor="file">
                                                            {Array.from(selectedFiles).length > 0 ? Array.from(selectedFiles).map((data, index) => {
                                                                return (<p>{(index + 1) + ". " + data.name}</p>);
                                                            }) : "Choose file"}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-dark radius-25">
                                                        <i className="fa fa-upload mr-2"/>
                                                        Upload
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default StudentRegistrationUpload;
