import React, {useEffect, useState} from "react";
import * as EvaluationQuestionSubmissionApi from "../../../api/EvaluationQuestionSubmissionApi";
import * as EvaluationQuestionApi from "../../../api/EvaluationQuestionApi";
import CommonLayout from "../../layout/commonLayout";
import {Form, Formik} from "formik";
import FormTitle from "../../Typhography/formTitle";
import Loader from "../../common/Loader";
import {toast} from "react-toastify";
import * as LinkFactory from "../../../util/LinkFactory";
import {useNavigate} from "react-router-dom";

function EvaluationQuestionTeacher(props) {
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const [evaluationSubmissionList, setEvaluationSubmissionList] = useState([]);
  const [evalQuesList, setEvalQuesList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    findAllQuestionByActiveSet();
  }, []);

  const notifySuccess = () => toast.success("Evaluation Complete!", {
    position: toast.POSITION.TOP_RIGHT
  });

  const findAllQuestionByActiveSet = () => {
    setLoader(true);
    EvaluationQuestionApi.getEvaluationQuestionByActiveSet().then(res => {
      setEvalQuesList(res.data);
      setLoader(false);
    }, err => {
      console.log("Error in submission");
    });
  };

  const handleChange = (evaluationQuestionsOptionId, questionId, index) => {
    var evaluationQuestionsOption = { id: evaluationQuestionsOptionId };
    var evaluationSubmission = { evaluationQuestionsOption: evaluationQuestionsOption, index: index,evaluationQuestionId:questionId, channel: {id: Number(props?.match?.params?.id)} };

    if (evaluationSubmissionList.find(evaluationSubmissionObj => evaluationSubmissionObj.index === index) !== undefined) {
      evaluationSubmissionList.find(evaluationSubmissionObj => evaluationSubmissionObj.index === index).evaluationQuestionsOption = evaluationQuestionsOption;
    } else {
      evaluationSubmissionList.push(evaluationSubmission);
    }
    console.log(evaluationSubmissionList);
  };

  const handleTextField = (value, questionId, index) => {
    var evaluationSubmission = { index: index, channel: {id: Number(props?.match?.params?.id)},evaluationQuestionId: questionId, comment: value };
    if (evaluationSubmissionList.find(evaluationSubmissionObj => evaluationSubmissionObj.index === index) !== undefined) {
      evaluationSubmissionList.find(evaluationSubmissionObj => evaluationSubmissionObj.index === index).comment = value;
      evaluationSubmissionList.find(evaluationSubmissionObj => evaluationSubmissionObj.index === index).evaluationQuestionId = questionId;
    } else {
      evaluationSubmissionList.push(evaluationSubmission);
    }

  }

  const handleSubmit = (evalSubmissionList) => {
    console.log(props?.match?.params?.id);
    EvaluationQuestionSubmissionApi.saveAll(evalSubmissionList).then(res => {
      console.log(res.data);
      notifySuccess();
      navigateTo(LinkFactory.channelBoard + "/" + props?.match?.params?.id);
    });
  };

  return (
    <>
      <CommonLayout headerName="Course Evaluation">
        <main>
          <div>
            <h3 className="mt-4">Course Evaluation</h3>
            <br />
            <div className="card-body">
              <Formik initialValues={evaluationSubmissionList}
                      enableReinitialize={true}
                      onSubmit={handleSubmit}
              >
                <Form>
                  <div className="form-row">
                    {evalQuesList && evalQuesList?.length > 0 ?
                      evalQuesList.map((data, index) => {
                        return (
                          <div className="col-12">
                            <div className="form-group">
                              <FormTitle>{index + 1}. {data.question}</FormTitle>
                              {data.evaluationQuestionsOptionList && data.evaluationQuestionsOptionList.length > 0 ?
                                data.evaluationQuestionsOptionList.map((evaluationQuestionsOption, index2) => {
                                  return (
                                  evaluationQuestionsOption?.questionOptionTypeEnum == "RADIO" ?
                                    <div>
                                      <input type="radio" name={data.id}
                                             onClick={() => handleChange(evaluationQuestionsOption.id, data.id, index)}
                                             value={evaluationQuestionsOption.id} />&nbsp;
                                      <label>{evaluationQuestionsOption.option}</label>
                                    </div>
                                    :
                                    <div>
                                      <input type="text" name={data.id}  onChange={event => handleTextField(event.target.value, data.id ,index)} />&nbsp;
                                      <label>{evaluationQuestionsOption.option}</label>
                                    </div>
                                  )


                                }) : <div className="text-center">
                                  {loader ? <Loader /> : <h5>No data found</h5>}
                                </div>
                              }
                            </div>
                          </div>
                        );
                      })
                      : <div className="text-center">
                        {loader ? <Loader /> : <h5>No data found</h5>}
                      </div>
                    }
                    {evalQuesList && evalQuesList?.length > 0 ?
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-lg btn-success float-right">
                          Save
                        </button>
                      </div> : <div></div>
                    }
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationQuestionTeacher;
