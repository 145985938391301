import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";


export const save = evalQuest => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question", evalQuest);
};

export const update = evalQuest => {
  return AxiosAgent.requests.put(API_MAIN_ROOT + "/evaluation-question", evalQuest);
};

export const search = evalQuest => {
  return AxiosAgent.requests.post(API_MAIN_ROOT + "/evaluation-question/search", evalQuest);
};

export const deleteEvaluationQuestion = id => {
  return AxiosAgent.requests.delete(API_MAIN_ROOT + "/evaluation-question/"+ id);
};

export const getEvaluationQuestionById = id => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/evaluation-question/" + id);
};

export const getEvaluationQuestionByActiveSet = () => {
  return AxiosAgent.requests.get(API_MAIN_ROOT + "/evaluation-question/active-set");
};
