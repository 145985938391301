import React from "react";
import PropTypes from "prop-types";

class SelectDropdown extends React.PureComponent {
  render() {
    const {
      handleChangeSection,
      fieldName,
      required,
      label,
      placeholder,
      form: { dirty, touched, errors, setFieldValue },
      field,
      options,
      fullWidth,
      margin,
      ...other
    } = this.props;
    const errorText = errors[field.name];
    const hasError = dirty && touched[field.name] && errorText !== undefined;
    const renderValue = value => {
      return JSON.stringify(value);
    };
    return (
      <div className="form-group">
        {/*<label htmlFor={field.name}>{label}</label>*/}
        <select
          className="form-control"
          {...field}
          value={renderValue(field.value)}
          onChange={event => {
            setFieldValue(event.target.name, JSON.parse(event.target.value));
            if (
              handleChangeSection !== undefined &&
              handleChangeSection !== null &&
              handleChangeSection !== ""
            ) {
              handleChangeSection(
                event.target.name,
                JSON.parse(event.target.value)
              );
            }
          }}
          {...other}
        >
          <option value={""}>{placeholder}</option>
          {options && options.length
            ? options.map((item, index) => (
                <option value={renderValue(item)} key={index}>
                  {item[fieldName]}
                </option>
              ))
            : null}
        </select>
        {hasError && <small>{errorText}</small>}
      </div>
    );
  }
}

SelectDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.object
  }).isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  fieldName: PropTypes.string
};

SelectDropdown.defaultProps = {
  required: false,
  fullWidth: true,
  margin: "normal",
  fieldName: "name"
};

export default SelectDropdown;
