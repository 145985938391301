import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import "./dropzone.css";

const previewStyle = {
    display: "block",
    width: "300px",
    height: "300px",
    objectFit: "scale-down",
    overflow: "hidden"
};

const iconStyle = {
    display: "block",
    width: "300px",
    height: "300px",
    overflow: "hidden"
};

function ImageUploader({image, cbImage}) {
    const [files, setFiles] = useState([]);
    const [fileErrors, setfileErrors] = useState([]);
    const MAX_SIZE = 2000000;

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: "image/jpg, image/jpeg",
        maxSize: MAX_SIZE,
        onDrop: (acceptedFiles, rejectedFiles) => {
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
            setfileErrors(rejectedFiles.length > 0 ? rejectedFiles[0].errors : []);
            showText = files.length === 0;
        },
        onDragEnter: () => {
            setFiles([]);
            setfileErrors([]);
        }
    });

    let classes = "dropzone";
    let showText = files.length === 0;

    const additionalClass = isDragAccept
        ? `${classes} accept`
        : isDragReject
            ? `${classes} reject`
            : classes;

    const revokeDataUri = files => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    };

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            // files.forEach((file) => URL.revokeObjectURL(file.preview));
            revokeDataUri(files);
        },
        [files]
    );

    const onClickHandler = () => {
        // files.forEach((file) => URL.revokeObjectURL(file.preview));
        revokeDataUri(files);
        setFiles([]);
        setfileErrors([]);
    };

    const errors = {
        FILESIZE: "More than 2MB in size",
        FILETYPE: "Not an image file"
    };

    const getErrorMessage = () => {
        switch (fileErrors[0].code) {
            case "file-invalid-type":
                return <p className={"error"}>{errors.FILETYPE}</p>;
            case "file-too-large":
                return <p className={"error"}>{errors.FILESIZE}</p>;
            default:
                return <p className={"error"}>File error</p>;
        }
    };

    useEffect(() => {
        cbImage(files);
    }, [files]);

    return (
        <section className="container">
            <div {...getRootProps({className: `${additionalClass}`})}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    isDragReject ? (
                        <p>Not an image file</p>
                    ) : (
                        <p>Drop image here ...</p>
                    )
                ) : (
                    showText &&
                    (fileErrors.length > 0 ? (
                        getErrorMessage()
                    ) : (
                        <>
                        <img alt="upload" key={1} src={image} style={iconStyle}/>
                        <>
                           {/* <p>[ .jpg, .jpeg, .png, .gif ]</p>
                          <p>2MB max size</p> */}
                          <p>
                            Drag and drop your image file here, or click to select
                            files
                          </p>
                        </>
                        </>
                    ))
                )}
                {files.map(file => (
                    <img
                        alt="Preview"
                        key={file.preview}
                        src={file.preview}
                        style={previewStyle}
                    />
                ))}
            </div>
            {files.length > 0 ? (
                <button
                    className={"btn btn-danger remove mx-auto"}
                    onClick={onClickHandler}
                >
                    Remove image
                </button>
            ) : (
                ""
            )}
        </section>
    );
}

export default ImageUploader;
