import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import Loader from "../common/Loader";
import {toast} from "react-toastify";
import * as ChannelApi from "../../api/ChannelApi";
import {useParams} from "react-router-dom";


function EvaluationCompleteStudentList(props) {
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [evaluationCompleteStudentList, setEvaluationCompleteStudentList] = useState([]);

  useEffect(()=> {
    getEvaluationCompleteStudentList(params.channelId);
  }, []);

  const notifyDelete = () => toast.success("Successfully Deleted!", {
    position: toast.POSITION.TOP_CENTER
  });


  const getEvaluationCompleteStudentList = (channelId) => {
    setLoader(true);
    ChannelApi.getAllDiscussGroupMembersByChannelIdAndEvaluationComplete(channelId).then(res=>{
      setEvaluationCompleteStudentList(res.data);
      setLoader(false);
    });
  }

  return (
    <>
      <CommonLayout headerName="Evaluation Incomplete Student List">
        <main>
          <div>
            <h3 className="mt-4 mb-4">
              Evaluation Incomplete Student List
            </h3>

            <div className="table-responsive table-light table-shadow radius-15 mt-4">
              <table
                  className="table table-light table-hover"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
              >
                <thead>
                <tr>
                  <th scope="col">Sl</th>
                  <th scope="col">Class</th>
                  <th scope="col">Semester</th>
                  <th scope="col" className="text-right">Student Name</th>
                </tr>
                </thead>
                <tbody>
                {undefined!==evaluationCompleteStudentList && evaluationCompleteStudentList?.length > 0
                  ? evaluationCompleteStudentList.map((data, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{data?.channel?.channelName}</td>
                        <td>{data?.channel?.semester?.semesterName}</td>
                        <td>{data?.user?.firstName}</td>
                      </tr>
                    );
                  })
                  : <td colSpan="12" className="text-center">
                    {loader ? <Loader className="my-5"/> : <h6 className="my-5">No data found</h6>}
                  </td>}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </CommonLayout>
    </>
  );
}

export default EvaluationCompleteStudentList;
