import React from "react";
import {useNavigate} from "react-router-dom";
import * as LinkFactory from "../../util/LinkFactory";


export default function VerificationPage(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const goToLogin = () => {
        navigateTo(LinkFactory.login);
    }
    return (
        <div>
            <div className="bg-default verification-page d-flex">
                <div id="reg_layout">
                    <div id="reg_content">
                        <main>
                            <div id="registrations">
                                <div id="login">
                                    <div className="container">
                                        <div className="brand">
                                            <img src="img/Edooket.png" alt={"Edoocate logo"}/>
                                            {/*<h3>Verification Page</h3>*/}
                                        </div>
                                        <div className="card-default">
                                            <div className="input-reg">
                                                <form>
                                                    <div className="block-group">
                                                        <div className="block ">

                                                            {/*<h5>Please verify your email</h5>*/}
                                                            {/*<p>Tap the button below to confirm your email address.*/}
                                                            {/*    If you didn't request for this process, you can ignore this email.</p>*/}
                                                            <div className="text-center">
                                                                <img src="img/check-your-email-icon.png"/>
                                                                <p>Check you email</p>
                                                            </div>
                                                            <div className="sent-verificaiton-link text-center">
                                                                <p>We have sent a verification link to</p>
                                                                <span>oceanazim@gmail.com</span>
                                                            </div>
                                                            {/*<div>*/}
                                                            {/*    <button*/}
                                                            {/*        type="submit"*/}
                                                            {/*        onClick={goToLogin}*/}
                                                            {/*        className="btn btn-outline-dark w-100"*/}
                                                            {/*    >*/}
                                                            {/*        <p>Go to Sign In</p>*/}
                                                            {/*    </button>*/}
                                                            {/*</div>*/}


                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="footer-instruction">
                                            <p>
                                                {/*Already Registered?*/}
                                                <div>
                                                    <a href=" "
                                                       onClick={goToLogin}
                                                       className="back-button p-0">
                                                        <i
                                                            className="fa fa-arrow-left "> </i>
                                                        Back to Log in
                                                    </a>
                                                </div>
                                                {/*<p className="text-center text-muted">If that doesn't work, than contact with support!</p>*/}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="login-image">
                    <div className="gradient-background">
                        <div className="image-container">
                            <p>The first ever <span>smart school solution </span> to automate your whole institution</p>
                            <img src="img/forgot_pass2.png" alt=" "/>
                        </div>
                    </div>

                </div>
            </div>
            {/*<div id="layoutAuthentication_footer">*/}
            {/*    <footer className="py-4 mt-auto" style={{background: '#dadce3'}}>*/}
            {/*        <div className="container-fluid">*/}
            {/*            <div className="d-flex align-items-center justify-content-center small">*/}
            {/*                <div className="text-muted">*/}
            {/*                    Copyright &copy; Edooket {new Date().getFullYear()}*/}
            {/*                </div>*/}
            {/*                <div></div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </footer>*/}
            {/*</div>*/}
        </div>
    );

}
