import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const registerAttendance = attendance => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/register-attendance/",
        attendance
    );
};
export const attendanceUserListByParams = attendance => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/attendance/get-student-list-for-attendance/",
        attendance
    );
};

export const attendanceTypeWiseDate = attendance => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/attendance/student-attendance-type-wise-date",
        attendance
    );
};
export const attendanceReport = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/attendance-report/" + channelId
    );
};
export const currentRoutinesTotalClassByChannelId = channelId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/attendance-report-class-count/" + channelId
    );
};

export const generateAttendanceReportSheet = (channelId) => {
    return AxiosAgent.requests.getV2(API_MAIN_ROOT + "/attendance-report-sheet/" + channelId);
};

export const generateAttendanceReportSheetWithDates = (channelId) => {
    return AxiosAgent.requests.getV2(API_MAIN_ROOT + "/attendance-report-sheet-with-dates/" + channelId);
};
