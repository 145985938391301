import React, {Fragment, useState} from "react";
import {Field, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import FormTitle from "../Typhography/formTitle";
import * as yup from "yup";
import Error from "../Error";

const options = [
  {value: true, label: "True"},
  {value: false, label: "False"}
];

const initialState = {
  questionNo: 1,
  optionsNo: 1,
  // sameMark: false,
  marksPerQuestion: 1
};
const mySchema = yup.object({
  questionNo: yup
    .number()
    .min(1, "Must be 1 or greater than 1")
    .positive()
    .required("Required"),
  optionsNo: yup
    .number()
    .min(1, "Must be 1 or greater than 1")
    .positive()
    .required("Required"),
  marksPerQuestion: yup
    .number()
    .min(1, "Must be 1 or greater than 1")
    .positive()
    .required("Required")
});

function GenerateQuestions({ goBack, cbSubmit, mcqQuestionDTOList }) {
  const [hide, setHide] = useState(true);

  async function getOptionsList(optionsNumber) {
    let options = [];
    for (let i = 0; i < optionsNumber; i++) {
      options.push({
        answer: "",
        sl_no: i + 1,
        isRight: false
      });
    }
    return options;
  }

  async function getQuestionsList(
    questionNumber,
    marksPerQuestion,
    optionsNumber
  ) {
    let arr = [];
    const options = await getOptionsList(optionsNumber);
    for (let i = 0; i < questionNumber; i++) {
      arr.push({
        question: "",
        sl_no: i + 1,
        marks: marksPerQuestion,
        mcqOptionsDTOList: options
      });
    }
    return await arr;
  }

  // const handleSubmit = values => {
  //   const data = values;
  //   console.log(data, ".......check");
  //   // handleGenerateQuestions(data).then(r => console.log(r));
  // };
  const handleBack = () => {
    setHide(true);
  };

  const onSubmit = async (values, formikProps) => {
    const questions = await getQuestionsList(
      values.questionNo,
      values.marksPerQuestion,
      values.optionsNo
    );
    const preValue = {
      mcqQuestionDTOList: questions
    };
    mcqQuestionDTOList(preValue);
    setHide(true);
    return questions;
  };

  return (
    <Fragment>
      {hide ? (
        <div className="form-group question-generator mt-2 mb-4">
          <button
            onClick={() => setHide(false)}
            className="btn  bg-c-blue-light mx-auto">
            <i>
              <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m14.802 10.034-1.158-1a6.486 6.486 0 0 0 0-2.065l1.158-1a.573.573 0 0 0 .164-.628l-.015-.047a7.943 7.943 0 0 0-1.41-2.463l-.032-.037a.568.568 0 0 0-.62-.17l-1.438.516a6.216 6.216 0 0 0-1.763-1.028L9.41.594a.574.574 0 0 0-.456-.46L8.906.127a7.913 7.913 0 0 0-2.812 0l-.048.009a.565.565 0 0 0-.456.459l-.28 1.525a6.208 6.208 0 0 0-1.75 1.025l-1.448-.52a.56.56 0 0 0-.621.17l-.032.037a7.984 7.984 0 0 0-1.41 2.463l-.015.047a.577.577 0 0 0 .164.628l1.173 1.01a6.344 6.344 0 0 0 0 2.04L.198 10.03a.573.573 0 0 0-.164.629l.016.046c.32.9.794 1.73 1.409 2.463l.032.037a.568.568 0 0 0 .62.17l1.449-.52a6.167 6.167 0 0 0 1.75 1.025l.28 1.525a.574.574 0 0 0 .456.46l.048.008a7.87 7.87 0 0 0 2.812 0l.048-.008a.565.565 0 0 0 .456-.46l.278-1.517a6.174 6.174 0 0 0 1.763-1.03l1.438.517a.56.56 0 0 0 .62-.17l.032-.037a7.985 7.985 0 0 0 1.41-2.463l.015-.046a.573.573 0 0 0-.164-.625ZM12.388 7.18a5.104 5.104 0 0 1 0 1.646l-.117.717 1.321 1.14c-.2.467-.453.908-.753 1.315l-1.641-.587-.556.46a4.93 4.93 0 0 1-1.402.818l-.674.256-.316 1.732c-.5.057-1.004.057-1.503 0L6.43 12.94l-.668-.259a4.936 4.936 0 0 1-1.392-.816l-.555-.463-1.652.593c-.3-.409-.552-.85-.754-1.314L2.745 9.53l-.115-.714a5.192 5.192 0 0 1-.066-.813c0-.273.021-.546.066-.812l.115-.715-1.336-1.152c.2-.466.453-.905.754-1.314l1.652.593.555-.463c.419-.348.888-.623 1.392-.816l.67-.255.316-1.736a6.611 6.611 0 0 1 1.504 0l.316 1.732.674.256c.507.193.98.468 1.402.818l.556.46 1.64-.587c.301.409.553.85.754 1.314l-1.321 1.141.115.713ZM7.502 4.682c-1.72 0-3.113 1.407-3.113 3.143 0 1.735 1.394 3.143 3.113 3.143s3.112-1.408 3.112-3.143c0-1.736-1.393-3.143-3.112-3.143Zm1.4 4.557a1.972 1.972 0 0 1-1.4.586 1.964 1.964 0 0 1-1.4-.586 1.994 1.994 0 0 1-.58-1.414c0-.534.206-1.036.58-1.415a1.957 1.957 0 0 1 1.4-.585c.529 0 1.025.207 1.4.585a1.994 1.994 0 0 1 .58 1.415c0 .534-.206 1.035-.58 1.414Z" fill="#4575EE"/></svg>
              <span>Generate question set</span>
            </i>
            <i className="fa fa-chevron-down" />
          </button>
        </div>
      ) : (
        <div className="question-set">
          <div>
            <div>
              <button
                  onClick={() => setHide(true)}
                  className="btn  bg-c-blue-light mx-auto">
                <i>
                  <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m14.802 10.034-1.158-1a6.486 6.486 0 0 0 0-2.065l1.158-1a.573.573 0 0 0 .164-.628l-.015-.047a7.943 7.943 0 0 0-1.41-2.463l-.032-.037a.568.568 0 0 0-.62-.17l-1.438.516a6.216 6.216 0 0 0-1.763-1.028L9.41.594a.574.574 0 0 0-.456-.46L8.906.127a7.913 7.913 0 0 0-2.812 0l-.048.009a.565.565 0 0 0-.456.459l-.28 1.525a6.208 6.208 0 0 0-1.75 1.025l-1.448-.52a.56.56 0 0 0-.621.17l-.032.037a7.984 7.984 0 0 0-1.41 2.463l-.015.047a.577.577 0 0 0 .164.628l1.173 1.01a6.344 6.344 0 0 0 0 2.04L.198 10.03a.573.573 0 0 0-.164.629l.016.046c.32.9.794 1.73 1.409 2.463l.032.037a.568.568 0 0 0 .62.17l1.449-.52a6.167 6.167 0 0 0 1.75 1.025l.28 1.525a.574.574 0 0 0 .456.46l.048.008a7.87 7.87 0 0 0 2.812 0l.048-.008a.565.565 0 0 0 .456-.46l.278-1.517a6.174 6.174 0 0 0 1.763-1.03l1.438.517a.56.56 0 0 0 .62-.17l.032-.037a7.985 7.985 0 0 0 1.41-2.463l.015-.046a.573.573 0 0 0-.164-.625ZM12.388 7.18a5.104 5.104 0 0 1 0 1.646l-.117.717 1.321 1.14c-.2.467-.453.908-.753 1.315l-1.641-.587-.556.46a4.93 4.93 0 0 1-1.402.818l-.674.256-.316 1.732c-.5.057-1.004.057-1.503 0L6.43 12.94l-.668-.259a4.936 4.936 0 0 1-1.392-.816l-.555-.463-1.652.593c-.3-.409-.552-.85-.754-1.314L2.745 9.53l-.115-.714a5.192 5.192 0 0 1-.066-.813c0-.273.021-.546.066-.812l.115-.715-1.336-1.152c.2-.466.453-.905.754-1.314l1.652.593.555-.463c.419-.348.888-.623 1.392-.816l.67-.255.316-1.736a6.611 6.611 0 0 1 1.504 0l.316 1.732.674.256c.507.193.98.468 1.402.818l.556.46 1.64-.587c.301.409.553.85.754 1.314l-1.321 1.141.115.713ZM7.502 4.682c-1.72 0-3.113 1.407-3.113 3.143 0 1.735 1.394 3.143 3.113 3.143s3.112-1.408 3.112-3.143c0-1.736-1.393-3.143-3.112-3.143Zm1.4 4.557a1.972 1.972 0 0 1-1.4.586 1.964 1.964 0 0 1-1.4-.586 1.994 1.994 0 0 1-.58-1.414c0-.534.206-1.036.58-1.415a1.957 1.957 0 0 1 1.4-.585c.529 0 1.025.207 1.4.585a1.994 1.994 0 0 1 .58 1.415c0 .534-.206 1.035-.58 1.414Z" fill="#4575EE"/></svg>
                  <span>Generate question set</span>
                </i>
                <i className="fa fa-chevron-up"/>
              </button>
              <br />
              <Formik
                initialValues={initialState}
                // enableReinitialize={true}
                onSubmit={onSubmit}
                validationSchema={mySchema}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    setValues,
                    getValues,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting
                  } = props;
                  return (
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div>
                        <div>
                          <FormTitle>
                            <div>Total number of questions</div>
                          </FormTitle>
                          <Field
                            type="number"
                            name="questionNo"
                            placeholder="Question number"
                            component={TextFieldComponent}
                          />
                          {errors.questionNo && touched.questionNo ? (
                            <Error> {errors.questionNo}</Error>
                          ) : null}
                        </div>
                        <div className="mt-3">
                          <FormTitle>
                            <div>
                              Number of options per question
                            </div>
                          </FormTitle>
                          <Field
                            type="number"
                            name="optionsNo"
                            placeholder="Options number"
                            component={TextFieldComponent}
                          />
                          {errors.optionsNo && touched.optionsNo ? (
                            <Error> {errors.optionsNo}</Error>
                          ) : null}
                        </div>

                        {/*<div className="col-6">*/}
                        {/*  <FormTitle>*/}
                        {/*    <div>Does every question have same mark?</div>*/}
                        {/*  </FormTitle>*/}
                        {/*  <Select*/}
                        {/*    value={values.sameMar}*/}
                        {/*    onChange={option => setValues("sameMark", option.value)}*/}
                        {/*    options={options}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        {/*{values.sameMark === true ? (*/}
                        <div className="mt-3">
                          <FormTitle>
                            <div>Per question mark</div>
                          </FormTitle>
                          <Field
                            type="number"
                            name="marksPerQuestion"
                            placeholder="Marks Per Question"
                            component={TextFieldComponent}
                          />
                          {errors.marksPerQuestion &&
                          touched.marksPerQuestion ? (
                            <Error> {errors.marksPerQuestion}</Error>
                          ) : null}
                        </div>

                        {/*) : (*/}
                        {/*  ""*/}
                        {/*)}*/}

                        <div className="form-row">
                          <div className="form-group mt-2">
                            <button
                                type="submit"
                                className="btn btn-generate btn-red-md w-100">
                              Generate
                            </button>
                          </div>
                          <div className="form-group mt-2">
                            <button
                                type="button"
                                onClick={handleBack}
                                className="btn w-100 btn-cancel">
                              {" "}
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default GenerateQuestions;
