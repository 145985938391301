import React from "react";
import Spinner from "react-bootstrap/Spinner";

const SmallSpinner = () => {
    return (
        <div className="spinner-sm">
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        </div>
    );
};

export default SmallSpinner;
