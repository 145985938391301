import React, {useEffect, useState} from "react";
import * as GradingApi from "../../api/GradingApi";

import * as LinkFactory from "../../util/LinkFactory";
import Loader from "../common/Loader";
import CommonLayout from "../layout/commonLayout";
import {toast} from "react-toastify";
import * as Utils from "../../util/Utils";
import {useNavigate, useParams} from "react-router-dom";

function GradingListByChannel(props) {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const params = useParams();
    const [gradings, setGradings] = useState([]);
    const [id, setId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [gradebook, setGradebook] = useState(null);

    const notifyDelete = () => toast.success("deleted!", {
        position: toast.POSITION.TOP_CENTER
    });
    const isUserHasFinalizedAccessPermission = () => {
        if (Utils.checkRolePermission(["ROLE_ADMIN", "ROLE_EXAM_CONTROLLER"])) {
            return true;
        } else if (Utils.getChannelType() === "class" && (null == gradebook?.isFinalized || false === gradebook?.isFinalized) && Utils.checkRolePermission(["ROLE_FACULTY"])) {
            return true;
        }
        return false;
    }

    function getGradingListByChannelId() {
        setLoader(true);
        GradingApi.findGradingListByChannelId(params.id)
            .then(json => {
                setGradings(json.data);
                setLoader(false);
            })
            .catch(errors => {
                console.log(errors);
                setLoader(false);
            });
    }

    useEffect(() => {
        getGradingListByChannelId();
        getGradeBookForThisRoom(params.id)
    }, []);
    const handleDelete = data => {
        setId(data.id);
    };

    const deleteData = () => {
        GradingApi.deleteGradingById(id).then(data => {
            setId(null);
            notifyDelete();
            window.location.reload();
        });
    };

    const getGradeBookForThisRoom = (channelId) => {
        GradingApi.getGradingByChannelId(channelId).then(res => {
            console.log(res.response.status);

            if (res.response.status !== 500) {
                setGradebook(res.data);
            }
        })
    }

    const handleView = data => {
        navigateTo(LinkFactory.gradingBreakdownView + "/" + data.id);
    };
    const handleEdit = data => {
        navigateTo(LinkFactory.gradingEvaluationEdit + "/" + data.id);
    };

    const handleGrading = () => {
        navigateTo(LinkFactory.gradingNew + "/" + params.id);
    };

    function goBack() {
        navigateTo(-1)
    }

    return (
        <>
            <CommonLayout headerName={Utils.getCurrentChannelName()}>
                <main>
                    <div className="card-default gradinglistbychannel container-responsive">
                        <div className="title ">
                            <div className="mb-0 mr-5 d-flex d-flex justify-content-between align-items-center">
                                <div className="card-header d-flex p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 pr-3 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                    <p className="">
                                        Evaluation By Room
                                    </p>
                                </div>

                                <div>
                                    {gradings.length === 0 ?
                                        <button
                                            type="button"
                                            className="btn btn-outline-dark radius-25 float-right"
                                            onClick={() => handleGrading()}>
                                            <i className="fa fa-plus-circle mr-2"/>
                                            Create new Evaluation
                                        </button>
                                        : null}
                                </div>
                            </div>

                        </div>

                        <div>
                            <div className="table-responsive table-light ">
                                <table className="table table-light table-striped table-hover"
                                       id="dataTable"
                                       width="100%"
                                       cellSpacing="0">
                                    <thead>
                                    <tr>
                                        {/*<th scope="col">id</th>*/}
                                        <th scope="col">Semester</th>
                                        <th scope="col" width="15%">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {gradings.length > 0 ? (
                                        gradings.map((data, index) => {
                                            return (
                                                <tr>
                                                    {/*<th scope="row">{data.id}</th>*/}
                                                    <td>{data.name}</td>
                                                    <td>
                                                        {Utils.isUserHasGradingFinalizedAccessPermissionCheckByGradeBook(gradebook) ?
                                                            <button
                                                                title="Edit"
                                                                type="button"
                                                                className="btn btn-sm bg-c-light-orange mr-1"
                                                                onClick={() => handleEdit(data)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     width="18" height="15" fill="none">
                                                                    <path fill="#000"
                                                                          d="m16.202 8.72-2.01-2.011L15.54 5.36l2.01 2.01-1.348 1.348Zm-7.687 5.676v-2.01l5.014-5.015 2.01 2.01-5.014 5.015h-2.01ZM0 9.666V7.772h6.623v1.892H0ZM0 5.88V3.99h10.407V5.88H0Zm0-3.784V.205h10.407v1.892H0Z"/>
                                                                </svg>
                                                            </button> : <div/>}
                                                        &nbsp;
                                                        <button
                                                            title="View Breakdowns"
                                                            type="button"
                                                            className="btn btn-sm bg-c-blue-light mr-1"
                                                            onClick={() => handleView(data)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 width="17" height="12" fill="none">
                                                                <path fill="#4575EE"
                                                                      d="M8.5 1.75A7.55 7.55 0 0 1 15.316 6 7.541 7.541 0 0 1 8.5 10.25 7.541 7.541 0 0 1 1.685 6 7.55 7.55 0 0 1 8.5 1.75Zm0-1.545C4.636.205 1.337 2.608 0 6c1.337 3.392 4.636 5.795 8.5 5.795S15.663 9.392 17 6C15.663 2.608 12.364.205 8.5.205Zm0 3.863a1.932 1.932 0 1 1 0 3.864 1.932 1.932 0 0 1 0-3.864Zm0-1.545A3.483 3.483 0 0 0 5.023 6 3.483 3.483 0 0 0 8.5 9.477 3.483 3.483 0 0 0 11.977 6 3.483 3.483 0 0 0 8.5 2.523Z"/>
                                                            </svg>
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            title="Delete"
                                                            type="button"
                                                            className="btn btn-sm bg-c-red-light"
                                                            data-toggle="modal"
                                                            data-target="#deleteModal"
                                                            onClick={() => handleDelete(data)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 width="13" height="14" fill="none">
                                                                <path fill="#D81414"
                                                                      d="M2.8 13.704c-.412 0-.766-.147-1.06-.44a1.442 1.442 0 0 1-.44-1.06v-9.75H.55v-1.5H4.3v-.75h4.5v.75h3.75v1.5h-.75v9.75c0 .413-.147.766-.441 1.06-.294.294-.647.441-1.059.44H2.8Zm7.5-11.25H2.8v9.75h7.5v-9.75Zm-6 8.25h1.5v-6.75H4.3v6.75Zm3 0h1.5v-6.75H7.3v6.75Z"/>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <td colSpan="12" className="text-center">
                                            {loader ? <Loader/> : <h5>No data found</h5>}
                                        </td>

                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayout>

            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="actionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="circle-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" fill="none">
                                    <path fill="#D81414"
                                          d="M6.606 37.609c-1.144 0-2.123-.403-2.938-1.208-.815-.804-1.221-1.77-1.22-2.899V6.812H.369V2.705h10.394V.652h12.473v2.053H33.63v4.107h-2.079v26.69c0 1.13-.407 2.097-1.222 2.901-.815.805-1.793 1.207-2.935 1.206H6.606ZM27.394 6.812H6.606v26.69h20.788V6.812Zm-16.63 22.584h4.157V10.918h-4.158v18.478Zm8.314 0h4.158V10.918h-4.158v18.478Z"/>
                                </svg>

                            </div>
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="close"*/}
                            {/*    data-dismiss="modal"*/}
                            {/*    aria-label="Close"*/}
                            {/*>*/}
                            {/*    <span aria-hidden="true">&times;</span>*/}
                            {/*</button>*/}
                            <div className="modal-body pt-4 pb-0">Are you sure you want to delete ?</div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className=" "
                                data-dismiss="modal"
                            >
                                {/*<b className="text-light">&#10094;</b> */}
                                Delete
                            </button>
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="btn btn-danger"*/}
                            {/*    data-dismiss="modal"*/}
                            {/*    onClick={deleteData}*/}
                            {/*>*/}
                            {/*    <b className="text-light">&#128465;</b> Confirm Delete*/}
                            {/*</button>*/}
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                                // onClick={deleteData}
                            >
                                {/*<b className="text-light">&#10094;</b> */}
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GradingListByChannel;
