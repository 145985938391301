import React, {useEffect, useState} from "react";

import * as LinkFactory from "../../util/LinkFactory";

import * as AssignmentApi from "../../api/AssignmentApi";
import CommonLayout from "../layout/commonLayout";
import {useNavigate, useParams} from "react-router-dom";

function AssignmentListOfUser(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const params = useParams();
    const [assignment, setAssignment] = useState({});

    useEffect(() => {
        AssignmentApi.findAssignmentListByChannelId(params.id).then(
            taskListResponse => {
                console.log(taskListResponse.data);
                setAssignment(taskListResponse.data);
            }
        );
    }, []);

    const handleSubmit = data => {
        navigateTo(LinkFactory.assignmentSubmit + "/" + data.id);
    };
    const handleView = data => {
        navigateTo(LinkFactory.assignmentSubmittedView + "/" + data.id);
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h2 className="mt-4">Your Assignment List</h2>

                        <div className="table-responsive ">
                            <table
                                className="table table-bordered mt-4"
                                id="dataTable"
                                width="100%"
                                cellSpacing="0"
                            >
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col">id</th>
                                    <th scope="col">Assignment Name</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {assignment.length > 0
                                    ? assignment.map((data, index) => {
                                        return (
                                            <tr>
                                                <th scope="row">{data.id}</th>
                                                <td>{data.name}</td>
                                                <td className="text-right">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-success"
                                                        onClick={() => handleSubmit(data)}
                                                    >
                                                        <i className="fa fa-eye"></i> Submit
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => handleView(data)}
                                                    >
                                                        <i className="fa fa-eye"></i> View
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default AssignmentListOfUser;
