import {ERROR_SHOW, ERROR_UNLOADED} from "../../constants/ActionTypes";

export default function SystemErrorReducer(state = [], action) {
    state.files = state.files || [];
    switch (action.type) {
        case ERROR_SHOW:
            return {...state, message: action.error, category: action.category};
        case ERROR_UNLOADED:
            return {...state, message: ""};
        default:
            return state;
    }
}
