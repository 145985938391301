import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";

export const saveGradingEvaluation = gradingEvaluation => {
    return AxiosAgent.requests.post(
        API_MAIN_ROOT + "/grading-evaluation/",
        gradingEvaluation
    );
};

export const updateGradingEvaluation = gradingEvaluation => {
    return AxiosAgent.requests.put(
        API_MAIN_ROOT + "/grading-evaluation/",
        gradingEvaluation
    );
};
export const getGradingEvaluationById = gradingEvaluationId => {
    return AxiosAgent.requests.get(
        API_MAIN_ROOT + "/grading-evaluation/" + gradingEvaluationId
    );
};
export const deleteGradingEvaluationById = gradingEvaluationId => {
    return AxiosAgent.requests.delete(
        API_MAIN_ROOT + "/grading-evaluation/" + gradingEvaluationId
    );
};
export const getAll = () => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/grading-evaluation");
};
