import React, {useState} from "react";
import {Field, Form, Formik} from "formik";

import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";
import DatePicker from "react-datepicker";
import * as SemesterApi from "../../api/SemesterApi";

import * as LinkFactory from "../../util/LinkFactory";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function SemesterNew(props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [semester, setSemester] = useState({
        markingPeriodStart: "",
        markingPeriodEnd: "",
        semesterName: ""
    });
    const notifySave = () => toast.success("Semester Created!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    const handleSubmit = data => {
        if(
            (null == data.markingPeriodStart || '' === data.markingPeriodStart) ||
            (null == data.markingPeriodEnd || '' === data.markingPeriodEnd) ||
            (null == data.semesterName || '' === data.semesterName)
        ) {
            notifyValidation();
        } else {
            SemesterApi.saveSemester(data).then(data => {
                notifySave();
                navigateTo(LinkFactory.semesterList);

            });
        }

    };
    const DatePickerComponent = ({field, form: {touched, errors, setFieldValue}}) => {
        return (
            <div className="mr-2">
                <DatePicker
                    showTimeInput2
                    selected={Date.parse(field.value)}
                    placeholderText="Pick date and time"
                    onChange={value => setFieldValue(field.name, value)}
                    className="form-control btn-block w-100 overflow-auto "
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                />
            </div>
        );
    };

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout>
                <main>
                    <div className="card-default semester-new container-responsive">
                        <div className="title-header mt-3">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Semester New</p>
                            </div>
                        </div>
                        <div>
                            <Formik
                                initialValues={semester}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {props => (
                                    <Form>
                                        <div className="table-responsive table-light  ">
                                            <table
                                                className="table"
                                                id="dataTable"
                                                width="100%"
                                                cellSpacing="0"
                                            >
                                                <thead>
                                                <tr>
                                                    <th scope="col">Semester Name</th>
                                                    <th scope="col">Marking Period Start</th>
                                                    <th scope="col">Marking Period End</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className="input-default-white">
                                                    <td>
                                                        <Field
                                                            name="semesterName"
                                                            placeholder="Semester Name"
                                                            component={TextFieldComponent}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Field
                                                            name="markingPeriodStart"
                                                            placeholder="Marking Period Start"
                                                            component={DatePickerComponent}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Field
                                                            name="markingPeriodEnd"
                                                            placeholder="Marking Period End"
                                                            component={DatePickerComponent}
                                                        />
                                                    </td>
                                                </tr>
                                                <div className="text-left">
                                                    <button type="submit" className="btn btn-red-md my-4">
                                                        Submit
                                                    </button>
                                                </div>
                                                </tbody>
                                            </table>

                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default SemesterNew;
