import {AUTH_LOGIN, AUTH_LOGOUT} from "../../constants/ActionTypes";
import * as Utils from "../../util/Utils";

let initialState = {};

function isAuthenticatedD() {
    return Utils.isAuthenticated();
}

function setAuthenticatedUser() {
    initialState = isAuthenticatedD() ? {loggedIn: true} : {};
}

setAuthenticatedUser();

export default function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_LOGIN:
            return {...state, user: action.payload, loggedIn: isAuthenticatedD()};
        case AUTH_LOGOUT:
            return {...state, user: {}, loggedIn: isAuthenticatedD()};
        default:
            return state;
    }
}
