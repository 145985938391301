import React, {useState} from "react";

const LikedUserModal = props => {
    console.log(props.likeLogs);
    const likeLogs = props.likeLogs || [];
    const [user, setUser] = useState([]);

    return (
        <div id="application">
            <h3>People who liked</h3>
            <div className="main-content d-flex justify-content-between align-items-center">
                <div>
                    {/*{likeLogs.length > 0*/}
                    {/*    ? likeLogs.map((likeLog, index) => {*/}
                    {/*        return <p> {likeLog.createUser.firstName}</p>;*/}
                    {/*    })*/}
                    {/*    : null}*/}

                    {likeLogs.length > 0 ? (
                        <div>
                            {likeLogs.map((likeLog, index) => (
                                <div className="like-log d-flex justify-content-between align-items-center " key={index}>

                                    <p>{likeLog.createUser.firstName}</p>
                                    <i className="fa fa-thumbs-up c-blue"> </i>
                                </div>
                            ))}
                        </div>
                    ) : null}


                </div>
            </div>
        </div>
    );
};

export default LikedUserModal;
