export const FETCH_ROOM_BOARD_SEARCH_BEGIN = "FETCH_ROOM_BOARD_SEARCH_BEGIN";
export const FETCH_ROOM_BOARD_SEARCH_SUCCESS = "FETCH_ROOM_BOARD_SEARCH_SUCCESS";
export const FETCH_ROOM_BOARD_SEARCH_FAILURE = "FETCH_ROOM_BOARD_SEARCH_FAILURE";

export const fetchRoomBoardSearchBegin = () => ({
    type: FETCH_ROOM_BOARD_SEARCH_BEGIN
});

export const fetchRoomBoardSearchSuccess = (data) => ({
    type: FETCH_ROOM_BOARD_SEARCH_SUCCESS,
    payload: {
        searchQuery: data
    }
});

export const fetchRoomBoardSearchFailure = (error) => ({
    type: FETCH_ROOM_BOARD_SEARCH_FAILURE,
    payload: {error}
});
