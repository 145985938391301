import React, {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import CommonLayout from "../layout/commonLayout";
import * as GradingCalculatedResultApi from "../../api/GradingCalculatedResultApi";
import {Form, Formik} from "formik";
import {FormikTextField} from "../FormikTextField";
import * as Utils from "../../util/Utils"
import {FILES_URL} from "../../util/LinkFactory";
import {useNavigate, useParams} from "react-router-dom";

function StudentCourseHistory(props) {
  const params = useParams();
  const [studentCourseHistory, setStudentCourseHistory] = useState(null);
  const [searchField, setSearchField] = useState({searchField: props?.match?.params?.rollNo ? props?.match?.params?.rollNo : ""});
  let history = useNavigate();

  function navigateTo(pathname) {
    history(pathname);
  }

  const studentCourseHistoryByStudentRollOrReg = searchField => {
    console.log(searchField);
    GradingCalculatedResultApi.studentCourseHistory(searchField.searchField).then(res => {
      console.log(res.data);
      setStudentCourseHistory(res.data);
    });
  };

  useEffect(() => {
    if (props?.match?.params?.rollNo) {
      studentCourseHistoryByStudentRollOrReg({'searchField': params.rollNo});
    }
  }, []);

  function goBack() {
    navigateTo(-1)
  }
  return (
    <CommonLayout>
      <main className="mb-5">
        <div className="card-default student-course-history container-responsive">
          <div className="title-header ">
            <div className="mb-0 d-flex align-items-center">
              <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                <a onClick={goBack} className="pl-0 back-button">
                  <i className="fa fa-chevron-left"> </i> </a>
              </div>
              <p>Student Course History</p>
            </div>
          </div>
          <div className="studentcoursehistory-body">
            <div className="mx-4 mt-3">
              <div>
                <Formik initialValues={searchField}
                        onSubmit={studentCourseHistoryByStudentRollOrReg}>
                  <Form>
                    <div className="form-row">
                      <div className="col-4">
                        <div className="form-group input-default-white">
                          <div className="mb-0">
                            <FormikTextField label="Student Id"
                                             name="searchField"
                                             type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group input-default-white">
                          <label> &nbsp;</label>
                          <button type="submit" className="btn btn-dark radius-25 d-block px-4">
                            <i className="fa fa-id-card mr-2"/>
                            Load
                          </button>
                        </div>
                      </div>

                    </div>
                  </Form>
                </Formik>
              </div>

              <div className="row mt-4">
                <div className="col-md-4 input-secondary">
                  <div className="bg-grey py-2 px-3 radius-10">
                    <p className="mb-0 py-2 fw-600">
                      Student Name:
                      <span className="ml-4">
                    <b>{studentCourseHistory?.studentName}</b>
                  </span>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 input-secondary">
                  <div className="bg-grey py-2 px-3 radius-10">
                    <p className="mb-0 py-2 fw-600">
                      Batch: <b> {studentCourseHistory?.batchNo}</b>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 input-secondary">
                  <div className="bg-grey py-2 px-3 radius-10">
                    <p className="mb-0 py-2 fw-600">
                      Program: <b> {studentCourseHistory?.program}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 mt-3">
              <div>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                  <p className="mr-4 bg-light py-2 px-3 radius-10">CGPA: <b>{Utils.twoDecimalPoint(studentCourseHistory?.cgpa)}</b></p>
                  <p className="mr-4 bg-light py-2 px-3 radius-10">Major: <b>Dummy</b></p>
                  <p className="mr-4 bg-light py-2 px-3 radius-10">Major 2: <b>Dummy</b></p>
                  <p className="mr-4 bg-light py-2 px-3 radius-10">Degree Req: <b>Dummy</b></p>
                  <p className="bg-light py-2 px-3 radius-10">Com. Credits: <b>Dummy</b></p>
                </div>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                  <p className="mr-4 bg-light py-2 px-3 radius-10">Probation: <b>Dummy</b></p>
                  <p className="mr-4 bg-light py-2 px-3 radius-10">Active Status: <b>Dummy</b></p>
                  <p className="mr-4 bg-light py-2 px-3 radius-10">Att. Credits: <b>Dummy</b></p>
                  <p className="bg-light py-2 px-3 radius-10">Transferred Credits: <b>Dummy</b></p>
                </div>
              </div>
            </div>

            <div className="mt-3 ml-4">
              <h6 className="fw-600">Massage: </h6>
            </div>

            <div className="mt-3">
              <div className="">
                <div className="ml-3">
                  <h5><b>Semester wise GPA & CGPA</b></h5>
                  <div className="avatar my-4">
                    {studentCourseHistory?.photoName ? (
                        <img
                            style={{width: "200px", height: "auto"}}
                            src={FILES_URL + studentCourseHistory?.photoName}
                            alt={studentCourseHistory?.firstName}
                        />
                    ) : (
                        "No photo available"
                    )}
                  </div>
                </div>

                <div className="result-area">
                  <div className="table-responsive table-light  table-striped border-bottom">
                    <table
                        className="table  table-light"
                        id="dataTable"
                        width="90%"
                        cellSpacing="0"
                    >
                      <thead>
                      <tr>
                        <th>Semester Name</th>
                        <th>Semester Year</th>
                        <th>SGPA</th>
                        <th width="20%">Semester CGPA</th>
                      </tr>
                      </thead>
                      <tbody>
                      {studentCourseHistory?.resultSemesterDtoList?.length> 0 ?
                          (studentCourseHistory?.resultSemesterDtoList.map((resultSemesterDto, index) => {
                            return <tr>
                              <td>{resultSemesterDto?.semesterName}</td>
                              <td>{resultSemesterDto?.semesterYear}</td>
                              <td>{Utils.twoDecimalPoint(resultSemesterDto?.sgpa)}</td>
                              <td>{Utils.twoDecimalPoint(resultSemesterDto?.semesterCgpa)}</td>
                            </tr>
                          }))
                          : <td colSpan="12" className="text-center w-100 my-4">No Result Found</td>}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>

            <div className="mt-3">
              <div>
                <div className="ml-3">
                  <h5 className="mb-4"><b>Result of completed Courses</b></h5>
                </div>
                <div className="result-area">
                  <div className="table-responsive table-light table-striped border-bottom">
                    <table
                        className="table table-light"
                        id="dataTable"
                        width="90%"
                        cellSpacing="0"
                    >
                      <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Semester</th>
                        <th>Course ID</th>
                        <th>Course Name</th>
                        <th>Credit</th>
                        <th>Full Score</th>
                        <th>Score</th>
                        <th>Grade</th>
                        <th>Point</th>
                      </tr>
                      </thead>
                      <tbody>
                      {studentCourseHistory?.resultSubjectWiseDtoList?.length > 0 ?
                          (studentCourseHistory?.resultSubjectWiseDtoList.map((resultSubjectWiseDto, index) => {
                            return <tr>
                              <td>{index+1}</td>
                              <td>{resultSubjectWiseDto?.semesterName} {resultSubjectWiseDto?.semesterYear}</td>
                              <td>{resultSubjectWiseDto?.courseId}</td>
                              <td>{resultSubjectWiseDto?.subjectName}</td>
                              <td>{Utils.twoDecimalPoint(resultSubjectWiseDto?.creditHour)}</td>
                              <td>{Utils.twoDecimalPoint(resultSubjectWiseDto?.fullScore)}</td>
                              <td>{Utils.twoDecimalPoint(resultSubjectWiseDto?.obtainedScoreMark)}</td>
                              <td>{resultSubjectWiseDto?.obtainedLetterGrade}</td>
                              <td>{Utils.twoDecimalPoint(resultSubjectWiseDto?.gradePoint)}</td>
                            </tr>
                          }))
                          : <div className="text-center w-100 my-4">No Result Found</div>}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </CommonLayout>
  );

}

export default StudentCourseHistory;
