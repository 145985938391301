import moment from "moment";
import React from "react";
import * as Utils from "./Utils";

export default class DateUtils {
    static dateDiffInDays(date1, date2) {
        if (
            typeof date1.getTime === "function" &&
            typeof date2.getTime === "function"
        ) {
            let t2 = date2.getTime();
            let t1 = date1.getTime();

            return parseInt((t2 - t1) / (24 * 3600 * 1000));
        }
        return 0;
    }
}

export function getRelativeTime(time) {
    moment.locale();
    let now = new Date();
    let todayDate = moment(now);
    let pastDate = moment(time);

    var dDiff2 = pastDate.diff(todayDate);
    if (dDiff2 > 0) {
        return (
            <span className="">
        {" "}
                {moment(time).format("LL")} (
                {moment(time)
                    .startOf("day")
                    .fromNow()}
                ){" "}
      </span>
        );
    } else {
        return (
            <span className="text-red-400">
        {" "}
                {moment(time).format("LL")} (
                {moment("01-02-2021")
                    .startOf("day")
                    .fromNow()}
                ){" "}
      </span>
        );
    }
}

export const getReadableTime = function getReadableTime(time) {
    moment.locale("en");
    return moment(time).format("LL");
};

export function getDateAndTime(date, format) {
    return moment(date).format(format);
}

export function getToday() {
    moment.locale("es");
    let start = moment(new Date()).format("YYYY-MM-DD");
    return start;
}

export function getIsToday(date) {
    let day = moment(new Date(date)).format("YYYY-MM-DD");
    let getTodayDay = moment().format("YYYY-MM-DD");

    if (
        moment(day).isSame(getTodayDay, "month") &&
        moment(day).isSame(getTodayDay, "day") &&
        moment(day).isSame(getTodayDay, "year")
    ) {
        return true;
    }
    return false;
}

export function getMonthLastDate() {
    let end = new moment().endOf("month").format("YYYY-MM-DD");
    return end;
}

export function getMonthFirstDate() {
    let start = new moment().startOf("month").format("YYYY-MM-DD");
    return start;
}

export function getDateProperFormat(a) {
    let start = moment(a).format("DD-MM-YY");
    return start;
}

export function getTimeWithType(a) {
    let start = moment(a, ["HH.mm:ss"]).format("hh:mm a");
    return start;
}

export function getTimeObject(a, b) {
    let startEndTime = {
        start: moment(a, ["HH.mm:ss"]).format("hh:mm a"),
        end: moment(b, ["HH.mm:ss"]).format("hh:mm a")
    };
    return startEndTime;
}

export function createDateObject(date, time) {
    let dateObj = moment(date + "T" + time);
    return new Date(dateObj);
}

export function getTimeBetweenResult(date, start, end) {
    const startDateTime = `${date} ${start}`;
    const endDateTime = `${date} ${end}`;
    const dateFormat = "YYYY-MM-DD hh:mm:ss";

    let time = moment(new Date(), dateFormat),
        beforeTime = moment(startDateTime, dateFormat),
        afterTime = moment(endDateTime, dateFormat);

    if (time.isBetween(beforeTime, afterTime)) {
        return true;
    } else {
        return false;
    }
}

export function getDateInputType(a) {
    let start = moment(a, "DD-MM-YYYY").format("YYYY-MM-DD");
    return start;
}

export function getFirstDayOfMonth(month) {
    let year = new Date().getFullYear();
    let getFirstDay = year + "-" + month + "-" + 1;
    return getFirstDay;
}

export function getLastDayOfMonth(month, cbYear) {
    let year = cbYear ? cbYear : new Date().getFullYear();
    moment.locale();
    let lastDay = new moment(year + "-" + month + "-" + 1, "YYYY-MM-DD")
        .endOf("month")
        .format("YYYY-MM-DD");
    return lastDay;
}

export function getFirstMonthDateObject(month) {
    let year = new Date().getFullYear();
    let getFirstDay = year + "-" + month + "-" + 1;
    return moment(getFirstDay, "YYYY-MM-DD").toDate();
}

export function getLastDayOfMonthNumber() {
    moment.locale();
    let lastDay = new moment().endOf("month").format("DD");
    return parseInt(lastDay);
}

export function getHourMin(a) {
    let startEndTime = moment(a, ["HH.mm:ss"]).format("hh:mm a");
    return startEndTime;
}

export function getYear() {
    let start = moment().format("YYYY");
    return start;
}

export function getTodayObject() {
    return moment(new Date()).toDate();
}

export function getDateProperObject(date, format) {
    return moment(date, format).toDate();
}

export function getThisMonthNo() {
    return moment().format("M");
}

export function getDurationBetweenTwoTimes(startTime, endTime) {
    const today = getToday();
    const startDateTime = `${today} ${startTime}`;
    const endDateTime = `${today} ${endTime}`;
    const dateFormat = "YYYY-MM-DD hh:mm:ss";
    const hours = moment
        .utc(
            moment(endDateTime, dateFormat).diff(moment(startDateTime, dateFormat))
        )
        .format("HH");
    const min = moment
        .utc(
            moment(endDateTime, dateFormat).diff(moment(startDateTime, dateFormat))
        )
        .format("mm");

    return `${parseInt(hours) ? `${hours}h : ` : ""}${min}m`;
}

export function convertUTCDateToLocalDate(date) {
    if (Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(date))
        return moment(new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)).format("MMM Do YY h:mm a");
}

export function convertUTCDateToLocalDatePostFormat(date) {
    if (Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(date)){
        return moment(new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)).format("DD/MM/YY @ hh:mm a");
    }else {
        return null
    }

}

export function dateTimeForNotification(notificationTimestamp) {
    var notificationDate = moment(notificationTimestamp);

    var now = moment();
    var diffDays = now.diff(notificationDate, 'days');
    var timeAgo;

    if (notificationDate == 0) {
        timeAgo = "a moment ago";
    } else if (diffDays < 1 && notificationDate != 0) {
        timeAgo = notificationDate.fromNow();
    } else if (diffDays < 7) {
        timeAgo = notificationDate.format('dddd') + ", " + notificationDate.format("YYYY-MM-DD") + " " + notificationDate.fromNow();
    } else if (diffDays < 365 && diffDays > 7) {
        timeAgo = notificationDate.format("YYYY-MM-DD") + " " + notificationDate.fromNow();
    } else if (diffDays > 365) {
        timeAgo = notificationDate.fromNow();
    }

    return timeAgo;

}

export function dateTimeForChat(chatTime) {
    var notificationDate = moment(chatTime);
    return notificationDate.fromNow();

}
