import React, {useEffect} from "react";
import {toast} from "react-toastify";
import CommonLayout from "../layout/commonLayout";


toast.configure();

export default function OpportunitiyDetails() {


    useEffect(() => {
    }, []);


    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>
                        <div className="card-default opportunities ">
                            <div className="title ">
                                <div className="mb-0 d-flex justify-content-between">
                                    <div className="card-header p-0 m-0 mr-1 ">
                                        <a className="pl-0 back-button">
                                            <i className="fa fa-chevron-left"> </i> </a>
                                    </div>

                                    <p className="mt-1"> Opportunity Details</p>
                                </div>
                            </div>
                            <div className="opportunity-box">
                                <div className="rightbar-card opportunities-card">
                                    <div className="details">
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/company.png" alt="Company Icon" className="icon"/>
                                                <strong>Company:</strong>
                                            </div>
                                            <p>Pro holmes sports...</p>
                                        </div>

                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/opportunity.png" alt="Opportunity Icon" className="icon"/>
                                                <strong>Opportunity:</strong>
                                            </div>
                                            <p>Graduate Assistant...</p>
                                        </div>

                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/industry.png" alt="Industry Icon" className="icon"/>
                                                <strong>Industry:</strong>
                                            </div>
                                            <p>General Labor</p>
                                        </div>

                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/expiration-date.png" alt="Date Icon" className="icon"/>
                                                <strong>Expiration Date:</strong>
                                            </div>
                                            <p>2024-1-20</p>
                                        </div>
                                    </div>

                                    <div className="description">
                                        <h4>Description</h4>
                                        <p>
                                            The central focus for the position is to coordinate and assist with all
                                            aspects of the
                                            team by performing the assist with all aspects of the
                                            team by performing.
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}