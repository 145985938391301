import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import * as DepartmentApi from "../../api/DepartmentApi";
import CommonLayout from "../layout/commonLayout";
import TextFieldComponent from "../common/TextFieldComponent";

import * as LinkFactory from "../../util/LinkFactory";
import {toast} from "react-toastify";
import SelectDropdown from "../common/SelectDropwodn";
import * as ProgramApi from "../../api/ProgramApi";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../common/Loader";

function ProgramNew(props) {

    let history = useNavigate();

    function navigateTo(pathname) {

        history(pathname);
    }

    const params = useParams();
    const [program, setProgram] = useState({
        name: "",
        department: {},
    });
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [saving, setSaving] = useState(false);
    const notifySuccess = () => toast.success("Program Created!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyUpdate = () => toast.success("Updated!", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    const handleSubmit = values => {
        setSaving(true);
        if (
            (null == values.name || '' === values.name) ||
            (Object.keys(values.department).length === 0)
        ) {
            notifyValidation();
        } else {
            let data = {};
            let id = params.id;
            if (params.id) {
                data = {
                    department: null != values.department ? values.department : null,
                    name: values.name,
                    id: parseInt(params.id)
                }
                ProgramApi.updateProgram(data)
                    .then(data => {
                        notifyUpdate();
                        setSaving(false);
                        navigateTo(LinkFactory.programList);
                    });
            } else {
                data = {
                    department: null != values.department ? values.department : null,
                    name: values.name
                }
                ProgramApi.saveProgram(data)
                    .then(data => {
                        notifySuccess();
                        setSaving(false);
                        navigateTo(LinkFactory.programList);
                    });
            }
        }

    };

    const setDepartmentObj = value => {
        getProgramsByDepartmentId(value.id);
    }

    const getProgramsByDepartmentId = (departmentId) => {
        ProgramApi.getAllByDepartmentId(departmentId).then(json => {
            setPrograms(json.data);
        });
    }

    function getDepartments() {
        DepartmentApi.getAllDepartments().then(json => {
            setDepartments(json.data);
            if (params.id) {
                ProgramApi.getProgram(params.id).then(res => {
                    setProgram({
                        name: res.data.name,
                        department: res.data.department
                    })
                })
            }


        });
    }

    useEffect(() => {
        getDepartments();
    }, []);

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayout headerName="Program">
                <main>
                    <div className="card-default program-new container-responsive">
                        <div className="title-header mt-3">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                                    <a onClick={goBack} className="pl-0 back-button">
                                        <i className="fa fa-chevron-left"> </i> </a>
                                </div>
                                <p>Program New</p>
                            </div>
                        </div>
                        <div>
                            <Formik
                                initialValues={program}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({props, values, setFieldValue}) => (
                                    <Form>

                                        <div className="form-row">
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <label>Department</label>
                                                    <Field
                                                        type="text"
                                                        name="department"
                                                        placeholder={values?.name || "Select Department"}
                                                        fieldName={"name"}
                                                        handleChangeSection={(fieldName, value) => {
                                                            setFieldValue("department", value);
                                                            setDepartmentObj(value)
                                                        }}
                                                        options={departments || []}
                                                        component={SelectDropdown}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4 px-3">
                                                <div className="form-group input-default-white">
                                                    <label>Program Name</label>
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        placeholder="Program Name"
                                                        component={TextFieldComponent}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-btn">
                                            <div className="form-group input-default-white">

                                                <button
                                                    type="submit"
                                                    disabled={saving}
                                                    className="btn btn-dark radius-25 px-4 d-block">
                                                    <i className="fa fa-bookmark mr-2"/>
                                                    Save
                                                </button>
                                                <div className="loader">
                                                    {saving ? <Loader className="my-5"/> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default ProgramNew;
