import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";

import SelectDropdown from "../common/SelectDropwodn";
import * as StudentApi from "../../api/StudentApi";
import * as MessageApi from "../../api/MessageApi";
import * as Utils from "../../util/Utils";
import CommonLayout from "../layout/commonLayout";

function DirectMessage(props) {
    const [sportsType, setSportsType] = useState({states: "", id: ""});
    const [memberList, setMemberList] = useState([]);
    const [receiver, setReceiver] = useState([]);
    useEffect(() => {
        StudentApi.getAll()
            .then(json => {
                console.log(json);
                setMemberList(json.data);
            })
            .catch(errors => {
                console.log(errors);
            });
    }, []);

    const handleSubmit = data => {
        console.log(data);
        let directMessageThreadDTO = {
            sender: Utils.getUserFromLocalStorage(),
            receiver: receiver,
            message: data.message
        };
        console.log(directMessageThreadDTO);
        MessageApi.sendMessage(directMessageThreadDTO).then(data => {
            console.log(data.json);
        });
    };
    const handleChangeSection = (fieldName, value) => {
        setReceiver(value);
    };
    const TextField = ({field, form: {touched, errors}}) => {
        return (
            <div className="input-group mb-3">
                <input
                    {...field}
                    type="text"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                />
            </div>
        );
    };
    return (
        <>
            <CommonLayout>
                <main>
                    <div>
                        <h1 className="mt-4">Sports Type New</h1>
                        <br/>
                        <div className="card-body">
                            <Formik
                                initialValues={sportsType}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {props => (
                                    <Form>
                                        <div className="table-responsive">
                                            <Field
                                                type="text"
                                                name="firstName"
                                                placeholder="First Name"
                                                fieldName={"firstName"}
                                                options={memberList || []}
                                                handleChangeSection={handleChangeSection}
                                                component={SelectDropdown}
                                            />
                                            <Field
                                                type="text"
                                                name="message"
                                                placeholder="message"
                                                component={TextField}
                                            />

                                            <button
                                                type="submit"
                                                className="btn btn-lg btn-primary float-right"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default DirectMessage;
