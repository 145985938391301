import {Field, Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import CommonLayout from "../../layout/commonLayout";
import {toast} from "react-toastify";
import * as EvaluationInfoApi from "../../../api/EvaluationInfoApi";
import FormTitle from "../../Typhography/formTitle";
import * as LinkFactory from "../../../util/LinkFactory";
import DatePicker from "react-datepicker";
import {useNavigate} from "react-router-dom";


function EvaluationInfo (props) {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [evaluationInfo, setEvaluationInfo] = useState({
        startDateTime: "",
        endDateTime: "",
        evaluationStart: false
    });
    const [disable, setDisable] = React.useState(false);

    const notifyValidation = () => toast.success("All fields required", {
        position: toast.POSITION.TOP_CENTER
    });
    const notifySuccess = () => toast.success("Evaluation question Created!", {
        position: toast.POSITION.TOP_RIGHT
    });
    const handleSubmit = value => {
        setDisable(true);
        if((null == value.startDateTime || '' === value.startDateTime) && (null == value.startDateTime || '' === value.startDateTime)) {
            notifyValidation();
            setDisable(false);
        } else {
            if(null == value.id) {
                EvaluationInfoApi.save(value).then(res => {
                    notifySuccess();
                    handleList();
                });
            } else {
                EvaluationInfoApi.update(value).then(res => {
                    notifySuccess();
                    handleList();
                });
            }
        }
    }

    const DatePickerComponent = ({field, form: {touched, errors, setFieldValue}}) => {
        return (
            <div className="mr-2">
                <DatePicker
                    showTimeInput2
                    selected={Date.parse(field.value)}
                    placeholderText={field.placeholder}
                    onChange={value => setFieldValue(field.name, value)}
                    className="form-control btn-block w-100 overflow-auto "
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                />
            </div>
        );
    };

    const handleList = () => {
        navigateTo(LinkFactory.evaluationInfoList);
    };

    const getEvaluationInfoById = id => {
        EvaluationInfoApi.getEvaluationInfoById(id).then(res=> {
            setEvaluationInfo(res.data);
        });
    }

    function handleCheckBox(event) {
        console.log(event?.target?.checked);
        var ev = evaluationInfo;
        ev.evaluationStart = event?.target?.checked;
        setEvaluationInfo(ev);
    }

    useEffect(()=> {
        console.log(props?.match?.params?.id);
        if (props?.match?.params?.id) {
            getEvaluationInfoById(props?.match?.params?.id)
        }
    }, [])

    return (
        <>
            <CommonLayout headerName="Evaluation Info">
                <main>
                    <div>
                        <h3 className="mt-4 mb-4">Evaluation Info</h3>
                        <div>
                            <Formik
                                initialValues={evaluationInfo}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                            >
                                {({props, values, setFieldValue}) => (
                                    <Form>

                                        <div className="form-row">
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <label>Start Date</label>
                                                    <Field
                                                        name="startDateTime"
                                                        placeholder="Start Date"
                                                        component={DatePickerComponent}
                                                    />
                                                    <div className="form-group">
                                                        <FormTitle>Start Evaluation</FormTitle>
                                                        <Field
                                                            type="checkbox"
                                                            name="evaluationStart"
                                                            style={{
                                                                marginLeft: 14,
                                                                marginTop: 12,
                                                                alignItems: "center",
                                                                fontSize: "36px",
                                                                size: "36px"
                                                            }}
                                                            // component={CheckBoxComponent}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 px-3">
                                                <div className="form-group input-default-white">
                                                    <label>End Date</label>
                                                    <Field
                                                        name="endDateTime"
                                                        placeholder="End Date"
                                                        component={DatePickerComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group input-default-white">
                                                    <label> &nbsp;</label>
                                                    <button
                                                        type="submit"
                                                        disabled={disable}
                                                        className="btn btn-dark radius-25 px-4 d-block">
                                                        <i className="fa fa-bookmark mr-2"/>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </>
    );
}

export default EvaluationInfo;
