import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import Loader from "../common/Loader";
import {isArray} from "lodash";
import * as SemesterApi from "../../api/SemesterApi";
import * as FacultyApi from "../../api/FacultyApi";
import * as DepartmentApi from "../../api/DepartmentApi";
import * as ProgramApi from "../../api/ProgramApi";
import * as PrincipleAttendanceApi from "../../api/PrincipleAttendanceApi";
import {ErrorMessage, Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import TextFieldComponent from "../common/TextFieldComponent";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import * as Utils from "../../util/Utils";
import * as MessageApi from "../../api/MessageApi";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import {TextArea} from "semantic-ui-react";

const StudentsHistory = props => {
    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [resultSearch, setResultSearch] = useState({
        facultyName: {}, departmentName: {}, programName: {}, semester: {}, marks_highest: "", marks: "", resultTypeEnum: ""

    });
    const [studentsData, setStudentsData] = useState(null);
    const [marksType, setMarksType] = useState("Higher");
    const [marks, setMarks] = useState(10);
    const [loading, setLoading] = useState(false);
    const imageBase = process.env.REACT_APP_FILES_URL;
    const [faculty, setFaculty] = useState({});
    const [department, setDepartment] = useState({});
    const [program, setProgram] = useState({});
    const [session, setSession] = useState("");
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [semesterList, setSemesterList] = useState([]);
    const [semester, setSemester] = useState({});
    const [recipientUser, setRecipientUser] = useState({});
    const [messageFormData, setMessageFormData] = useState({message: ""});
    const [messageModalShow, setMessageModalShow] = useState(false);
    const TextAreaComponent = ({field, form: {touched, errors, ...rest}}) => {
        return (
            <div className="input-group mb-3">
                <TextArea
                    {...field}
                    {...rest}
                    type="text"
                    cols="50"
                    className="form-control"
                    aria-label="industry"
                    aria-describedby="basic-addon1"
                    placeholder="Write your message here…"
                />
            </div>
        );
    };

    const handleSemesterSelection = option => {
        setSemester(option)
    };

    useEffect(() => {
        loadFaculty();
        loadAllSemester();

    }, []);


    const setFacultyObj = value => {
        setFaculty(value);
        getDepartmentsByFaculty(value.id);
    }
    const getDepartmentsByFaculty = (facultyId) => {
        DepartmentApi.getAllByFacultyId(facultyId).then(json => {
            setDepartments(json.data);
        });
    }
    const setDepartmentObj = value => {
        setDepartment(value)
        getProgramsByDepartmentId(value.id);
    }
    const getProgramsByDepartmentId = (departmentId) => {
        if (departmentId !== undefined && departmentId !== 0) {
            ProgramApi.getAllByDepartmentId(departmentId).then(json => {
                setPrograms([...json.data, ...[{id: 0, name: "Unassigned"}]]);
            });
        }
    }

    function loadAllSemester() {
        SemesterApi.getAll().then(response => {
            setSemesterList(response.data);
        });
    }

    function loadFaculty() {
        FacultyApi.getAllFaculties().then(json => {
            setFaculties([...json.data, ...[{id: 0, name: "Unassigned"}]]);
        });
    }

    const handleSetHighestMarksType = event => {
        setMarksType(event.target.value);
    };
    const handleMarks = e => {
        e.preventDefault();
        setMarks(e.target.value);
    };

    const handleSearch = values => {
        console.log(values);
        if (values.resultTypeEnum === undefined || values.resultTypeEnum === null || values.resultTypeEnum === "") {
            toast("Please select a result type!");
            return;
        }
        if ((!values.resultTypeEnum === "Score") && values.higherLower === undefined || values.higherLower === null || values.higherLower === "") {
            toast("Please choose more than or less than Radio Button!");
            return;
        }
        const searchParams = {
            score: values.marks,
            higherLower: values.higherLower,
            resultTypeEnum: values.resultTypeEnum,
            facultyId: values.facultyName.id,
            departmentId: values.departmentName.id,
            programId: values.programName.id,
            semesterId: values.semester.id
        }
        console.log(searchParams);
        PrincipleAttendanceApi.getStatisticsForPrinciple(searchParams)
            .then(res => {
                setStudentsData(res.response.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    console.log(studentsData, "s");

    function goBack() {
        navigateTo(-1)
    }

    function handleMessagingPopup(recipientStudentUser) {
        setRecipientUser(recipientStudentUser);
        setMessageModalShow(true);
    }

    function handleSendMessage(value) {
        var receiver = [];
        receiver.push(recipientUser);
        let directGroupMessageThreadDTO = {
            sender: Utils.getUserFromLocalStorage(), message: value.message, receiverList: receiver
        };
        MessageApi.sendGroupMessage(directGroupMessageThreadDTO).then(data => {
            setMessageModalShow(false);
            Swal.fire("Sent", "Message sent to " + recipientUser.firstName, "success");
        });
    }

    function MessageModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="result-statistics"
            >
                <Modal.Body>
                    <Formik
                        initialValues={messageFormData}
                        enableReinitialize={true}
                        onSubmit={handleSendMessage}>
                        {props => (
                            <Form>
                                <div className="input-default">
                                    <Field
                                        type="text"
                                        name="message"
                                        placeholder="message"
                                        component={TextAreaComponent}
                                    />
                                    <div className="message-modal-buttons ">
                                        <button
                                            type="submit"
                                            className="btn btn-success">
                                            <i className="fa fa-send mr-1"/>
                                            Send
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-cancel ml-3"
                                            onClick={() => setMessageModalShow(false)}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal>
        );
    }

    return (<CommonLayout headerName={"Result Statistics"}>
        <main>
            <MessageModal
                show={messageModalShow}
                onHide={() => setMessageModalShow(false)}
            />
            <div className="card-default result-statistics container-responsive">
                <div className="title ">
                    <div className="mb-0 d-flex align-items-center">
                        <div className="card-header p-0 m-0 mr-3 bg-c-light-grey">
                            <a onClick={goBack} className="pl-0 back-button">
                                <i className="fa fa-chevron-left"> </i> </a>
                        </div>

                        <p>Result Statistics</p>
                    </div>
                </div>

                <div className="result-dropdown-area">
                    <div>
                        <Formik
                            initialValues={resultSearch}
                            enableReinitialize={true}
                            onSubmit={handleSearch}>
                            {({props, values, setFieldValue}) => {
                                return (<Form>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Faculty{" "}
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="facultyName"
                                                    placeholder={"Select Faculty"}
                                                    fieldName={"name"}
                                                    label="facultyName"
                                                    handleChangeSection={(fieldName, value) => {
                                                        setFacultyObj(value)
                                                    }}
                                                    required
                                                    options={faculties || []}
                                                    component={SelectDropdown}
                                                />
                                                <ErrorMessage
                                                    name="facultyName"/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Department{" "}
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="departmentName"
                                                    placeholder={"Select Department"}
                                                    fieldName={"name"}
                                                    label="departmentName"
                                                    handleChangeSection={(fieldName, value) => {

                                                        setDepartmentObj(value)
                                                    }}

                                                    options={departments || []}
                                                    component={SelectDropdown}
                                                />
                                                <ErrorMessage
                                                    name="departmentName"/>

                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Program{" "}
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="programName"
                                                    placeholder={"Select Program"}
                                                    fieldName={"name"}
                                                    label="departmentName"
                                                    handleChangeSection={(fieldName, value) => {

                                                        setProgram(value)
                                                    }

                                                    }
                                                    options={programs || []}
                                                    component={SelectDropdown}
                                                />
                                                <ErrorMessage
                                                    name="programName"/>

                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    Course
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="programName"
                                                    placeholder={"Type total GPA"}
                                                    fieldName={"name"}
                                                    label="CourseGPA"
                                                    component={SelectDropdown}
                                                />
                                                <ErrorMessage
                                                    name="courseName"/>

                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label
                                                    className="font-weight-medium text-gray-25 text-lg-left mb-2">
                                                    {" "}
                                                    Semester{" "}
                                                </label>
                                                {/*<p>Semester</p>*/}
                                                <Field
                                                    type="text"
                                                    name="semester"
                                                    placeholder={"Select Semester"}
                                                    fieldName={"semesterName"}
                                                    label="semester"
                                                    handleChangeSection={(fieldName, value) => {
                                                        handleSemesterSelection(value)
                                                    }}
                                                    required
                                                    options={semesterList || []}
                                                    component={SelectDropdown}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-2 input-secondary">
                                            <label className="font-weight-medium text-gray-25 text-lg-left mb-2 d-flex align-items-center">Result Type</label>
                                            <div>
                                                <Field
                                                    type="radio"
                                                    name="resultTypeEnum"
                                                    label="resultTypeEnum"
                                                    value="Score"
                                                    id="Score"
                                                />
                                                <label htmlFor="Score">Score </label>
                                            </div>
                                            <div className="mr-4">
                                                <Field
                                                    type="radio"
                                                    name="resultTypeEnum"
                                                    label="resultTypeEnum"
                                                    value="SGPA"
                                                    id="SGPA"
                                                />
                                                <label htmlFor="SGPA">SGPA </label>
                                            </div>
                                            <div className="mr-4">
                                                <Field
                                                    type="radio"
                                                    name="resultTypeEnum"
                                                    label="resultTypeEnum"
                                                    value="CGPA"
                                                    id="CGPA"
                                                />
                                                <label htmlFor="CGPA">CGPA </label>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group input-default-white">
                                                <label>Value</label>
                                                <Field
                                                    className="py-2 form-control"
                                                    min="0"
                                                    type="number"
                                                    name="marks"
                                                    placeholder="Score"
                                                    component={TextFieldComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-2 result-check-box input-secondary d-flex">
                                            {/*<label className="font-weight-medium text-gray-25 text-lg-left mb-2 d-flex align-items-center">Higher / Lower than score</label>*/}
                                            <div className="mr-4">
                                                <Field
                                                    type="radio"
                                                    name="higherLower"
                                                    label="semester"
                                                    value="Higher"
                                                    id="higher"
                                                />
                                                <label htmlFor="higher">More than </label>
                                            </div>
                                            <div className="mr-4">
                                                <Field
                                                    type="radio"
                                                    name="higherLower"
                                                    label="semester"
                                                    value="Lower"
                                                    id="lower"
                                                />
                                                <label htmlFor="Lower">Less than </label>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="form-group input-default-white">
                                                <label className="d-block">&nbsp;</label>
                                                <button
                                                    type="submit"
                                                    className="btn btn-dark radius-25">
                                                    <i className="fa fa-search mr-1"/>
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>)
                            }}</Formik>
                    </div>
                </div>

                <div className="min-vh-100">
                    <div className="">
                        {loading ? (<div className="container">
                            <Loader/>
                        </div>) : (<div className="result-area">
                            {!loading && studentsData && isArray(studentsData) ? (<div className="table-responsive table-light mt-4">
                                <table
                                    className="table table-light table-hover"
                                    id="dataTable"
                                    width="90%"
                                    cellSpacing="0"
                                >
                                    <thead>
                                    <tr>
                                        {/*<th>#</th>*/}
                                        <th>Student Name</th>
                                        <th>Course Name</th>
                                        <th>Teacher's Name</th>
                                        <th>Result</th>
                                        <th>Message</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {!loading && studentsData && isArray(studentsData) ? (studentsData.map((student, index) => (

                                        <tr>
                                            {/*<td>{index + 1}</td>*/}
                                            <td>
                                                <img
                                                    alt={"Student Picture"}
                                                    src={student.studentUser.imageUrl ? imageBase + student.studentUser.imageUrl : "img/maleStudent.svg"}
                                                    style={{
                                                        width: "35px", height: "35px", objectFit: "cover"
                                                    }}
                                                    className="rounded-circle p-1"
                                                />
                                                {student.studentUser?.firstName || ""} {student.studentUser?.lastName || ""}
                                            </td>
                                            <td>{student?.channel?.channelName || ""}{" "}</td>
                                            <td>{student?.teacherName || ""}{" "}</td>
                                            <td>{student.obtainedScore} / {student.fullScore}</td>
                                            <td onClick={() => {
                                                handleMessagingPopup(student?.studentUser)
                                            }}>
                                                <div className='box-icon bg-c-blue-light'>
                                                    <svg width="15" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M1.9 1.4h11.2v8.4H2.719l-.819.819V1.4Zm0-1.4C1.13 0 .507.63.507 1.4L.5 14l2.8-2.8h9.8c.77 0 1.4-.63 1.4-1.4V1.4c0-.77-.63-1.4-1.4-1.4H1.9Zm1.4 7h8.4v1.4H3.3V7Zm0-2.1h8.4v1.4H3.3V4.9Zm0-2.1h8.4v1.4H3.3V2.8Z"
                                                            fill="#4575EE"/>
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>


                                    ))) : (<div>No student found </div>)}
                                    </tbody>
                                </table>

                            </div>) : <div>
                                <div className="body-water-mark">
                                    <i className="fa fa-search"/>
                                    <h5 className="mb-0 py-3 text-opacity-25">
                                        Your search result will
                                        appear here, please select your desired filters
                                    </h5>
                                </div>
                            </div>}
                        </div>)}
                    </div>
                </div>
            </div>

        </main>
    </CommonLayout>);
};

export default StudentsHistory;
